export const ProspectorTableCell = (props: { cell: any }) => {
    interface ElementListProps {
        elements: string[];
      }
    const companyLogoObjectData = ["companyLogo"];
    const businessLocationsObjectData = ["businessLocations"];
    const ArrayData = ["alternateBusinessNames", "phoneNumber", "socialProfiles"];
    const ArrayDataFlag = ArrayData.indexOf(props.cell.column.id) !== -1;
    const businessLocations = businessLocationsObjectData.indexOf(props.cell.column.id) !== -1;
    const companyLogo = companyLogoObjectData.indexOf(props.cell.column.id) !== -1;
    const isWebsite = props.cell.column.id === "companyWebiste";
    const companyLogoImage = () => {
        if (companyLogoObjectData.indexOf(props.cell.column.id) !== -1) {
            if (props.cell.value.length > 0) {
                return (
                    <div className="prospector-logo-image"><img alt={""} src={props.cell.value[0]["url"] ? props.cell.value[0]["url"] : (props.cell.value.length > 1 ? props.cell.value[1]["url"] : "NA")}></img></div>

                )
            }
        }
    };
    const BusinessLocationRender = () => {
        if (businessLocationsObjectData.indexOf(props.cell.column.id) !== -1) {
            if (props.cell.value) {
                return (
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                    <li style={{ display: 'inline-block', marginRight: '10px' }}>
                        <div>{props.cell.value["address"]}</div>
                        <div>{props.cell.value["latitude"]?`latitude:${props.cell.value["latitude"]}`:""}</div>
                        <div>{props.cell.value["longitude"]?`longitude:${props.cell.value["longitude"]}`:""}</div>
                    </li>
                </ul>

                )
            }
        }
    };
    const ElementList: React.FC<ElementListProps> = ({ elements }) => (
    <ul style={{ listStyleType: 'none', padding: 0 }}>
        {elements.map((element, index) => (
        <li key={index} style={{ display: 'inline-block', marginRight: '10px' }}>
            {element}
        </li>
        ))}
    </ul>
    );
    const genericRenderingElements = [companyLogo,businessLocations,isWebsite,ArrayDataFlag]

    return (<td {...props.cell.getCellProps()} className="prospector-table-cell">
        {genericRenderingElements.every(element => element === false) &&  props.cell.value}
        {companyLogo && companyLogoImage()}
        {businessLocations && BusinessLocationRender()}
        {ArrayDataFlag && <ElementList elements={props.cell.value}></ElementList>}
        {isWebsite && <a href={`${props.cell.value}`}>{props.cell.value}</a>}
    </td>)
}