import React, { useCallback, useEffect, useState } from "react";
import "../bulkEnrich/BulkEnrich.css";
import { Button, Card, NavTab, NavTabItem, Snackbar, Breadcrumbs, BreadcrumbItem, Spinner, Robot } from "@cimpress/react-components/";
import CSVIcon from "../../assets/csv-icon.svg";
import iButton from "../../assets/i-button.svg";
import { SingleFileUpload } from "./singleFileUpload";
import { ENRICH_HISTORY_TAB, NEW_ENRICH_TAB, enrichment_template } from "../../config";
import { EnrichHistory } from "./enrichHistory";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import EnrichmentTemplate from "../../assets/files/enrichment_template_bulk_enrichment.csv"
import { EnrichmentHistoryData, bulkEnrichmentTrigger, setUploadError, TenantRecordStatus, setIsFilteredData, setFilteredData, setFromDate, setToDate, setEnrichProgress, setEnrichStatus, setisFilePresent, setFiles} from "../../features/bulkEnrichment/bulkEnrichmentSlice";
import { useAppDispatch, useAppSelector } from "../../reduxHooks";
import { AuthContext } from "../../context/authContext";
import { TenantDropDown } from "./tenantDropDown";
import { getGroups } from "../../features/view/viewSlice";
import { NotShowingLoadingTime, historyTablePollingTime } from "../../utils/commonUtility";

export const BulkEnrich = () => {
  const [selectedTab, setSelectedTab] = useState(NEW_ENRICH_TAB);
  const { total_records, error, enrichHistoryData, isLoading, sheetValidationErrors, isFilePresent, selectedTenant, files  } = useAppSelector((state) => state.bulkEnrichment);
  const [enrichStatusLoading,setEnrichStatusLoading] = useState(false);
  const [isUploadSection, setIsUploadSection] = useState(true);
  const [fileSentStatus, setSentFileStatus] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const { profile } = React.useContext(AuthContext);
  const [triggerLoading, setTriggerLoading] = useState(false);
  const emailId = profile?.email || "";
  const canonicalId = profile?.["https://claims.cimpress.io/canonical_id"];
	const userId = canonicalId ? canonicalId : emailId;
  const user_name = profile?.name || "";
  const user_tenant = selectedTenant ? selectedTenant : "test"
  const total_number_records = total_records;
  const dispatch = useAppDispatch();
  const handleTemplateDownload = () => {
    const link = document.createElement('a');
    link.href = EnrichmentTemplate;
    link.download = enrichment_template;
    link.click();
  };

  useEffect(() => {
    dispatch(EnrichmentHistoryData({ user: emailId }));
    dispatch(TenantRecordStatus({ tenant: user_tenant }));
    
    const checkAdminUser = async () => {
      try {
        if (userId) {
          const resultAction = await dispatch(getGroups({ userId }));
          if (getGroups.fulfilled.match(resultAction)) {
            const hasAdminAccess = resultAction.payload.includes("Evo portal admins") || 
                                   resultAction.payload.includes("Evo portal stage");
            setIsAdmin(hasAdminAccess);
          }
        }
      } catch (error) {
        console.error('Error fetching groups:', error);
      }
    };
    checkAdminUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  
  useEffect(() => {
    let intervalId: null | NodeJS.Timer = null;
    const pollEnrichmentData = () => {
      setEnrichStatusLoading(true);
      setTimeout(() => {
        setEnrichStatusLoading(false);
      }, NotShowingLoadingTime);
      dispatch(EnrichmentHistoryData({ user: emailId }));
    };
    const shouldNotPoll = () => {
      return enrichHistoryData?.user_data?.every(
        (record) => record.total_records === record.processed_records
      );
    };
    if (enrichHistoryData && !shouldNotPoll()) {
      intervalId = setInterval(() => {
        pollEnrichmentData();
      }, historyTablePollingTime);
    };
    if (enrichHistoryData && shouldNotPoll()) {
      intervalId && clearInterval(intervalId);
    }
    return () => {
      intervalId && clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[emailId, enrichHistoryData])
  
  const handleStartEnrichment = async () => {
    setTriggerLoading(true);
    const formData = new FormData();
    formData.append('file', files[0].file);
    formData.append('user_email', emailId);
    formData.append('user_name', user_name);
    formData.append('user_tenant', user_tenant);
    formData.append('total_records', total_number_records.toString());

    try {
      const response = await dispatch(bulkEnrichmentTrigger({ formData }));
      if (response.meta.requestStatus === "fulfilled") {
        setIsUploadSection(false);
        setTimeout(() => {
          setIsUploadSection(true);
        }, 30000);
        dispatch(setFiles([]));
        dispatch(setisFilePresent(false));
        setSentFileStatus(true);
        dispatch(EnrichmentHistoryData({ user: emailId }));
      }
      else {
        dispatch(setFiles([]));
        dispatch(setisFilePresent(false));
        dispatch(setUploadError("Failed to upload the file, Try again."));
      }
    } catch (error) {
      console.error('Error in bulk enrichment:', error);
      dispatch(setUploadError("Failed to upload the file, Try again."));
    }finally {
      setTriggerLoading(false)
  }
  };
  const newEnrichTabContent = (
    <>
      <div className="bulk-enrich-card">
        {isLoading === "loading" && !enrichStatusLoading && <div className="after-upload"><Spinner></Spinner></div>}
        {(isLoading === "succeeded" || enrichStatusLoading) && <Card isMinimal={true}>
          <div className="accepted-file-container">
            <div className="accepted-file-non-info-container">
              <img src={CSVIcon} alt="CSV logo" className="csv-logo" />
              <div className="csv-template">
                <div className="csv-header">CSV Template</div>
                <div className="csv-elements">
                Download the template & enter data to be enriched in the same format.
                </div>
                <Button variant="primary" className="csv-elements" onClick={handleTemplateDownload}>
                  Download
                </Button>
              </div>
              {isAdmin && (
                <div className="tenant-dropdown-wrapper">
                  <TenantDropDown />
                </div>
              )}
              <>

              {(
                <div style={{ marginTop: "80px" }}>
                  {((((enrichHistoryData?.user_data?.every(record => record.processed_records === record.total_records)) || enrichHistoryData?.user_data?.length === 0 ) && isUploadSection) || !('user_data' in enrichHistoryData)) && <SingleFileUpload
                    setFilePresent={setisFilePresent}
                    isFilePresent={isFilePresent}
                  ></SingleFileUpload>}
                  {(isFilePresent &&  !sheetValidationErrors) &&(
                    <Button
                      variant="primary"
                      style={{ marginLeft: "15vw", marginTop: "2vh",width:"11vw" }}
                      onClick={handleStartEnrichment}
                      disabled={triggerLoading ? true : false}
                    >
                      {triggerLoading ? "Uploading....." : "Start Enrichment"}
                    </Button>

                  )}
                  {
                    (((!(enrichHistoryData?.user_data?.every(record => record.processed_records === record.total_records)) && enrichHistoryData?.user_data?.length !== 0) || !isUploadSection) && ('user_data' in enrichHistoryData)) && <div className="after-upload">
                      <div>To check the progress of enrichment, please go to the enrichment history tab.</div>
                      <div>You may upload another file once status for the current enrichment changes to Processing</div>
                    </div>
                  }
                </div>)}</>
              
            </div>
            <div className="description-card">
              <Card isMinimal={true}>
                <img src={iButton} alt="info-button" onClick={() => {}}></img>
                <div className="decsription-header">How to bulk enrich data?</div>
                <div>
                  1. Please make sure that column names in uploaded file are same as the template.
                </div>
                <div>2. Upload the CSV file & click on "Start Enrichment"</div>
                <div style={{ marginTop: "2vh" }}>
                  <ul>
                    <li>
                    The # of records to be enriched in a day per Business is limited to 5000.
                    </li>
                    <li>
                    One file can contain a maximum of 5000 records (In case a file with 2000 records is uploaded, please wait for it to get enriched & then upload the next file).
                    </li>
                    <li>The Columns Customer Number or Customer ID, with either Email or Company Name and Country Code are mandatory.</li>
                    <li>Country Code must be in ISO 2 code format.</li>
                    <li>Records that have been overridden through Enrichment Validation & Override Portal, would not be enriched.</li>
                  </ul>
                </div>
              </Card>
              <Card className="description-footer" variant="layout">
              Enrichment can take upto 3 hours depending on the number of records in a file. You can safely close the application once file is uploaded & track progress under Enrichment History tab.
              </Card>
            </div>
          </div>
          {
            <Snackbar show={fileSentStatus} delay={5000} status="success">
              <div className="csv-template-container"><div>Enrichment in progress. Check the Enrichment History tab for status updates. </div>
              </div>

            </Snackbar>
          }
          {
            <Snackbar show={error ? true : false} delay={5000} status="danger">
              {error}
            </Snackbar>
          }
        </Card>
        }
      </div>
      {isLoading === "idle" && <div className="no-data">
        <Robot status={"danger"}></Robot>
        <h2>Something went wrong, Please try again later.</h2>
      </div>}
    </>

  );
  const triggerEnrichHistory = () => {
    setSelectedTab(ENRICH_HISTORY_TAB);
    setSentFileStatus(false);
    dispatch(setIsFilteredData(false));
    dispatch(setFilteredData([]));
    dispatch(setFromDate(null));
    dispatch(setToDate(null));
    dispatch(EnrichmentHistoryData({ user: emailId }))
  };
  const triggerNewEnrichmentTab = useCallback(() => {
    setSelectedTab(NEW_ENRICH_TAB);
    dispatch(EnrichmentHistoryData({ user: emailId }));
    dispatch(setEnrichProgress({
      created_at: "",
      enrichment_id: "",
      file_name: "",
      processed_records: 0,
      tenant: "",
      total_records: 0,
      user_email: "",
      user_name: "",
      enriched_count: 0,
      non_enriched_count: 0,
    }));
    dispatch(setEnrichStatus("unknown"));
  }, [dispatch, emailId])

  const enrichHistoryTabContent =
    <div><EnrichHistory></EnrichHistory>
      {isLoading === "idle" && <div className="no-data">
        <Robot status={"danger"}></Robot>
        <h2>Something went wrong, Please try again later.</h2>
      </div>}
    </div>;

  const tabContent =
    selectedTab === NEW_ENRICH_TAB
      ? newEnrichTabContent
      : enrichHistoryTabContent;
  return (
    <div className="bulk-enrich-container">
      <Breadcrumbs>
        <BreadcrumbItem>
          <Button variant="anchor" href="/home">Home</Button>
        </BreadcrumbItem>
        <BreadcrumbItem active>Bulk Enrichment</BreadcrumbItem>
      </Breadcrumbs>
      <div className="bulk-enrich">
        <h2>Bulk Enrichment</h2>
      </div>
      <div className="bulk-enrich-nav-tab">
        <NavTab>
          <NavTabItem active={selectedTab === NEW_ENRICH_TAB}>
            <button onClick={triggerNewEnrichmentTab}>
              <h5>New Enrichment</h5>
            </button>
          </NavTabItem>
          <NavTabItem active={selectedTab === ENRICH_HISTORY_TAB}>
            <button onClick={triggerEnrichHistory}>
              <h5>Enrichment History</h5>
            </button>
          </NavTabItem>
        </NavTab>
      </div>
      <div>{tabContent}</div>
    </div>
  );
};
