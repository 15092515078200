import { Button } from '@cimpress/react-components';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import UploadIcon from "../../assets/upload-icon.svg";
import './docIntent.css'
import { IMAGE_ACCEPT_FORMAT } from '../../config';
interface UploadImageProps {
  imageSrc: string | ArrayBuffer | null;
  setImageSrc: (imageSrc: string | ArrayBuffer | null) => void;
  isFilePresent: boolean;
  setIsFilePresent: (isFilePresent: boolean) => void;
  onFileChange: (file: File) => void;

}
export const UploadImage: React.FC<UploadImageProps> = ({ imageSrc,
  setImageSrc,
  isFilePresent,
  setIsFilePresent,
  onFileChange
}) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageSrc(reader.result);
      setIsFilePresent(true);
    };
    if (file) {
      onFileChange(file)
      reader.readAsDataURL(file);
    }
  }, [setImageSrc, setIsFilePresent, onFileChange]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    onDrop,
     accept: IMAGE_ACCEPT_FORMAT,
  });

  return (
    <div>
      <div>{imageSrc && <img className='img-uploaded' src={imageSrc as string} alt="uploaded"></img>}</div>
      <div {...getRootProps()} className={imageSrc ? "" : 'file-uploader-dum'}>
        <input {...getInputProps()} />
        <div className="file-select-button">
          {isDragActive && !isFilePresent ? (
            <p>Drop the file here ...</p>
          ) : (
            !isFilePresent && (
              <div>
                <Button variant="primary">Select File</Button>
              </div>
            )
          )}
        </div>
        {!isFilePresent && (
          <div className="drag-drop">
            <img
              src={UploadIcon}
              alt="Upload File"
              style={{ marginLeft: "3.5vw" }}
            />
            <div>Drag and drop or select file to upload</div>
          </div>
        )}
      </div></div>

  );
}

