import { Button, Checkbox, Modal } from "@cimpress/react-components";
import React, { useMemo } from "react";
import { useRef, useState } from "react";
import { AuthContext } from "../../context/authContext";
import {
	deleteView,
	getViewData,
	getViews,
	setLoader,
	setSnackBar,
	setSnackBarMessage,
	setSnackBarStatus,
	setView,
	shareWithOrganization,
} from "../../features/view/viewSlice";
import { deleteSessionData } from "../../hooks/view-data";
import { useAppDispatch, useAppSelector } from "../../reduxHooks";
import "../../styles/settings.css";
import { DefaultView, DeleteCount } from "../../types";
import { DetectOutsideClick } from "../detectClickOutside";
export function Settings(props: { setSettingOpen: React.Dispatch<React.SetStateAction<boolean>> }) {
	const [isOpen, setOpen] = useState(false);
	const [isChecked, setChecked] = useState(false);
	const { profile } = React.useContext(AuthContext);
	const userId = profile?.email || "";
	const {
		pageSize,
		pageNumber,
		selectedView,
		selectedTable,
		sortColumn,
		unsavedFilters,
		sortOrder,
		viewsList,
		quickFilters,
		filterOP
	} = useAppSelector((state) => state.view);

	const setSnackbars = (message: string, label: string) => {
		dispatch(setSnackBar(true));
		dispatch(setSnackBarMessage(message));
		dispatch(setSnackBarStatus(label));
	};
	const getViewRequestData = () => {
		return {
			currentPage: pageNumber,
			size: pageSize,
			viewId: selectedView ? selectedView.viewId : DefaultView.Default,
			unsavedFilters:
				unsavedFilters.length > 0 ? unsavedFilters : selectedView?.unsavedFilters || [],
			savedFilters: [],
			selectedTable: selectedTable,
			selectedColumns: selectedView?.selectedHeaders || [],
			visibleColumns: selectedView?.selectedHeaders || [],
			sortingColumn: sortColumn,
			sortingOrder: sortOrder,
			userId: userId,
			quickFilters: quickFilters || selectedView?.quickFilters,
			isRefresh: true,
			filterOp: filterOP,
		};
	};

	const viewId = selectedView ? selectedView.viewId : DefaultView.Default;
	const [alreadyshared, setAlreadyShared] = useState(false);
	useMemo(
		() =>
			viewsList.forEach((view) => {
				if (
					view.viewId !== DefaultView.Default &&
					view.viewId === selectedView?.viewId &&
					view.shared
				) {
					setAlreadyShared(true);
					setChecked(true);
				}
			}),
		[viewsList.length, selectedView] // eslint-disable-line react-hooks/exhaustive-deps
	);
	const labelOptionRef = useRef(null);
	const dispatch = useAppDispatch();
	DetectOutsideClick(labelOptionRef, props.setSettingOpen);
	const shareWithOrg = () => {
		if (selectedView?.viewId && selectedView.viewId !== DefaultView.Default) {
			setChecked(true);

			dispatch(setLoader("loading"));
			dispatch(shareWithOrganization({ viewId: selectedView?.viewId })).then((response) => {
				if (userId)
					dispatch(getViews({ userId, tableName: selectedTable })).then((res) => {
						dispatch(setLoader("idle"));
						setSnackbars("View successfully shared with organization", "success");
						props.setSettingOpen(false);
					});
			});
		}
	};
	const refreshPage = () => {
		if (userId) {
			deleteSessionData(DeleteCount.Single, userId, selectedTable, viewId).then((result) => {
				dispatch(getViewData(getViewRequestData())).then((res) => {
					setOpen(false);
					props.setSettingOpen(false);
					setSnackbars("View refreshed successfully ", "success");
				});
			});
		}
	};
	const defaultView = {
		viewId: DefaultView.Default,
		name: DefaultView.Default,
		value: DefaultView.Default,
		label: DefaultView.Default,
		createdAt: undefined,
		savedFilters: [],
		unsavedFilters: [],
		selectedHeaders: [],
		sortColumn: undefined,
		sortOrder: undefined,
		userId: userId,
		visiblColumns: [],
	};
	const removeView = () => {
		if (selectedView?.viewId && selectedView.viewId !== DefaultView.Default) {
			dispatch(setLoader("loading"));
			props.setSettingOpen(false);
			dispatch(deleteView({ viewId: selectedView?.viewId })).then((response) => {
				if (userId) {
					dispatch(getViews({ userId, tableName: selectedTable })).then((res) => {
						dispatch(setLoader("idle"));
						setSnackbars(
							`View ${selectedView?.name} deleted successfully, fetching default view data`,
							"success"
						);
						dispatch(setView({ ...defaultView }));
					});
				}
			});
		}
	};

	return (
		<div
			className="settings-dropdown"
			data-testid="settings-buttton"
			ref={!isOpen ? labelOptionRef : null}
		>
			<div>
				<Button variant="anchor" onClick={refreshPage}>
					Refresh
				</Button>
			</div>
			<div className="org-share-option">
				<Button
					variant="anchor"
					disabled={selectedView?.viewId === DefaultView.Default || alreadyshared}
					style={{
						color: selectedView?.viewId === DefaultView.Default || alreadyshared ? "grey" : "",
						textDecoration:
							selectedView?.viewId === DefaultView.Default || alreadyshared ? "none" : "",
					}}
					onClick={shareWithOrg}
				>
					Share with organization
				</Button>
				<Checkbox
					style={{
						cursor: "not-allowed",
						marginTop: "12px",
						marginLeft: "12px",
					}}
					label={undefined}
					checked={isChecked}
					disabled={selectedView?.viewId === DefaultView.Default || alreadyshared}
					payload={undefined}
					onChange={() => {
						if (!alreadyshared) shareWithOrg();
					}}
				></Checkbox>
			</div>

			<div className="remove-view">
				<Button
					variant="anchor"
					disabled={selectedView?.viewId === DefaultView.Default}
					onClick={() => setOpen(true)}
					style={{
						color: selectedView?.viewId === DefaultView.Default ? "grey" : "",
						textDecoration: selectedView?.viewId === DefaultView.Default ? "none" : "",
					}}
				>
					Remove view
				</Button>

				<Modal
					className="settings-delete-modal"
					status="success"
					show={isOpen}
					onRequestHide={() => {
						setOpen(false);
						props.setSettingOpen(false);
					}}
					title={<div className="delete-popup-header">Delete View</div>}
					closeButton={true}
					footer={
						<div>
							<Button
								style={{ marginRight: "12px" }}
								onClick={() => {
									props.setSettingOpen(false);
									setOpen(false);
								}}
							>
								Cancel
							</Button>
							<Button
								style={{
									marginRight: "12px",
									backgroundColor: "#D24A35",
									border: "1px solid #D24A35",
								}}
								variant="primary"
								onClick={removeView}
							>
								Delete
							</Button>
						</div>
					}
				>
					<div className="delete-popup-text">
						Are you sure you want to delete the view {selectedView?.name} ?
					</div>
				</Modal>
			</div>
		</div>
	);
}
