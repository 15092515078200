import "../../styles/table.css";

import { TableCell } from "./tableCell";

export function TableRow(props: { row: any; rowIndex: number }) {
  const { row, rowIndex } = props;

  return (
    <tr className="table-row" {...row.getRowProps()} id={`${rowIndex}`}>
      {row.cells.map((cell: any, cellIndex: number) => {
        return (
          <TableCell
            cell={cell}
            cellIndex={cellIndex}
            row={row}
            rowIndex={rowIndex}
          />
        );
      })}
    </tr>
  );
}
