import React from "react";
import { startCase } from "lodash";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useTable, useColumnOrder, useResizeColumns} from "react-table";
import { useSticky } from "react-table-sticky";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useFlexLayout } from "react-table";
import TableBody from "./tableBody";
import { TableHeader } from "./tableHeader";
import { useAppSelector, useAppDispatch } from "../../reduxHooks";
import "../../styles/table.css";
import { IRow, IStatus } from "../../types";
import { notEditableColumns } from "../../config";
import { setStatusArr } from "../../features/view/viewSlice";
import { Robot } from "@cimpress/react-components/";


//Function to create rows for useTable hook and creating status array to track status of each column in row
const fetchRow = (row: IRow[], statuses: IStatus[][], visibleColumns: string[]) => {
	let rowObj = {};
	const arr: IStatus[] = [];
	row.forEach((cell) => {
		const { status, commented, overwritten, ...rest } = cell;
		const key = Object.keys(rest)[0];
		if (visibleColumns.indexOf(key) !== -1) {
			arr.push({ cell: key, status: status, commented, overwritten });
		}
		rowObj = { ...rowObj, ...rest };
	});
	statuses.push(arr);
	return rowObj;
};

export function TableContainer() {
	const columnOrder = notEditableColumns;
	const tableHeaders = useAppSelector((state) => state.view.selectedHeaders);
	const { visibleColumns, unsavedFilters, apiError } = useAppSelector((state) => state.view);
	const isLoading = useAppSelector((state) => state.view.isLoading);
	const dispatch = useAppDispatch();
	const rowData = useAppSelector((state) => state.view.rows);
	const data = React.useMemo(() => {
		const statuses: IStatus[][] = [];
		const row = rowData.map((row) => fetchRow(row, statuses, visibleColumns));
		dispatch(setStatusArr(statuses));
		return row;
	}, [rowData]); // eslint-disable-line react-hooks/exhaustive-deps
	const stickyColumns = ['COMPANY_NAME','CUSTOMER_COMPANY_NAME']
	const renderVisible = React.useMemo(
		() =>
			visibleColumns.map((header) => {
				return {
					Header: startCase(header),
					accessor: header,
					sticky: stickyColumns.indexOf(header) !==-1 ? 'left' : undefined,
				};
			}),
		[visibleColumns] // eslint-disable-line react-hooks/exhaustive-deps
	);
	const renderTableHeaders = React.useMemo(
		() =>
			tableHeaders.map((header) => ({
					Header: startCase(header),
					accessor: header,
					sticky: stickyColumns.indexOf(header) !==-1 ? 'left' : undefined,
			})),
		[tableHeaders] // eslint-disable-line react-hooks/exhaustive-deps
	);
	const columns = renderVisible.length ? renderVisible : renderTableHeaders;
	const tableInstance = useTable(
		{
			columns,
			data,
			initialState: { columnOrder },
		},
		useResizeColumns,
		useFlexLayout,
		useColumnOrder,
		useSticky
	);

	const { headerGroups, getTableBodyProps, rows, state, prepareRow } = tableInstance;
	const isColumnResizing = !!state.columnResizing.isResizingColumn;
	const noDataMessage =
		apiError.length > 0
			? apiError
			: unsavedFilters.length > 0
			? "No matching records found"
			: "No Data Found";

	return (
		<div className="table-wrapper">
			{data.length === 0 && isLoading !== "loading" ? (
				<div className="no-data-found">
					<div><Robot status="danger"></Robot> </div>
					<div>{noDataMessage}</div>
				</div>
			) : (
				<table className="table-class" id="scroll-table">
					<TableHeader headerGroups={headerGroups} />

					<TableBody
						rows={rows}
						getTableBodyProps={getTableBodyProps}
						prepareRow={prepareRow}
						isColumnResizing={isColumnResizing}
					/>
				</table>
			)}
		</div>
	);
}
