// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ExclamationIcon from "../../assets/exclamation-circle.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import StopCirlce from "../../assets/stop-circle.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CorrectIcon from "../../assets/check-circle-solid.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import QuestionIcon from "../../assets/question-circle.svg";
import "../../styles/table.css";
import { useState } from "react";
import "../../styles/editicons.css";

import { LabelOptions } from "./labelOptions";
import { Status } from "../../types";
import { ToolTipComp } from "../Hocs/toolTip";

export function EditIcons(props: {
	rowIndexes?: number[];
	identifierId?: string;
	columnName: string;
	columnStatus: string;
	setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const message = "Click to open status options";
	const [y, setY] = useState(0);
	const [totalHeight, setTotalHeight] = useState(0);
	const { rowIndexes, columnName, columnStatus } = props;
	const [isDropDown, setIsDropDown] = useState<boolean>(false);
	const NotSure = (
		<img
			data-testid="validation-label-icon"
			alt=""
			src={QuestionIcon}
			onClick={(e: any) => {
				setY(e.clientY);
				setTotalHeight(e.view.innerHeight);
				setIsDropDown(!isDropDown);
			}}
		/>
	);
	const NotVerified = (
		<img
			data-testid="validation-label-icon"
			alt=""
			src={StopCirlce}
			onClick={(e: any) => {
				setY(e.clientY);
				setTotalHeight(e.view.innerHeight);
				setIsDropDown(!isDropDown);
			}}
		/>
	);
	const Incorrect = (
		<img
			data-testid="validation-label-icon"
			alt=""
			src={ExclamationIcon}
			onClick={(e: any) => {
				setY(e.clientY);
				setTotalHeight(e.view.innerHeight);
				setIsDropDown(!isDropDown);
			}}
		/>
	);
	const Correct = (
		<img
			data-testid="validation-label-icon"
			alt=""
			src={CorrectIcon}
			onClick={() => setIsDropDown(!isDropDown)}
		/>
	);
	return (
		<>
			<div
				className={columnStatus !== "notVerified" ? "visible-validate-icon" : "hide"}
				data-testid={columnStatus !== "notVerified" ? "visible-validate-icon" : "hide"}
			>
				{columnStatus === Status.NotVerified && ToolTipComp({ children: NotVerified, message })}
				{columnStatus === Status.NotSure && ToolTipComp({ children: NotSure, message })}
				{columnStatus === Status.Incorrect && ToolTipComp({ children: Incorrect, message })}
				{columnStatus === Status.Correct && ToolTipComp({ children: Correct, message })}
			</div>
			{isDropDown && (
				<LabelOptions
					y={y}
					totalHeight={totalHeight}
					currentStatus={columnStatus}
					position="bottom"
					rowIndexes={rowIndexes}
					identifierId={props.identifierId}
					columnNames={[columnName]}
					setIsDropDown={setIsDropDown}
					isDropDown={isDropDown}
					setLoading={props.setLoading}
				/>
			)}
		</>
	);
}
