import { useAppDispatch, useAppSelector } from "../../reduxHooks";
import { cloneDeep } from "lodash";
import "../../styles/editicons.css";
import { DefaultView, IRow, Status } from "../../types";
import {
	setAllLoading,
	setLoadingEmployeeRows,
	setLoadingIndustryRows,
	setLoadingWebsiteRows,
	setMetaData,
	setRows,
	setSnackBar,
	setSnackBarMessage,
	setSnackBarStatus,
} from "../../features/view/viewSlice";
import { updateRecordLabel } from "../../features/view/viewSlice";
import React, { useRef } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as ExclamationIcon } from "../../assets/exclamation-circle.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as StopCirlce } from "../../assets/stop-circle.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as CorrectIcon } from "../../assets/check-circle-solid.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as QuestionIcon } from "../../assets/question-circle.svg";
import { DetectOutsideClick } from "../detectClickOutside";
import { AuthContext } from "../../context/authContext";
import { postSessionData } from "../../hooks/view-data";
import _ from "lodash";
import { getIdentifierIds } from "../../utils/commonUtility";
import { Buffer } from "buffer";

export function LabelOptions(props: {
	identifierId?: string;
	y?: number;
	totalHeight?: number;
	setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
	currentStatus?: string;
	rowIndexes?: number[];
	columnNames: string[];
	setIsDropDown: React.Dispatch<React.SetStateAction<boolean>>;
	isDropDown: boolean;
	position: string;
}) {
	const { rowIndexes, columnNames } = props;
	const { profile } = React.useContext(AuthContext);
	const email = profile?.email || "";
	const rowData = useAppSelector((state) => state.view.rows);
	const {
		selectedView,
		pageSize,
		pageNumber,
		savedFilters,
		unsavedFilters,
		selectedHeaders,
		visibleColumns,
		sortColumn,
		sortOrder,
		metaData,
	} = useAppSelector((state) => state.view);
	const viewName = selectedView?.name;
	const selectedTable = useAppSelector((state) => state.view.selectedTable);
	const clonedRow: IRow[][] = cloneDeep(rowData);
	const selectedRows = clonedRow.filter(
		(value, index) => rowIndexes && rowIndexes.indexOf(index) !== -1
	);
	const dispatch = useAppDispatch();
	const setLoadingRows = () => {
		let loadingRows: { [key: string]: boolean } = {};
		if (rowIndexes) {
			let loadingRows: { [key: string]: boolean } = {};
			for (let rowIndex of rowIndexes) {
				loadingRows[rowIndex] = true;
			}
		}
		return loadingRows;
	};

	const setLoadingRowsMap = () => {
		if (columnNames.length === 1 && columnNames[0].toLowerCase() === "ce_industry_l1") {
			const loadingRows = setLoadingRows();
			dispatch(setLoadingIndustryRows(loadingRows));
		} else if (columnNames.length === 1 && columnNames[0].toLowerCase() === "website") {
			const loadingRows = setLoadingRows();
			dispatch(setLoadingWebsiteRows(loadingRows));
		} else if (
			columnNames.length === 1 &&
			columnNames[0].toLowerCase().indexOf("employees") !== -1
		) {
			const loadingRows = setLoadingRows();
			dispatch(setLoadingEmployeeRows(loadingRows));
		}
	};

	const setNoty = (message: string, status: string) => {
		dispatch(setSnackBar(true));
		dispatch(setSnackBarMessage(message));
		dispatch(setSnackBarStatus(status));
	};
	const labelOptionRef = useRef(null);
	DetectOutsideClick(labelOptionRef, props.setIsDropDown);
	const setLabel = (status: string) => {
		selectedRows.forEach((cols) => {
			cols.forEach((obj) => {
				columnNames.forEach((colName) => {
					if (colName in obj) {
						obj["status"] = status;
						return obj;
					}
					return;
				});
			});
		});
		dispatch(setRows(clonedRow));
	};
	const unsetLoadingRows = () => {
		if (columnNames.length === 1 && columnNames[0].toLowerCase() === "ce_industry_l1") {
			dispatch(setLoadingIndustryRows({}));
		} else if (columnNames.length === 1 && columnNames[0].toLowerCase() === "website") {
			dispatch(setLoadingWebsiteRows({}));
		} else if (
			columnNames.length === 1 &&
			columnNames[0].toLowerCase().indexOf("employees") !== -1
		) {
			dispatch(setLoadingEmployeeRows({}));
		}
	};
	const unsetLoaders = () => {
		if (props.setLoading) {
			props.setLoading(false);
		}
		if (columnNames.length > 1) {
			dispatch(setAllLoading(false));
		}
		if (!props.currentStatus) {
			unsetLoadingRows();
			// dispatch(setLoadingRows({}));
		}
	};
	const getPostSessionData = () => {
		return {
			currentPage: pageNumber,
			size: pageSize,
			viewId: selectedView ? selectedView.viewId : DefaultView.Default,
			selectedTable,
			userId: email,
			isAction: true,
			savedFilters: savedFilters,
			unsavedFilters: unsavedFilters,
			selectedColumns: selectedHeaders || [],
			visibleColumns: visibleColumns || [],
			sortingColumn: sortColumn,
			sortingOrder: sortOrder,
		};
	};
	const setLoaders = () => {
		if (props.setLoading) props.setLoading(true);

		if (!props.currentStatus) {
			setLoadingRowsMap();
		}
		if (columnNames.length > 1) {
			dispatch(setAllLoading(true));
		}
	};
	const detailedViewValidation = (identifierId: string, status: string) => {
		if (props.setLoading) props.setLoading(true);
		const searchParams = new URLSearchParams(window.location.search);
		const encodedTable = searchParams.get("tableName") || "";
		const existingMetaData = cloneDeep(metaData);
		columnNames.forEach((col) => (existingMetaData.VALIDATION_LABELS[col] = status));
		dispatch(setMetaData(existingMetaData));
		dispatch(
			updateRecordLabel({
				validationIds: [identifierId],
				label: status,
				tableName: Buffer.from(encodedTable, "base64").toString("ascii"),
				targetColumns: columnNames,
				viewName,
				userId: email,
				action: "validate",
			})
		)
			.then((response) => {
				const message =
					columnNames.length > 1
						? `Marked all data points as ${
								status === "notSure" ? "Unable To Specify" : _.startCase(status)
						  }`
						: `Marked ${_.startCase(props.columnNames[0].toLowerCase())} as ${
								status === "notSure" ? "Unable To Specify" : _.startCase(status)
						  }`;
				setNoty(message, "success");
				if (props.setLoading) props.setLoading(false);
				setNoty(message, "success");
			})
			.catch(() => {
				if (props.setLoading) props.setLoading(false);
			});
		props.setIsDropDown(false);
	};

	const setStatus = (status: string) => {
		if (props.identifierId) {
			detailedViewValidation(props.identifierId, status);
			return;
		}
		if (props.currentStatus && props.currentStatus === status) {
			alert(`The field status is already marked ${status} `);
		} else {
			setLabel(status);
			setLoaders();
			const identifierIds = getIdentifierIds(selectedRows);
			dispatch(
				updateRecordLabel({
					validationIds: identifierIds,
					label: status,
					tableName: selectedTable,
					targetColumns: columnNames,
					viewName,
					userId: email,
					action: "validate",
				})
			)
				.then((response) => {
					unsetLoaders();
					const message =
						columnNames.length > 1
							? `Marked all data points as ${
									status === "notSure" ? "Unable To Specify" : _.startCase(status)
							  }`
							: `Marked ${_.startCase(props.columnNames[0].toLowerCase())} as ${
									status === "notSure" ? "Unable To Specify" : _.startCase(status)
							  }`;
					setNoty(message, "success");
					if (email) postSessionData(getPostSessionData());
				})
				.catch((err) => {
					unsetLoaders();
				});
		}

		props.setIsDropDown(false);
	};
	return (
		<div
			className={
				props.position === "right"
					? "label-dropdown-right"
					: props.totalHeight && props.y && props.totalHeight - props.y > 400
					? "label-dropdown"
					: "label-dropdown-upward"
			}
			ref={labelOptionRef}
		>
			{props.identifierId && (
				<div className="validation-menu-header">
					{" "}
					Validate Data Points ({props.columnNames.length})
				</div>
			)}
			<div className="label-option" id="label-option-stop-circle" onClick={() => setStatus(Status.NotVerified)}>
				<StopCirlce></StopCirlce>

				<span className="dropdown-label-content" id="dropdown-label-content-not-verified"> Not verified</span>
			</div>
			<div className="label-option"  id="label-option-correct-icon" onClick={() => setStatus(Status.Correct)}>
				<CorrectIcon />
				<span className="dropdown-label-content" id="dropdown-label-content-correct"> Correct</span>
			</div>
			<div className="label-option" id="label-option-exclamation-mark" onClick={() => setStatus(Status.Incorrect)}>
				<ExclamationIcon></ExclamationIcon>
				<span className="dropdown-label-content" id="dropdown-label-content-correctt"> Incorrect</span>
			</div>
			<div className="label-option"  id="label-option-question-mark" onClick={() => setStatus(Status.NotSure)}>
				<QuestionIcon />
				<span className="dropdown-label-content" id="dropdown-label-content-unable-to-specify"> Unable to specify</span>
			</div>
		</div>
	);
}
