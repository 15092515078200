import { contentIdMap, nalaAPIPrefix, configurationIdMap, defaultConfigurationId } from "../../config";
import { axiosClient } from "../../axiosClient";
import { useEffect, useState } from "react";
import { Spinner } from "@cimpress/react-components";
import { useAppDispatch, useAppSelector } from "../../reduxHooks";
import React from "react";
import { AuthContext } from "../../context/authContext";
import { useLocation } from "react-router-dom";
import { getGroups } from "../../features/view/viewSlice";
import { DashboardType, GenerateLinkResponse } from "../../types";
import { BreadCrumbs } from "../BreadCrumbs/breadCrumbs";

export const Dashboard = () => {
	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const dashboardType = params.get("type");
	const { groupList } = useAppSelector((state) => state.view);
	const [ssoLink, setSsoLink] = useState("");
	const [isLoading, setLoading] = useState(true);
	const dispatch = useAppDispatch();
	const { profile } = React.useContext(AuthContext);
	const breadCrumbPath = ["/home", `${dashboardType}-dashboard`];
	const userId = profile?.email;
	const generateSSOLink = async (contentId: string) => {
		const configurationId = configurationIdMap[contentId] || defaultConfigurationId; // Handle default case if needed
		const resp: GenerateLinkResponse = await axiosClient.post(`${nalaAPIPrefix}v1/embed/generateLink`, {
			configurationId
		});
	
		const link = resp.data.signedEmbedUrl;
		setSsoLink(link);
		setLoading(false);
	};
	
	useEffect(() => {
		if (userId) dispatch(getGroups({ userId })); // eslint-disable-next-line
	}, []);
	
	useEffect(() => {
		const contentId =
			groupList.length === 0
				? (dashboardType === DashboardType.evo ? contentIdMap.newTenant : contentIdMap.enrichmentId)
				: (dashboardType === DashboardType.evo ? contentIdMap.existingTenant : contentIdMap.enrichmentId);
		
		generateSSOLink(contentId); // eslint-disable-next-line
	}, [groupList]);	
	return (
		<div>
			<BreadCrumbs items={breadCrumbPath} />
			{isLoading && (
				<>
					<div className="table-spinner-overlay"></div>
					<Spinner className="table-spinner" />
				</>
			)}
			<iframe src={ssoLink} width="100%" height="1000" title="dashboard" />
		</div>
	);
};
