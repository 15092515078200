// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useState } from "react";
import "./commentsStyle.css";
import { CommentsModal } from "./commentsModal";
import { ToolTipComp } from "../Hocs/toolTip";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CommentIcon from "../../assets/messages-bubble.svg";
export const Comments = (props: {
	field: string;
	id: string;
	commented: string;
	rowIndex: number[];
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [y, setY] = useState(0);
	const [totalHeight, setTotalHeight] = useState(0);
	const commentIcon = (
		<img
			alt=""
			src={CommentIcon}
			onClick={(e: any) => {
				setIsOpen(!isOpen);
				setY(e.clientY);
				setTotalHeight(e.view.innerHeight);
			}}
		/>
	);
	return (
		<>
			<div className={props.commented === "true" ? "visible-comment" : "hide-comment"}>
				<ToolTipComp children={commentIcon} message={"Click to add comments"} />
			</div>
			{isOpen && (
				<CommentsModal
					field={props.field}
					id={props.id}
					setOpen={setIsOpen}
					y={y}
					totalHeight={totalHeight}
					rowIndex={props.rowIndex}
				/>
			)}
		</>
	);
};
