import { Select, Toggle } from "@cimpress/react-components";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { AuthContext } from "../../context/authContext";
import {
	setQuickFilters,
	getViewData,
	setCorrectRecordFilter,
	setCommentedFilter,
	setOverWrittenFilter,
	setValidationFilter,
} from "../../features/view/viewSlice";
import { useAppDispatch, useAppSelector } from "../../reduxHooks";
import "../../styles/quickFilters.css";
import { DefaultView, IFilter } from "../../types";
const validationOptions = ["correct", "incorrect", "notSure", "notVerified", "all"];
const getValidationOptions = () => {
	return validationOptions.map((option) => {
		if (option === "notSure") {
			return { label: "Unable To Specify", value: option };
		} else
			return {
				label: _.startCase(option),
				value: option,
			};
	});
};
export const QuickFilters = (props: any) => {
	const {
		unsavedFilters,
		pageSize,
		selectedView,
		selectedTable,
		sortColumn,
		sortOrder,
		visibleColumns,
		selectedHeaders,
		quickFilters,
		correctRecordFilter,
		overwrittenFilter,
		commentedFilter,
		validationFilter,
		filterOP
	} = useAppSelector((state) => state.view);
	const [allQuickFilters, setAllQuickFilters] = useState<IFilter[]>([]);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (quickFilters) {
			let validationFilters = quickFilters.filter(
				(singleFilter) => validationOptions.indexOf(singleFilter.value[0]) !== -1
			);
			dispatch(
				setValidationFilter(validationFilters.length > 0 ? validationFilters[0].value[0] : "All")
			);
			dispatch(
				setOverWrittenFilter(
					quickFilters.filter((singleFilter) => singleFilter.value[0] === "overwritten").length > 0
				)
			);
			dispatch(
				setCommentedFilter(
					quickFilters.filter((singleFilter) => singleFilter.value[0] === "commented").length > 0
				)
			);

			setAllQuickFilters([...quickFilters]);
		}
	}, [quickFilters.length]); // eslint-disable-line react-hooks/exhaustive-deps
	const getViewRequestData = (allQuickFilters: IFilter[]) => {
		return {
			currentPage: 0,
			size: pageSize,
			viewId: selectedView ? selectedView.viewId : DefaultView.Default,
			unsavedFilters,
			savedFilters: [],
			selectedTable: selectedTable,
			selectedColumns: selectedHeaders || [],
			visibleColumns: visibleColumns || [],
			sortingColumn: sortColumn,
			sortingOrder: sortOrder,
			userId: email,
			isApplyFilters: true,
			quickFilters: allQuickFilters,
			filterOp: filterOP,
		};
	};
	const applyQuickFilters = (type: string, value?: string) => {
		if (type === "validate") {
			if (value) {
				let index = allQuickFilters.findIndex(
					(f, index) => validationOptions.indexOf(f.value[0]) !== -1 && f.column === "any"
				);
				if (index !== -1 && value === "all") {
					allQuickFilters.splice(index, 1);
				} else if (index !== -1)
					allQuickFilters[index] = { column: "any", operator: "is", value: [value] };
				else allQuickFilters.push({ column: "any", operator: "is", value: [value] });
				dispatch(setValidationFilter(value));
			} else {
				if (correctRecordFilter) {
					let index = allQuickFilters.findIndex((f, index) => f.value[0] === "correct");
					allQuickFilters.splice(index, 1);
					dispatch(setCorrectRecordFilter(false));
				} else {
					allQuickFilters.push({ column: "any", operator: "is", value: ["correct"] });
					dispatch(setCorrectRecordFilter(true));
				}
			}
		} else if (type === "overwrite") {
			if (overwrittenFilter) {
				let index = allQuickFilters.findIndex((f, index) => f.value[0] === "overwritten");
				allQuickFilters.splice(index, 1);
				dispatch(setOverWrittenFilter(false));
			} else {
				allQuickFilters.push({ column: "any", operator: "is", value: ["overwritten"] });
				dispatch(setOverWrittenFilter(true));
			}
		} else if (type === "comment") {
			if (commentedFilter) {
				let index = allQuickFilters.findIndex((f, index) => f.value[0] === "commented");
				allQuickFilters.splice(index, 1);
				dispatch(setCommentedFilter(false));
			} else {
				allQuickFilters.push({ column: "any", operator: "is", value: ["commented"] });
				dispatch(setCommentedFilter(true));
			}
		}
		dispatch(setQuickFilters([...allQuickFilters]));
		dispatch(getViewData(getViewRequestData(allQuickFilters)));
	};

	const { profile } = React.useContext(AuthContext);
	const email = profile?.email || "";
	const getValue = () => {
		if (validationFilter) {
			return {
				label: validationFilter === "notSure" ? "Unable To Specify" : _.startCase(validationFilter),
				value: validationFilter,
			};
		} else {
			return {
				label: "All",
				value: "all",
			};
		}
	};
	return (
		<div className="quick-filters">
			<div className="toggle-button" data-testid="overwrite-toggle">
				<Toggle
					on={overwrittenFilter}
					onClick={(e) => {
						applyQuickFilters("overwrite");
					}}
					disabled={false}
					size="sm"
				/>
				<span className="filter-label">Overridden values only</span>
			</div>
			<div className="toggle-button" data-testid="comment-toggle">
				<Toggle
					on={commentedFilter}
					onClick={() => applyQuickFilters("comment")}
					disabled={false}
					size="sm"
				/>
				<span className="filter-label">Comments only</span>
			</div>
			<div className="validation-dropdown-wrapper" data-testid="validation-options">
				<Select
					id=""
					data-testid="validation-options"
					className="validation-dropdown"
					label={undefined}
					isSearchable={false}
					value={getValue()}
					options={getValidationOptions()}
					onChange={(obj) => applyQuickFilters("validate", obj.value)}
					helpText={undefined}
				/>
			</div>
		</div>
	);
};
