import { Label, Tooltip } from '@cimpress/react-components/';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useTable, Row, Cell, HeaderGroup, ColumnInstance } from 'react-table'; 
import downloadIcon from "../../assets/download-icon.svg";
import { completed, inProgress, processing } from '../../utils/commonUtility';
import { useAppDispatch, useAppSelector } from '../../reduxHooks';
import { EnrichmentIdInfo, setEnrichStatus, setHistoryTableRowIndex, setProgressLoading } from '../../features/bulkEnrichment/bulkEnrichmentSlice';

type RowDataType = {
    fileName: string;
    noRecords: string;
    enrichedOn: string;
    enrichmentStatus: string;
    download: string;
};

const columns = [
    {
        Header: 'File Name',
        accessor: 'file_name',
        width: 271,
    },
    {
        Header: '# Records',
        accessor: 'total_records',
        width: 99,
    },
    {
        Header: 'Enriched On',
        accessor: 'created_at',
        width: 200,
    },
    {
        Header: 'Enrichment Status',
        accessor: 'enrichmentStatus',
        width: 166,
    },
    {
        Header: 'Download',
        accessor: 'download',
        width: 106,
    },
];

export const EnrichHistoryTable = (props: { pageNumber: number }) => {
    const dispatch = useAppDispatch();
    const { enrichHistoryData, isFilteredData, FilteredData, historyTableRowIndex } = useAppSelector((state) => state.bulkEnrichment);
    const data = isFilteredData ? FilteredData : (enrichHistoryData.user_data ? enrichHistoryData.user_data : []);
    const itemsPerPage = 10;
    const currentItems = data.slice((props.pageNumber) * itemsPerPage, (props.pageNumber + 1) * itemsPerPage);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data: currentItems,
    });

    const handleCompletedDownload = (row: Row<RowDataType>) => {
        const link = document.createElement('a');
        link.href = row.original.enriched_data_url;
        link.setAttribute('download', '');
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    const handleCellClick = (row: Row<RowDataType>, rowIndex: number) => {
        dispatch(setHistoryTableRowIndex(rowIndex));
        dispatch(EnrichmentIdInfo({ id: row.original.enrichment_id }));
        dispatch(setProgressLoading(true));
        setTimeout(() => {
            dispatch(setProgressLoading(false));
        }, 1000);
        const status = row.original.processed_records !== row.original.total_records
                        ? inProgress
                        : row.original.enriched_data_url
                        ? completed
                        : processing;
        dispatch(setEnrichStatus(status));
    }

    const enrichmentStatus = (row: Row<RowDataType>) => {
        return row.original.processed_records !== row.original.total_records
            ? <Label text="In progress" status="info" />
            : row.original.processed_records === row.original.total_records && row.original.enriched_data_url
            ? <Label text="Completed" status="success" />
            : <Label text="Processing" status="default" />;
    }

    const downloadImage = (row: Row<RowDataType>) => {
        return row.original.enriched_data_url ? (
            <Tooltip
                direction="top"
                contents={<div>CSV file with all details</div>}
            >
                <img src={downloadIcon} alt="download" onClick={() => handleCompletedDownload(row)} />
            </Tooltip>
        ) : <></>;
    }
    return (
        <div>
            <table {...getTableProps()} style={{ minWidth: "65vw" }}>
                <thead>
                    {headerGroups.map((headerGroup: HeaderGroup<RowDataType>) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: ColumnInstance<RowDataType>) => (
                                <th {...column.getHeaderProps()} className='enrich-table-head' style={{ width: column.width }}>
                                    <span className='enrich-header-content'>{column.render('Header')}</span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()} style={{cursor:"pointer"}}>
                    {rows.map((row: Row<RowDataType>, rowIndex: number) => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps()}
                                onClick={() => handleCellClick(row, rowIndex)}
                                style={{
                                    backgroundColor: historyTableRowIndex === rowIndex ? '#8090a259' : '',
                                }}
                            >
                                {row.cells.map((cell: Cell<RowDataType>) => (
                                    <td className='enrich-table-row' {...cell.getCellProps()} style={{ width: cell.column.width }}>
                                        <div style={{ maxHeight: '4vh' }}>
                                            <span>
                                                <div style={{ textAlign: "center" }}>
                                                    {cell.column.id === "enrichmentStatus" && enrichmentStatus(row)}
                                                    {cell.column.id === "download" && downloadImage(row)}
                                                </div>
                                                {cell.column.id === "created_at" && cell.value}
                                                {cell.column.id !== "created_at" && cell.column.id !== "enrichmentStatus" && cell.column.id !== "download" && cell.value}
                                            </span>
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
