import { Accordion, Button, Card, Snackbar, Spinner } from "@cimpress/react-components/"
import { useState } from "react";
import _ from "lodash";
import { UploadImage } from "./uploadImage";
import { docIntent } from "../../hooks/view-data";
const accordianHeaderStyle = { height: "5px", marginTop: "-3vh", marginLeft: "-1vw", fontWeight: "800" };
const GPT_MODEL = 'gpt-4o';
type Item = string | number | boolean | Record<string, any>;
type ItemsArray = Item[];
type RenderItemFunction = (item: Item) => JSX.Element;
type ObjectData = Record<string, Item>;

export const DocIntent = () => {
  const [isFilePresent, setIsFilePresent] = useState(false)
  const [imageSrc, setImageSrc] = useState<string | ArrayBuffer | null>(null);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState('');
  const [data, setData] = useState(null);

  const handleFileChange = (selectedFile: File) => {
    setFile(selectedFile);
  };
  const handleClear = () => {
    setFile(null);
    setImageSrc(null);
    setIsFilePresent(false);
    setError("");
    setData(null)
  }
  const model = GPT_MODEL
  const renderArray = (items: ItemsArray, renderItem: RenderItemFunction): JSX.Element => {
    return items.length > 0 ? (
      <ul>
        {items.map((item, index) => (
          <li key={index}>{renderItem(item)}</li>
        ))}
      </ul>
    ) : (
      <p>No items to display.</p>
    );
  };
  const renderObject = (obj: ObjectData) => {
    return (
      <>
        {Object.entries(obj).map(([key, value], index) => (
          value === null || value === undefined ?
            <p key={index}><strong>{_.startCase(key)}:</strong> Not available</p> :
            Array.isArray(value) ?
              (
                <div key={index}>
                  <Accordion className="doc-intent-acccordian" title={_.startCase(key)} variant="minimal"
                    headerStyle={accordianHeaderStyle} bodyStyle={{ fontWeight: "500" }}>
                    {renderArray(value, item => typeof item === 'object' ? renderObject(item) : <span>{item}</span>)}
                  </Accordion>
                </div>
              ) : typeof value === 'object' ? (
                <div key={index}>
                  <Accordion className="doc-intent-acccordian" title={_.startCase(key)} variant="minimal"
                    headerStyle={accordianHeaderStyle} bodyStyle={{ fontWeight: "500" }}>
                    {renderObject(value)}
                  </Accordion>
                </div>
              ) : (
                <p key={index}><strong>{_.startCase(key)}:</strong> {typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value}</p>
              )
        ))}
      </>
    );
  };
  const handleRequest = async () => {
    setLoading(true);
    setError('');
    const formData = new FormData();
    formData.append('imageUrl', '');
    formData.append('model', model);
    if (file) {
      formData.append('image', file, `${file.name}`);
    }
    try {
      const response = await docIntent(formData)
      if (response.status !== 200) {
        let errorMessage = 'Network response was not ok';
        if (response.status >= 400 && response.status < 500) {
          errorMessage = `Client Error: ${response.status}`;
        } else if (response.status >= 500) {
          errorMessage = `Server Error: ${response.status}`;
        }
        setError(errorMessage)
        throw new Error(errorMessage);
      }
      const result = await response.data;
      setData(result)
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("An unknown error occurred");
      }
    } finally {
      setLoading(false);
    }
  };
  return (<div style={{ marginLeft: "2vw" }}>
    <h3>Document Intent</h3>
    <div style={{ display: "flex" }}>
      <Snackbar show={error ? true : false} status="danger" delay={6000}>{error} please try again.</Snackbar>
      <Snackbar show={loading} status="info" delay={6000}>Generating results this might take some time.</Snackbar>
      <Card isMinimal={true} className="upload-image-card">
        <div>
          <div className="doc-intent-upload-image">
            <UploadImage
              imageSrc={imageSrc}
              setImageSrc={setImageSrc}
              isFilePresent={isFilePresent}
              setIsFilePresent={setIsFilePresent}
              onFileChange={handleFileChange}
            />
          </div>
          <div className="util-buttons">
            {imageSrc && <Button variant="primary" onClick={handleRequest}>Generate Now</Button>}
            {imageSrc && <Button onClick={handleClear}>Clear</Button>}
          </div>
        </div>
      </Card>
      {loading && <Spinner></Spinner>}
      {(!loading && data) && <Card style={{ marginLeft: "2vw" }}>
        <div>
          {renderObject(data)}
        </div>
      </Card>}
    </div>

  </div>)
}