import { Button, Select, TextField } from "@cimpress/react-components";
import { useMemo, useRef, useState } from "react";
import "../../styles/overWrite.css";
import { DetectOutsideClick } from "../detectClickOutside";
import { useAppDispatch, useAppSelector } from "../../reduxHooks";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import WarningIcon from "../../assets/warning.svg";
import React from "react";
import {
	setRows,
	setSnackBar,
	setSnackBarMessage,
	setSnackBarStatus,
	updateRecordLabel,
	setbusinessFields,
	setcustomFields,
	setfirmographics,
	setDetailedViewRow,
	setMetaData,
	setEnrichedValue,
	setDependencyCells,
	setCurrentRowIndex
} from "../../features/view/viewSlice";
import { AuthContext } from "../../context/authContext";
import _ from "lodash";
import { DefaultView, IRow, ISearch } from "../../types";
import { messageLabels, overwriteRow } from "../../utils/overWrite";
import { postSessionData } from "../../hooks/view-data";
import { GCCINDUSTRIES } from "../../utils/gccIndustries";
import ReactDOM from "react-dom";
import { OVERWRITE_ACTION } from "../../config";
import { industryConfidenceScore } from "../../utils/commonUtility";

const getObjectFromRows = (row: IRow[]) => {
	const finalObj: { [key: string]: string } = {};
	for (let col of row) {
		const { status, ...rest } = col;
		finalObj[Object.keys(rest)[0]] = Object.values(rest)[0];
	}
	return finalObj;
};

export interface IndustryOption {
	label: string;
	value: string;
}
export interface IGCCOption {
	label: string | undefined;
	value: string | undefined;
}

const industryHeightStyle = { height: "280px" };
const commonHeightStyle = { height: "180px" };
export const numericFields = ["employee", "revenue", "sbi", "employee_range"];
export const establishedYearFields = [
	"ESTABLISHMENT_YEAR",
	"DATE_OF_ESTABLISHMENT",
	"YEAR_OF_ESTABLISHMENT",
];
export const l1IndustryCols = ["CE_INDUSTRY_L1", "CATEGORY_L1", "industry_l1"];
export const l2IndustryCols = ["CE_INDUSTRY_L2", "CATEGORY_L2", "industry_l2"];
export const dropdownFields = ["SBI", "ZZP", "SUB_SEGMENT"];
export const getOptions = (
	field: string,
	allMapings: {
		[key: string]: {
			[key: string]: string;
		};
	},
	segment_list: string[] | any[]
) => {
	if (field === "SUB_SEGMENT") {
		return segment_list.map((val) => ({
			label: _.startCase(val),
			value: _.startCase(val),
		}));
	}
	let optionsArr =
		field === "SBI"
			? _.uniq(Object.keys(allMapings["sbi_to_sic_map"])).map((sbi) => ({ label: sbi, value: sbi }))
			: ["True", "False"].map((value) => ({
				label: _.startCase(value),
				value: _.startCase(value),
			}));
	return optionsArr;
};

export const isDateField = (field: string) => {
	return establishedYearFields.indexOf(field) !== -1;
};
export const isNumeric = (field: string) => {
	let isNumeric = false;
	numericFields.forEach((numericField) => {
		if (field.toLowerCase().indexOf(numericField) !== -1) {
			isNumeric = true;
		}
	});
	return isNumeric;
};
export function OverWriteModal(props: {
	y: number;
	totalHeight: number;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	field: string;
	value: string;
	identifierId: string;
	identifier: string;
	isOpen: React.Dispatch<React.SetStateAction<boolean>>;
	rowIndex: number[];
}) {
	const { profile } = React.useContext(AuthContext);
	const email = profile?.email || "";
	const {
		industryMap,
		selectedTable,
		selectedView,
		rows,
		allMappings,
		subSegments,
		pageSize,
		pageNumber,
		savedFilters,
		unsavedFilters,
		selectedHeaders,
		visibleColumns,
		sortColumn,
		sortOrder,
		businessFields,
		firmographics,
		customFields,
		isDetailedView,
		DetailedViewRow,
		detailedViewTable,
		metaData,
		enrichedValues,
	} = useAppSelector((state) => state.view);
	const DetailedViewRowModify = [[DetailedViewRow]];
	// const selectedRows = clonedRow.filter((value, index) => props.rowIndex.indexOf(index) !== -1);
	const dispatch = useAppDispatch();
	const [error, setError] = useState("");
	const viewName = selectedView?.name;
	const [showl2, setShowL2] = useState(l2IndustryCols.indexOf(props.field) !== -1);
	const [websiteAlert, setWebsiteAlert] = useState(false);
	const [textOverideValue, setTextValue] = useState<string>(props.value);
	const [industryL1, setIndustryL1] = useState<IndustryOption>({
		label: props.value,
		value: props.value,
	});
	const [gccIndustry, setGCCIndustry] = useState<IndustryOption>({
		label: props.value,
		value: props.value,
	});
	const [industryL2, setIndustryL2] = useState<IndustryOption>({
		label: props.value,
		value: props.value,
	});

	const [L1Options, setL1Options] = useState<IndustryOption[]>([{ label: "", value: "" }]);

	const [L2Options, setL2Options] = useState<IndustryOption[]>([{ label: "", value: "" }]);
	const [gccOptions] = useState<IGCCOption[]>(
		GCCINDUSTRIES.map((industry) => ({ label: industry, value: industry }))
	);
	const getObjectForDetailedView = (
		row: IRow,
		DetailedViewUpdateFields: { [key: string]: { [key: string]: string } }
	) => {
		const updatedFields = { ...DetailedViewUpdateFields };
		for (const subDictKey in updatedFields) {
			const subDict = updatedFields[subDictKey];
			for (const key in subDict) {
				if (key in row) {
					subDict[key] = row[key];
				}
			}
		}
		return updatedFields;
	};
	const getMetaDataDetailedView = (data: { [key: string]: { [key: string]: string } }) => {
		const metaData = { ...data };
		metaData["OVERWRITTEN_LABELS"] = { ...metaData["OVERWRITTEN_LABELS"] };
		if (!(props.field in metaData["OVERWRITTEN_LABELS"])) {
			metaData["OVERWRITTEN_LABELS"][props.field] = 'true';
		}
		return metaData;
	}

	const setOverwriteStatus = (allRows: IRow[][]) => {
		const copiedRows = _.cloneDeep(allRows);
		const selectedRows = copiedRows.filter((value, index) => props.rowIndex.indexOf(index) !== -1);

		selectedRows.forEach((cols) => {
			cols.forEach((obj) => {
				if (props.field in obj) {
					obj["overwritten"] = "true";
					return obj;
				}
				return;
			});
			if (props.field === "CE_INDUSTRY_L1") {
				cols.forEach((obj) => {
					if ("CE_INDUSTRY_L2" in obj) {
						obj["overwritten"] = "true";
						return obj;
					}
					if (industryConfidenceScore in obj){
						obj["overwritten"] = "true";
						return obj;
					}
					return;
				});
			}
			if (props.field === "CATEGORY") {
				cols.forEach((obj) => {
					if (industryConfidenceScore in obj){
						obj["overwritten"] = "true";
						return obj;
					}
					return;
				});
			}
		});
		dispatch(setRows(copiedRows));
	};
	useMemo(() => {
		if (l1IndustryCols.indexOf(props.field) !== -1 && industryL1.value) {
			setIndustryL2({ label: "", value: "" });

			let l2List = industryMap[industryL1.value]
				? industryMap[industryL1.value].map((l2) => ({
					label: l2,
					value: l2,
				}))
				: [];
			setL2Options([...l2List]);
		}
	}, [industryL1]); // eslint-disable-line react-hooks/exhaustive-deps

	const isValidInput = (field: string, values: string[]) => {
		if (
			(field === "" || l1IndustryCols.indexOf(field) !== -1) &&
			(values[0] === "" || values[1] === "")
		) {
			setError("Field can not be empty");
			return false;
		}

		if (values[0] === "") {
			setError("Field can not be empty");
			return false;
		}
		if (isDateField(field)) {
			const value = values[0];
			if (new Date().getFullYear() < Number(value)) {
				setError(`${field} can not be future date`);
				return false;
			}
			if (!/[0-9]{4}$/.test(value)) {
				setError(`Please enter date in YYYY format`);
				return false;
			}
		}

		return true;
	};
	useMemo(() => {
		if (l1IndustryCols.indexOf(props.field) !== -1)
			setL1Options(Object.keys(industryMap).map((l1) => ({ label: l1, value: l1 })));
		if (l2IndustryCols.indexOf(props.field) !== -1) {
			let l2Arr: any = [];
			Object.keys(industryMap).forEach((l1) => {
				if (industryMap[l1].indexOf(props.value) !== -1)
					l2Arr = industryMap[l1].map((l2) => ({ label: l2, value: l2 }));
			});
			setL2Options(l2Arr);
		}
	}, [industryMap]); // eslint-disable-line react-hooks/exhaustive-deps

	const labelOptionRef = useRef(null);

	DetectOutsideClick(labelOptionRef, props.isOpen);
	const onL1Change = (obj: { label: string; value: string }) => {
		setIndustryL1(obj);
		setError(
			"Changing L1 Industry impacts L2 Industry, please select the corresponding L2 Industry"
		);
		setShowL2(true);
	};
	const onL2Change = (obj: { label: string; value: string }) => {
		setIndustryL2(obj);
		setError("");
	};
	const onGCCChange = (obj: { label: string; value: string }) => {
		setGCCIndustry(obj);
		setError("");
	};
	const onTextOverride = (value: any) => {
		setTextValue(value);
	};
	const setSnackbarNoty = (message: string, status: string) => {
		dispatch(setSnackBar(true));
		dispatch(setSnackBarMessage(message));
		dispatch(setSnackBarStatus(status));
	};

	const overWriteValue = (alert?: boolean) => {
		let fields: string[] = [];
		let values: string[] = [];
		if (props.field === "CATEGORY") {
			if (gccIndustry.value === "") {
				setError("Category can not be empty");
				return;
			} else {
				fields = ["CATEGORY"];
				values = [gccIndustry.value];
			}
		} else if (l1IndustryCols.indexOf(props.field) !== -1) {
			if (isValidInput(props.field, [industryL1.value, industryL2.value])) {
				fields =
					props.field === "CE_INDUSTRY_L1"
						? ["CE_INDUSTRY_L1", "CE_INDUSTRY_L2"]
						: ["CATEGORY_L1", "CATEGORY_L2"];
				values = [industryL1.value, industryL2.value];
			} else return;
		} else if (l2IndustryCols.indexOf(props.field) !== -1 && industryL2.label !== "") {
			if (isValidInput(props.field, [industryL2.value])) {
				fields = props.field === "CE_INDUSTRY_L2" ? ["CE_INDUSTRY_L2"] : ["CATEGORY_L2"];
				values = [industryL2.value];
			} else return;
		} else {
			if (props.field === "SBI" && !(textOverideValue in allMappings["sbi_to_sic_map"])) {
				setError("Incorrect SBI value, please input correct SBI value");
				return;
			}
			if (isValidInput(props.field, [textOverideValue]) && textOverideValue !== props.value) {
				fields = [props.field];
				values = [textOverideValue];
			} else {
				return;
			}
		}
		const getId = (data: ISearch): string => {
			const ENRICHMENT_CORRELATION_ID = data["ENRICHMENT_CORRELATION_ID"];
			const idArray = ["CUSTOMER_NUMBER", "CUSTOMERID", "CUSTOMER_ID"];

			for (const key of idArray) {
				if (ENRICHMENT_CORRELATION_ID === data[key]) {
					return key;
				}
			}

			return "CUSTOMER_CORRELATION_ID";
		};

		// let handled_identifier: string;
		const firstRow = rows[0] || DetailedViewRowModify[0];
		const firstRowData = isDetailedView ? DetailedViewRowModify[0][0] : rows[0][0];
		const handled_identifier: string = ((firstRow) && props.identifier === "CUSTOMER_CORRELATION_ID") ?
			getId(firstRowData) : props.identifier
		const { allRows, updatedRow, rowBeforeUpdate } = overwriteRow(
			fields,
			values,
			handled_identifier,
			props.identifierId,
			isDetailedView ? DetailedViewRowModify : rows,
			allMappings
		);
		const DetailedViewFields = {
			businessFields: { ...businessFields },
			firmographics: { ...firmographics },
			customFields: { ...customFields },
			enrichedValues: { ...enrichedValues },
		};
		const updatedDetailedFields = isDetailedView
			? getObjectForDetailedView(updatedRow[0], DetailedViewFields)
			: {};
		const getMainKey = (data: Record<string, any>): string => {
			return Object.keys(data)[0];
		};
		const findChangedKeys = (updated: Record<string, any>[], original: Record<string, any>[]): string[] => {
			const updatedDict: Record<string, Record<string, any>> = {};
			const originalDict: Record<string, Record<string, any>> = {};
			updated.forEach(item => {
				const key = getMainKey(item);
				updatedDict[key] = item;
			});
			original.forEach(item => {
				const key = getMainKey(item);
				originalDict[key] = item;
			});
			const changedKeys: string[] = Object.keys(updatedDict).filter((key) => {
			return (
				key in originalDict &&
				JSON.stringify(updatedDict[key]) !== JSON.stringify(originalDict[key])
			);
			});
			return changedKeys;
		};
		if (isDetailedView) {
			dispatch(setDetailedViewRow(updatedRow[0]));
		}
		if (allRows.length > 0) {
			if (!isDetailedView) {
				dispatch(setRows(allRows));
			}
			const identifierField = _.startCase(handled_identifier.toLowerCase());
			dispatch(setDependencyCells(findChangedKeys(updatedRow,rowBeforeUpdate)));
			dispatch(setCurrentRowIndex(props.rowIndex[0]));
			props.setLoading(true);
			const message = `${messageLabels(fields)}  for ${identifierField} ${props.identifierId
				} is getting updated in the backend. This may take a couple of minutes.`;
			setSnackbarNoty(message, "info");
		}
		const updatedRowObject = isDetailedView ? updatedRow[0] : getObjectFromRows(updatedRow);
		const rowBeforeUpdateObject = isDetailedView
			? rowBeforeUpdate[0]
			: getObjectFromRows(rowBeforeUpdate);
		const getPostSessionData = () => {
			return {
				currentPage: pageNumber,
				size: pageSize,
				viewId: selectedView ? selectedView.viewId : DefaultView.Default,
				selectedTable,
				userId: email,
				isAction: true,
				savedFilters: savedFilters,
				unsavedFilters: unsavedFilters,
				selectedColumns: selectedHeaders || [],
				visibleColumns: visibleColumns || [],
				sortingColumn: sortColumn,
				sortingOrder: sortOrder,
			};
		};
		const getResponseOverWritten = (res: { [key: string]: string }) => {
			if (!isDetailedView) {
				setOverwriteStatus(allRows);
			}
			if (isDetailedView) {
				dispatch(setbusinessFields(updatedDetailedFields["businessFields"]));
				dispatch(setfirmographics(updatedDetailedFields["firmographics"]));
				dispatch(setcustomFields(updatedDetailedFields["customFields"]));
				dispatch(setMetaData(getMetaDataDetailedView(metaData)));
				dispatch(setEnrichedValue(updatedDetailedFields["enrichedValues"]));
			}
			const identifierField = _.startCase(handled_identifier.toLowerCase());
			dispatch(setDependencyCells([]));
			dispatch(setCurrentRowIndex(null));
			props.setLoading(false);
			const message =
				fields[0].toLowerCase() === "website" || fields[0].toLowerCase() === "company_website"
					? res.message
					: `${messageLabels(fields)} updated successfully for ${identifierField} ${props.identifierId
					}`;
			setSnackbarNoty(message, "success");

			if (!isDetailedView) {
				postSessionData(getPostSessionData());
			}
			if (alert) {
				setWebsiteAlert(false);
			}
		}
		dispatch(
			updateRecordLabel({
				identifierId: props.identifierId,
				tableName: isDetailedView ? detailedViewTable : selectedTable,
				identifier: handled_identifier,
				action: OVERWRITE_ACTION,
				userId: email,
				updatedRow: updatedRowObject,
				fields,
				rowBeforeUpdate: rowBeforeUpdateObject,
				viewName,
			})
		)
			.unwrap()
			.then((res) => {
				getResponseOverWritten(res)
			})
			.catch((err) => {
				dispatch(setDependencyCells([]));
				dispatch(setCurrentRowIndex(null));
				props.setLoading(false);
				dispatch(setRows(rows));
				const identifierField = _.startCase(handled_identifier.toLowerCase());
				const message = `${messageLabels(fields)} for ${identifierField} ${props.identifierId
					} failed to get updated. Please refresh the view to see  the current status`;
				setSnackbarNoty(message, "danger");
				if (alert) {
					setWebsiteAlert(false);
				}
			});
		props.isOpen(false);
		setWebsiteAlert(false);
	};
	const handleKeyDown = (e: any, field: string) => {
		if (isNumeric(props.field) || isDateField(props.field)) {
			if (e.key.includes("e") || e.key.includes("-") || e.key.includes(".")) {
				e.preventDefault();
			}
		}
	};

	const Popup = () => {
		const targeElement = document.getElementById("popup-root");
		if (targeElement) {
			return ReactDOM.createPortal(
				<div className="popup-overlay"
					data-testid="popup-overlay"
					id="popup-overlay">
					<div className="popup-content">
						<h3 className="website-alert-title">
							<img
								alt=""
								height={32}
								width={32}
								style={{ marginTop: -8, marginLeft: 6 }}
								className="warnining-image"
								src={WarningIcon}
							/>
							Website Override{" "}
						</h3>

						<div className="website-alert-content">
							<span className="website-alert-text">
								The website is used as an input for enriching data - changing website values here
								might change the associated fields for this record. This would take about 24 hours
								to reflect on the portal. Please wait for 24 hours before validating/editing this
								record after changing the website value.
							</span>
						</div>

						<div className="website-alert-footer">
							<Button
								className="website-alert-cancel-button"
								onClick={() => {
									setWebsiteAlert(false);
									props.isOpen(false);
								}}
							>
								Cancel
							</Button>
							<Button
								className="website-alert-continue-button"
								variant="primary"
								onClick={() => overWriteValue(true)}
							>
								Continue
							</Button>
						</div>
					</div>
				</div>,
				targeElement
			);
		}
		return null;
	};
	return (
		<div>
			{!websiteAlert ? (
				<div
					className={
						props.totalHeight - props.y > 400
							? "overwrite-modal-wrapper"
							: "overwrite-modal-wrapper-upward"
					}
					style={
						l1IndustryCols.indexOf(props.field) !== -1 && showl2
							? industryHeightStyle
							: commonHeightStyle
					}
					ref={labelOptionRef}
				>
					<div className="override-label">OVERRIDE VALUE</div>
					{l1IndustryCols.indexOf(props.field) !== -1 && (
						<div>
							<div className="industry-select">
								<Select
									maxMenuHeight={150}
									menuShouldScrollIntoView={false}
									label={industryL1 ? "" : "Please select L1"}
									isSearchable={true}
									id={`industry-select-${props.field}`}
									value={industryL1}
									options={L1Options}
									onChange={onL1Change}
									helpText={undefined}
								/>
							</div>
							{showl2 && (
								<div className="industry-select">
									<Select
										maxMenuHeight={150}
										menuShouldScrollIntoView={false}
										label={industryL2.value ? "" : "Please select Industry L2"}
										isSearchable={true}
										id={`industry-select-${props.field}`}
										value={industryL2}
										options={L2Options}
										onChange={onL2Change}
										helpText={<div style={{ fontSize: "12px", color: "#D24A35" }}>{error}</div>}
									/>
								</div>
							)}
						</div>
					)}
					{l2IndustryCols.indexOf(props.field) !== -1 && (
						<div className="industry-select">
							<Select
								menuShouldScrollIntoView={false}
								maxMenuHeight={150}
								label={industryL2.value ? "" : "Please select Industry L2"}
								isSearchable={true}
								id={`industry-select-${props.field}`}
								value={industryL2}
								options={L2Options}
								onChange={onL2Change}
								helpText={undefined}
							/>
						</div>
					)}
					{props.field === "CATEGORY" && (
						<div className="industry-select">
							<Select
								maxMenuHeight={150}
								menuShouldScrollIntoView={false}
								label={gccIndustry.value ? "" : "Please select category"}
								isSearchable={true}
								id={`industry-select-${props.field}`}
								value={gccIndustry}
								options={gccOptions}
								onChange={onGCCChange}
								helpText={undefined}
							/>
						</div>
					)}
					{l1IndustryCols.indexOf(props.field) === -1 &&
						l2IndustryCols.indexOf(props.field) === -1 &&
						props.field !== "CATEGORY" && (
							<div className="override-text-input">
								{dropdownFields.indexOf(props.field) !== -1 ? (
									<Select
										label={undefined}
										isSearchable={true}
										menuShouldScrollIntoView={false}
										id={`override-text-input-${props.field}`}
										value={{
											label: _.startCase(textOverideValue),
											value: _.startCase(textOverideValue),
										}}
										options={getOptions(props.field, allMappings, subSegments)}
										onChange={(obj) => onTextOverride(obj.value)}
									/>
								) : (
									<>
										<TextField
											helpText={<div style={{ fontSize: "12px", color: "#D24A35" }}>{error}</div>}
											min={0}
											type={isNumeric(props.field) || isDateField(props.field) ? "number" : "text"}
											onKeyPress={(e) => handleKeyDown(e, props.field)}
											id={`override-text-input-${props.field}`}
											name={undefined}
											value={textOverideValue}
											onChange={(e) => onTextOverride(e.target.value)}
										/>
									</>
								)}
							</div>
						)}
					<div className="footer">
						<Button className="cancel-button" onClick={() => props.isOpen(false)}>
							Cancel
						</Button>
						<Button
							className="save-button"
							variant="primary"
							onClick={() => {
								if (props.field === "WEBSITE" || props.field === "COMPANY_WEBSITE") {
									setWebsiteAlert(true);
								} else {
									overWriteValue();
								}
							}}
						>
							Save
						</Button>
					</div>
				</div>
			) : (
				<Popup />
			)}
		</div>
	);
}
