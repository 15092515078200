import { useState, useCallback, useRef, useEffect } from "react";
import { Accordion, Button, Card, Copy, Pagination, ProgressBar, Spinner } from "@cimpress/react-components/";
import { EnrichHistoryTable } from "./EnrichHistoryTable";
import CSVIconDefault from "../../assets/csv-icon-default.svg";
import triangleDisabled from "../../assets/triangle-disabled.svg";
import windowLayout from "../../assets/window-layout.svg";
import CSVIcon from "../../assets/csv-icon.svg";
import triangle from "../../assets/triangle.svg";
import windowFill from "../../assets/window-layout-filled.svg";
import CorrectIcon from "../../assets/check-circle-solid.svg";
import { useAppDispatch, useAppSelector } from "../../reduxHooks";
import { EnrichmentHistoryData, EnrichmentIdInfo, setEnrichStatus, setFilteredData, setHistoryTableRowIndex, setIsFilteredData } from "../../features/bulkEnrichment/bulkEnrichmentSlice";
import { DatePickerCustom } from "./DatePickerCustom";
import { NotShowingLoadingTime, completed, historyTablePollingTime, inProgress, no_user_data_count, processing, timeForEachPoll, waitTimeBeforeProgressData } from "../../utils/commonUtility";
import React from "react";
import { AuthContext } from "../../context/authContext";
import { DetectOutsideClick } from "../detectClickOutside";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { ToolTipComp } from "../Hocs/toolTip";
import { LastUpdated } from "./updatedTime";

const accordianHeaderStyle = { height: "0.5vh", marginTop: "-3vh", marginLeft: "-1vw", fontWeight: "800" };
export const EnrichHistory = () => {
  const { enrichHistoryData, isLoading, enrichProgressData, isFilteredData, FilteredData, dateFrom, dateTo, isLoadingInfo, progressLoading, enrichStatus, currentTime } = useAppSelector((state) => state.bulkEnrichment);
  const dispatch = useAppDispatch();
  const [pageNumber, setPageNumber] = useState(0);
  const enrichStatusRef = useRef(null);
  const enrichTableRef = useRef(null);
  const [enrichStatusLoading,setEnrichStatusLoading] = useState(false);
  const handlePageNumber = (e: any) => {
    setPageNumber(e.selected)
  };
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);
  const processToCompleteRef = useRef<NodeJS.Timeout | null>(null);
  const { profile } = React.useContext(AuthContext);
  const emailId = profile?.email || "";
  useEffect(() => {
    const timeoutId = setTimeout(() => {
        if (enrichStatus === inProgress) {
            if (intervalIdRef.current) {
                clearInterval(intervalIdRef.current);
                intervalIdRef.current = null;
            }
            if (enrichProgressData?.enrichment_id && (enrichProgressData.processed_records !== enrichProgressData.total_records)) {
                intervalIdRef.current = setInterval(() => {
                    dispatch(EnrichmentIdInfo({ id: enrichProgressData.enrichment_id }));
                }, timeForEachPoll);
            }
            if (enrichProgressData?.enrichment_id && (enrichProgressData.processed_records === enrichProgressData.total_records)) {
                dispatch(EnrichmentHistoryData({ user: emailId }));
                dispatch(setEnrichStatus(processing));
                setEnrichStatusLoading(true);
                setTimeout(() => {
                  setEnrichStatusLoading(false);
                }, NotShowingLoadingTime);
            }
            }
        
        if (enrichStatus === processing) {
          if (processToCompleteRef.current) {
              clearInterval(processToCompleteRef.current);
              processToCompleteRef.current = null;
          }
          if (enrichProgressData?.enrichment_id && (enrichProgressData.processed_records === enrichProgressData.total_records) && !enrichProgressData.enriched_data_url) {
              intervalIdRef.current = setInterval(() => {
                  dispatch(EnrichmentIdInfo({ id: enrichProgressData.enrichment_id }));
              },historyTablePollingTime );
          }
          if (enrichProgressData?.enrichment_id && (enrichProgressData.processed_records === enrichProgressData.total_records) && enrichProgressData.enriched_data_url) {
              dispatch(EnrichmentHistoryData({ user: emailId }));
              dispatch(setEnrichStatus(completed));
              setEnrichStatusLoading(true);
              setTimeout(() => {
                setEnrichStatusLoading(false);
              }, NotShowingLoadingTime);
          }
          }
            }, waitTimeBeforeProgressData);
    return () => {
        if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
            intervalIdRef.current = null;
        }
        if (processToCompleteRef.current) {
          clearInterval(processToCompleteRef.current);
          processToCompleteRef.current = null;
      }
        clearTimeout(timeoutId);
    };
        }, [
            dispatch, 
            emailId, 
            enrichProgressData,
            enrichStatus
        ]);
        
  const handleDateFilter = useCallback(() => {
    const filteredData = enrichHistoryData.user_data?.filter((entry: { created_at: string | null; }) => {
      if (entry.created_at && dateFrom && dateTo) {
        return entry.created_at.split(' ')[0] >= dateFrom && entry.created_at.split(' ')[0] <= dateTo;
      }
      return false;
    }) || [];
    dispatch(setIsFilteredData(true));
    dispatch(setFilteredData(filteredData));
  }, [dateFrom, dateTo, dispatch, enrichHistoryData]);
  const enrichmentPercentage = (enrichProgressData.processed_records/enrichProgressData.total_records)*100;
  const handleDefaultEnrichStatus = () => {
    dispatch(setEnrichStatus("unknown"));
  };
  const handleTableRowSelection = () => {
    dispatch(setHistoryTableRowIndex(null));
  }
  DetectOutsideClick(enrichStatusRef, handleDefaultEnrichStatus, enrichTableRef);
  DetectOutsideClick(enrichTableRef, handleTableRowSelection, enrichStatusRef);
  const enrichIDRender = (EnrichId:string) => {
    return (
      <div style={{display:"flex"}}>
            <div className="enrichment-id-copy">
              <span>Enrichment ID : {EnrichId}</span>
            </div>
            <Copy value={EnrichId} variant="inline"> </Copy>
      </div>
    )
  }
  const enrichmentInprogressSVG = () => {
      return (
        <div className="enrich-content-inprogress">
          <img src={CSVIcon} alt="" style={{ marginRight: "1vw" }}></img>
          <img src={triangle} alt="" style={{ marginRight: "1vw" }}></img>
          <img src={windowFill} alt=""></img>
        </div>
      )
  };
  return (
    <div>
      {isLoading === "loading" && !enrichStatusLoading && <Spinner></Spinner>}
      {(isLoading === "succeeded" || enrichStatusLoading) && <div className="enrich-history-container">
        <Card isMinimal={true} className="enrich-history-card">
          <div className="accepted-file-container"><h4>Uploaded Files ({isFilteredData ? FilteredData.length : enrichHistoryData.count?enrichHistoryData.count:no_user_data_count})</h4>
            <div className="enrich-search">
            {ToolTipComp({children:<FontAwesomeIcon icon={faRotateRight} className="refresh-icon" onClick={() => {
              dispatch(EnrichmentHistoryData({ user: emailId }));
            }}/>,message:"Refresh to see updated status."})}
              <DatePickerCustom></DatePickerCustom>
              <Button style={{ height: "40px" }} disabled={(dateFrom && dateTo) ? false : true} onClick={handleDateFilter}>Search</Button>
            </div>
          </div>
          <LastUpdated lastUpdated={currentTime}></LastUpdated>
          <div ref={enrichTableRef}><EnrichHistoryTable pageNumber={pageNumber}></EnrichHistoryTable></div>
          <div style={{ float: "right" }}><Pagination initialPage={0}
            pageCount={Math.ceil(isFilteredData ? FilteredData.length : enrichHistoryData.count?enrichHistoryData.count:no_user_data_count) / 10}
            onPageChange={handlePageNumber}
            pagesOutsideElipses={2}
            pagesBetweenElipses={3}></Pagination></div>
        </Card>
        <div className="enrich-status-card-container" >
          <Card isMinimal={true} className="enrich-status-card">
            {enrichStatus === "unknown" &&
              <div className="enrich-status-card-content">
                <div className="accepted-file-container">
                  <img src={CSVIconDefault} alt="" ></img>
                  <img src={triangleDisabled} alt=""></img>
                  <img src={windowLayout} alt=""></img>
                </div>
                <div className="enrich-status-description">
                  <div style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>Details of uploaded files will be shown here</div>
                  <div>Select file to see enrichment status</div>
                  <div>OR</div>
                  <div>Upload a new file to start enrichment.</div>
                </div>
              </div>}
            {enrichStatus === inProgress &&
              <>
                {progressLoading && <Spinner className="no-data"></Spinner>}
                {!progressLoading && <div ref={enrichStatusRef}>
                  {enrichmentInprogressSVG()}
                  <div className="enrich-status-description" style={{ textAlign: "left" }}>
                    <div style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>Enrichment in progress......</div>
                    <div>{enrichProgressData.file_name}</div>
                    {enrichIDRender(enrichProgressData.enrichment_id)}
                    <ProgressBar status="info" size="m" value={enrichmentPercentage} />
                    <div>{enrichProgressData.processed_records}/{enrichProgressData.total_records}</div>
                    <Card className="description-footer" variant="layout">
                      Enrichment can take upto 3 hours depending on amount of records in
                      the file.
                    </Card>
                  </div>
                </div>}
              </>
            }
            {enrichStatus === processing &&
              <>
                {progressLoading && <Spinner className="no-data"></Spinner>}
                {!progressLoading && <div ref={enrichStatusRef}>
                  {enrichmentInprogressSVG()}
                  <div className="enrich-status-description" style={{ textAlign: "left" }}>
                    <div style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>Enrichment completed. Processing in progress......</div>
                    <div>{enrichProgressData.file_name}</div>
                    {enrichIDRender(enrichProgressData.enrichment_id)}
                    {(enrichProgressData.processed_records === enrichProgressData.total_records) && <p style={{ color: "green" }}>Enrichment done, Please refresh page after 15 min for download URL.</p>}
                    <Card className="description-footer" variant="layout">
                      Enrichment can take upto 3 hours depending on amount of records in
                      the file.
                    </Card>
                  </div>
                </div>}
              </>
            }
            {
              enrichStatus === completed &&
              <div className="enrich-content-completed" ref={enrichStatusRef}>
                {isLoadingInfo === "loading" ? <Spinner></Spinner> : (
                  <>
                    <img src={CorrectIcon} alt="" style={{ position: "fixed", height: "2.5vh" }}></img>
                    <div>
                      <div className="enrich-content-completed-header">
                        <div style={{ fontSize: "medium", fontWeight: "800" }}>Enrichment Statistics</div>
                        <div>
                          {enrichProgressData.file_name}
                          {enrichIDRender(enrichProgressData.enrichment_id)}
                          <div style={{ marginTop: "4vh", fontWeight: "800" }}>
                            Total records processed : {enrichProgressData.total_records}
                            {(enrichProgressData.enriched_count || enrichProgressData.non_enriched_count) ? (
                                <div style={{ marginTop: "2vh" }}>
                                  {enrichProgressData.enriched_count ? (
                                    <Accordion 
                                      className="enrich-history-acccordian" 
                                      title={`Enriched(${enrichProgressData.enriched_count})`} 
                                      variant="minimal" 
                                      headerStyle={accordianHeaderStyle} 
                                      bodyStyle={{ fontWeight: "500" }}
                                    >
                                      Total {enrichProgressData.enriched_count} records enriched.
                                    </Accordion>
                                  ) : null}
                                  {enrichProgressData.non_enriched_count ? (
                                    <Accordion 
                                      className="enrich-history-acccordian" 
                                      title={`Not Enriched(${enrichProgressData.non_enriched_count})`} 
                                      variant="minimal" 
                                      headerStyle={accordianHeaderStyle} 
                                      bodyStyle={{ fontWeight: "500" }}
                                    >
                                      Total {enrichProgressData.non_enriched_count} records not enriched.
                                    </Accordion>
                                  ) : null}
                                </div>
                              ) : <></>}

                          </div>
                        </div>
                      </div>
                    </div>
                  </>)}

              </div>
            }
          </Card></div>
      </div>
      }

    </div>
  );
};

