import { Tooltip } from "@cimpress/react-components";
import "../../styles/editicons.css";
export const ToolTipComp = (props: {
  children: JSX.Element;
  message: string;
}) => {
  return (
    <Tooltip
      direction="top"
      contents={<div className="tooltip-content">{props.message}</div>}
    >
      {props.children}
    </Tooltip>
  );
};
