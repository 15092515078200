import { configureStore } from '@reduxjs/toolkit'
import viewReducer from './features/view/viewSlice'
import bulkEnrichmentReducer from './features/bulkEnrichment/bulkEnrichmentSlice'

export const store= configureStore({
  reducer: {
    view: viewReducer,
    bulkEnrichment: bulkEnrichmentReducer
  }
})
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
