
import _ from "lodash"
import { IFilter } from "../../types"
import { IError,  Operators } from "./types"


export const isEmpty=(allFilters:IFilter[])=>{
    let isValid=true
    const errors:{[key:number]:IError}={}
    allFilters.forEach((singleFilter,index)=>{
        let error:IError={
            columnName:"",value:[],operator:""
        }
        error=singleFilter.column===''?{...error,columnName:"Please select the column"}:error
        error=singleFilter.operator===""?{...error,operator:"Please select the operator"}:error
        error= singleFilter.value.length===0|| singleFilter.value[0]==='' || _.isUndefined(singleFilter.value[0])?{...error,value:["Please select the value"]}:error
        if(singleFilter.operator===Operators.isInRange && singleFilter.value.length===0)
        error={...error,value:["Please select the from date","please select the to date"]}
        else if(singleFilter.operator===Operators.isInRange && singleFilter.value.length===1){
            error={...error,value:["","please select the to date"]}
        }
        if(singleFilter.operator==="" ||singleFilter.column==="" ||singleFilter.value.length===0|| singleFilter.value[0]==="" || (singleFilter.operator===Operators.isInRange && _.isUndefined(singleFilter.value[1]))){
        isValid=false
      }
      errors[index]=error
    })
    if(!isValid)
        return {isValid:false,errors}
    else return {isValid}
}
export const isValidFilter=(allUnsavedFilters:IFilter[],visibleColumns:string[])=>{
    const emptyValidation=isEmpty(allUnsavedFilters)
    const {isValid}=emptyValidation
    if (!isValid)
    return emptyValidation
}