import { IFilter, IIndustryMap } from '../../types'

export const textOperators=[
"is",
"is not",
"contains",
"doesn’t contain",
"starts with",
"ends with",
"doesn’t start with",
"doesn’t end with",
 "has comments",
 "was overridden",
 "validated as"
]

export const numericOperators=[
    "is greater than", "is less than", "equals", "is not equal to", "has comments",
 "was overridden",
 "validated as"
]
export const employee_range_wmd=['1 to 10', '11 to 50', '51 to 200', '201 to 500', '501 to 1000', '1001 to 5000', '5001 to 10000', '10000+']
export const employee_range =['1-10', '11-50', '51-200', '201-500', '501-1000', '1001-5000', '5001-10000', '10000+']
export const linked_account_range=["1","2","3-10","11-99","100+"]
export const webpagesRange=["1","2-5","6-10","11-99","100+"]
export const dateOperators=[ "is on the day", "is in range", "is before", "is on or after", "is in the year"]
export const calenderOperator=["is on the day", "is in range", "is before", "is on or after",]

export const rangeOperator=["is in range"]
export const validationOptions=["correct","incorrect","notSure","notVerified"]
export const  actionFilters=[ "has comments",
 "was overridden",
 "validated as"]
 export const disabledDropdowns=["has comments","was overridden"]
export const booleanColumns=["zzp","active"]
export const booleanValues=["TRUE","FALSE"]
export const dropDownOperatorsColumns=["zzp","active","tenant","country_code","ce_industry_l1","ce_industry_l2","countrycode","country","employee_range","is_https_site","linked_account_range","web_pages_range","category_l1","category_l2","category","is_enriched", "isfreelancer", "company_status", "physical_identifier", "ecommerce_identifier", "website_ssl_status"]
export const tenantOptions=["druck","bas","tradeprint","test","WMD","easyflyer","vistaprint","pixart","test","boxup","exagroup","nationalpen","printdeal"]
export const countryOptions=["US","BE","NL","UK","DE","FR"]
export const countryCodeColumns=["country_code","countrycode","country"]
  export const l1Selected=(unsavedFilters:IFilter[])=>{
    return unsavedFilters.some((f)=>f.column.toLowerCase()==='ce_industry_l1' || f.column.toLowerCase()==='category_l1' )
  }
  export const getL2List=(unsavedFilters:IFilter[],industryMap:IIndustryMap)=>{
    const l1Filter=unsavedFilters.filter((f)=>(f.column.toLowerCase()==='ce_industry_l1' ||f.column.toLowerCase()==='category_l1') && f.value[0]!=='')[0]
    const selectedL1=l1Filter.value[0]
    return industryMap[selectedL1]
  }
  