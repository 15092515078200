import { Snackbar } from "@cimpress/react-components";
import { setSnackBarMessage } from "../features/view/viewSlice";
import { useAppDispatch, useAppSelector } from "../reduxHooks";
import "../styles/snackbar.css";
import { SnackbarStatus } from "../types";
export const SnackBarNoty = (props: {
  message: string;
  status: SnackbarStatus;
}) => {
  const { message, status } = props;
  const snackbar = useAppSelector((state) => state.view.snackbar);
  const dispatch = useAppDispatch();
  const delay = 5000;
  const hideSnackBar = () => {
    dispatch(setSnackBarMessage(""));
  };
  return (
    <Snackbar
      className="snackbar"
      show={snackbar}
      status={status}
      delay={delay}
      onHideSnackbar={hideSnackBar}
    >
      {message}
    </Snackbar>
  );
};
