import axios, { InternalAxiosRequestConfig } from "axios";
import { auth } from "./authentication/authHook";
import { REACT_APP_BASE_URL } from "./config";
import {
  deleteSessionData
} from "./hooks/view-data";
import { Auth0Profile, DeleteCount } from "./types";

export const axiosClient = axios.create({ baseURL: `${REACT_APP_BASE_URL}` });

axiosClient.interceptors.request.use(
  (config: InternalAxiosRequestConfig<any>) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers["Authorization"] = "Bearer " + accessToken;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      auth.logout("/");
      localStorage.clear();
      deleteSessionDataLogOut();
    }
    return Promise.reject(error);
  }
);
function deleteSessionDataLogOut() {
  const profile: Auth0Profile = auth.getProfile();
  const email = profile?.email || "";
  if (email)
    deleteSessionData(
      DeleteCount.All,
      email,
      DeleteCount.Null,
      DeleteCount.Null
    );
}
