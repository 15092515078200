import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteFilter,
  deleteSingleView,
  getAllFilters,
  getAllMappings,
  getData,
  getIndustryMapping,
  getTablesOptions,
  getViewOptions,
  saveUserFilters,
  saveView,
  segmentList,
  shareView,
  updateFilter,
  linkUnlinkCustomerIds,
  getProspectorData,
  getUserGroups,
} from "../../hooks/view-data";
import {
  Auth0Profile,
  IFilter,
  ILinkUnlinkData,
  IProspectorRequestData,
  ISavedFilters,
  IUpdateData,
  IViewRequestData,
  IViewStateType,
} from "../../types";
import { columnData, updateLabel } from "../../hooks/view-data";
import _ from "lodash";
import { auth } from "../../authentication/authHook";
interface ISaveViewRequestData {
  userId: string | undefined;
  tableName: string;
  savedFilters: ISavedFilters[];
  unsavedFilters: IFilter[];
  selectedHeaders: string[];
  sortingColumn: string;
  sortingOrder: string;
  visibility: boolean;
  name: string;
  visibleColumns: string[];
}

export const getViewData = createAsyncThunk(
  "views/getViewData",
  async (viewData: IViewRequestData, { rejectWithValue }) => {
    try {
      const {
        currentPage,
        size,
        viewId,
        savedFilters,
        unsavedFilters,
        selectedTable,
        selectedColumns,
        sortingColumn,
        sortingOrder,
        isSearch,
        searchResponse,
        userId,
        isApplyFilters,
        isSort,
        isRefresh,
        visibleColumns,
        quickFilters,
        filterOp,
      } = viewData;
      const data = {
        currentPage,
        size,
        viewId,
        savedFilters,
        unsavedFilters,
        selectedTable,
        selectedColumns,
        sortingColumn,
        sortingOrder,
        isSearch,
        searchResponse,
        userId,
        isApplyFilters,
        isRefresh,
        visibleColumns,
        isSort,
        quickFilters,
        filterOp,
      };

      if (!_.isUndefined(selectedTable) && selectedTable !== "") {
        const response = await getData(data);
        return response;
      }
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);

export const getProspectorViewData = createAsyncThunk(
  "views/getProspectorViewData",
  async (viewData: IProspectorRequestData, { rejectWithValue }) => {
    try {
      const {
        currentPage,
        size,
        fieldSearchResult,
      } = viewData;
      const data = {
        currentPage,
        size,
        fieldSearchResult,
      };
      const response = await getProspectorData(data);
      return response;
      
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);

export const saveViewData = createAsyncThunk(
  "views/saveViewData",
  async (data: ISaveViewRequestData, { rejectWithValue }) => {
    return await saveView(data);
  }
);
export const deleteSingleFilter = createAsyncThunk(
  "views/deleteSingleFilter",
  async (data: { id: string; filterName: string }) => {
    return await deleteFilter(data.filterName, data.id);
  }
);
export const getColumnData = createAsyncThunk(
  "views/getColumnData",
  async (data: { userId: string }, { rejectWithValue }) => {
    try {
      const { userId } = data;
      return await columnData(userId);
    } catch (err: any) {
      const error = { code: err.code, message: err.message };
      return rejectWithValue(error);
    }
  }
);
export const updateRecordLabel = createAsyncThunk(
  "views/updateRecordLabel",
  async (recordData: IUpdateData, { rejectWithValue }) => {
    try {
      return await updateLabel(recordData);
    } catch (err: any) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const getTables = createAsyncThunk(
  "views/getTables",
  async (data: { userId: string }, { rejectWithValue }) => {
    try {
      const { userId } = data;
      return await getTablesOptions(userId);
    } catch (err: any) {
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const getViews = createAsyncThunk(
  "views/getViews",
  async (data: { userId: string; tableName: string }) => {
    const { userId, tableName } = data;
    return await getViewOptions(userId, tableName);
  }
);
export const saveFilters = createAsyncThunk(
  "views/saveFilters",
  async (
    data: {
      userId: string;
      tableName: string;
      allFilters: IFilter[];
      filterName: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const { userId, tableName, allFilters, filterName } = data;
      return await saveUserFilters(userId, tableName, allFilters, filterName);
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateFilters = createAsyncThunk(
  "views/updateFilters",
  async (data: { updatedFilters: IFilter[]; filterId: string }) => {
    const { updatedFilters, filterId } = data;
    return await updateFilter(updatedFilters, filterId);
  }
);

export const getFilters = createAsyncThunk(
  "views/getFilters",
  async (data: { userId: string; tableName: string }) => {
    const { userId, tableName } = data;
    return await getAllFilters(userId, tableName);
  }
);

export const industryMapping = createAsyncThunk(
  "views/getIndustryMapping",
  async () => {
    return await getIndustryMapping();
  }
);
export const allMappings = createAsyncThunk(
  "views/getAllMappings",
  async () => {
    return await getAllMappings();
  }
);
export const subSegmentList = createAsyncThunk(
  "views/segments",
  async (data: { tenant: string }) => {
    const { tenant } = data;
    return await segmentList(tenant);
  }
);

export const deleteView = createAsyncThunk(
  "views/deleteView",
  async (data: { viewId: string }) => {
    return await deleteSingleView(data.viewId);
  }
);
const profile: Auth0Profile = auth.getProfile();
const email = profile?.email;
export const shareWithOrganization = createAsyncThunk(
  "views/shareWtihOrg",
  async (data: { viewId: string }) => {
    return await shareView(data.viewId);
  }
);

export const linkUnlinkIds = createAsyncThunk(
  "views/linkUnlinkIds",
  async (linkUnlinkData: ILinkUnlinkData, { rejectWithValue }) => {
    try {
      const {
        tableName,
        identifierId,
        actor,
        action,
        idsToLink,
        idsToUnlink,
        organizationId,
      } = linkUnlinkData;
      const data = {
        tableName,
        identifierId,
        actor,
        action,
        idsToLink,
        idsToUnlink,
        organizationId,
      };
      const response = await linkUnlinkCustomerIds(data);
      return response;

    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);

export const getGroups = createAsyncThunk(
  "views/getGroups",
  async (data: { userId: string }, { rejectWithValue }) => {
    try {
      const { userId } = data;
      return await getUserGroups(userId);
    } catch (err: any) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
const initialState: IViewStateType = {
  selectedTable: "",
  tableList: [],
  viewsList: [],
  selectedView: {
    viewId: "default",
    name: "All Columns Visible",
    createdAt: "",
    savedFilters: [],
    unsavedFilters: [],
    selectedHeaders: [],
    sortColumn: "",
    sortOrder: "",
    shared: false,
    userId: email,
    visibleColumns: [],
    quickFilters: [],
  },
  selectedHeaders: [],
  rows: [],
  pageNumber: 1,
  totalRecords: 0,
  pageSize: 100,
  pageCount: 10,
  columnOrder: [],
  notEditableColumns: [],
  isLoading: "idle",
  apiError: "",
  visibleColumns: [],
  selectedAvailableColumn: "",
  selectedVisibleColumn: "",
  snackbar: false,
  snackbarMessage: "",
  snackbarStatus: "info",
  savedFilters: [],
  appliedSavedFilters: [],
  unsavedFilters: [],
  sortColumn: "",
  sortOrder: "DEFAULT",
  renderVisibleColumns: false,
  headerChecked: false,
  selectedRowsIndexes: [],
  industryMap: {},
  fetchData: true,
  callViewsApi: false,
  callFilterApi: false,
  statusArr: [],
  allMappings: {},
  subSegments: [],
  loadingIndustryRows: {},
  loadingWebsiteRows: {},
  loadingEmployeeRows: {},
  allLoading: false,
  userId: email,
  quickFilters: [],
  commentedFilter: false,
  correctRecordFilter: false,
  overwrittenFilter: false,
  validationFilter: undefined,
  permissionError: "",
  filterOP: "OR",
  isDetailedView: false,
  detailedViewTable: "",
  inputFields: {},
  firmographics: {},
  customFields: {},
  linkedCustomerIds: [{}],
  businessFields: {},
  cIdPageNumber: 1,
  isLinkedCid: false,
  clearLinkedCidSearch: false,
  initialDetailedView: true,
  selectedLinkUnlinkCustomerIds: [],
  searchedCorrelationIds: [],
  searchedCustomerIds: [],
  DetailedViewRow: {},
  metaData:{},
  enrichedValues: {},
  dataProviderResponse: {},
  defaultOrgIdMapping:{},
  prospectIsLoading:"idle",
  prospectorColumns:[],
  prospectorRows:[],
  prospectorTotalRecords:0,
  isProspectorFiltered:false,
  prospectorFilteredData:{country:"",field:"",fieldValue:[""]},
  groupList:[],
  checkedStateCorrect:[],
  dependencyCells: [],
  currentRowIndex: null,
};
const viewSlice = createSlice({
  name: "view",
  initialState,
  reducers: {
    setTable(state, action) {
      state.selectedTable = action.payload;
      state.selectedRowsIndexes = [];
      state.headerChecked = false;
      state.fetchData = true;
      state.unsavedFilters = [];
      state.sortColumn = "Default";
      state.sortOrder = "";
      state.correctRecordFilter = false;
      state.overwrittenFilter = false;
      state.commentedFilter = false;
      state.quickFilters = [];
    },
    setView(state, action) {
      state.selectedView = action.payload;
      state.selectedRowsIndexes = [];
      state.headerChecked = false;
      state.fetchData = true;
      state.unsavedFilters = [];
      state.sortColumn = "Default";
      state.sortOrder = "";
      state.correctRecordFilter = false;
      state.overwrittenFilter = false;
      state.commentedFilter = false;
      state.quickFilters = [];
    },
    setTableHeaders(state, action) {
      state.selectedHeaders = action.payload;
    },
    setRows(state, action) {
      state.rows = action.payload;
    },
    setPageNumber(state, action) {
      state.pageNumber = action.payload;

      state.selectedRowsIndexes = [];
      state.headerChecked = false;
    },
    setPageSize(state, action) {
      state.pageSize = action.payload;
      state.selectedRowsIndexes = [];
      state.headerChecked = false;
    },
    setPageCount(state, action) {
      state.pageCount = action.payload;
    },
    setColumnOrder(state, action) {
      state.columnOrder = action.payload;
    },
    setEditableColumns(state, action) {
      state.notEditableColumns = action.payload;
    },
    setVisibleColumns(state, action) {
      state.visibleColumns = action.payload;
    },
    setSelectedAvailableColumn(state, action) {
      state.selectedAvailableColumn = action.payload;
    },
    setSelectedVisibleColumn(state, action) {
      state.selectedVisibleColumn = action.payload;
    },
    updateVisibleColumn(state, action) {
      if (action.payload.type === "ADD") {
        const arr = [...action.payload.payload];
        const visible = state.visibleColumns;
        visible.push(...arr);
        state.visibleColumns = visible;
      } else {
        const arr = [...action.payload.payload];
        const visible = state.visibleColumns;
        const index = visible.indexOf(arr[0]);
        visible.splice(index, 1);
        state.visibleColumns = visible;
      }
    },
    setSnackBar(state, action) {
      state.snackbar = action.payload;
    },
    setSnackBarMessage(state, action) {
      state.snackbarMessage = action.payload;
    },
    setSnackBarStatus(state, action) {
      state.snackbarStatus = action.payload;
    },
    setSortColumn(state, action) {
      state.sortColumn = action.payload;
    },
    setSortOrder(state, action) {
      state.sortOrder = action.payload;
    },
    setRenderVisibleColumns(state, action) {
      state.renderVisibleColumns = action.payload;
    },
    setHeaderChecked(state, action) {
      state.headerChecked = action.payload;
    },
    setSelectedRowIndexes(state, action) {
      state.selectedRowsIndexes = action.payload;
    },
    setUnsavedFilters(state, action) {
      state.unsavedFilters = action.payload;
    },
    setQuickFilters(state, action) {
      state.quickFilters = action.payload;
    },
    setSavedFilters(state, action) {
      state.savedFilters = action.payload;
    },
    setAppliedSavedFilters(state, action) {
      state.appliedSavedFilters = action.payload;
    },
    setLoader(state, action) {
      state.isLoading = action.payload;
    },
    setCorrectRecordFilter(state, action) {
      state.correctRecordFilter = action.payload;
    },
    setOverWrittenFilter(state, action) {
      state.overwrittenFilter = action.payload;
    },
    setCommentedFilter(state, action) {
      state.commentedFilter = action.payload;
    },
    setValidationFilter(state, action) {
      state.validationFilter = action.payload;
    },
    setStatusArr(state, action) {
      state.statusArr = action.payload;
    },
    setLoadingIndustryRows(state, action) {
      state.loadingIndustryRows = action.payload;
    },
    setLoadingWebsiteRows(state, action) {
      state.loadingWebsiteRows = action.payload;
    },
    setLoadingEmployeeRows(state, action) {
      state.loadingEmployeeRows = action.payload;
    },
    setAllLoading(state, action) {
      state.allLoading = action.payload;
    },
    setfilterOP(state, action) {
      state.filterOP = action.payload;
    },
    setisDetailedView(state, action) {
      state.isDetailedView = action.payload;
    },
    setDetailedViewTable(state, action) {
      state.detailedViewTable = action.payload;
    },
    setinputFields(state, action) {
      state.inputFields = action.payload;
    },
    setfirmographics(state, action) {
      state.firmographics = action.payload;
    },
    setcustomFields(state, action) {
      state.customFields = action.payload;
    },
    setlinkedCustomerIds(state, action) {
      state.linkedCustomerIds = action.payload;
    },
    setbusinessFields(state, action) {
      state.businessFields = action.payload;
    },
    setcIdPageNumber(state, action) {
      state.cIdPageNumber = action.payload;
    },
    setIsLinkedCid(state, action) {
      state.isLinkedCid = action.payload;
    },
    setclearLinkedCidSearch(state, action) {
      state.clearLinkedCidSearch = action.payload;
    },
    setinitialDetailedView(state, action) {
      state.initialDetailedView = action.payload;
    },
    setDetailedViewRow(state, action) {
      state.DetailedViewRow = action.payload;
    },
    setSelectedLinkUnlinkCustomerIds(state, action) {
      state.selectedLinkUnlinkCustomerIds = action.payload;
    },
    setSearchedCorrelationIds(state, action) {
      state.searchedCorrelationIds = action.payload;
    },
    setSearchedCustomerIds(state, action) {
      state.searchedCustomerIds = action.payload;
    },
    setMetaData(state, action) {
      state.metaData = action.payload;
    },
    setEnrichedValue(state,action) {
      state.enrichedValues = action.payload;
    },
    setDataProviderResponse(state,action) { 
      state.dataProviderResponse = action.payload;
    },
    setDefaultOrgIdMapping(state, action) {
      state.defaultOrgIdMapping = action.payload;
    },
    setisProspectorFiltered(state, action){
      state.isProspectorFiltered = action.payload;
    },
    setprospectorFilteredData(state, action){
      state.prospectorFilteredData = action.payload;
    },
    setCheckedStateCorrect(state, action){
      state.checkedStateCorrect = action.payload;
    },
    setDependencyCells(state,action){
      state.dependencyCells = action.payload;
    },
    setCurrentRowIndex(state,action){
      state.currentRowIndex = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProspectorViewData.pending, (state,action) => {
        state.prospectIsLoading = "loading";
      })
      .addCase(getProspectorViewData.fulfilled, (state, action) => {
        if (!_.isUndefined(action.payload)) {
          state.prospectorColumns = action.payload.results.length !== 0 ? Object.keys(action.payload.results[0]) : [];
          state.prospectorRows = action.payload.results;
          state.prospectIsLoading = "succeeded";
          state.prospectorTotalRecords = action.payload.total;
        }})
      .addCase(getProspectorViewData.rejected, (state, action: any) => {
        state.prospectIsLoading = "idle";
      })
      .addCase(getViewData.pending, (state, action) => {
        state.isLoading = "loading";
        state.allLoading = false;
      })
      .addCase(getViewData.fulfilled, (state, action) => {
        if (!_.isUndefined(action.payload)) {
          state.selectedHeaders = action.payload.selected_columns;
          state.visibleColumns =
            "visible_columns" in action.payload
              ? action.payload.visible_columns
              : action.payload.selected_columns;
          state.rows = action.payload.data.values;
          state.selectedTable = action.payload.table_name;
          state.totalRecords = action.payload.total_records;
          state.savedFilters = action.payload.saved_filters;
          state.unsavedFilters = action.payload.unsaved_filters;
          state.quickFilters = action.payload.quick_filters;
          state.isLoading = "succeeded";
          state.selectedRowsIndexes = [];
          state.headerChecked = false;
          state.fetchData = false;
          state.isDetailedView = false;
          state.detailedViewTable = action.payload.table_name;
          state.inputFields = {};
          state.firmographics = {};
          state.customFields = {};
          state.businessFields = {};
          state.linkedCustomerIds = [{}];
        }
      })
      .addCase(getViewData.rejected, (state, action: any) => {
        state.isLoading = "idle";
        state.apiError = action.payload.data.message;
        state.fetchData = false;
      })
      .addCase(getColumnData.fulfilled, (state, action) => {
        state.columnOrder = action.payload.columnOrder;
        state.notEditableColumns = action.payload.notEditableColumns;
      })
      .addCase(getColumnData.rejected, (state, action) => {})
      .addCase(updateRecordLabel.fulfilled, (state, action) => {})
      .addCase(updateRecordLabel.rejected, (state, action: any) => {
        state.isLoading = "failed";
        state.apiError = action.error;
        state.snackbarMessage = action.payload.errors;
      })
      .addCase(getTables.fulfilled, (state, action) => {
        state.tableList = action.payload.tables;
        state.selectedRowsIndexes = [];
        state.permissionError = "";
      })
      .addCase(getTables.rejected, (state, action: any) => {
        state.permissionError = action.payload;
        state.isLoading = "idle";
      })
      .addCase(getViews.fulfilled, (state, action) => {
        state.viewsList = action.payload.views.map((view: any) => ({
          viewId: view.view_id,
          name: view.name,
          createdAt: view.created_at,
          savedFilters: view.saved_filters,
          unsavedFilters: view.unsaved_filters,
          quickFilters: view.quick_filters,
          selectedHeaders: view.selected_columns,
          sortColumn: view.sorting_column,
          sortOrder: view.sorting_order,
          shared: view.visibility,
          userId: view.user_id,
          visibleColumns: view.visible_columns,
        }));
        state.selectedRowsIndexes = [];
        state.headerChecked = false;
      })
      .addCase(saveViewData.fulfilled, (state, action) => {
        state.snackbar = true;
        state.snackbarStatus = "success";
        state.snackbarMessage = action.payload.message;
        state.isLoading = "succeeded";
        state.viewsList = action.payload.views.map((view: any) => ({
          viewId: view.view_id,
          name: view.name,
          createdAt: view.created_at,
          savedFilters: view.saved_filters,
          unsavedFilters: view.unsaved_filters,
          selectedHeaders: view.selected_columns,
          sortColumn: view.sorting_column,
          quickFilters: view.quick_filters,
          sortOrder: view.sorting_order,
          shared: view.visibility,
          userId: view.user_id,
          visibleColumns: view.visible_columns,
        }));
        let savedView = state.viewsList.filter(
          (view) => view.viewId === action.payload.view_added.view_id
        );
        state.selectedHeaders = savedView[0].selectedHeaders;
        state.selectedView = savedView[0];
      })
      .addCase(saveViewData.pending, (state, action) => {
        state.isLoading = "loading";
      })
      .addCase(saveViewData.rejected, (state, action) => {
        state.snackbar = true;
        state.snackbarStatus = "danger";
        state.isLoading = "idle";
        state.snackbarMessage = "View could not be created";
      })
      .addCase(saveFilters.fulfilled, (state, action) => {
        state.snackbarStatus = "success";
        state.snackbarMessage = action.payload.message;
        state.isLoading = "succeeded";
        state.snackbar = true;
      })
      .addCase(saveFilters.pending, (state, action) => {
        state.isLoading = "loading";
      })
      .addCase(saveFilters.rejected, (state, action: any) => {
        state.isLoading = "idle";
        state.snackbarMessage = action.payload.errors;
        state.snackbarStatus = "danger";
        state.snackbar = true;
      })
      .addCase(getFilters.fulfilled, (state, action) => {
        state.savedFilters = action.payload.map((filter: any) => ({
          filterId: filter.filter_id,
          name: filter.filter_name,
          filters: filter.filters,
        }));
        state.snackbar = true;
        state.snackbarStatus = "success";
        state.snackbarMessage = action.payload.message;
        state.callFilterApi = false;
      })
      .addCase(industryMapping.fulfilled, (state, action) => {
        state.industryMap = action.payload;
      })
      .addCase(allMappings.fulfilled, (state, action) => {
        state.allMappings = action.payload;
      })
      .addCase(subSegmentList.fulfilled, (state, action) => {
        state.subSegments = action.payload.sub_segments;
      })
      .addCase(deleteSingleFilter.fulfilled, (state, action) => {
        state.snackbar = true;
        state.snackbarStatus = "success";
        state.snackbarMessage = action.payload.message;
        state.isLoading = "succeeded";
        state.callFilterApi = true;
      })
      .addCase(deleteSingleFilter.pending, (state, action) => {
        state.isLoading = "loading";
      })
      .addCase(updateFilters.fulfilled, (state, action) => {
        state.snackbar = true;
        state.snackbarStatus = "success";
        state.snackbarMessage = action.payload.message;
        state.isLoading = "succeeded";
      })
      .addCase(updateFilters.pending, (state, action) => {
        state.isLoading = "loading";
      })
      .addCase(linkUnlinkIds.fulfilled, (state, action) => {
        state.searchedCorrelationIds = [];
        state.selectedLinkUnlinkCustomerIds = [];
        state.searchedCustomerIds = [];
      })
      .addCase(linkUnlinkIds.rejected, (state, action: any) => {
        state.snackbarMessage = action.payload.errors;
        state.snackbarStatus = "danger";
      })
      .addCase(getGroups.fulfilled, (state, action) => {
        state.groupList = action.payload;
      })
  },
});

export const {
  setColumnOrder,
  setEditableColumns,
  setTable,
  setView,
  setTableHeaders,
  setPageSize,
  setPageNumber,
  setPageCount,
  setRows,
  setLoader,
  setSnackBar,
  setSelectedAvailableColumn,
  setSelectedVisibleColumn,
  setVisibleColumns,
  updateVisibleColumn,
  setSortColumn,
  setSortOrder,
  setSnackBarStatus,
  setSnackBarMessage,
  setRenderVisibleColumns,
  setHeaderChecked,
  setSelectedRowIndexes,
  setUnsavedFilters,
  setSavedFilters,
  setAppliedSavedFilters,
  setStatusArr,
  setLoadingIndustryRows,
  setLoadingWebsiteRows,
  setLoadingEmployeeRows,
  setAllLoading,
  setQuickFilters,
  setCommentedFilter,
  setCorrectRecordFilter,
  setOverWrittenFilter,
  setValidationFilter,
  setfilterOP,
  setisDetailedView,
  setDetailedViewTable,
  setinputFields,
  setfirmographics,
  setcustomFields,
  setlinkedCustomerIds,
  setbusinessFields,
  setcIdPageNumber,
  setIsLinkedCid,
  setclearLinkedCidSearch,
  setinitialDetailedView,
  setDetailedViewRow,
  setSelectedLinkUnlinkCustomerIds,
  setMetaData,
  setSearchedCorrelationIds,
  setSearchedCustomerIds,
  setEnrichedValue,
  setDataProviderResponse,
  setDefaultOrgIdMapping,
  setisProspectorFiltered,
  setprospectorFilteredData,
  setCheckedStateCorrect,
  setDependencyCells,
  setCurrentRowIndex
} = viewSlice.actions;

export default viewSlice.reducer;
