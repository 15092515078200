import { Select } from "@cimpress/react-components";
import _ from "lodash";
import React, { useEffect, useState, useMemo } from "react";
import { AuthContext } from "../../context/authContext";
import { getGroups } from "../../features/view/viewSlice";
import { setTenant } from "../../features/bulkEnrichment/bulkEnrichmentSlice";
import { useAppDispatch, useAppSelector } from "../../reduxHooks";
import { ITableOption, ITenantOption } from "../../types";
import "./BulkEnrich.css";
import { tenants } from "../../utils/commonUtility";

export function TenantDropDown() {
  const { profile } = React.useContext(AuthContext);
  const emailId = profile?.email;
  const canonicalId = profile?.["https://claims.cimpress.io/canonical_id"];
  const userId = canonicalId ? canonicalId : emailId;
  const dispatch = useAppDispatch();
  const defaultOption = { label: "Select a tenant", value: "Select a tenant" };
  const initialTenant = tenants.length > 0
    ? { label: tenants[0], value: tenants[0] }
    : defaultOption;

  const [tenant, setSelectedTenant] = useState<ITenantOption>(initialTenant);
  const { selectedTenant } = useAppSelector((state) => state.bulkEnrichment);

  useEffect(() => {
    if (userId) dispatch(getGroups({ userId })); // eslint-disable-next-line
  }, []);

  const defaultOptions: ITenantOption[] = useMemo(() => {
    return tenants.length > 0
      ? _.uniq(tenants).map((tenant, index) => ({
          label: <div id={`tenant-dropdown-item-${index}`}>{tenant}</div>,
          value: tenant,
        }))
      : [];// eslint-disable-next-line
  }, [tenants]);

  const onSingleSelectionChange = (tenant: ITableOption) => {
    if (tenant.value === selectedTenant) {
      return;
    }
    setSelectedTenant(tenant);
    dispatch(setTenant(tenant.value));
  };
  return (
    <Select
      id="table-dropdown"
      className="tenant-dropdown-select"
      label={undefined}
      isSearchable={false}
      value={tenant}
      options={defaultOptions}
      onChange={onSingleSelectionChange}
      helpText={"Select the tenant for enrichment"}
    />
  );
}
