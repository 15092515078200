import { BreadcrumbItem, Breadcrumbs, Button } from "@cimpress/react-components";
import { useNavigate } from "react-router-dom";
import { setTable } from "../../features/view/viewSlice";
import { useAppDispatch } from "../../reduxHooks";
import { Buffer } from "buffer";

export function BreadCrumbs(props: { items: string[] }) {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const searchParams = new URLSearchParams(window.location.search);
	const encodedTable = searchParams.get("tableName") || "";
	const searchTableName = Buffer.from(encodedTable, "base64").toString("ascii");
	const renderAllRecords = (path: string) => {
		if (searchTableName) dispatch(setTable(searchTableName));
		navigate(`${path}`);
	};
	return (
		<Breadcrumbs style={{ fontSize: "12px", fontFamily: "Open Sans" }}>
			{props.items.map((path, index: number) => {
				let isActive = index === props.items.length - 1;
				let breadcrumb = !isActive ? (
					<BreadcrumbItem style={{ fontSize: "12px", fontFamily: "Open Sans" }} key={index}>
						<Button
							style={{ fontSize: "12px", fontFamily: "Open Sans" }}
							variant="anchor"
							onClick={() => renderAllRecords(path.trim())}
						>
							{path}
						</Button>
					</BreadcrumbItem>
				) : (
					<BreadcrumbItem style={{ fontSize: "12px", fontFamily: "Open Sans" }} active key={index}>
						{path}
					</BreadcrumbItem>
				);
				return breadcrumb;
			})}
		</Breadcrumbs>
	);
}
