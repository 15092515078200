export interface AuthConfig {
  EVO_PORTAL_CLIENT_ID: string;
  REDIRECT_ROUTE: string;
  DOMAIN: string;
  AUDIENCE: string;
  SCOPE: string;
}
export const overWritableColumns = [
  "WEBSITE",
  "COMPANY_WEBSITE",
  "ESTABLISHMENT_YEAR",
  "ESTABLISHED_YEAR",
  "YEAR_OF_ESTABLISHMENT",
  "DATE_OF_ESTABLISHMENT",
  "EMPLOYEE_COUNT",
  "NUMBER_OF_EMPLOYEES",
  "GLOBAL_EMPLOYEE_COUNT",
  "LOCAL_EMPLOYEE_COUNT",
  "EMPLOYEES",
  "CE_INDUSTRY_L1",
  "CE_INDUSTRY_L2",
  "SBI",
  "ZZP",
  "REVENUE_IN_USD",
  "REVENUE",
  "REVENUE_IN_EUROS",
  "REVENUE_USD",
  "REVENUE_EUROS",
  "CATEGORY_L1",
  "CATEGORY_L2",
  "CATEGORY",
  "SUB_SEGMENT",
  "INDUSTRY_CONFIDENCE_SCORE"
];
export const notEditableColumns = [
  "COMPANY_NAME",
  "CUSTOMER_COMPANY_NAME",
  "ORGANIZATION_ID",
  "ORGANIZATION_NAME",
  "CUSTOMERID",
  "CUSTOMER_ID",
  "CUSTOMER_CORRELATION_ID",
  "CUSTOMER_NUMBER",
  "KUNDENNUMMER",
  "EMAIL_ID",
  "EMAIL",
  "COUNTRY",
  "STREET",
  "POSTCODE",
  "CITY",
  "STREET",
  "COUNTRY_CODE",
  "COUNTRYCODE",
  "PLACE",
  "EMAILID",
  "COMAPANY_NAME",
  "INDUSTRY_CONFIDENCE_SCORE"
];
export const linkColumns = ["WEBSITE", "COMPANY_WEBSITE"];

export const authConfigs = {
  clientID: "yZRpCNaBz8EpFMuTnekkbe1nswmUKJKF",
  redirectRoute: "/",
  domain: "cimpress.auth0.com",
  audience: "https://api.cimpress.io/",
  scope: "offline_access",
  logoutRedirectUri: "/",
};
export const prodConfigs = {
  clientID: "I9RdCv23obDN5apayGzhQcUfTQJQJEFN",
  redirectRoute: "/",
  domain: "cimpress.auth0.com",
  audience: "https://api.cimpress.io/",
  scope: "offline_access",
  logoutRedirectUri: "/",
};
export const isStage = window.location.hostname.startsWith("stg");
export const isProd = window.location.hostname.startsWith("enrichment");
export const isDev = window.location.hostname.startsWith("tst");
export const REACT_APP_BASE_URL =
  (isProd &&
    "https://prod-cluster.mat.cimpress.io/customer-enrichment-api/portal/v0") ||
  ((isStage || isDev) &&
    "https://integration-dc.mat.cimpress.io/customer-enrichment-api/portal/v0") ||
  "http://127.0.0.1:8000/customer-enrichment-api/portal/v0";

export const DEBOUNCE_TIME = 80;
export const MAX_SEARCH_CHARACTERS = 50;

export const NOT_SEARCH = window.location.href.indexOf("search") === -1;
export const INTERNAL_VALIDATION_TABLES = ["CE_INTERNAL_VALIDATION_DATA"];

export const WMD_TABLES = ["WMD_CE_V2", "WMD_CE_V2_TEST_TABLE"];

export const PRINTDEAL_TABLES = [
  "PRINTDEAL_NL_CE_V2",
  "PRINTDEAL_BE_CE_V2",
  "PRINTDEAL_NL_CE_V2_TEST_TABLE",
  "PRINTDEAL_BE_CE_V2_TEST_TABLE",
  "PRINTDEAL_BE_CE_V2_TEST",
  "PRINTDEAL_NL_CE_V2_TEST",
  "PRINTDEAL_GLOBAL_CE_V2",
  "PRINTDEAL_GLOBAL_CE_V2_TEST",
];

export const VISTA_TABLES = [
  "VISTAPRINT_CE",
  "EVO_DEMO_DATA",
  "EVO_TEST_TABLE",
];

export const PIXART_TABLES = [
  "PIXART_CE_V2",
  "EASYFLYER_CE_V2",
  "PIXART_CE_V2_TEST_TABLE",
  "EASYFLYER_CE_V2_TEST_TABLE",
];

export const matchingColumns = [
  "KUNDENNUMMER",
  "CUSTOMER_ID",
  "CUSTOMERID",
  "CUSTOMER_CORRELATION_ID",
];

export const linkedMessageMap: Record<string, string> = {
  WMD_CE_V2: "Linked Kundennummern",
  WMD_CE_V2_TEST_TABLE: "Linked Kundennummern",
  VISTAPRINT_CE: "Linked Customer Correlation IDs",
  EVO_DEMO_DATA: "Linked Customer Correlation IDs",
  EVO_TEST_TABLE: "Linked Customer Correlation IDs",
};

export const linkTitleMap: Record<string, string> = {
  WMD_CE_V2: "Link/Unlink Kundennummern",
  WMD_CE_V2_TEST_TABLE: "Link/Unlink Kundennummern",
  VISTAPRINT_CE: "Link/Unlink Customer Correlation IDs",
  EVO_DEMO_DATA: "Link/Unlink Customer Correlation IDs",
  EVO_TEST_TABLE: "Link/Unlink Customer Correlation IDs",
};

export const displayIdMap: Record<string, string> = {
  WMD_CE_V2: "Kundennummern",
  WMD_CE_V2_TEST_TABLE: "Kundennummern",
  VISTAPRINT_CE: "Customer Correlation IDs",
  EVO_DEMO_DATA: "Customer Correlation IDs",
  EVO_TEST_TABLE: "Customer Correlation IDs",
};

export const searchLabels: Record<string, string> = {
  WMD_CE_V2: "Search Kundennummern",
  WMD_CE_V2_TEST_TABLE: "Search Kundennummern",
  VISTAPRINT_CE: "Search Customer Correlation IDs",
  EVO_DEMO_DATA: "Search Customer Correlation IDs",
  EVO_TEST_TABLE: "Search Customer Correlation IDs",
  PRINTDEAL_NL_CE_V2: "Search Customer IDs",
  PRINTDEAL_NL_CE_V2_TEST_TABLE: "Search Customer IDs",
  PRINTDEAL_BE_CE_V2: "Search Customer IDs",
  PRINTDEAL_BE_CE_V2_TEST_TABLE: "Search Customer IDs",
  PIXART_CE_V2: "Search Customer IDs",
  PIXART_EASYFLYER_CE_V2: "Search Customer IDs",
  PIXART_CE_V2_TEST_TABLE: "Search Customer IDs",
  PIXART_EASYFLYER_CE_V2_TEST_TABLE: "Search Customer IDs",
};

export const multiselectStyle = {
  option: { background: "#fff", color: "black" },
  chips: {
    background: "#0088A9",
  },
};
export const nalaAPIPrefix = "https://nala.cimpress.io/";
export const contentIdMap = {
  newTenant: "26393",
  existingTenant: "26075",
  enrichmentId: "22960"
};

export const configurationIdMap: Record<string, string> = {
  '26075': 'd7241592-f40b-4cd9-862b-28ca3926eb6c',
  '26393': '116a444f-30aa-486a-a37d-ab00cd23f698',
  '22960': '61fc25d8-43d3-477e-abfd-769d6a6aba8b',
};

export const defaultConfigurationId = '116a444f-30aa-486a-a37d-ab00cd23f698';

export const IS_ENRICHED_COLUMN = "IS_ENRICHED";
export const IS_FREELANCER_COLUMN = "ISFREELANCER"
export const COMPANY_STATUS_COLUMN = "COMPANY_STATUS"
export const PHYSICAL_IDENTIFIER_COLUMN = "PHYSICAL_IDENTIFIER"
export const ECOMMERCE_IDENTIFIER_COLUMN = "ECOMMERCE_IDENTIFIER"
export const WEBSITE_SSL_STATUS_COLUMN = "WEBSITE_SSL_STATUS"

export const countryList = ["US", "FR"];
export const companyName = [
  "COMPANY_NAME",
  "CUSTOMER_COMPANY_NAME",
  "company_name",
];
export const OptionDrop = [
  "industry_l1",
  "company_name",
  "industry_l2",
  "employee_range",
];
export const NEW_ENRICH_TAB = "New Enrich";
export const ENRICH_HISTORY_TAB = "Enrich History";
export const formats = {
  ".csv": [],
  "text/csv": [],
  "application/csv": [],
  "text/x-csv": [],
  "application/x-csv": [],
  "text/comma-separated-values": [],
  "text/x-comma-separated-values": [],
};
export const OVERWRITE_ACTION = "overwrite";
export const enrichment_template = "EnrichmentTemplate.csv";
export const IMAGE_ACCEPT_FORMAT = {
  'image/jpg': [],
  'image/jpeg': [],
  'image/png': [],
}