const globalEmployeeCountOptions=["is much higher than the value listed here","is lesser than the value listed here"];
const employeeCountOptions=["Emp Count is more than the value listed here","Emp Count is less than the value listed here"]
const localEmployeeCountOptions=["is more than the value listed here","is less than the value listed here"]
const establishmentYearOptions=["Date of Establishment lies outside the Establishemnt range","Date is incorrect","Year value is incorrect","Month is incorrect"]
const revenueDefaultOptions=["Revenue is lesser than the value listed here","Revenue is greater than the value listed here"]

const sbiOptions=["SBI code captured correctly",
"Company is a Reseller",
"SBI code doesn’t belong to the Industry ",
"Sbi Description doesn’t match SBI Code"]
const websitedefaultOptions = [
  "The website isn't working",
  "The domain has expired"
];
const sbiDescription=[" Sbi description doesn’t match SBI Code"]
const sbi2DigitDescription=[" Sbi 2 description doesn’t match SBI2 Code"]
const zzpOptions=["Maked incorrectly as true, this isn't a freelancer","Maked incorrectly as false, this is a freelancer"]
const caseNumberOptions=["doesn’t belong to the organization id"]
const activeOptions=["Falsely marked as true, the company isn't active","Falsely marked as false, the company is active"]
const existingTradeNameOptions=["Existing trade name value doesn’t match company name","Existing trade name value correctly captured"]
const houseNumberOptions=["House number doesn’t exist in the area"]
const industryL1Options=["L1 Industry is not related to L2 Industry"]
const industryL2Options=["L2 Value is not a sub-type of L1"]

const descriptionOptions=["Descriprtion captured isnt associtaed with the company. The industry for the company is different from what is inferred from description"]
const postcodeOptions=["No such postcode exists in the area"]
const streetOptions=["No such street exists in the area","Street name is correctly captured"]
const tradeNameOptions=["Trade name value doesn’t match company name"]
export const commentsMap:{[key:string]:string[]}={
   "description":descriptionOptions,
   "global_employee_count":globalEmployeeCountOptions,
   "local_employee_count":localEmployeeCountOptions,
   "employees":employeeCountOptions,
   "no_of_employees":employeeCountOptions,
   "number_of_employees":employeeCountOptions,
   "employee_count":employeeCountOptions,
   "date_of_establishment":establishmentYearOptions,
   "year_of_establishment":establishmentYearOptions,
   "established_year":establishmentYearOptions,
   "establishment_year":establishmentYearOptions,
   "establishment":establishmentYearOptions,
   "revenue":revenueDefaultOptions,
   "revenue_in_usd":revenueDefaultOptions,
   "revenue_usd":revenueDefaultOptions,
   "revenue_in_euros":revenueDefaultOptions,
   "sbi":sbiOptions,
   "sbi_description":sbiDescription,
   "sbi_2_digit_code_description":sbi2DigitDescription,
   "sbi_sector_code_description":sbi2DigitDescription,
   "zzp":zzpOptions,
   "website":websitedefaultOptions,
   "case_number":caseNumberOptions,
   "active":activeOptions,
   "existing_trade_name_1":existingTradeNameOptions,
   "existing_trade_name_2":existingTradeNameOptions,
   "existing_trade_name_3":existingTradeNameOptions,
   "existing_trade_name_4":existingTradeNameOptions,
   "existing_trade_name_5":existingTradeNameOptions,
   "house_number":houseNumberOptions,
   "statutory_trade_name_1":existingTradeNameOptions,
   "statutory_trade_name_2":existingTradeNameOptions,
   "statutory_trade_name_3":existingTradeNameOptions,
   "statutory_trade_name_4":existingTradeNameOptions,
   "ce_industry_l1":industryL1Options,
   "ce_industry_l2":industryL2Options,
    "subtype":["Type & sub-type are not inter-related"],
    "sub_type":["Type & sub-type are not inter-related"],
    "postcode":postcodeOptions,
    "post_code":postcodeOptions,
    "street":streetOptions,
    "trade_name":tradeNameOptions
}


export const partialOptionFields={
   "global_employee_count":true,
   "local_employee_count":true,
   "sbi_2_digit_code":true,
   "sbi_sector_code":true,
  "case_number":true,
}
