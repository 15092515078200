import React from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import pattern_1 from "../../assets/pattern_1.png";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import pattern_2 from "../../assets/pattern_2.png";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SearchLeft from "../../assets/SearchLeft.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SearchRight from "../../assets/SearchRight.svg";
import Welcome from "./welcome";
import "../../styles/AccessPortalStyles/welcome.css";
import { AuthContext } from "../../context/authContext";

const determineBackground = (): string => {
	const currentPattern = sessionStorage.getItem("currentPattern");
	if (currentPattern) return currentPattern;
	const availablePatterns = [pattern_1, pattern_2];
	const newCurrentPattern = availablePatterns[Math.floor(Math.random() * availablePatterns.length)];
	sessionStorage.setItem("currentPattern", newCurrentPattern);
	return determineBackground();
};

export const TopBanner = (props: { message?: string }) => {
	const { profile } = React.useContext(AuthContext);
	return (
		// <div className="discovery-container">
		<div className="section-jumbo__container">
			<section
				className="section-jumbo"
				style={{
					background: `url(${determineBackground()}) no-repeat`,
					backgroundPosition: "100% 20%",
					backgroundSize: "cover",
				}}
			>
				<img className="section-jumbo--left" src={SearchLeft} alt="" />
				<img className="section-jumbo--right" src={SearchRight} alt="" />
				<div className="section-jumbo__content">
					<Welcome user={profile?.name} message={props.message} />
				</div>
			</section>
		</div>
		// </div>
	);
};
