import { Pagination, Select } from "@cimpress/react-components";
import "../styles/prospectorPagination.css"
import { useAppSelector } from "../reduxHooks";
import { useState } from "react";
export const ProspectorTablePagination = (props: {
    getdata(pageNumber: number, pageSize: number): void;
    setprospectPageNumber:any, setprospectPageSize:any
  }) => {
    const [prospectorPageNumber, setProspectorPageNumber] = useState(1);
    const [prospectorPageSize, setProspectorPageSize] = useState(100);
    const {prospectorTotalRecords} = useAppSelector((state) => state.view);
    const pageSizes = [
        { label: "50", value: 50 },
        { label: "100", value: 100 },
      ];
    const totalPages = Math.ceil(prospectorTotalRecords / prospectorPageSize);
    const handlePageChange = ({ selected }: { selected: number }) => {
        setProspectorPageNumber(selected);
        props.setprospectPageNumber(selected)
        props.getdata(selected, prospectorPageSize);
    }
    const handlePageSizeChange = (selected: { label: string; value: number }) => {
        setProspectorPageSize(selected.value);
        props.setprospectPageSize(selected.value);
        props.getdata(prospectorPageNumber, selected.value);
      };
    return(<div className="prospector-pagination-wrapper">
    <div className="prospector-page-size-selector">
      <Select
        className="prospector-page-selector-dropdown"
        isClearable={false}
        value={{value:prospectorPageSize,label:prospectorPageSize}}
        options={pageSizes}
        onChange={handlePageSizeChange}
        menuPlacement="auto"
        hideSelectedOptions={true}
        menuPosition="absolute"
      />
    </div>
    <div className="prospector-page-selector" >
      <Pagination
        initialPage={0}
        pageCount={totalPages}
        onPageChange={handlePageChange}
        pagesOutsideElipses={1}
        pagesBetweenElipses={3}
      />
    </div>
  </div>);
}