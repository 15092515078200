import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { lastUpdatedTime } from '../../utils/commonUtility';

interface LastUpdatedProps {
  lastUpdated: string;
}
export const LastUpdated: React.FC<LastUpdatedProps> = ({ lastUpdated }) => {
  const [timeAgo, setTimeAgo] = useState<string>('');
  const calculateTimeAgo = () => {
    const now = dayjs();
    const lastUpdatedTime = dayjs(lastUpdated);
    const diffInMinutes = now.diff(lastUpdatedTime, 'minute'); 
    if (diffInMinutes < 1) {
      setTimeAgo('just now');
    } else if (diffInMinutes === 1) {
      setTimeAgo('1 minute ago');
    } else {
      setTimeAgo(`${diffInMinutes} minutes ago`);
    }
  };
  useEffect(() => {
    calculateTimeAgo();
    const interval = setInterval(() => {
      calculateTimeAgo();
    }, lastUpdatedTime);

    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdated]); 

  return <div>Last updated: Data refreshed {timeAgo}.</div>;
};
