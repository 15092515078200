import React from 'react';
import '../dataProvider/dataProviderSections.css'
import { handleSourceName } from './dataProvidersView';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import IconInformationCircle from "@cimpress-technology/react-streamline-icons/lib/IconInformationCircle";
import { Tooltip } from '@cimpress/react-components';

export const DataProviderSections = (props: { data: { [key: string]: string }; title: string }) => {
    const kboOrder = [ "nace_code","region","establishment_year", "address", "postCode", "name"];
    const knowledgeGraphOrder = ["type", "website","category", "address", "company_name"];
    const hitHorizonsOrder = [ "revenue","website", "establishment_year", "company_name"];
    const PeopleDataLabsOrder = ["type", "website", "employee_count","address", "size", "company_name"];
    const googlePlacesOrder = ["type", "website","company_name", "address"];
    const kvkOrder = ["type","website","establishment_year","street","employees","sbi","postcode"]
    
    const handleProviderDataElement = (data: { [key: string]: string }, element: string) => {
        if (element !== "sourceName") {
            return (<div className='data-provider-section-body' data-testid='data-provider-section-body'
                id='data-provider-section-body' key={element}>
                <div className='provider-content-header' data-testid='provider-content-header'
                    id='provider-content-header'>
                    {handleSourceName(element.toUpperCase())}
                </div>
                <div className='provider-content-body' data-testid='provider-content-body'
                    id='provider-content-body'>
                    {data[element]}
                </div>
            </div>)
        }
        else return <div key={element}></div>
    }
    const handleProviderData = (data: { [key: string]: string }) => {
        const data_map: any = {
            "kbo": kboOrder.map(kboElement => {
                return handleProviderDataElement(data, kboElement)
            }), "hitHorizons_search_data_vat_number": hitHorizonsOrder.map(hitHorizonElement => {
                return handleProviderDataElement(data, hitHorizonElement)
            }), "hitHorizons": hitHorizonsOrder.map(hitHorizonElement => {
                return handleProviderDataElement(data, hitHorizonElement)
            }), "hitHorizons_search_data_kvk_number": hitHorizonsOrder.map(hitHorizonElement => {
                return handleProviderDataElement(data, hitHorizonElement)
            }),
            "knowledge_graph": knowledgeGraphOrder.map(knowledgeGraphElement => {
                return handleProviderDataElement(data, knowledgeGraphElement)
            }), "PeopleDataLabs": PeopleDataLabsOrder.map(PeopleDataLabsElement => {
                return handleProviderDataElement(data, PeopleDataLabsElement)
            }), "google_places": googlePlacesOrder.map(googlePlacesElement => {
                return handleProviderDataElement(data, googlePlacesElement)
            }), "kvk": kvkOrder.map(kvkElement => {
                return handleProviderDataElement(data, kvkElement)
            })
        }
        if (data["sourceName"]) return data_map[data["sourceName"]]
        else return <div></div>
    }
    return (
        <div className='data-provider-section' data-testid='data-provider-section'
            id='data-provider-section' >
            <div>
                <div className='data-provider-section-header' data-testid='data-provider-section-header'
                    id='data-provider-section-header'>
                    <div className='data-provider-section-title'>{props.title}
                        <div style={{ marginLeft: "10px" }}><Tooltip
                            direction="top"
                            contents={
                                <div className="and-or-tooltip" data-testid="and-or-tooltip"
                                    id="and-or-tooltip">
                                    This section contains data that comes from data provider {props.title}. You can cross-verify the enriched data values by comparing with this data provider
                                </div>
                            }
                        >
                            <IconInformationCircle weight="fill" color="#0088A9" size="lg" />
                        </Tooltip></div>
                    </div>
                </div>
            </div>
            <div>
                {handleProviderData(props.data)}
            </div>

        </div>
    )

}
