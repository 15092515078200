import { useEffect, useState } from "react";
import { Auth0Profile, AuthenticationProps } from "../types";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { centralizedAuth } from "@cimpress/simple-auth-wrapper";
import { authConfigs, prodConfigs } from "../config";
import { postUserAcitivity } from "../hooks/view-data";

const environment = window.location.hostname;
const options = environment.startsWith("enrichment")
  ? prodConfigs
  : authConfigs;
export const auth = new centralizedAuth(options);
export const useAuthentication = (): AuthenticationProps => {
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useState<string>("");
  const [error, setError] = useState<Error | undefined>(undefined);
  const [profile, setProfile] = useState<Auth0Profile | undefined>(undefined);

  const logout = async () => {
    await auth.logout("/");
  };

  const login = async () => {
    if (!accessToken) {
      const nextUri = window.location.pathname + window.location.search;
      setError(undefined);
      setIsAuthenticating(true);
      try {
        await auth.ensureAuthentication({ nextUri });
        const profile: Auth0Profile = auth.getProfile();
        const accessToken = auth.getAccessToken();
        setProfile(profile);
        setAccessToken(accessToken);
        postUserAcitivity(profile.email, profile.updated_at);
      } catch (error: any) {
        setError(error);
      }
      setIsAuthenticating(false);
    }
  };

  useEffect(() => {
    login(); // eslint-disable-line react-hooks/exhaustive-deps
  }, [accessToken]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    isAuthenticating,
    profile,
    accessToken,
    authenticationError: error,
    login: () => login(),
    logout,
  };
};
