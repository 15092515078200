import { startCase } from "lodash";
import { useAppSelector, useAppDispatch } from "../../reduxHooks";
import {
  setSelectedAvailableColumn,
  setSelectedVisibleColumn,
} from "../../features/view/viewSlice";
import "./editColumns.css";
import { notEditableColumns } from "../../config";
import Sortable, { SortableEvent } from "sortablejs";


// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React, { useEffect, useRef } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ArrowCircleLeft from "../../assets/arrow-circle-left.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ArrowCircleRight from "../../assets/arrow-circle-right.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ArrowCircleLeftDisabled from "../../assets/arrow-circle-left-disabled.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ArrowCircleRightDisabled from "../../assets/arrow-circle-right-disabled.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import LockIcon from "../../assets/lock.svg";
export const nonHideableColumns = [
  "CUSTOMERID",
  "CUSTOMER_ID",
  "CUSTOMER_CORRELATION_ID",
  "CUSTOMER_NUMBER",
  "ORGANIZATION_ID",
  "COMPANY_NAME",
  "CUSTOMER_COMPANY_NAME",
  "ORGANISATION_ID",
  "COMAPANY_NAME",
  "KUNDENNUMMER",
  "ENRICHMENT_CORRELATION_ID",
  "IS_ENRICHED"
];

export function AvailableColumns(props: {
  visibleColumns: string[];
  setVisibleColumns: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  const dispatch = useAppDispatch();
  const { selectedAvailableColumn, selectedVisibleColumn, selectedHeaders } =
    useAppSelector((state) => state.view);

  const setSelectedAvailable = (column: string) => {
    dispatch(setSelectedAvailableColumn(column));
    dispatch(setSelectedVisibleColumn(""));
  };
  const setVisibleSelected = (column: string) => {
    dispatch(setSelectedVisibleColumn(column));
    dispatch(setSelectedAvailableColumn(""));
  };

  const selectedRight = React.useMemo(
    () =>
      selectedAvailableColumn ? (
        <img alt="" src={ArrowCircleRight} />
      ) : (
        <img alt="" src={ArrowCircleRightDisabled} />
      ),
    [selectedAvailableColumn]
  );
  const selectedLeft = React.useMemo(
    () =>
      selectedVisibleColumn ? (
        <img alt="" src={ArrowCircleLeft} />
      ) : (
        <img alt="" src={ArrowCircleLeftDisabled} />
      ),
    [selectedVisibleColumn]
  );

  const availableArr: string[] = [];
  const visibleArr: string[] = [];

  const sortableContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (sortableContainerRef.current) {
      const sortableContainer = document.getElementById("sortableContainer");
      const sortable = Sortable.create(sortableContainerRef.current, {
        animation: 250, // Animation speed in milliseconds (optional)
        onEnd: (event: SortableEvent) => {
          if (sortableContainer) {
            const sortedItems = Array.from(sortableContainer.children).map(
              (item: Element) => (item as HTMLElement).textContent!
            );
            const resultArray = sortedItems.map((item) => {
              const transformedItem = item
                .toUpperCase() // Convert to uppercase
                .replace(/\s+/g, "_") // Replace spaces with underscores
                .replace(/\W/g, ""); // Remove non-alphanumeric characters

              return transformedItem;
            });
            props.setVisibleColumns(resultArray);
          }
        },
      });

      return () => {
        sortable.destroy(); // Cleanup the Sortable instance when the component unmounts
      };
    }
    
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setVisibleSelected(availableArr[0]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const moveAvailable = () => {
    const arr = [...props.visibleColumns].filter(
      (column) => !nonHideableColumns.includes(column)
    );
    const nextIndex = availableArr.indexOf(selectedAvailableColumn) + 1;
    const columnLength = availableArr.length;
    if (selectedAvailableColumn && arr.indexOf(selectedAvailableColumn) === -1)
      arr.push(selectedAvailableColumn);
    if (arr.length > availableArr.length) {
      dispatch(setSelectedAvailableColumn(""));
    }
    if (arr.includes(selectedAvailableColumn)) {
      dispatch(setSelectedAvailableColumn(""));
    }
    if (
      selectedAvailableColumn !== availableArr[columnLength - 1] &&
      arr.length < columnLength
    ) {
      dispatch(
        setSelectedAvailableColumn(availableArr[nextIndex % columnLength])
      );
    }
    props.setVisibleColumns(arr);
  };
  const moveVisible = () => {
    const nextIndex = props.visibleColumns.indexOf(selectedVisibleColumn) + 1;
    const remainingSize = props.visibleColumns.length;
    const nextSelected =
      visibleArr.length === 0 || visibleArr.length === 1
        ? ""
        : props.visibleColumns[nextIndex % remainingSize];
    const arr = [...props.visibleColumns].filter(
      (column) => column !== selectedVisibleColumn
    );
    props.setVisibleColumns(arr);
    dispatch(setSelectedVisibleColumn(nextSelected));
    if (visibleArr.length === 0 || visibleArr.length === 1) {
      dispatch(setSelectedAvailableColumn(availableArr[0]));
    }
  };
  return (
    <>
      <div className="edit-columns-modal">
        <div className="column-box">
          <div className="column-header">Available Columns</div>
          <div className="columns-list">
            {selectedHeaders.map((column, index) => {
              if (nonHideableColumns.indexOf(column) === -1) {
                availableArr.push(column);
                const editable = notEditableColumns.indexOf(column) === -1;
                const disabled = props.visibleColumns.indexOf(column) !== -1;
                if (visibleArr.length === availableArr.length) {
                  setSelectedAvailable(availableArr[0]);
                }
                return (
                  <div className="option-wrapper" key={`available-${index}`}>
                    <div
                      tabIndex={0}
                      className={
                        disabled
                          ? "not-clickable-column"
                          : selectedAvailableColumn === column
                          ? "selected-column"
                          : "column-option"
                      }
                      onClick={() => {
                        if (!disabled) {
                          setSelectedAvailable(column);
                        }
                      }}
                    >
                      {startCase(column)}
                    </div>
                    {!editable && (
                      <span className="header-edit-icon">
                        <img alt="" src={LockIcon} />
                      </span>
                    )}
                  </div>
                );
              }
              return null;
            })}
          </div>
          <div className="column-length-header">
            Total Available Columns : {availableArr.length}
          </div>
        </div>
        <div className="move-icon-wrapper">
          <div
            className="button edit-column-move-right-button"
            onClick={() => moveAvailable()}
          >
            {selectedRight}
          </div>
          <div
            className="button edit-column-move-left-button"
            onClick={() => moveVisible()}
          >
            {selectedLeft}
          </div>
        </div>

        <div className="column-box">
          <div className="column-header">Visible Columns</div>
          <div
            ref={sortableContainerRef}
            id="sortableContainer"
            className={
              visibleArr.length === 0 || visibleArr.length === 1
                ? "empty-columns-list"
                : "columns-list"
            }
          >
            {props.visibleColumns.map((column, index) => {
              if (nonHideableColumns.indexOf(column) === -1) {
                if (visibleArr.indexOf(column) === -1) visibleArr.push(column);
                const editable = notEditableColumns.indexOf(column) === -1;
                return (
                  <div className="option-wrapper" key={`visible-${index}`}>
                    <div
                      tabIndex={0}
                      className={
                        selectedVisibleColumn === column
                          ? "selected-column"
                          : "column-option"
                      }
                      onClick={() => setVisibleSelected(column)}
                    >
                      {startCase(column)}
                    </div>
                    {!editable && (
                      <span className="header-edit-icon">
                        <img alt="" src={LockIcon} />
                      </span>
                    )}
                  </div>
                );
              }
              return null;
            })}
          </div>
          <div className="column-length-header">
            Total Visible Columns : {visibleArr.length} / {availableArr.length}
          </div>
        </div>
      </div>
    </>
  );
}
