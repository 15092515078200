
export const correctStyle={
 color:"#008000",
 size:"lg",
 weight:"fill"
}
export const notSureStyle={
 color:"#e87121",
 size:"lg",
 weight:"fill" 
}

export const editIconStyle={
color:"#0088A9",
 size:"lg",
 weight:"fill"  
}
export const editPencilStyle={
 color:"#0088A9",
 size:"lg",
 weight:"fill" 
}