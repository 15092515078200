import { useAppSelector } from "../../reduxHooks";
import "./dropDowns.css";
import { FilterTag } from "./filterTags";
import { MultiUpdate } from "./multiUpdate";
import { SearchBar } from "./searchBar";
import { TableDropDown } from "./tableDropdown";
import { ViewDropDown } from "./viewDropdown";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as SettingsIcon } from "../../assets/settings.svg";
import { useState } from "react";
import { Settings } from "./settingsDropdown";
import { Tag } from "@cimpress/react-components";
import { QuickFilters } from "./quickFilters";
import { ModalsComp } from "./modals";

export function DropDowns() {
  const { selectedRowsIndexes: selectedIndexes, totalRecords } = useAppSelector(
    (state) => state.view
  );
  const [settingOpen, setSettingOpen] = useState(false);
  const openSettings = () => {
    setSettingOpen(!settingOpen);
  };

  return (
    <div>
      <div className="dropdown-wrapper">
        <div className="table-dropdown">
          {" "}
          <TableDropDown />
        </div>
        <div className="view-dropdown">
          <ViewDropDown />
        </div>
        <div className="settings-wrapper">
          <SettingsIcon onClick={openSettings} />
          {settingOpen && <Settings setSettingOpen={setSettingOpen}></Settings>}
        </div>
        {selectedIndexes.length > 0 && <MultiUpdate></MultiUpdate>}
        <div style={{ position: "absolute", right: "0px" }}>
          <ModalsComp></ModalsComp>
        </div>
      </div>
      <div
        style={{
          display: "inline-block",
          margin: "auto",
          width: "100%",
        }}
      >
        <SearchBar></SearchBar>
        <QuickFilters />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",

          paddingLeft: "20px",
        }}
      >
        <FilterTag />
        <Tag
          style={{ marginRight: "4px", marginTop: "10px", fontSize: "10px" }}
          key={"totalRecords"}
          removable={false}
          value={`Total Records:${totalRecords}`}
        />
      </div>
    </div>
  );
}
