import React, { useEffect, useMemo, useRef, useState } from "react";
import "./detailedViewSections.css";
import "../../styles/overWrite.css";
import {
  DetailedViewSectionsData,
  DetailedViewSectionsProps,
  ILinkUnlinkData,
  LinkButton,
} from "../../types";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ArrowIcon from "../../assets/icon-button-arrow.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import WarningIcon from "../../assets/warning.svg";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import BusinessDetailsIcon from "../../assets/icon-factory-regular.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FirmographicsIcon from "../../assets/icon-book-regular.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CustomFieldsIcon from "../../assets/icon-accounting-regular.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import OrganizationIcon from "../../assets/icon-id-card-regular.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import EllipsisIcon from "../../assets/icon-vertical-ellipsis.svg";
import { useAppSelector, useAppDispatch } from "../../reduxHooks";
import { Button, Toggle, Tooltip } from "@cimpress/react-components";
import { Buffer } from "buffer";
import {
  setinitialDetailedView,
  setIsLinkedCid,
  setclearLinkedCidSearch,
  setSnackBar,
  setSnackBarMessage,
  setSnackBarStatus,
  linkUnlinkIds,
  setlinkedCustomerIds,
} from "../../features/view/viewSlice";
import { linkAction } from "../../hooks/view-data";
import { PaginationContent } from "./orgIdPagination";
import { AuthContext } from "../../context/authContext";
import { DetailedViewCheckbox } from "./detailedViewCheckbox";
import { DetailedViewMultiSelect } from "./detailedViewMultiSelect";
import { Spinner } from "@cimpress/react-components";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import IconInformationCircle from "@cimpress-technology/react-streamline-icons/lib/IconInformationCircle";
import {
  displayIdMap,
  linkedMessageMap,
  linkTitleMap,
  notEditableColumns,
} from "../../config";
import { LabelOptions } from "../tabularView/labelOptions";
import { DetailedViewItem } from "./detailedViewItem";
import Multiselect from "multiselect-react-dropdown";

const ellipsisStyle = {
  borderRadius: "2px",
  border: "1px solid #0088A9",
  background: "#FFF",
  width: "3vh",
  height: "3vh",
  paddingLeft: "0.4vw",
  paddingRight: "0.4vw",
};
export const DetailedViewSections = ({
  title,
  data,
  tab,
  customerIdentifier,
}: DetailedViewSectionsProps) => {
  const searchParams = new URLSearchParams(window.location.search);
  const [isOpen, setIsOpen] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [linkingWrapperHeight, setLinkingWrapperHeight] = useState("auto");
  const linkingWrapperRef = useRef<HTMLDivElement>(null);
  const detailedViewSelectRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const toggleLinkingWrapperHeight = () => {
    setLinkingWrapperHeight((prevHeight) =>
      prevHeight === "auto" ? "45vh" : "auto"
    );
  };

  const resetLinkingWrapperHeight = () => {
    setLinkingWrapperHeight("auto");
  };

  const multiselectRef = useRef<Multiselect | null>(null);

  const resetSelectField = () => {
    if (multiselectRef.current) {
      multiselectRef.current.resetSelectedValues();
    }
  };

  useEffect(() => {
    const handleClickOutsideLinkedCidSearch = (event: MouseEvent) => {
      if (
        linkingWrapperRef.current &&
        !linkingWrapperRef.current.contains(event.target as Node) &&
        detailedViewSelectRef.current &&
        !detailedViewSelectRef.current.contains(event.target as Node)
      ) {
        resetLinkingWrapperHeight();
      }
    };

    document.body.addEventListener("click", handleClickOutsideLinkedCidSearch);
    return () => {
      document.body.removeEventListener(
        "click",
        handleClickOutsideLinkedCidSearch
      );
    };
  }, []);

  const icon =
    title === "Firmographics"
      ? FirmographicsIcon
      : title === "Business Details"
      ? BusinessDetailsIcon
      : CustomFieldsIcon;

  const dispatch = useAppDispatch();
  const { profile } = React.useContext(AuthContext);
  const userEmail = profile?.email || "";
  const enrichmentCorrelationId =
    searchParams.get("enrichmentCorrelationId") || "";
  const encodedTable = searchParams.get("tableName") || "";
  const searchTableName = Buffer.from(encodedTable, "base64").toString("ascii");
  const handleToggle = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };
  const [labelMenu, setLabelMenu] = useState(false);
  const {
    businessFields,
    cIdPageNumber,
    firmographics,
    customFields,
    isLinkedCid,
    searchedCorrelationIds,
    selectedLinkUnlinkCustomerIds,
    searchedCustomerIds,
    initialDetailedView,
    defaultOrgIdMapping,
  } = useAppSelector((state) => state.view);
  const linkedCIds = useMemo(() => (Array.isArray(data) ? data : []), [data]);
  const displayIds: string[] = linkedCIds.map((item) => item.DISPLAY_ID);
  const isUnlinkEnabled = selectedLinkUnlinkCustomerIds.length > 0;
  const isLinkEnabled = searchedCorrelationIds.length > 0;
  const isSingleCid = linkedCIds.length === 1;
  const [linkOperator, setLinkOpertaor] = useState(
    isSingleCid ? LinkButton.Link : LinkButton.Unlink
  );
  const itemsPerPage =
    Object.keys(businessFields).length >= 4 &&
    Object.keys(firmographics).length >= 4 &&
    Object.keys(customFields).length >= 4
      ? 20
      : 10;
  const startIndex = cIdPageNumber * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const isDefaultOrgId = Object.values(defaultOrgIdMapping).includes(
    businessFields.ORGANIZATION_ID
  );

  useEffect(() => {
    linkedCIds.length === 1
      ? setLinkOpertaor(LinkButton.Link)
      : setLinkOpertaor(LinkButton.Unlink);
  }, [linkedCIds]);

  const getEnrichmentCorrelationIdFromDisplayId = (displayId: string) => {
    const matchedItem = linkedCIds.find(
      (item) => item.DISPLAY_ID === displayId
    );
    return matchedItem ? matchedItem.ENRICHMENT_CORRELATION_ID : "";
  };

  const searchedIds = searchedCustomerIds.map((customerId, index) => ({
    DISPLAY_ID: customerId ? customerId : searchedCorrelationIds[index],
    ENRICHMENT_CORRELATION_ID: searchedCorrelationIds[index],
  }));

  const getUpdatedLinkedCids = (linkingAction: string) => {
    let updatedData: DetailedViewSectionsData[] = [];
    if (linkingAction === linkAction.unlink && isUnlinkEnabled) {
      updatedData = linkedCIds.filter((item) => {
        return !selectedLinkUnlinkCustomerIds.includes(item.DISPLAY_ID);
      });
    }
    if (
      linkingAction === linkAction.unlink &&
      searchedCorrelationIds.length > 0
    ) {
      updatedData = linkedCIds.filter((item) => {
        return !searchedCorrelationIds.includes(item.ENRICHMENT_CORRELATION_ID);
      });
    }
    if (
      linkingAction === linkAction.link &&
      searchedCorrelationIds.length > 0
    ) {
      updatedData = [...linkedCIds, ...searchedIds];
    }
    return updatedData.length > 0 ? updatedData : linkedCIds;
  };

  const getlinkUnlinkData = (linkingAction: string) => {
    const enrichmentCorrelationIds =
      linkingAction === linkAction.link
        ? searchedCorrelationIds
        : searchedCorrelationIds.length === 0
        ? selectedLinkUnlinkCustomerIds.map((displayId) =>
            getEnrichmentCorrelationIdFromDisplayId(displayId)
          )
        : searchedCorrelationIds;
    let linkUnlinkData: ILinkUnlinkData = {
      tableName: searchTableName.toLowerCase(),
      identifierId: enrichmentCorrelationId,
      actor: userEmail,
      action: linkingAction,
    };
    linkUnlinkData =
      linkingAction === linkAction.link
        ? {
            ...linkUnlinkData,
            idsToLink: enrichmentCorrelationIds,
            organizationId: businessFields.ORGANIZATION_ID,
          }
        : {
            ...linkUnlinkData,
            idsToUnlink: enrichmentCorrelationIds,
          };
    return linkUnlinkData;
  };

  const getColumnNames = () => {
    if (title === "Customer Declared Information") {
      const editablefields = {
        ...firmographics,
        ...businessFields,
        ...customFields,
      };
      return Object.keys(editablefields).filter(
        (col) => notEditableColumns.indexOf(col) === -1
      );
    }
    return Object.keys(data).filter(
      (col) => notEditableColumns.indexOf(col) === -1
    );
  };
  const getSnackbarInfoMessage = (linkingAction: string) => {
    let message = ``;
    if (linkingAction === linkAction.link) {
      if (searchedCustomerIds.length === 1) {
        message = `The Customer Id  ${searchedCustomerIds} is getting linked to Organization Id ${businessFields.ORGANIZATION_ID}.`;
      } else {
        message = `The  selected Customer Ids are getting linked to Organization Id ${businessFields.ORGANIZATION_ID}.`;
      }
    } else {
      if (searchedCustomerIds.length === 1) {
        message = `The  Customer Id  ${searchedCustomerIds} is getting unlinked from  Organization Id ${businessFields.ORGANIZATION_ID}.`;
      } else if (selectedLinkUnlinkCustomerIds.length === 1) {
        message = `The  Customer Id  ${selectedLinkUnlinkCustomerIds} is getting unlinked from  Organization Id ${businessFields.ORGANIZATION_ID}.`;
      } else {
        message = `The  selected Customer Ids are getting unlinked from  Organization Id ${businessFields.ORGANIZATION_ID}.`;
      }
    }
    setSnackbarNoty(message, "info");
  };

  const getSnackbarSuccessMessage = (linkingAction: string) => {
    let message = ``;
    if (linkingAction === linkAction.link) {
      if (searchedCustomerIds.length === 1) {
        message = `The Customer Id  ${searchedCustomerIds} has been linked to Organization Id ${businessFields.ORGANIZATION_ID}.`;
      } else {
        message = `The  selected Customer Ids are linked to Organization Id ${businessFields.ORGANIZATION_ID}.`;
      }
    } else {
      if (searchedCustomerIds.length === 1) {
        message = `The  Customer Id  ${searchedCustomerIds} has been unlinked from  Organization Id ${businessFields.ORGANIZATION_ID}.`;
      } else if (selectedLinkUnlinkCustomerIds.length === 1) {
        message = `The  Customer Id  ${selectedLinkUnlinkCustomerIds} has been unlinked from  Organization Id ${businessFields.ORGANIZATION_ID}.`;
      } else {
        message = `The  selected Customer Ids are unlinked from  Organization Id ${businessFields.ORGANIZATION_ID}.`;
      }
    }
    setSnackbarNoty(message, "success");
  };

  const getSnackbarFailureMessage = (linkingAction: string) => {
    let message = ``;
    if (linkingAction === linkAction.link) {
      if (searchedCustomerIds.length === 1) {
        message = `The  Customer Id  ${searchedCustomerIds} failed to get linked to Organization Id ${businessFields.ORGANIZATION_ID}.`;
      } else {
        message = `The  selected Customer Ids failed to get linked to Organization Id ${businessFields.ORGANIZATION_ID}. Please check back after 1-2 minutes by refreshing the page.`;
      }
    } else {
      if (searchedCustomerIds.length === 1) {
        message = `The  Customer Id  ${searchedCustomerIds} failed to get unlinked from  Organization Id ${businessFields.ORGANIZATION_ID}. Please check back after 1-2 minutes by refreshing the page.`;
      } else if (selectedLinkUnlinkCustomerIds.length === 1) {
        message = `The  Customer Id  ${selectedLinkUnlinkCustomerIds} failed to get unlinked from  Organization Id ${businessFields.ORGANIZATION_ID}. Please check back after 1-2 minutes by refreshing the page.`;
      } else {
        message = `The selected Customer Ids failed to get unlinked from Organization Id ${businessFields.ORGANIZATION_ID}.`;
      }
    }
    setSnackbarNoty(message, "danger");
  };

  const handleLinkUnlink = (linkingAction: string) => {
    dispatch(setinitialDetailedView(true));
    dispatch(setIsLinkedCid(false));
    dispatch(setclearLinkedCidSearch(true));
    getSnackbarInfoMessage(linkingAction);
    resetSelectField();
    dispatch(setlinkedCustomerIds(getUpdatedLinkedCids(linkingAction)));
    dispatch(linkUnlinkIds(getlinkUnlinkData(linkingAction)))
      .then((res) => {
        getSnackbarSuccessMessage(linkingAction);
      })
      .catch((err) => {
        getSnackbarFailureMessage(linkingAction);
        dispatch(setlinkedCustomerIds(linkedCIds));
      });
    setShowPopup(false);
  };

  const changeLinkOperator = () => {
    setLinkOpertaor(() =>
      linkOperator === LinkButton.Unlink ? LinkButton.Link : LinkButton.Unlink
    );
    dispatch(setinitialDetailedView(false));
    dispatch(setIsLinkedCid(false));
  };

  const getMessage = (selectedTable: string, messageType: string) => {
    if (messageType === "linked") {
      return linkedMessageMap[selectedTable] || "Linked Customer IDs";
    }
    if (messageType === "search") {
      return linkTitleMap[selectedTable] || "Link/Unlink Customer IDs";
    }
    if (messageType === "default")
      return displayIdMap[selectedTable] || "Customer Id";
  };
  const setSnackbarNoty = (message: string, status: string) => {
    dispatch(setSnackBar(true));
    dispatch(setSnackBarMessage(message));
    dispatch(setSnackBarStatus(status));
  };

  const getButtonLabel = () => {
    if (linkOperator === LinkButton.Link) {
      return LinkButton.Link;
    } else if (linkOperator === LinkButton.Unlink) {
      return LinkButton.Unlink;
    } else if (isUnlinkEnabled || initialDetailedView || isLinkedCid) {
      return LinkButton.Unlink;
    } else {
      return LinkButton.Link;
    }
  };

  const OrgIdContent = (
    <>
      <div
        className="org-id-content"
        data-testid="org-id-content"
        id="org-id-content"
      >
        <div className="org-id" data-testid="org-id" id="org-id">
          <span>{businessFields.ORGANIZATION_ID}</span>
        </div>
        <hr className="divider" />
        <div
          className="linked-customer-id-content"
          data-testid="linked-customer-id-content"
        >
          <div
            className="linked-cid-item"
            data-testid="linked-cid-item"
            id="linked-cid-item"
          >
            No Customer Ids are currently linked
          </div>
        </div>
      </div>
      <div className="linking-wrapper">
        <hr className="divider" />
        <div
          className="link-title"
          style={{ top: "0vh" }}
          data-testid="link-title"
          id="link-title"
        >
          {getMessage(searchTableName, "search")}
        </div>
        <div
          className="link-title"
          style={{ top: "2vh" }}
          data-testid="link-title"
          id="link-title"
        >
          <Toggle
            on={
              isSingleCid
                ? linkOperator === LinkButton.Link
                : linkOperator === LinkButton.Unlink
            }
            onClick={changeLinkOperator}
            disabled={true}
            size="sm"
          />
          <span className="filter-label">{linkOperator}</span>
          <Tooltip
            direction="right"
            contents={
              <div className="and-or-tooltip">
                You can either <b>Link</b> Customer Ids or <b>Unlink</b> at a
                given time. Use toggle to select the desired action.
              </div>
            }
          >
            <IconInformationCircle weight="fill" color="#0088A9" size="lg" />
          </Tooltip>
        </div>
        <div
          className="linked-cid-search"
          data-testid="linked-cid-search"
          id="linked-cid-search"
        >
          <DetailedViewMultiSelect
            toggleLinkingWrapperHeight={toggleLinkingWrapperHeight}
            linkOperator={linkOperator}
            displayIds={displayIds}
            linkedCIds={linkedCIds}
            multiselectRef={multiselectRef}
          ></DetailedViewMultiSelect>
        </div>
        <div
          className="linked-cid-buttons"
          id="linked-cid-buttons"
          data-testid="linked-cid-buttons"
        >
          <Button className="unlink-button" disabled={true} variant="primary">
            {getButtonLabel()}
          </Button>
        </div>
      </div>
    </>
  );

  const handleLinkClick = () => {
    setShowPopup(false);
    if (isLinkEnabled) {
      handleLinkUnlink(linkAction.link);
    } else {
      setShowPopup(true);
    }
  };

  const checkStatus = () => {
    if (initialDetailedView) {
      return true;
    } else if (isUnlinkEnabled) {
      return false;
    } else if (isLinkedCid || isLinkEnabled) {
      return false;
    } else if (linkOperator === LinkButton.Link && isUnlinkEnabled) {
      return true;
    }
    return false;
  };

  const handlePopupClick = () => {
    setShowPopup(true);
  };
  const CustomPopup = ({ title, message,onContinue, buttonText }: {
    title: string;
    message: string;
    onContinue?: () => void;
    buttonText: string;
  }) => {
    return (
      <div className="popup-overlay" data-testid="popup-overlay" id="popup-overlay">
        <div className="popup-content" data-testid="popup-content" id="popup-content">
          <h3 className="website-alert-title" data-testid="website-alert-title" id="website-alert-title">
            <img alt="" height={32} width={32} style={{ marginTop: -8, marginLeft: 6 }} className="warnining-image" src={WarningIcon} />
            {title}
          </h3>
  
          <div className="website-alert-content" data-testid="website-alert-content" id="website-alert-content">
            <span className="website-alert-text">
              {message}
            </span>
          </div>
  
          <div className="website-alert-footer" data-testid="website-alert-footer" id="website-alert-footer">
            <Button className="website-alert-cancel-button" data-testid="website-alert-cancel-button" onClick={() => setShowPopup(false)}>
              Cancel
            </Button>
            { onContinue && <Button className="website-alert-continue-button" data-testid="website-alert-continue-button" variant="primary" onClick={onContinue}>
              {buttonText}
            </Button>}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`accordion-container ${isOpen ? "isOpen" : ""}`}
      data-testid="accordion-container"
      id="accordion-container"
    >
      {" "}
      <>
        {loading && (
          <>
            <div className="detailed-cell-overlay"></div>
            <Spinner size="large" className="detailed-cell-spinner" />
          </>
        )}
      </>
      <div className="accordian-header-items">
        <div
          className="left-section"
          data-testid="accordion-header"
          id="accordion-header"
          onClick={handleToggle}
        >
          <img
            alt=""
            src={title === "Organization ID" ? OrganizationIcon : icon}
            className="user-icon"
            data-testid="user-icon"
            id="user-icon"
          />
          <div
            className="accordion-title"
            data-testid="accordion-title"
            id="accordion-title"
          >
            {title}
          </div>
        </div>

        <div className="right-section">
          {title !== "Organization ID" &&
            title !== "Customer Declared Information" && (
              <img
                alt=""
                src={EllipsisIcon}
                className="ellipsis-icon"
                data-testid="ellipsis-icon"
                id={`ellipsis-icon-${title}`}
                style={ellipsisStyle}
                onClick={() => setLabelMenu(!labelMenu)}
              />
            )}
          <img
            alt=""
            src={ArrowIcon}
            onClick={handleToggle}
            className={`arrow-icon ${isOpen ? "is-open" : ""}`}
            data-testid="arrow-icon"
            id={`arrow-icon-${title}`}
            style={{
              borderRadius: "2px",
              background: "#0088A9",
              width: "3vh",
              height: "3vh",
              paddingLeft: "0.4vw",
              paddingRight: "0.4vw",
            }}
          />
        </div>
        {labelMenu && getColumnNames().length > 0 && (
          <LabelOptions
            setLoading={setLoading}
            y={100}
            totalHeight={1200}
            identifierId={enrichmentCorrelationId}
            rowIndexes={[]}
            columnNames={getColumnNames()}
            setIsDropDown={setLabelMenu}
            isDropDown={false}
            position="bottom"
          ></LabelOptions>
        )}
      </div>
      {isOpen && data && (
        <div
          className="accordion-content"
          data-testid="accordion-content"
          id="accordion-content"
        >
          {Array.isArray(data) && data.length === 0 ? (
            OrgIdContent
          ) : Array.isArray(data) ? (
            <>
              <div
                className="org-id-content"
                id="org-id-content"
                data-testid="org-id-content"
              >
                <div className="org-id" data-testid="org-id" id="org-id">
                  <span>{businessFields.ORGANIZATION_ID}</span>
                  {isDefaultOrgId ? (
                    <div className="default-org-id-tooltip">
                      <Tooltip
                        direction="right"
                        contents={
                          <div className="and-or-tooltip">
                            This is a default Organization ID. Used when a
                            Customer ID is unlinked but not linked to any other
                            Organization ID. You cannot perform{" "}
                            <b>Link/Unlink </b>
                            operations here.
                          </div>
                        }
                      >
                        <IconInformationCircle
                          weight="fill"
                          color="#0088A9"
                          size="lg"
                        />
                      </Tooltip>{" "}
                    </div>
                  ) : undefined}
                </div>
                <hr className="divider" />
                <div
                  className="linked-cid-title"
                  data-testid="linked-cid-title"
                  id="linked-cid-title"
                >
                  {getMessage(searchTableName, "linked")}
                </div>
                {data.length > itemsPerPage
                  ? data.slice(startIndex, endIndex).map((item, index) => (
                      <div
                        className="linked-customer-id-content"
                        id="linked-customer-id-content"
                        data-testid="linked-customer-id-content"
                        key={index}
                      >
                        <div className="linked-cid-item">
                          <span>
                            <DetailedViewCheckbox
                              displayedId={item.DISPLAY_ID}
                              linkedCIds={linkedCIds}
                              linkOperator={linkOperator}
                              itemIndex={index}
                              tab={tab}
                              customerIdentifer={customerIdentifier}
                            />
                          </span>
                        </div>
                      </div>
                    ))
                  : data.map((item, index) => (
                      <div
                        className="linked-customer-id-content"
                        data-testid="linked-customer-id-content"
                        id="linked-customer-id-content"
                        key={index}
                      >
                        <div
                          className="linked-cid-item"
                          data-testid="linked-cid-item"
                          id="linked-cid-item"
                        >
                          <span>
                            <DetailedViewCheckbox
                              displayedId={item.DISPLAY_ID}
                              linkedCIds={linkedCIds}
                              linkOperator={linkOperator}
                              itemIndex={index}
                              tab={tab}
                              customerIdentifer={customerIdentifier}
                            />
                          </span>
                        </div>
                      </div>
                    ))}

                <PaginationContent></PaginationContent>
                <div
                  className="linking-wrapper"
                  ref={linkingWrapperRef}
                  style={{ height: linkingWrapperHeight }}
                >
                  <hr
                    className="divider"
                    style={{ top: "2vh", position: "relative" }}
                  />
                  <div
                    className="link-title"
                    data-testid="link-title"
                    id="link-title"
                  >
                    {getMessage(searchTableName, "search")}
                  </div>
                  <div
                    className="link-toggle"
                    data-testid="link-toggle"
                    id="link-toggle"
                  >
                    <Toggle
                      on={
                        linkOperator ===
                        (isSingleCid ? LinkButton.Link : LinkButton.Unlink)
                      }
                      onClick={changeLinkOperator}
                      disabled={isSingleCid || isDefaultOrgId ? true : false}
                      size="sm"
                    />
                    <span className="filter-label">{linkOperator}</span>
                    <Tooltip
                      direction="right"
                      contents={
                        <div className="and-or-tooltip">
                          You can either <b>Link</b>{" "}
                          {getMessage(searchTableName, "default")} or{" "}
                          <b>Unlink</b> at a given time. Use toggle to select
                          the desired action.
                        </div>
                      }
                    >
                      <IconInformationCircle
                        weight="fill"
                        color="#0088A9"
                        size="lg"
                      />
                    </Tooltip>
                  </div>
                  <div
                    className="linked-cid-search"
                    data-testid="linked-cid-search"
                    id="linked-cid-search"
                    ref={detailedViewSelectRef}
                  >
                    <DetailedViewMultiSelect
                      toggleLinkingWrapperHeight={toggleLinkingWrapperHeight}
                      multiselectRef={multiselectRef}
                      linkOperator={linkOperator}
                      displayIds={displayIds}
                      linkedCIds={linkedCIds}
                    ></DetailedViewMultiSelect>
                  </div>
                  <div
                    className="linked-cid-buttons"
                    data-testid="linked-cid-buttons"
                    id="linked-cid-buttons"
                  >
                    <Button
                      className="unlink-button"
                      data-testid="unlink-button"
                      variant="primary"
                      disabled={checkStatus()}
                      onClick={isLinkedCid ? handlePopupClick : handleLinkClick}
                    >
                      {getButtonLabel()}
                    </Button>
                    {isLinkedCid && showPopup && (
                      <CustomPopup
                        title="Unlink selected Customer Ids?"
                        message={`This action will permanently unlink selected Customer Ids from the Organization Id ${businessFields.ORGANIZATION_ID}. Are you sure you want to proceed?`}
                        onContinue={() => handleLinkUnlink(linkAction.unlink)}
                        buttonText="Unlink"
                      />
                    )}
                    {!isLinkedCid && (searchedCorrelationIds.length === 0 || selectedLinkUnlinkCustomerIds.length === 0) && showPopup && (
                      <CustomPopup
                        title="No Customer IDs selected"
                        message="Please select a Customer Id before trying to Unlink/Link"
                        buttonText="Cancel"
                      />
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            Object.entries(data).map(([name, value]) => {
              return (
                <DetailedViewItem
                  key={name}
                  data={data}
				  title=""
                  name={name}
                  value={value}
                  identifierId={enrichmentCorrelationId}
                />
              );
            })
          )}
        </div>
      )}
    </div>
  );
};
