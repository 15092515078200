import { useEffect, useMemo, useState } from "react";
import { Button, Modal } from "@cimpress/react-components";
import { AvailableColumns, nonHideableColumns } from "./AvailableColumns";
import { useAppDispatch, useAppSelector } from "../../reduxHooks";
import {
	setSelectedAvailableColumn,
	setSelectedVisibleColumn,
	setVisibleColumns,
} from "../../features/view/viewSlice";
import { postSessionData } from "../../hooks/view-data";
import { DefaultView } from "../../types";
import React from "react";
import { AuthContext } from "../../context/authContext";	


enum Reset {
	clearAll = "Clear All",
	addAll = "Add All",
}

export function EditColumns() {
	const [isOpen, setOpen] = useState(false);
	const dispatch = useAppDispatch();
	const {
		visibleColumns: existingVisibleColumns,
		selectedHeaders,
		selectedView,
		selectedTable,
		pageSize,
		pageNumber,
		savedFilters,
		unsavedFilters,
	} = useAppSelector((state) => state.view);
	const [visibleColumns, setAllVisibleColumns] = useState<string[]>(existingVisibleColumns);

	const { profile } = React.useContext(AuthContext);
	const userId = profile?.email || "";
	const [reset, setReset] = useState<string>(Reset.clearAll);
	useMemo(() => setAllVisibleColumns(existingVisibleColumns), [existingVisibleColumns]);

	const getUpdateSessionData = (updatedVisibleColumns: string[]) => {
		return {
			currentPage: pageNumber,
			size: pageSize,
			viewId: selectedView ? selectedView.viewId : DefaultView.Default,
			selectedTable,
			userId: userId,
			visibleColumns: updatedVisibleColumns || [],
			selectedColumns: selectedHeaders || [],
			isEditCols: true,
			savedFilters: savedFilters,
			unsavedFilters: unsavedFilters,
		};
	};

	useEffect(() => {
		if (visibleColumns.length === 0 || visibleColumns.length === 1) {
			setReset(Reset.addAll);
		}
		if (visibleColumns.length > 0 && visibleColumns.length < 4) {
			visibleColumns.forEach((column) => {
				if (nonHideableColumns.indexOf(column) !== -1) {
					setReset(Reset.addAll);
				}
			});
		}
		if (visibleColumns.length >= availableArr.length) {
			setReset(Reset.clearAll);
		}
	}, [visibleColumns.length]); // eslint-disable-line react-hooks/exhaustive-deps

	const undoVisible = () => {
		setAllVisibleColumns(existingVisibleColumns);
		setOpen(false);
	};

	const displayVisible = () => {
		let finalVisibleArr: string[] = [];
		nonHideableColumns.forEach((column) => {
			if (selectedHeaders.indexOf(column) !== -1 && visibleColumns.indexOf(column) === -1)
				finalVisibleArr.push(column);
		});
		const updatedVisibleColumns: string[] = [...visibleColumns, ...finalVisibleArr];
		setAllVisibleColumns(updatedVisibleColumns);
		dispatch(setVisibleColumns(updatedVisibleColumns));
		if (userId) postSessionData(getUpdateSessionData(updatedVisibleColumns));
		setOpen(false);
	};

	const availableArr: string[] = [];
	const visibleArr: string[] = [];

	selectedHeaders.forEach((column) => {
		if (nonHideableColumns.indexOf(column) === -1) {
			availableArr.push(column);
		}
	});

	visibleColumns.forEach((column) => {
		if (nonHideableColumns.indexOf(column) === -1) {
			if (visibleArr.indexOf(column) === -1) visibleArr.push(column);
		}
	});

	const resetValue = () => {
		if (reset === Reset.clearAll) {
			setAllVisibleColumns([]);
			dispatch(setSelectedVisibleColumn(""));
			dispatch(setSelectedAvailableColumn(availableArr[0]));
		} else {
			setAllVisibleColumns(selectedHeaders);
			dispatch(setSelectedVisibleColumn(availableArr[0]));
			dispatch(setSelectedAvailableColumn(""));
		}
	};
	return (
		<div>
			<div className="edit-column-button">
				<Button
					className="right-buttons edit-column-dialogue-button"
					data-testid="edit-columns"
					onClick={() => setOpen(true)}
				>
					Edit Columns
				</Button>
			</div>
			<div className="edit-column-modal-wrapper">
				<Modal
					key="edit-modal"
					className="edit-column-modal"
					status="success"
					show={isOpen}
					onRequestHide={() => setOpen(false)}
					title={
						<div className="edit-column-header" key="edit-columns-title">
							Edit Columns
						</div>
					}
					closeButton={true}
					footer={[
						<div className="footer-buttons" key={"edit-footer-buttons"}>
							<div className="clear-all" onClick={resetValue} key="edit-clear-all">
								{reset}
							</div>
							<Button
								className="modal-cancel-button"
								onClick={undoVisible}
								key="edit-cancel-button"
							>
								Cancel
							</Button>
							<Button
								variant="primary"
								className="modal-confirm-button"
								onClick={displayVisible}
								key="edit-columns-apply"
							>
								Apply
							</Button>
						</div>,
					]}
				>
					<AvailableColumns
						key={"available-visible-modal"}
						visibleColumns={visibleColumns}
						setVisibleColumns={setAllVisibleColumns}
					></AvailableColumns>
				</Modal>
			</div>
		</div>
	);
}
