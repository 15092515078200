// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Checkbox, Spinner } from "@cimpress/react-components";
import { TextArea } from "@cimpress/react-components";
import { Button } from "@cimpress/react-components";
import InitialsAvatar from "react-initials-avatar";
import "react-initials-avatar/lib/ReactInitialsAvatar.css";
import { useEffect, useMemo, useRef, useState } from "react";
import "./commentsStyle.css";
import React from "react";
import { AuthContext } from "../../context/authContext";
import { SingleComment } from "./singleComment";
import { addComment, getAllComments } from "../../hooks/view-data";
import { useAppDispatch, useAppSelector } from "../../reduxHooks";
import { commentsMap, partialOptionFields } from "./defaultComments";
import { DetectOutsideClick } from "../detectClickOutside";
import _ from "lodash";
import { IRow } from "../../types";
import { setRows } from "../../features/view/viewSlice";
export const CommentsModal = (props: {
	totalHeight: number;
	y: number;
	field: string;
	id: string;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	rowIndex: number[];
}) => {
	const labelOptionRef = useRef(null);
	DetectOutsideClick(labelOptionRef, props.setOpen);
	const [text, setText] = useState("");
	const { selectedTable, rows } = useAppSelector((state) => state.view);
	const [loading, setLoading] = useState(false);
	const { field, id } = props;
	const [comments, setComments] = useState([]);
	const y = props.y;
	const { profile } = React.useContext(AuthContext);
	const userName = profile?.name || "";
	const userEmail = profile?.email;
	const clonedRow: IRow[][] = _.cloneDeep(rows);
	const selectedRows = clonedRow.filter((value, index) => props.rowIndex.indexOf(index) !== -1);
	const allComments = useMemo(() => [...comments], [comments]);
	const getComments = () => {
		setLoading(true);
		getAllComments(selectedTable, id, field).then((response) => {
			setComments(response);
			setLoading(false);
		});
	};
	const dispatch = useAppDispatch();
	useEffect(() => {
		setLoading(true);
		getAllComments(selectedTable, id, field).then((response) => {
			setComments(response);
			setLoading(false);
		});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	const setCommentStatus = (status: string) => {
		selectedRows.forEach((cols) => {
			cols.forEach((obj) => {
				if (props.field in obj) {
					obj["commented"] = status;

					return obj;
				}
				return;
			});
		});
		dispatch(setRows(clonedRow));
	};
	const addNewComment = () => {
		if (userEmail) {
			setLoading(true);
			addComment(selectedTable, id, field, text, userEmail).then((response) => {
				setLoading(false);
				getComments();
				setCommentStatus("true");
			});
			setText("");
		}
	};
	const getDefaultOptions = () => {
		const column = props.field.toLowerCase();
		if (column in commentsMap) {
			const optionsArray = commentsMap[column];
			if (column in partialOptionFields) {
				return optionsArray.map((option) => `${_.startCase(column)} ${option}`);
			} else return optionsArray;
		}
		return [];
	};
	return (
		<div
			className={props.totalHeight - y < 400 ? "comments-dropdown-upward" : "comments-dropdown"}
			ref={labelOptionRef}
		>
			<div className="comments-heading">COMMENT</div>
			{loading && (
				<>
					<div className="comments-overlay"></div>
					<Spinner className="comments-spinner" />
				</>
			)}
			{allComments.length === 0 && !loading ? (
				<div className="checkbox-options">
					{getDefaultOptions().length !== 0 && (
						<div className="users-initials">
							<InitialsAvatar name={userName} />
						</div>
					)}
					{getDefaultOptions().length > 0 ? (
						<div className="default-checkbox">
							{getDefaultOptions().map((content) => (
								<CheckboxComments content={content} setText={setText} text={text} />
							))}
						</div>
					) : (
						<div className="no-comments">No comment found</div>
					)}
				</div>
			) : (
				<>
					<div className="comments-container">
						{allComments.map((singleComment: any) => (
							<SingleComment
								totalComments={allComments.length}
								columnName={field}
								comment={singleComment}
								identifierId={id}
								getComments={() => getComments()}
								setCommentStatus={(status: string) => setCommentStatus(status)}
							/>
						))}

						{allComments.length > 0 && <hr className="horizontal-line "></hr>}
					</div>
				</>
			)}

			<div className="bottom-input-text">
				<div className="users-initials">
					<InitialsAvatar name={userName} />
				</div>

				<div className="comment-input">
					<TextArea
						className="comment-text-area"
						height={200}
						value={text}
						onChange={(e) => setText(e.target.value)}
						label={
							comments.length === 0
								? "Select option(s) or type comment here..."
								: "Type comment here..."
						}
					/>
				</div>
			</div>

			<div className="footer-button">
				<div className="cancel-button">
					<Button variant="default" onClick={() => props.setOpen(false)}>
						Cancel
					</Button>
				</div>
				<div className="save-button">
					<Button variant="primary" disabled={text.length === 0} onClick={addNewComment}>
						Save
					</Button>
				</div>
			</div>
		</div>
	);
};
function CheckboxComments(props: {
	content: string;
	setText: React.Dispatch<React.SetStateAction<string>>;
	text: string;
}) {
	const [isChecked, setChecked] = useState(false);
	const addDefaultText = (checked: boolean) => {
		if (checked) {
			const newText = props.text.replace(props.content, "");
			const splitedText = newText
				.split(",")
				.filter((text) => text !== "")
				.join();
			props.setText(splitedText);
		} else {
			const newText = props.text.length > 0 ? props.text + "," + props.content : props.content;
			props.setText(newText);
		}
		setChecked(!checked);
	};
	return (
		<div>
			<Checkbox
				label={props.content}
				checked={isChecked}
				payload=""
				onChange={() => addDefaultText(isChecked)}
			/>
		</div>
	);
}
