import React from "react";
import { NavTab, NavTabItem, Robot } from "@cimpress/react-components";
import "../../styles/detailedView.css";
import "./detailedViewSections";
import { DetailedViewSections } from "./detailedViewSections";
import { useAppSelector } from "../../reduxHooks";
import { DataProvidersView } from "./dataProvider/dataProvidersView";
import { useNavigate } from "react-router-dom";

interface Tab {
	id: string;
	label: string;
	disabled: boolean;
	active: boolean;
}

const viewNavTabs = ["overview","dataProviders","historyLog"];
export const DetailedViewNavigation = (props:{customerIdentifier:string}) => {
	const searchParams = new URLSearchParams(window.location.search);
	const viewTab = searchParams.get("viewTab") || "";
	const { firmographics, customFields, linkedCustomerIds, businessFields } = useAppSelector(
		(state) => state.view
	);
	const navigateTabUrl = (url: string, tab: string) => {
		let newURL = url;
		viewNavTabs.forEach((word:string) => {
			const regex = new RegExp(`\\?viewTab=${word}`, 'g');
			newURL = newURL.replace(regex, `?viewTab=${tab}`);
		});
		return newURL;
	}
	const navigate = useNavigate();
	const navigateClick = (id: string) => {
		navigate(navigateTabUrl(window.location.search, id));
	}
	const overviewTabContent = (
		<div
			className="detailed-view-wrapper"
			data-testid="detailed-view-wrapper"
			id="detailed-view-wrapper"
		>
			<div className="details-wrapper" data-testid="details-wrapper" id="details-wrapper">
				<div
					className="business-details-wrapper"
					data-testid="business-details-wrapper"
					id="business-details-wrapper"
				>
					<DetailedViewSections title="Business Details" data={businessFields} tab={viewTab} customerIdentifier={props.customerIdentifier}/>
				</div>
				<div
					className="firmographics-wrapper"
					data-testid="firmographics-wrapper"
					id="firmographics-wrapper"
				>
					<DetailedViewSections title="Firmographics" data={firmographics} tab={viewTab} customerIdentifier={props.customerIdentifier}/>
				</div>
				<div
					className="custom-fields-wrapper"
					data-testid="custom-fields-wrapper"
					id="custom-fields-wrapper"
				>
					<DetailedViewSections title="Miscellaneous Fields" data={customFields} tab={viewTab} customerIdentifier={props.customerIdentifier}/>
				</div>
			</div>
			<div
				className="organization-wrapper"
				data-testid="organization-wrapper"
				id="organization-wrapper"
			>
				<DetailedViewSections title="Organization ID" data={linkedCustomerIds} tab={viewTab} customerIdentifier={props.customerIdentifier}/>
			</div>
		</div>
	);
	const dataProviderTabContent = (
		<div
			className="data-provider-view-wrapper"
			data-testid="data-provider-view-wrapper"
			id="data-provider-view-wrapper"
		>
			<div className="data-provider-wrapper" data-testid="data-provider-wrapper" id="data-provider-wrapper">
				<div
					className="enriched-values-wrapper"
					data-testid="enriched-values-wrapper"
					id="enriched-values-wrapper"
				>
					<div>
						<DataProvidersView title="Enriched Values" ></DataProvidersView>
					</div>
				</div>
			</div>
		</div>
	);
	const historyLogTabContent = (
		<div
			className="data-provider-view-wrapper"
			data-testid="data-provider-view-wrapper"
			id="data-provider-view-wrapper"
		>
			<div className="data-provider-wrapper" data-testid="data-provider-wrapper" id="data-provider-wrapper">
				<div
					className="enriched-values-wrapper"
					data-testid="enriched-values-wrapper"
					id="enriched-values-wrapper"
				>
				<div>
					<Robot status="success" alternate />
					<h2>🚀 This feature is on our roadmap, patiently waiting for its time to shine! 
						No code has been harmed in the making of this message... yet. Stay tuned for updates! 😎📅</h2>
					</div>
				</div>
			</div>
		</div>
	);
	const tabContentMap: Record<string, React.ReactNode> = {
		overview: overviewTabContent,
		dataProviders: dataProviderTabContent,
		historyLog: historyLogTabContent,
	};

	const tabs: Tab[] = [
		{ id: "overview", label: "Overview", disabled: false, active: "overview" === viewTab ? true : false },
		{
			id: "dataProviders",
			label: "Data Providers",
			disabled: false,
			active: "dataProviders" === viewTab ? true : false,
		},
		{ id: "historyLog", label: "History Log", disabled: true, active: "historyLog" === viewTab ? true : false },
	];

	const tabContent = tabContentMap[viewTab];

	return (
		<React.Fragment>
			<div className="nav-tab-wrapper" data-testid="nav-tab-wrapper" id="nav-tab-wrapper">
				<NavTab vertical={true}>
					<div className="nav-tab-content">{tabContent}</div>
					{tabs.map((tab) => (
						<NavTabItem key={tab.id} active={tab.active} disabled={tab.disabled}>
							<button onClick={() => navigateClick(tab.id)}>{tab.label}</button>
						</NavTabItem>
					))}
				</NavTab>
			</div>
		</React.Fragment>
	);
};
