import { DatePicker, Select, TextField } from "@cimpress/react-components";
import { useMemo, useState } from "react";
import { useAppSelector } from "../../reduxHooks";
import { IFilter, IIndustryMap } from "../../types";
import "./filters.css";
import { cloneDeep, startCase, uniq, flatten } from "lodash";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import RemoveFilter from "../../assets/remove-filter.svg";

import {
	actionFilters,
	booleanValues,
	calenderOperator,
	countryCodeColumns,
	countryOptions,
	dateOperators,
	disabledDropdowns,
	dropDownOperatorsColumns,
	employee_range,
	employee_range_wmd,
	getL2List,
	l1Selected,
	linked_account_range,
	numericOperators,
	rangeOperator,
	tenantOptions,
	textOperators,
	validationOptions,
	webpagesRange,
} from "./operators";
import _ from "lodash";
import { IError } from "./types";
import { isEmpty } from "./validateFilters";
import { GCCINDUSTRIES } from "../../utils/gccIndustries";
import {IS_ENRICHED_COLUMN, IS_FREELANCER_COLUMN, COMPANY_STATUS_COLUMN, PHYSICAL_IDENTIFIER_COLUMN, ECOMMERCE_IDENTIFIER_COLUMN, WEBSITE_SSL_STATUS_COLUMN} from "../../config"
const booleanColumns = [
	IS_ENRICHED_COLUMN.toLowerCase(),
	IS_FREELANCER_COLUMN.toLowerCase(),
	COMPANY_STATUS_COLUMN.toLowerCase(),
	PHYSICAL_IDENTIFIER_COLUMN.toLowerCase(),
	ECOMMERCE_IDENTIFIER_COLUMN.toLowerCase(),
	WEBSITE_SSL_STATUS_COLUMN.toLowerCase()
];
const numericKeywords = [
	"employee",
	"revenue",
	"sbi",
	"sic",
	"number",
	"establishment",
	"industry_confidence_score",
	"confidence_score",
	"count",
];
const numericColumns = [
	"YEAR_OF_ESTABLISHMENT",
	"SBI_2",
	"SBI_3",
	"SBI_4",
	"SBI_5",
	"EMPLOYEES",
	"SBI",
	"SBI_2_DIGIT_CODE",
	"REVENUE_IN_EUROS",
	"ESTABLISHMENT_YEAR",
	"NUMBER_OF_EMPLOYEES",
	"REVENUE_IN_USD",
	"REVENUE_USD",
	"CONFIDENCE",
	"SIC_CODE",
	"WEBSITE_ACCURACY",
	"INDUSTRY_CONFIDENCE_SCORE",
	"SIC_CODE",
	"EMPLOYEE_COUNT",
	"ESTABLISHMENT_YEAR",
	"REVENUE_USD",
	"NUMBER_OF_WEB_PAGES",
	"RNO",
	"GLOBAL_EMPLOYEE_COUNT",
	"LOCAL_EMPLOYEE_COUNT",
	"REVENUE_IN_EUROS",
	"SBI",
	"CASE_NUMBER",
	"HOUSE_NUMBER",
	"INDUSTRY_CONFIDENCE_SCORE",
	"FILL_RATIO",
	"SDR_WEB_SCRAPE",
	"SDR_INFERRED",
	"SDR_GOVT",
	"SDR_OPEN_DATASET",
	"SDR_JOB_HISTORY",
	"SDR_ARTWORK",
];
const isNumeric = (field: string) => {
	return (
		numericKeywords.filter(
			(keyword) =>
				field.toLowerCase().indexOf(keyword) !== -1 &&
				field.toLowerCase().indexOf("range") === -1 &&
				field.toLowerCase().indexOf("description") === -1
		).length !== 0
	);
};
interface IOptions {
	label: string;
	value: string;
}
export const dropDownValues = (
	selectedColumn: { value: string; label: string },
	l1List: string[],
	l2List: string[],
	unsavedFilters: IFilter[],
	industryMap: IIndustryMap,
	tableName: string
) => {
	let options: IOptions | any = [];
	let nullOptions = { label: "Null", value: "null" };
	const lowerColumn = selectedColumn?.value.toLowerCase();
	if (selectedColumn.value.toLowerCase() === "tenant") {
		options = tenantOptions.map((tenant: string | any) => ({
			label: <div id={tenant}>{tenant}</div>,
			value: tenant,
		}));
	} else if (countryCodeColumns.indexOf(selectedColumn.value.toLowerCase()) !== -1) {
		options = countryOptions.map((country) => ({
			label: <div id={country}>{country}</div>,
			value: country,
		}));
	} else if (
		selectedColumn.value.toLowerCase() === "zzp" ||
		selectedColumn.value.toLowerCase() === "active"
	) {
		options = booleanValues.map((val) => ({
			label: <div id={val}>{val}</div>,
			value: startCase(val),
		}));
	} else if (
		selectedColumn.value.toLowerCase() === "ce_industry_l1" ||
		selectedColumn.value.toLowerCase() === "category_l1"
	) {
		options = l1List.map((l1) => ({ label: <div id={l1}>{startCase(l1)}</div>, value: l1 }));
	} else if (
		selectedColumn.value.toLowerCase() === "ce_industry_l2" ||
		selectedColumn.value.toLowerCase() === "category_l2"
	) {
		l2List = l1Selected(unsavedFilters) ? getL2List(unsavedFilters, industryMap) : l2List;
		options = l2List.map((l2) => ({ label: <div id={l2}>{startCase(l2)}</div>, value: l2 }));
	} else if (selectedColumn.value.toLowerCase() === "employee_range") {
		const ranges =
			tableName.toLowerCase().indexOf("wmd") !== -1 ? employee_range_wmd : employee_range;
		options = ranges.map((range) => ({ label: <div id={range}>{range}</div>, value: range }));
	} else if (selectedColumn.value.toLowerCase() === "is_https_site") {
		let op = ["True", "False", "No Website Found"];
		options = op.map((range) => ({ label: <div id={range}>{range}</div>, value: range }));
	} else if (selectedColumn.value.toLowerCase() === "linked_account_range") {
		options = linked_account_range.map((range) => ({
			label: <div id={range}>{range}</div>,
			value: range,
		}));
	} else if (selectedColumn.value.toLowerCase() === "web_pages_range") {
		options = webpagesRange.map((range) => ({
			label: <div id={range}>{range}</div>,
			value: range,
		}));
	} else if (selectedColumn.value.toLowerCase() === "category") {
		options = GCCINDUSTRIES.map((industry) => ({
			label: <div id={industry}>{industry}</div>,
			value: industry,
		}));
	}else if(booleanColumns.includes(lowerColumn)) {
		options = [{label: <div>true</div>,value: "true"},{label: <div>false</div>,value: "false"}];
	}
	!(selectedColumn.value.toLowerCase() === IS_ENRICHED_COLUMN.toLowerCase()) && options.splice(0, 0, nullOptions);
	return options;
};

export const operatorOptions = (selectedColumn?: { label: string; value: string }) => {
	if (!selectedColumn?.value) return [];
	const lowerColumn = selectedColumn.value.toLowerCase();
	let choices: string[] = [];
	const columnNames = lowerColumn.split("_");
	if (booleanColumns.includes(lowerColumn)) {
		choices = ["is"];
	} else if (dropDownOperatorsColumns.includes(lowerColumn)) {
		choices = ["is", "is not", "validated as", "has comments", "was overridden"];
	} else if (columnNames.includes("timestamp")) {
		choices = dateOperators;
	} else if (numericColumns.includes(selectedColumn.value)) {
		choices = numericOperators;
	} else {
		choices = textOperators;
	}
	return choices.map(value => ({
		label: <div id={!dropDownOperatorsColumns.includes(lowerColumn) ? _.snakeCase(value) : value}>{value}</div>,
		value,
	}));
};
function ErrorMessage(props: { message: string }) {
	return <div style={{ fontSize: "14px", color: "#D24A35" }}>{props.message}</div>;
}
export function SingleFilter(props: {
	setAllUnsavedFilters: React.Dispatch<React.SetStateAction<IFilter[]>>;
	index: number;
	allUnsavedFilters: IFilter[];
	delete: (index: number) => void;
	errors: IError;
	setEmptyErrors: React.Dispatch<
		React.SetStateAction<
			| {
					[key: number]: IError | undefined;
			  }
			| undefined
		>
	>;
}) {
	const [toDateError, setToDateError] = useState("");
	const [fromDateError, setFromDateError] = useState("");
	const { visibleColumns, industryMap, selectedTable } = useAppSelector((state) => state.view);
	const [selectedColumn, setSelectedColumn] = useState<IOptions>();
	const [selectedOperator, setSelectedOperator] = useState<IOptions>();
	const [selectedValue, setSelectedValue] = useState<any[]>([]);
	const [dateFrom, setDateFrom] = useState<string | undefined>(undefined);
	const [dateTo, setDateTo] = useState<string | undefined>(undefined);

	const columnOptions = useMemo(() => {
		if (visibleColumns.length > 0) {
			return [...visibleColumns].sort().map((column, index) => ({
				label: <div id={`filter-columns-item-${index}`}>{_.startCase(column)}</div>,
				value: column,
			}));
		}
		return [];
	}, [visibleColumns]);
	const l1Industries = useMemo(() => Object.keys(industryMap), [industryMap]);
	const l2Industries = useMemo(() => uniq(flatten(Object.values(industryMap))), [industryMap]);
	useMemo(() => {
		setSelectedColumn({
			label: _.startCase(props.allUnsavedFilters[props.index].column),
			value: props.allUnsavedFilters[props.index].column,
		});
		setSelectedOperator({
			label: props.allUnsavedFilters[props.index].operator,
			value: props.allUnsavedFilters[props.index].operator,
		});
		setSelectedValue(props.allUnsavedFilters[props.index].value);
	}, [props.allUnsavedFilters]); // eslint-disable-line react-hooks/exhaustive-deps

	const onColumnChange = (obj: any) => {
		setSelectedColumn(obj);
		let arr = cloneDeep(props.allUnsavedFilters);
		arr[props.index].column = obj.value;
		arr[props.index].value = [];
		arr[props.index].operator = "";

		props.setAllUnsavedFilters([...arr]);
		setFromDateError("");
		setToDateError("");
		const emptyValidation = isEmpty([...arr]);
		const { errors } = emptyValidation;
		props.setEmptyErrors(errors);
	};
	const onOperatorChange = (obj: any) => {
		setSelectedOperator(obj);
		setSelectedValue([]);
		let arr = cloneDeep(props.allUnsavedFilters);
		arr[props.index].operator = obj.value;

		setDateTo(undefined);
		setDateFrom(undefined);
		setFromDateError("");
		setToDateError("");
		arr[props.index].value = [];
		if (disabledDropdowns.indexOf(obj.value) !== -1) {
			arr[props.index].value = ["true"];
		}

		props.setAllUnsavedFilters([...arr]);
		const emptyValidation = isEmpty([...arr]);
		const { errors } = emptyValidation;
		props.setEmptyErrors(errors);
	};
	const onValueChange = (value: string) => {
		setSelectedValue([value]);
		let arr = cloneDeep(props.allUnsavedFilters);
		setDateTo(undefined);
		setDateFrom(undefined);
		setFromDateError("");
		setToDateError("");
		arr[props.index].value = [value];
		props.setAllUnsavedFilters([...arr]);
		const emptyValidation = isEmpty([...arr]);
		const { errors } = emptyValidation;
		props.setEmptyErrors(errors);
	};

	const setFromDate = (d: any) => {
		if (dateTo && d >= dateTo) {
			setFromDateError("From date should be smaller than To date");
			setToDateError("");
			setFromDate("");
			return;
		} else {
			let arr = cloneDeep(props.allUnsavedFilters);
			arr[props.index].value[0] = d;
			setDateFrom(d);
			props.setAllUnsavedFilters([...arr]);
			const emptyValidation = isEmpty([...arr]);
			const { errors } = emptyValidation;
			props.setEmptyErrors(errors);
		}
	};

	const setToDate = (d: any) => {
		if (dateFrom && dateFrom >= d) {
			setToDateError("Please select date greater than From date");
			setFromDateError("");
			setToDate("");
			return;
		} else {
			let arr = cloneDeep(props.allUnsavedFilters);
			arr[props.index].value[1] = d;
			setDateTo(d);
			props.setAllUnsavedFilters([...arr]);
			setToDateError("");
			const emptyValidation = isEmpty([...arr]);
			const { errors } = emptyValidation;
			props.setEmptyErrors(errors);
		}
	};

	const isInputOperator = () => {
		if (
			selectedOperator &&
			actionFilters.indexOf(selectedOperator?.value) === -1 &&
			selectedOperator &&
			calenderOperator.indexOf(selectedOperator?.value) === -1 &&
			selectedColumn &&
			dropDownOperatorsColumns.indexOf(selectedColumn?.value.toLowerCase()) === -1
		) {
			return true;
		}
		return false;
	};
	const getValidationValues = () => {
		return validationOptions.map((option) => {
			if (option === "notSure")
				return {
					label: <div id="unable-to-specify">{startCase("unable to specify")}</div>,
					value: option,
				};
			else
				return {
					label: <div id={startCase(option)}> {startCase(option)}</div>,
					value: option,
				};
		});
	};
	const onDropDownValueChange = (obj: any) => {
		setSelectedValue(obj);
		let arr = cloneDeep(props.allUnsavedFilters);
		arr[props.index].value = [obj.value];
		props.setAllUnsavedFilters([...arr]);
		const emptyValidation = isEmpty([...arr]);
		const { errors } = emptyValidation;
		props.setEmptyErrors(errors);
	};
	const handleKeyDown = (e: any, value?: string) => {
		if (value && isNumeric(value)) {
			if (
				e.key.includes("e") ||
				e.key.includes("-") ||
				e.key.includes(".") ||
				e.key.includes("E")
			) {
				e.preventDefault();
			}
		}
	};
	const getDateCalenderClass = () => {
		return selectedOperator && rangeOperator.indexOf(selectedOperator?.value) !== -1
			? "range-date"
			: "single-date";
	};
	return (
		<>
			<div className="single-filter-wrapper">
				<div className="first-row-wrapper">
					<div className="field-operator-wrapper">
						<div className="filter-dropdown">
							<Select
								id="filter-column-dropdown"
								label={selectedColumn && selectedColumn.value === "" ? "Select column" : undefined}
								isSearchable={true}
								value={selectedColumn}
								options={columnOptions}
								onChange={onColumnChange}
								helpText={<ErrorMessage message={props.errors.columnName} />}
							/>
						</div>
						<div className="filter-operator">
							<Select
								id="filter-operator-dropdown"
								label={
									selectedOperator && selectedOperator.value === "" ? "Select operator" : undefined
								}
								isSearchable={false}
								value={selectedOperator}
								options={operatorOptions(selectedColumn)}
								onChange={onOperatorChange}
								helpText={<ErrorMessage message={props.errors.operator} />}
							/>
						</div>
					</div>
					<div className="remove-filter-icon" onClick={() => props.delete(props.index)}>
						<img alt="" src={RemoveFilter} />
					</div>
				</div>

				{isInputOperator() ? (
					<div className="filter-value">
						<TextField
							id="filter-input-field"
							type={selectedColumn && isNumeric(selectedColumn?.value) && selectedColumn?.value !=="CUSTOMER_NUMBER" ? "number" : "text"}
							name=""
							label={selectedValue && !selectedValue[0] ? "Enter the value" : undefined}
							value={selectedValue}
							onKeyPress={(e) => handleKeyDown(e, selectedColumn?.value)}
							onChange={(e) => onValueChange(e.target.value)}
							helpText={<ErrorMessage message={props.errors.value[0]} />}
						/>
					</div>
				) : (
					<>
						{selectedOperator && actionFilters.indexOf(selectedOperator?.value) !== -1 && (
							<div className="filter-operator-select-value">
								<Select
									isDisabled={
										selectedOperator && disabledDropdowns.indexOf(selectedOperator.value) !== -1
									}
									id="filter-operator-dropdown"
									label={undefined}
									isSearchable={false}
									value={
										selectedOperator && disabledDropdowns.indexOf(selectedOperator.value) === -1
											? {
													label: selectedValue[0],
													value: selectedValue[0],
											  }
											: { label: undefined, value: undefined }
									}
									options={getValidationValues()}
									onChange={onDropDownValueChange}
									helpText={<ErrorMessage message={props.errors.value[0]} />}
								/>
							</div>
						)}
						{selectedColumn &&
							selectedOperator &&
							dropDownOperatorsColumns.indexOf(selectedColumn.value.toLowerCase()) !== -1 &&
							(selectedOperator.value === "is" || selectedOperator.value === "is not") && (
								<div className="filter-value">
									<Select
										id="filter-input-value-dropdown"
										label={
											selectedValue && !selectedValue[0] ? "Please select the value" : undefined
										}
										isSearchable={true}
										value={{
											label: selectedValue[0],
											value: selectedValue[0],
										}}
										options={dropDownValues( 
											selectedColumn,
											l1Industries,
											l2Industries,
											props.allUnsavedFilters,
											industryMap,
											selectedTable
										)}
										onChange={onDropDownValueChange}
										helpText={<ErrorMessage message={props.errors.value[0]} />}
									/>
								</div>
							)}
						<div className="date-wrapper">
							{selectedOperator && calenderOperator.indexOf(selectedOperator?.value) !== -1 && (
								<div className={getDateCalenderClass()}>
									<DatePicker
										className="filter-date-picker"
										locale="UTC"
										utc={true}
										label={undefined}
										dateFormat="DD-MM-YYYY"
										helpText={
											fromDateError ? (
												<ErrorMessage message={fromDateError} />
											) : (
												<ErrorMessage message={props.errors.value[0]} />
											)
										}
										timeFormat={false}
										value={dateFrom || selectedValue[0]}
										closeOnClickOutside={true}
										closeOnSelect={true}
										isClearable={false}
										onChange={(d) => {
											setFromDate(d);
										}}
									/>
								</div>
							)}
							{selectedOperator && rangeOperator.indexOf(selectedOperator?.value) !== -1 && (
								<div className="range-date">
									<DatePicker
										helpText={
											toDateError ? (
												<ErrorMessage message={toDateError} />
											) : (
												<ErrorMessage message={props.errors.value[1]} />
											)
										}
										locale="UTC"
										className="filter-date-picker"
										utc={true}
										label={undefined}
										dateFormat="DD-MM-YYYY"
										timeFormat={false}
										value={dateTo || selectedValue[1]}
										closeOnClickOutside={true}
										closeOnSelect={true}
										isClearable={false}
										onChange={(d) => {
											setToDate(d);
										}}
									/>
								</div>
							)}
						</div>
					</>
				)}
			</div>
			<hr />
		</>
	);
}
