import "../../styles/table.css";
import { IRow } from "../../types";

import "../../styles/table.css";
import React from "react";
import { TableRow } from "./tableRow";

function TableBody(props: {
  rows: IRow[][];
  prepareRow: any;
  getTableBodyProps: any;
  isColumnResizing: any;
}) {
  const { rows, prepareRow, getTableBodyProps } = props;

  return (
    <tbody className="table-body" {...getTableBodyProps}>
      {rows.map((row: any, rowIndex: number) => {
        prepareRow(row);
        return <TableRow row={row} rowIndex={rowIndex} />;
      })}
    </tbody>
  );
}
export default React.memo(
  TableBody,
  (prevProps, nextProps: any) => nextProps.isColumnResizing
);
