import "./dashboards.css";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import evoDashboardIcon from "../../assets/evo-portal-dashboard-icon.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import arrowIcon from "../../assets/icon-arrow-right-regular.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import enrichmentIcon from "../../assets/enrichment-icon.svg";
import { TopBanner } from "../accessPortal/banner";
import { useNavigate } from "react-router-dom";

export const DashboardLandingPage = () => {
	const navigate = useNavigate();
	const dashboardRedirect = (type: string) => {
		navigate(`/dashboard?type=${type}`);
	};
	return (
		<div className="dashboard-wrapper">
			<TopBanner message="Please click on sections to view the stats" />
			<div className="card-wrapper">
				<div className="evo-dashboard-card">
					<div className="card-image">
						<img src={evoDashboardIcon} alt="" />
					</div>
					<div className="card-header" data-testId="evo-stats-header" id="evo-stats-header">
						EVO Portal
					</div>
					<div className="card-midline"></div>
					<div className="dashboard-link-wrapper">
						<div className="dashboard-link" onClick={() => dashboardRedirect("EVO")}>
							<div className="arrow-icon">
								<img src={arrowIcon} alt="" />
							</div>
							<div className="link-text" data-testId="evo-stats-link">
								View Evo Stats
							</div>
						</div>
					</div>
				</div>
				<div className="enrichment-dashboard-card">
					<div className="card-image">
						<img src={enrichmentIcon} alt="" />
					</div>
					<div className=" card-header" data-testId="enrichment-stats-header">
						Enrichment Stats
					</div>
					<div className="card-midline"></div>
					<div className="dashboard-link-wrapper">
						<div className="dashboard-link" onClick={() => dashboardRedirect("Enrichment")}>
							<div className="arrow-icon">
								<img src={arrowIcon} alt="" />
							</div>
							<div className="link-text" data-testId="enrichment-stats-link">
								View Enrichment Stats
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
