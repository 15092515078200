import { EditColumns } from "../editColumns/editColumns";
import { Filters } from "../filters";
import { SaveView } from "./saveView";

export function ModalsComp() {
	return (
		<div
			style={{
				marginRight: "12px",
				display: "flex",
				flexDirection: "row",
			}}
		>
			<div style={{ marginRight: "12px" }}>
				<SaveView />
			</div>
			<div style={{ marginRight: "12px" }}>
				<EditColumns></EditColumns>
			</div>
			<div>
				{" "}
				<Filters></Filters>{" "}
			</div>
		</div>
	);
}
