import React from 'react';
import { DatePicker } from 'antd';
import "../bulkEnrich/datePicker.css";
import dayjs,{ Dayjs } from 'dayjs';
import { setFromDate, setToDate, setIsFilteredData, setFilteredData } from "../../features/bulkEnrichment/bulkEnrichmentSlice";
import { useAppDispatch, useAppSelector } from '../../reduxHooks';
const { RangePicker } = DatePicker;

export const DatePickerCustom: React.FC = () => {
  const dispatch = useAppDispatch();
  const { dateFrom, dateTo } = useAppSelector((state) => state.bulkEnrichment);
  const fromDayjs = dateFrom ? dayjs(dateFrom): null; 
  const toDayjs = dateTo ? dayjs(dateTo): null;
  const handleRangePickerChange = (
    dates: [Dayjs | null, Dayjs | null] | null,
    dateStrings: [string, string]
  ) => {
    if (!dates) {
      dispatch(setIsFilteredData(false));
      dispatch(setFilteredData([]));
      dispatch(setFromDate(null));
      dispatch(setToDate(null));
    }
    else {
      dispatch(setFromDate(dateStrings[0]));
      dispatch(setToDate(dateStrings[1]));
    }
  };
  return (
    <div>
      <RangePicker value={[fromDayjs,toDayjs]} onChange={handleRangePickerChange} />
    </div>
  );
};