export enum Operators{
    isInRange='is in range',
   isInTheYear= "is in the year",
   isMarked="is marked"
}
export enum Message{
    EMPTYFIELDS="Some of the fields are empty, please check all input values"
}
export interface IError {
	value: string[];
	operator: string;
	columnName: string;
}