import React from "react";
import "../../styles/AccessPortalStyles/welcome.css";

type Props = { user: string | undefined; message?: string };

const Welcome = (props: Props): JSX.Element => {
	return (
		<div className="section-jumbo__welcome">
			<h1 className="h2_style">
				<b> Hello {props.user || "..."}!</b>
			</h1>
			<p className="p_style">Welcome to Customer Enrichment!</p>
			{props.message ? (
				<p className="p_style_2">{props.message}</p>
			) : (
				<p className="p_style_2">
					Let’s get you started! Choose from the list the correct business unit.
				</p>
			)}
		</div>
	);
};

export default Welcome;
