import { useAppSelector } from "../../reduxHooks";
import { SingleCheckbox } from "./singleCheckbox";
import "./filters.css";
import { IFilter } from "../../types";

import React, { useEffect, useState } from "react";

import { Accordion } from "@cimpress/react-components";

export function SavedFilters(props: {
  tableName: string;
  allUnsavedFilters: IFilter[];
  setAllUnsavedFilters: React.Dispatch<React.SetStateAction<IFilter[]>>;
  accordianOpen: boolean;
}) {
  const { savedFilters } = useAppSelector((state) => state.view);
  const [isOpen, setIsOpen] = useState(props.accordianOpen);
  useEffect(() => {
    setIsOpen(props.accordianOpen);
  }, [props.accordianOpen]);
  return (
    <Accordion title={"Saved Filters"} defaultOpen={isOpen}>
      {savedFilters.length === 0 ? (
        <div className="no-saved-filter">There are no saved filters</div>
      ) : (
        savedFilters.map((customFilter) => (
          <div className="checkbox-wrapper">
            <SingleCheckbox
              allUnsavedFilters={props.allUnsavedFilters}
              name={customFilter.name}
              id={customFilter.filterId}
              setAllUnsavedFilters={props.setAllUnsavedFilters}
            />
          </div>
        ))
      )}
    </Accordion>
  );
}
