import "./multiUpdate.css";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import IconArrowRightAlt from "@cimpress-technology/react-streamline-icons/lib/IconArrowRightAlt";
import { useRef, useState } from "react";
// import { Dropdown } from "@cimpress/react-components";
import { LabelOptions } from "../tabularView/labelOptions";
import { useAppSelector } from "../../reduxHooks";
import _ from "lodash";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as ArrowDown } from "../../assets/arrow-down.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as ArrowUP } from "../../assets/arrow-up.svg";

import { DetectOutsideClick } from "../detectClickOutside";
import { notEditableColumns } from "../../config";
export function MultiUpdate() {
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
	const [menuOpen, setMenuOpen] = useState<string | undefined>(undefined);
	const [isLabelOption, setLabelOption] = useState<boolean>(false);
	const labelOptionRef = useRef(null);
	const menuStatus = () => {
		setMenuOpen(undefined);
		setIsMenuOpen(false);
		setLabelOption(!isLabelOption);
	};
	const { selectedRowsIndexes, selectedHeaders, visibleColumns } = useAppSelector(
		(state) => state.view
	);

	const markableColumns = _.difference(visibleColumns, notEditableColumns);
	const allDataPoints = markableColumns.length;
	const editableFields = selectedHeaders
		.map((col) => {
			if (col.toLowerCase() === "ce_industry_l1" || col.toLowerCase() === "website") {
				return col;
			} else if (col.toLowerCase().indexOf("employees") !== -1) return col;
			else return undefined;
		})
		.filter((field) => field !== undefined);

	const title = `${selectedRowsIndexes.length} selected`;
	const openLabels = (menuName: string | undefined) => {
		if (menuName === menuOpen) {
			setLabelOption(!isLabelOption);
			setMenuOpen(undefined);
		} else {
			setLabelOption(true);
			setMenuOpen(menuName);
		}
	};
	DetectOutsideClick(labelOptionRef, menuStatus);
	return (
		<div className="multi-select-dropdown" ref={labelOptionRef}>
			<div className="title" onClick={() => setIsMenuOpen(!isMenuOpen)}>
				{title}{" "}
			</div>
			<div className="arrow-up-down">
				{isMenuOpen ? (
					<ArrowUP onClick={() => setIsMenuOpen(!isMenuOpen)} />
				) : (
					<ArrowDown onClick={() => setIsMenuOpen(!isMenuOpen)} />
				)}
			</div>

			{isMenuOpen && (
				<div className="validation-menu">
					<div style={{ fontWeight: 600, padding: "8px 8px", color: "#697582" }}>
						Validation for
					</div>
					<div
						className={menuOpen === "ALL" ? "selected-menu-item" : "menu-item"}
						onClick={() => openLabels("ALL")}
					>
						All data points ({allDataPoints}){" "}
						<span className="right-arrow">
							{" "}
							<IconArrowRightAlt />
						</span>
						{isLabelOption && menuOpen === "ALL" && (
							<LabelOptions
								position="right"
								rowIndexes={selectedRowsIndexes}
								columnNames={markableColumns}
								isDropDown={true}
								setIsDropDown={setIsMenuOpen}
							/>
						)}
					</div>
					{editableFields &&
						editableFields.map((field) => (
							<div
								className={menuOpen === field ? "selected-menu-item" : "menu-item"}
								onClick={() => openLabels(field)}
							>
								{field}{" "}
								<span className="right-arrow">
									{" "}
									<IconArrowRightAlt />
								</span>
								{field && isLabelOption && menuOpen === field && (
									<LabelOptions
										position="right"
										rowIndexes={selectedRowsIndexes}
										columnNames={[field] || []}
										isDropDown={true}
										setIsDropDown={setIsMenuOpen}
									/>
								)}
							</div>
						))}
				</div>
			)}
		</div>
	);
}
