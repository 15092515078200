import { Pagination } from "@cimpress/react-components";
import "../../styles/detailedView.css";
import { useAppDispatch, useAppSelector } from "../../reduxHooks";
import { setcIdPageNumber } from "../../features/view/viewSlice";

export function PaginationContent() {
  const { linkedCustomerIds, firmographics, customFields, businessFields } =
    useAppSelector((state) => state.view);
  const itemsPerPage =
    Object.keys(businessFields).length >= 4 &&
    Object.keys(firmographics).length >= 4 &&
    Object.keys(customFields).length >= 4
      ? 20
      : 10;
  const totalPages = Math.ceil(linkedCustomerIds.length / itemsPerPage);
  const displayedItemCount =
    linkedCustomerIds.length < itemsPerPage
      ? linkedCustomerIds.length
      : itemsPerPage;
  const dispatch = useAppDispatch();
  const onPageChange = ({ selected }: { selected: number }) => {
    dispatch(setcIdPageNumber(selected));
  };

  return (
    <div className="pagination-wrapper" data-testid="pagination-wrapper" id="pagination-wrapper">
      <div
        className="pagination-title"
        data-testid="pagination-title"
        id="pagination-title"
        style={
          linkedCustomerIds.length <= itemsPerPage
            ? { left: "1vw", top: "5vh" }
            : { left: "1vw",top:"7.5vh" }
        }>
        {displayedItemCount} out of {linkedCustomerIds.length}
      </div>
      <div
        className="pagination-title"
        style={
          linkedCustomerIds.length <= itemsPerPage
            ? { left: "4vw", top:"5vh" }
            : { left: "3.5vw",top:"7.5vh" }
        }
      >
        {" "}
        Page{" "}
      </div>
        <div className="pagination-box" data-testid="pagination-box" id="pagination-box" 
        style={
            linkedCustomerIds.length <= itemsPerPage
              ? { left: "10vw" }
              : { left: "9vw" }
          }>
        <Pagination
          initialPage={0}
          pageCount={totalPages}
          onPageChange={onPageChange}
          pagesOutsideElipses={0}
          pagesBetweenElipses={0}
        />
      </div>
      <div
        className="pagination-title"
        style={
          linkedCustomerIds.length <= itemsPerPage
            ? { left: "20vw", top: "2vh" }
            : { left: "21vw", top: "2vh" }
        }
      >
        {" "}
        of {totalPages}
      </div>
    </div>
  );
}
