import { ProspectorTableCell } from "./prospectorTableCell";

export const ProspectorTableBody =(props:any) =>{
    const {getTableBodyProps,rows,prepareRow} = props; 
    return(
        <tbody {...getTableBodyProps()}>
          {rows.map((row:any) => {
            prepareRow(row);
            return (
              <tr className="prospector-table-row" {...row.getRowProps()}>
                {row.cells.map((cell:any) => (
                    <ProspectorTableCell cell={cell}/>
                ))}
              </tr>
            );
          })}
        </tbody>
    )
}