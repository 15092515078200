import { Select } from "@cimpress/react-components";
import _ from "lodash";
import React from "react";
import { useEffect, useState } from "react";
import { AuthContext } from "../../context/authContext";
import {
	getTables,
	setRows,
	setTable,
	setTableHeaders,
	setVisibleColumns,
} from "../../features/view/viewSlice";
import { useAppDispatch, useAppSelector } from "../../reduxHooks";
import { ITableOption } from "../../types";
import "./dropDowns.css";

export function TableDropDown() {
	const { profile } = React.useContext(AuthContext);
	const emailId = profile?.email;
	const canonicalId = profile?.["https://claims.cimpress.io/canonical_id"];
	const userId = canonicalId ? canonicalId : emailId;
	const dispatch = useAppDispatch();
	const defaultOption = { label: "Select a table", value: "Select a table" };
	const { tableList, selectedTable, detailedViewTable, isDetailedView } = useAppSelector(
		(state) => state.view
	);
	const [table, setSelectedTable] = useState<ITableOption>(defaultOption);

	useEffect(() => {
		if (userId) {
			dispatch(getTables({ userId }));
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const defaultOptions: ITableOption[] | undefined = React.useMemo(() => {
		if (tableList.length > 0) {
			if (isDetailedView === false) {
				setSelectedTable({ label: tableList[0], value: tableList[0] });
				dispatch(setTable(tableList[0]));
			} else {
				if (detailedViewTable) {
					setSelectedTable({
						label: detailedViewTable,
						value: detailedViewTable,
					});
					dispatch(setTable(detailedViewTable));
				} else {
					setSelectedTable({ label: tableList[0], value: tableList[0] });
					dispatch(setTable(tableList[0]));
				}
			}
			return _.uniq(tableList).map((tableName, index) => {
				return {
					label: <div id={`table-dropdown-item-${index}`}>{tableName}</div>,
					value: tableName,
				};
			});
		}
	}, [tableList.length]); // eslint-disable-line react-hooks/exhaustive-deps

	const onSingleSelectionChange = (table: ITableOption) => {
		if (table.value === selectedTable) {
			return;
		}
		setSelectedTable(table);
		dispatch(setTable(table.value));
		dispatch(setRows([]));
		dispatch(setVisibleColumns([]));
		dispatch(setTableHeaders([]));
	};
	return (
		<Select
			id="table-dropdown"
			className="table-dropdown-select"
			label={undefined}
			isSearchable={false}
			value={table}
			options={defaultOptions}
			onChange={onSingleSelectionChange}
			helpText={undefined}
		/>
	);
}
