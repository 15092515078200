import { Checkbox } from "@cimpress/react-components";
import {  useMemo, useState } from "react";
import { setSelectedRowIndexes } from "../../features/view/viewSlice";
import { useAppDispatch, useAppSelector } from "../../reduxHooks";

export function SelectCheckBox(props: {
  isEnriched: boolean;
  rowIndex: number;
  indexesArr: number[];
}) {
  const { indexesArr, rowIndex } = props;
  const [isChecked, setIsChecked] = useState<boolean>(rowIndex in indexesArr);
  const isHeaderChecked= useAppSelector((state) => state.view.headerChecked);
  const dispatch = useAppDispatch();
  useMemo(() => {
    if (isHeaderChecked ) {
      setIsChecked(true);
    } 
    else {
      setIsChecked(false);
    }
  }, [isHeaderChecked]);
  const selectRow = (index: number) => {
    let indexes = [...indexesArr];
    if (indexes.indexOf(index) === -1) {
      indexes.push(index);
      setIsChecked(true);
    } else {
      indexes = indexes.filter((num) => num !== index);
      setIsChecked(false);
    }
    dispatch(setSelectedRowIndexes(indexes));
  };
  return (
    <div style={{ lineHeight: "16px", paddingBottom: "16px" }}>
      <Checkbox
        label={undefined}
        checked={isChecked}
        payload={undefined}
        onChange={() => selectRow(rowIndex)}
        disabled={!props.isEnriched}
      />
    </div>
  );
}
