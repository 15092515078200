import React from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthContext } from "./context/authContext";
import { PortalHeader } from "./components/header";
import { TableView } from "./components/tabularView/tableView";
import { Alert, Button, FourOhFour, Spinner } from "@cimpress/react-components";
import { useAppSelector } from "./reduxHooks";
import { SnackBarNoty } from "./components/snackbar";
import "./styles/index.css";
import { DetailedView } from "./components/detailedView";
import { Middle } from "./components/middleSection/middle";
import { Prospector } from "./prospector";
import { DashboardLandingPage } from "./components/dashboards";
import { Dashboard } from "./components/dashboards/dashboards";
import { BulkEnrich } from "./components/bulkEnrich";

import { evoPortalRoute } from "./utils/commonUtility";
import { DocIntent } from "./components/docintent/docIntent";
export function App() {
  const authContext = React.useContext(AuthContext);
  const { accessToken } = authContext;
  const { isLoading, permissionError } = useAppSelector((state) => state.view);
  const snackbarMessage = useAppSelector((state) => state.view.snackbarMessage);
  const navigate = useNavigate();
  const snackbarStatus = useAppSelector((state) => state.view.snackbarStatus);
  // Check if the hostname starts with "enrichment"
  const shouldHideFeatures = window.location.hostname.startsWith("enrichment");

  if (accessToken) {
    return (
      <>
        <div className="root-comp">
          {isLoading === "loading" && (
            <>
              <div className="spinner_overlay"></div>
              <Spinner className="spinner" />
            </>
          )}

          {snackbarMessage && snackbarMessage.length > 0 && (
            <SnackBarNoty
              message={snackbarMessage}
              status={snackbarStatus}
            ></SnackBarNoty>
          )}
          <div className="routes=wrapper" id="routes=wrapper">
            <Routes>
              <Route
                path={`/${evoPortalRoute}`}
                element={
                  <>
                    <PortalHeader />
                    {permissionError && (
                      <>
                        <div className="permission-overlay"></div>
                        <Alert
                          className="permission-alert"
                          status="info"
                          message={permissionError}
                        />
                      </>
                    )}
                    <Middle></Middle>
                    <TableView></TableView>
                  </>
                }
              />
              {(
                <Route
                  path="/home"
                  element={
                    <>
                      <PortalHeader /> <DashboardLandingPage />
                    </>
                  }
                />
              )}
              <Route path="/" element={<Navigate to={"/home"} />} />

              {/* Conditionally render Prospector route */}
              {!shouldHideFeatures && (
                <Route
                  path="/prospector"
                  element={
                    <>
                      <PortalHeader />
                      <Prospector />
                    </>
                  }
                />
              )}
              {/* Conditionally render BulkEnrich route */}
              {(
                <Route
                  path="/bulkEnrich"
                  element={
                    <>
                      <PortalHeader />
                      <BulkEnrich />
                    </>
                  }
                />
              )}
              <Route
                path="/doc-intent"
                element={
                  <>
                    <PortalHeader />
                    <DocIntent />
                  </>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <>
                    <PortalHeader /> <Dashboard />
                  </>
                }
              />
              <Route
                path={`/${evoPortalRoute}/detailedView`}
                element={
                  <>
                    <PortalHeader />
                    <div
                      className="detailed-view-outer-wrapper"
                      data-testid="detailed-view-outer-wrapper"
                    >
                      <DetailedView />
                    </div>
                  </>
                }
              />
              <Route
                path="*"
                element={
                  <>
                    <PortalHeader />
                    <div className="page-not-found">
                      <FourOhFour
                        message={
                          <div>
                            Oops! Something has gone wrong and the page you
                            were looking for could not be displayed. Try{" "}
                            <Button
                              className="error-anchor-button"
                              variant="anchor"
                              onClick={() => {
                                navigate(`/${evoPortalRoute}`);
                              }}
                            >
                              EVO Portal
                            </Button>
                            .
                          </div>
                        }
                      />
                    </div>
                  </>
                }
              />
            </Routes>
          </div>
        </div>
      </>
    );
  }

  return <></>;
}

export default App;