import { useEffect } from "react";

export function DetectOutsideClick(
	ref: React.MutableRefObject<any>,
	callback: React.Dispatch<React.SetStateAction<boolean>>,
	excludedRef?: React.MutableRefObject<any>
) {
	useEffect(() => {
		/**
      if clicked on outside of element
     */

		function handleClickOutside(event: any) {
			if (ref.current && !ref.current.contains(event.target) &&
			!(excludedRef?.current && excludedRef.current.contains(event.target))) {
				callback(false);
			}
		}
		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref]); // eslint-disable-line react-hooks/exhaustive-deps
}
