const markStyle: { [key: string]: string } = {
  width: "0px",
  height: "0px",
  borderStyle: "solid",
  borderWidth: "0 10px 10px 0",
  right: "0px",
  top: "0px",
  position: "absolute",
};
const colorStatusMap: { [key: string]: string } = {
  incorrect: "#B73721",
  correct: "#008000",
  notSure: "#e87121",
};
export function StatusMark(props: { status: string }) {
  const { status } = props;
  const color = status in colorStatusMap ? colorStatusMap[status] : "";

  return (
    <div
      style={{
        ...markStyle,
        borderColor: `transparent ${color} transparent transparent`,
      }}
    ></div>
  );
}
