import "../styles/prospectorTable.css";
export const ProspectorTableHeader = (props: any) => {
    const { headerGroups } = props;
    return (
        <thead>
            {headerGroups.map((headerGroup: any) => (
                <tr className="prospector-table-row" {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                        <th {...column.getHeaderProps()} className="prospector-table-head">
                            
                            {/* {column.render('Header')} */}
                            <div  {...column.getResizerProps()} className="resizer" />
                            <span className={`prospector-header-content ${(column.id === "companyLogo") ? "header-logo" : " "}`}>
                                {column.id.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')}
                                </span>
                        </th>
                    ))}
                </tr>
            ))}
        </thead>
    )
}