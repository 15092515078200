import { useRef, useState } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ChargerIcon from "../../../assets/icon-charger-alt-regular.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import EllipsisIcon from "../../../assets/icon-vertical-ellipsis.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ArrowIcon from "../../../assets/icon-button-arrow.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import IconInformationCircle from "@cimpress-technology/react-streamline-icons/lib/IconInformationCircle";
import "../../../../src/styles/detailedView.css"
import "../dataProvider/dataProvidersView.css"
import { DataProviderViewProps } from "../../../types";
import "../dataProvider/dataProviderSections.css"
import { DataProviderSections } from "./dataProvidersSections";
import { DetailedViewItem } from "../detailedViewItem";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "../../../reduxHooks";
import { DetectOutsideClick } from "../../detectClickOutside";
import { Checkbox, Tooltip } from "@cimpress/react-components";
import "../dataProvider/dataProviderSections.css"
import { setCheckedStateCorrect } from "../../../features/view/viewSlice";
const ellipsisStyle = {
    borderRadius: "2px",
    border: "1px solid #0088A9",
    background: "#FFF",
    width: "3vh",
    height: "3vh",
    paddingLeft: "0.4vw",
    paddingRight: "0.4vw",
};

export const handleSourceName = (name: string) => {
    const parts = name.split('_');
    if (parts.length > 1) {
        const result = parts.slice(0, 2).join(' ');
        if (name.includes("hitHorizons")) {
            return "Hit Horizons";
        }
        return _.startCase(result);
    } else {
        return _.startCase(name);
    }
}

export const DataProvidersView = ({ title }: DataProviderViewProps) => {
    const dispatch = useAppDispatch();
    const maxNoOfSectionsVisible = 3;
    const [isOpen, setIsOpen] = useState(true);
    const [isSectionOpen, setIsSectionOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen((prevIsOpen) => !prevIsOpen);
    };
    const handleSections = () => {
        setIsSectionOpen((prevIsOpen) => !prevIsOpen);
    }
    const searchParams = new URLSearchParams(window.location.search);
    const enrichmentCorrelationId = searchParams.get("enrichmentCorrelationId") || "";
    const ProviderMenuRef = useRef(null);
    DetectOutsideClick(ProviderMenuRef, setIsSectionOpen);
    const { enrichedValues, dataProviderResponse, checkedStateCorrect } = useAppSelector((state) => state.view);
    const AllProviderList = dataProviderResponse["all_providers"];
    const ProviderMenuAll: { [key: string]: string }[] = [];
    const ProviderMenuInitial: { [key: string]: string }[] = [];
    for (const key in dataProviderResponse) {
        if (AllProviderList && AllProviderList.indexOf(key) !== -1) {
            ProviderMenuAll.push(dataProviderResponse[key])
        }
    }
    for (let InitialProviderMenuIndex = 0; InitialProviderMenuIndex < (ProviderMenuAll.length <= maxNoOfSectionsVisible ? ProviderMenuAll.length : maxNoOfSectionsVisible); InitialProviderMenuIndex++) {
        ProviderMenuInitial.push(ProviderMenuAll[InitialProviderMenuIndex])
    }
    const [ProviderMenu, setProviderMenu] = useState(ProviderMenuInitial);
    const [isCheckBoxDisable, setCheckboxDisable] = useState(false);

    const countOccurrences = (arr: boolean[], target: boolean): number => {
        let count = 0;

        for (let arrIndex = 0; arrIndex < arr.length; arrIndex++) {
            if (arr[arrIndex] === target) {
                count++;
            }
        }

        return count;
    };

    const ProviderMneuFinal: { [key: string]: string }[] = [];
    const handleOnChangeCorrect = (position: number) => {
        const updatedCheckedStateCorrect = checkedStateCorrect.map((item, index) =>
            index === position ? !item : item
        );
        countOccurrences(updatedCheckedStateCorrect, true) >= maxNoOfSectionsVisible ? setCheckboxDisable(true) : setCheckboxDisable(false);
        for (let FinalMenuIndex = 0; FinalMenuIndex <= ProviderMenuAll.length; FinalMenuIndex++) {
            if (updatedCheckedStateCorrect[FinalMenuIndex] === true) {
                ProviderMneuFinal.push(ProviderMenuAll[FinalMenuIndex])
                setProviderMenu(ProviderMneuFinal);
            }
        }
        dispatch(setCheckedStateCorrect(updatedCheckedStateCorrect));
    };

    return (
        <div className={title === "Enriched Values" ? "data-provider-container" : "data-provider-unused-container"}
            data-testid="data-provider-container"
            id="data-provider-container" ref={ProviderMenuRef}>
            <div className="data-provider-header-items" >
                <div
                    className="left-section"
                    data-testid="left-section"
                    id="left-section"
                    onClick={handleToggle}
                >
                    <img
                        alt=""
                        src={title === "Enriched Values" ? ChargerIcon : ""}
                        className={title !== "Enriched Values" ? "" : "user-icon"}
                        data-testid="user-icon"
                        id="user-icon"
                    />
                    <div className="data-provider-title"
                        data-testid="data-provider-title"
                        id="data-provider-title">
                        {title}
                    </div>
                </div>
                <div className="right-section"
                    data-testid="right-section"
                    id="right-section" >
                    {title === "Enriched Values" && (
                        <div style={{ marginLeft: "10px" }}><Tooltip
                            direction="top"
                            contents={
                                <div className="and-or-tooltip" data-testid="and-or-tooltip"
                                    id="and-or-tooltip">
                                    This section contain all data provider list for following customer data
                                </div>
                            }
                        >
                            <img
                                alt=""
                                src={EllipsisIcon}
                                className="ellipsis-icon"
                                data-testid="ellipsis-icon"
                                id="ellipsis-icon"
                                style={ellipsisStyle}
                                onClick={handleSections}
                            />
                        </Tooltip></div>
                    )}
                    <img
                        alt=""
                        src={ArrowIcon}
                        onClick={handleToggle}
                        className={`arrow-icon ${isOpen ? "is-open" : ""}`}
                        data-testid="arrow-icon"
                        id="arrow-icon"
                        style={{
                            borderRadius: "2px",
                            background: "#0088A9",
                            width: "3vh",
                            height: "3vh",
                            paddingLeft: "0.4vw",
                            paddingRight: "0.4vw",
                        }}
                    />
                </div>
            </div>
            {isSectionOpen && title === "Enriched Values" && <div className="provider-enriched-menu"
                data-testid="provider-enriched-menu"
                id="provider-enriched-menu">
                <div className="provider-enriched-menu-wrapper"
                    data-testid="provider-enriched-menu-wrapper"
                    id="provider-enriched-menu-wrapper">
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}><span>edit columns</span><div style={{ marginLeft: "10px" }}><Tooltip
                        direction="top"
                        contents={
                            <div className="and-or-tooltip" data-testid="and-or-tooltip"
                                id="and-or-tooltip">
                                Maximum of three should be selected
                            </div>
                        }
                    >
                        <IconInformationCircle weight="fill" color="#0088A9" size="lg" />
                    </Tooltip></div></div>
                    <hr />
                </div>
                {ProviderMenuAll.map((provider, index) => {
                    return (
                        <div className="provider-enriched-menu-checkbox"
                            data-testid="provider-enriched-menu-checkbox"
                            id="provider-enriched-menu-checkbox">
                            <Checkbox key={index}
                                label={handleSourceName(provider.sourceName)}
                                disabled={isCheckBoxDisable && !checkedStateCorrect[index]}
                                checked={checkedStateCorrect[index]}
                                payload={undefined}
                                onChange={() => handleOnChangeCorrect(index)} />
                        </div>
                    )
                })}

            </div>}
            {isOpen && title === "Enriched Values" && <div className="data-provider-enrich-wrapper"
                data-testid="data-provider-enrich-wrapper"
                id="data-provider-enrich-wrapper">
                <div className="data-provider-section"
                    data-testid="data-provider-section"
                    id="data-provider-section" >
                    <div>
                        <div className="data-provider-section-header"
                            data-testid="data-provider-section-header"
                            id="data-provider-section-header">
                            <div style={{ marginLeft: "12px", display: "flex" }}>{"Enriched Value"}
                                <div style={{ marginLeft: "10px" }}><Tooltip
                                    direction="top"
                                    contents={
                                        <div className="and-or-tooltip" data-testid="and-or-tooltip"
                                            id="and-or-tooltip">
                                            This is the enriched data & you can Validate/Override values in this section
                                        </div>
                                    }
                                >
                                    <IconInformationCircle weight="fill" color="#0088A9" size="lg" />
                                </Tooltip></div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {
                            (
                                Object.entries(enrichedValues).map(([name, value]) => {
                                    return (
                                        <DetailedViewItem
                                            data={enrichedValues}
                                            title="Enriched Value"
                                            name={name}
                                            value={value}
                                            identifierId={enrichmentCorrelationId}
                                        />
                                    );
                                }
                                )
                            )
                        }
                    </div>
                </div>
                {(countOccurrences(checkedStateCorrect, true) === 0 ? ProviderMenuInitial : ProviderMenu).map((provider) => {
                    return (
                        <DataProviderSections title={handleSourceName(provider.sourceName)} data={provider} />
                    )
                })
                }</div>}
        </div>

    )
};
