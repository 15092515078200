import { useMemo, useState } from "react";
import { Button, Drawer, TextField, Toggle, Tooltip } from "@cimpress/react-components";
import { SavedFilters } from "./savedFilters";
import { SingleFilter } from "./singleFilter";
import { useAppDispatch, useAppSelector } from "../../reduxHooks";
import {
	getViewData,
	saveFilters,
	setUnsavedFilters,
	setAppliedSavedFilters,
	getFilters,
	updateFilters,
	setfilterOP,
} from "../../features/view/viewSlice";
import { DefaultView, IFilter } from "../../types";
import "./filters.css";
import React from "react";
import { AuthContext } from "../../context/authContext";
import { isEmpty, isValidFilter } from "./validateFilters";
import _ from "lodash";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import AddFilter from "../../assets/add-filter.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import IconCheck from "../../assets/icon-check.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import IconClose from "../../assets/icon-close.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import IconInformationCircle from "@cimpress-technology/react-streamline-icons/lib/IconInformationCircle";
import { IError } from "./types";
import { startCase } from "lodash";
const sameFilter = (filterOne: IFilter, filterTwo: IFilter) => {
	return (
		filterOne.column === filterTwo.column &&
		filterOne.operator === filterTwo.operator &&
		filterOne.value === filterTwo.value
	);
};
export function Filters(props: any) {
	const defaultUnsavedFilter = [
		{
			column: "",
			operator: "",
			value: [""],
		},
	];
	const [open, setOpen] = useState(false);
	const {
		unsavedFilters,
		pageSize,
		selectedView,
		selectedTable,
		sortColumn,
		sortOrder,
		visibleColumns,
		appliedSavedFilters,
		quickFilters,
		savedFilters,
		selectedHeaders,
		filterOP,
		isDetailedView,
		detailedViewTable
	} = useAppSelector((state) => state.view);
	const [error, setError] = useState<string | undefined>(undefined);
	const [emptyErrors, setEmptyError] = useState<{ [key: number]: IError | undefined }>();
	const { profile } = React.useContext(AuthContext);
	const email = profile?.email || "";
	const dispatch = useAppDispatch();
	const [filterOperator, setFilterOpertaor] = useState(filterOP);
	const [filterName, showFilterName] = useState(false);
	const [newFilterName, setNewFilterName] = useState("");
	const [allUnsavedFilters, setAllUnsavedFilters] = useState<IFilter[]>(
		unsavedFilters.length > 0 ? unsavedFilters : [...defaultUnsavedFilter]
	);
	const [accordianOpen, setAccordianOpen] = useState(savedFilters.length <= 3);
	useMemo(() => {
		setAllUnsavedFilters(allUnsavedFilters);
		if (allUnsavedFilters.length === 0) dispatch(setAppliedSavedFilters([]));
	}, [allUnsavedFilters]); // eslint-disable-line react-hooks/exhaustive-deps
	useMemo(
		() => setAllUnsavedFilters(unsavedFilters),
		[selectedTable, selectedView] // eslint-disable-line react-hooks/exhaustive-deps
	);
	useMemo(() => {
		if (email && open) {
			dispatch(
				getFilters({
					userId: email,
					tableName: selectedTable,
				})
			);
		}
		if (unsavedFilters.length === 0 && allUnsavedFilters.length === 0 && open) {
			setAllUnsavedFilters(defaultUnsavedFilter);
		} else {
			setAllUnsavedFilters(unsavedFilters);
		}
	}, [open]); // eslint-disable-line react-hooks/exhaustive-deps
	const [saveUpdateString, setSaveUpdateString] = useState("Save");
	useMemo(() => {
		if (
			appliedSavedFilters &&
			appliedSavedFilters.length === 1 &&
			savedFilters &&
			savedFilters.length > 0
		) {
			const filterId = appliedSavedFilters[0].filterId;
			const savedFilter = savedFilters.filter((singleFilter) => singleFilter.filterId === filterId);

			const shouldNotUpdate = _.isEqual(
				_.differenceBy(allUnsavedFilters, savedFilter[0].filters, () => [
					"column",
					"operator",
					"value",
				]),
				savedFilter[0].filters
			);
			const updateString = shouldNotUpdate ? "Save" : "Update";
			setSaveUpdateString(updateString);
		} else {
			setSaveUpdateString("Save");
		}
		setError("");
	}, [allUnsavedFilters]); // eslint-disable-line react-hooks/exhaustive-deps
	const addFilter = () => {
		let arr = [...allUnsavedFilters];
		arr.push({
			column: "",
			operator: "",
			value: [""],
		});
		setAllUnsavedFilters(arr);
		setError("");
	};
	const onDelete = (index: number) => {
		const arr = [...allUnsavedFilters];
		if (appliedSavedFilters && appliedSavedFilters.length > 0) {
			let appliedFilters = appliedSavedFilters[0].filters;
			let isApplied = false;
			appliedFilters.forEach((singleFilter) => {
				if (sameFilter(singleFilter, arr[index])) isApplied = true;
			});
			if (isApplied) {
				dispatch(setAppliedSavedFilters([]));
			}
		}
		arr.splice(index, 1);
		setAllUnsavedFilters(arr);
		setError("");
	};
	const updateFilter = () => {
		let updatedFilter = _.cloneDeep(appliedSavedFilters);
		updatedFilter[0].filters = [...allUnsavedFilters];
		dispatch(
			updateFilters({
				updatedFilters: updatedFilter[0].filters,
				filterId: updatedFilter[0].filterId,
			})
		).then((response) => {
			dispatch(setAppliedSavedFilters(updatedFilter));
			setSaveUpdateString("Save");
			if (email && open) {
				dispatch(
					getFilters({
						userId: email,
						tableName: selectedTable,
					})
				);
			}
			setEmptyError({});
		});
		setError("");
	};
	const saveAllFilters = (action: string, filterName: string) => {
		const emptyValidation = isEmpty(allUnsavedFilters);
		const { isValid } = emptyValidation;

		if (!isValid && emptyValidation.errors) {
			setEmptyError(emptyValidation.errors);
			return;
		} else if (filterName === "") {
			setError("Please enter the filter name");
		} else {
			if (filterName && email) {
				dispatch(
					saveFilters({
						userId: email,
						tableName: selectedTable,
						allFilters: allUnsavedFilters,
						filterName,
					})
				).then((res: any) => {
					const filterId = res.payload.filterId;
					dispatch(
						getFilters({
							userId: email,
							tableName: selectedTable,
						})
					).then((res) => {
						dispatch(
							setAppliedSavedFilters([
								{
									filterId,
									name: filterName,
									filters: allUnsavedFilters,
								},
							])
						);
					});
					const unsavedFilterCopy = _.cloneDeep(allUnsavedFilters);
					setAllUnsavedFilters([...unsavedFilterCopy]);
				});
			}
			setError("");
			setNewFilterName("");
			showFilterName(false);
			setEmptyError({});
		}
	};

	const getViewRequestData = () => {
		return {
			currentPage: 0,
			size: pageSize,
			viewId: selectedView ? selectedView.viewId : DefaultView.Default,
			unsavedFilters: allUnsavedFilters,
			savedFilters: [],
			selectedTable: isDetailedView? detailedViewTable : selectedTable,
			selectedColumns: selectedHeaders || [],
			visibleColumns: visibleColumns || [],
			sortingColumn: sortColumn,
			sortingOrder: sortOrder,
			userId: email,
			isApplyFilters: true,
			quickFilters: quickFilters || selectedView?.quickFilters,
			filterOp: filterOperator,
		};
	};
	const applyFilters = () => {
		const validation = isValidFilter(allUnsavedFilters, visibleColumns);
		if (validation && !validation.isValid) {
			setEmptyError(validation.errors);
			return;
		}
		dispatch(setUnsavedFilters([...allUnsavedFilters]));
		dispatch(setAppliedSavedFilters([...appliedSavedFilters]));
		if (email) dispatch(getViewData(getViewRequestData()));
		setOpen(false);
		setEmptyError({});
	};
	const closeFilterDrawer = () => {
		const unsavedFilterCopy = _.cloneDeep(unsavedFilters);
		setAllUnsavedFilters([...unsavedFilterCopy]);
		setOpen(false);
		showFilterName(false);
		setNewFilterName("");
		setAccordianOpen(savedFilters.length <= 3);
		setEmptyError({});
		setError("");
	};
	const clearFilters = () => {
		setAllUnsavedFilters([]);
		showFilterName(false);
		dispatch(setAppliedSavedFilters([]));
		setEmptyError({});
		setError("");
	};
	const saveFromSaveButton = () => {
		if (filterName) {
			saveAllFilters(saveUpdateString, newFilterName);
		} else {
			setEmptyError({});
			showFilterName(true);
		}
	};
	const changeFilterOperator = () => {
		dispatch(setfilterOP(filterOperator === "OR" ? "AND" : "OR"));
		setFilterOpertaor(() => (filterOperator === "OR" ? "AND" : "OR"));
	};
	return (
		<div>
			<Button
				className="right-buttons open-filter-dialogue"
				variant="primary"
				data-testid="right-filter-buttton"
				onClick={() => setOpen(true)}
			>
				Filters
			</Button>
			<Drawer
				position={"right"}
				autoResize={true}
				size={0.42}
				show={open}
				onRequestHide={applyFilters}
				header={undefined}
				closeOnClickOutside={true}
				footer={
					<div className="footer-buttons">
						<div className="clear-all" onClick={clearFilters}>
							Clear all
						</div>
						<Button
							className="single-footer-button close-button"
							data-testid="single-footer-button-close-button"
							onClick={closeFilterDrawer}
						>
							Close
						</Button>
						{allUnsavedFilters.length > 0 && (
							<Button
								className="single-footer-button save-update-button"
								data-testid="single-footer-button-save-update-button"
								onClick={() => {
									if (saveUpdateString === "Save") {
										saveFromSaveButton();
									} else {
										updateFilter();
									}
								}}
							>
								{saveUpdateString}
							</Button>
						)}

						<Button
							className="single-footer-button apply-button"
							variant="primary"
							onClick={applyFilters}
							data-testid="single-footer-button-apply-button"
						>
							Apply
						</Button>
					</div>
				}
			>
				<div className="filter-add-wrapper" data-testid="filter-add-wrapper-test">
					<SavedFilters
						tableName={selectedTable}
						allUnsavedFilters={allUnsavedFilters}
						setAllUnsavedFilters={setAllUnsavedFilters}
						accordianOpen={accordianOpen}
					/>
					{filterName && (
						<div className="save-filter-fields">
							<div className="add_new_filter">
								<div style={{ flex: 3, paddingTop: "8px" }}>
									<TextField
										helpText={<div style={{ fontSize: "14px", color: "#D24A35" }}>{error}</div>}
										required={true}
										type="text"
										autoFocus={true}
										value={startCase(newFilterName)}
										onChange={(e) => {
											setNewFilterName(e.target.value);
											setError("");
										}}
										label={"Please enter filter name"}
									/>
								</div>
							</div>
							<div className="icon-check">
								<img
									alt=""
									src={IconCheck}
									onClick={() => saveAllFilters(saveUpdateString, newFilterName)}
								/>
							</div>
							<div className="icon-close">
								<img
									alt=""
									src={IconClose}
									className="single-footer-button apply-button"
									onClick={() => {
										setNewFilterName("");
										showFilterName(false);
									}}
								/>
							</div>
						</div>
					)}
					<div>
						<Toggle
							on={filterOperator === "OR"}
							onClick={changeFilterOperator}
							disabled={false}
							size="sm"
						/>
						<span className="filter-label">{filterOperator}</span>
						<Tooltip
							direction="right"
							contents={
								<div className="and-or-tooltip">
									The filters by default are <b>OR</b> operation. To change this to <b>AND</b> click
									on the toggle
								</div>
							}
						>
							<IconInformationCircle weight="fill" color="#0088A9" size="lg" />
						</Tooltip>
					</div>
					<div className="unsaved-filters" data-testid="unsaved-filters-test">
						{allUnsavedFilters.map((filter, index) => (
							<SingleFilter
								key={`single-filter-${index}`}
								errors={
									(emptyErrors && emptyErrors[index]) || {
										columnName: "",
										value: [],
										operator: "",
									}
								}
								setEmptyErrors={setEmptyError}
								index={index}
								allUnsavedFilters={allUnsavedFilters}
								setAllUnsavedFilters={setAllUnsavedFilters}
								delete={(index) => onDelete(index)}
							/>
						))}

						<div className="add-filter-button" data-testid="add-filter-buttton" onClick={addFilter}>
							<div>Click to add filter</div>
							<div className="add-filter-icon">
								<img alt="" src={AddFilter} />
							</div>
						</div>

						<hr />
					</div>
				</div>
			</Drawer>
		</div>
	);
}
