import { InlineEdit } from "@cimpress/react-components";
import InitialsAvatar from "react-initials-avatar";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as CheckIcon } from "../../assets/icon-check-fill.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as CheckIconFaded } from "../../assets/icon-check-faded.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as BinIcon } from "../../assets/icon-bin-fill.svg";
import "./commentsStyle.css";
import { ToolTipComp } from "../Hocs/toolTip";
import _ from "lodash";
import { updateComment } from "../../hooks/view-data";
import { useState } from "react";
import { AuthContext } from "../../context/authContext";
import React from "react";
interface IComment {
	commentText: string;
	commentedBy: string;
	createdAt: string;
	field: string;
	id: string;
	isResolved: boolean;
	resolvedBy?: string;
}
export const SingleComment = (props: {
	totalComments: number;
	comment: IComment;
	identifierId: string;
	columnName: string;
	getComments: () => void;
	setCommentStatus: (status: string) => void;
}) => {
	const [isLoading, setLoading] = useState(false);
	const { profile } = React.useContext(AuthContext);
	const currentUserEmail = profile?.email;
	const { commentText, commentedBy, createdAt, id, isResolved, resolvedBy } = props.comment;
	const { identifierId, columnName } = props;
	const commentedByName = commentedBy.split("@")[0].split(".").join(" ");
	const canDelete = commentedBy === currentUserEmail;
	const DeleteButton = (
		<BinIcon
			onClick={() => {
				setLoading(true);
				updateComment(identifierId, id, columnName, "visibility", "archive", commentedBy).then(
					(response) => {
						if (props.totalComments === 1) {
							props.setCommentStatus("false");
						}
						setLoading(false);
						props.getComments();
					}
				);
			}}
		/>
	);

	const ResolveIcon = (isResolved: boolean) => {
		if (!isResolved)
			return (
				<CheckIcon
					onClick={() => {
						setLoading(true);
						updateComment(identifierId, id, columnName, "isResolved", true, commentedBy).then(
							(response) => {
								setLoading(false);
								props.getComments();
							}
						);
					}}
				/>
			);
		else {
			return <CheckIconFaded />;
		}
	};

	return (
		<div className="comment-metadata">
			{!isLoading && (
				<>
					<div className={isResolved ? "users-initials resolved-comment" : "users-initials"}>
						<InitialsAvatar name={commentedByName} />
					</div>

					<div className="username-date-wrapper">
						<div className={!isResolved ? "comment-author" : "comment-author resolved-comment"}>
							{_.startCase(commentedByName)}
						</div>

						<div className="date">{createdAt}</div>
						{isResolved && <div className=" resolved-by"> Resolved By: {resolvedBy}</div>}
						<div className="comment-text">
							{!isResolved ? (
								<InlineEdit
									name={undefined}
									placeholder={undefined}
									label={undefined}
									value={commentText}
									onSave={(obj: { value: string; name: string }) => {
										setLoading(true);
										updateComment(
											identifierId,
											id,
											columnName,
											"comment_text",
											obj.value,
											commentedBy
										).then((res) => {
											setLoading(false);
											props.getComments();
										});
									}}
									type="textarea"
								/>
							) : (
								<div className="resolved-comment-text">{commentText}</div>
							)}
						</div>
					</div>

					<div className="comment-bin-icon">
						{canDelete &&
							ToolTipComp({
								children: DeleteButton,
								message: "Delete",
							})}
					</div>
					<div className="comment-check-icon">
						{!isResolved
							? ToolTipComp({
									children: ResolveIcon(isResolved),
									message: "Resolve",
							  })
							: ResolveIcon(isResolved)}
					</div>
				</>
			)}
		</div>
	);
};
