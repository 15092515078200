import React, { useEffect, useRef, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import {
  DEBOUNCE_TIME,
  matchingColumns,
  multiselectStyle,
  searchLabels,
} from "../../config";
import { useAppDispatch, useAppSelector } from "../../reduxHooks";
import { AuthContext } from "../../context/authContext";
import {
  setIsLinkedCid,
  setSearchedCorrelationIds,
  setSearchedCustomerIds,
  setinitialDetailedView,
} from "../../features/view/viewSlice";
import { opensearchResults } from "../../hooks/view-data";
import _ from "lodash";
import { Buffer } from "buffer";
import "./detailedViewSearch.css";
import {
  DetailedViewSectionsData,
  DetailedViewSelectProps,
  ISearch,
  LinkButton,
  Option,
} from "../../types";

export const DetailedViewMultiSelect: React.FC<DetailedViewSelectProps> = ({
  toggleLinkingWrapperHeight,
  multiselectRef,
  linkOperator,
  displayIds,
  linkedCIds,
}) => {
  const searchParams = new URLSearchParams(window.location.search);
  const encodedTable = searchParams.get("tableName") || "";
  const searchTableName = Buffer.from(encodedTable, "base64").toString("ascii");
  // eslint-disable-next-line
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isMultiselectClicked, setIsMultiselectClicked] = useState(false);
  const [suggestions, setSuggestions] = useState<Option[]>([]);
  const [response, setResponse] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const { accessToken } = React.useContext(AuthContext);
  const { businessFields, defaultOrgIdMapping } = useAppSelector(
    (state) => state.view
  );

  const isDefaultOrgId = defaultOrgIdMapping ? Object.values(defaultOrgIdMapping).includes(businessFields.ORGANIZATION_ID) : false;

  const getOpensearchResult = (tableName: string, query: string) => {
    if (query && accessToken) {
      opensearchResults(tableName, query, accessToken).then((response) => {
        const formattedSuggestions = response.map((item: ISearch) => {
          let matchingColumn = matchingColumns.find((col) => item[col]);
          return {
            value: matchingColumn ? item[matchingColumn] : "",
            label: matchingColumn ? item[matchingColumn] : "",
          };
        });

        const filteredSuggestions = formattedSuggestions.filter((item: any) => {
          return !displayIds.includes(item.value);
        });
        setSuggestions(filteredSuggestions);
        setResponse(response);
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceOnChange = React.useCallback(
    _.debounce(getOpensearchResult, DEBOUNCE_TIME),
    []
  );

  const updateSearch = (tableName: string, searchValue: string) => {
    debounceOnChange(tableName, searchValue);
  };

  const handleMultiselectClick = () => {
    if (!isMultiselectClicked && selectedValues.length === 0) {
      setIsMultiselectClicked(true);
      toggleLinkingWrapperHeight();
    }
  };

  const getCustomerIdAndCorrelationId = (
    matchingResponseItem: DetailedViewSectionsData
  ) => {
    let customerIds = [];
    for (const column of matchingColumns) {
      if (column in matchingResponseItem) {
        customerIds.push(matchingResponseItem[column]);
        break;
      }
    }
    return {
      customerIds,
      correlationId: matchingResponseItem.ENRICHMENT_CORRELATION_ID,
    };
  };

  const handleSelectRemove = (selectedList: [Option]) => {
    if (!isMultiselectClicked) {
      setIsMultiselectClicked(true);
    }

    let customerIds: string[] = [];
    let correlationIds = [];
    if (linkOperator === LinkButton.Unlink) {
      correlationIds = selectedList.map((selectedItem: Option) => {
        const matchingResponseItem = linkedCIds.find(
          (linkedCId: DetailedViewSectionsData) => {
            return linkedCId.DISPLAY_ID === selectedItem.value;
          }
        );
        if (matchingResponseItem) {
          const { customerIds: extractedCustomerIds, correlationId } =
            getCustomerIdAndCorrelationId(matchingResponseItem);
          customerIds.push(...extractedCustomerIds);
          return correlationId;
        }
        return null;
      });
    } else {
      correlationIds = selectedList.map((selectedItem: Option) => {
        const matchingResponseItem = response.find((responseItem: ISearch) => {
          for (const column of matchingColumns) {
            if (responseItem[column] === selectedItem.value) {
              return responseItem[column];
            }
          }
          return null;
        });
        if (matchingResponseItem) {
          const { customerIds: extractedCustomerIds, correlationId } =
            getCustomerIdAndCorrelationId(matchingResponseItem);
          customerIds.push(...extractedCustomerIds);
          return correlationId;
        }
        return null;
      });
    }
    dispatch(setSearchedCustomerIds(customerIds));
    dispatch(setSearchedCorrelationIds(correlationIds));
    dispatch(setinitialDetailedView(correlationIds.length === 0));
    linkOperator === LinkButton.Unlink
      ? dispatch(setIsLinkedCid(true))
      : dispatch(setIsLinkedCid(false));
  };

  const getUnlinkOptions = () => {
    if (linkOperator === LinkButton.Unlink && displayIds.length > 0) {
      const formattedLinkedCids = displayIds.map((cId: string) => ({
        value: cId,
        label: cId,
      }));
      return formattedLinkedCids;
    }
    return [];
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const linkedCidSearchElement = document.getElementById("linkedCidSearch");

      if (
        linkedCidSearchElement &&
        !linkedCidSearchElement.contains(event.target as Node) &&
        isMultiselectClicked // Only toggle if the Multiselect was clicked initially
      ) {
        toggleLinkingWrapperHeight();
        setIsMultiselectClicked(false); // Reset initial click status
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [toggleLinkingWrapperHeight, isMultiselectClicked]);

  const handleSearch = (value: string) => {
    if (linkOperator === LinkButton.Unlink && displayIds.length > 0) {
      const formattedLinkedCids = displayIds.map((cId: string) => ({
        value: cId,
        label: cId,
      }));
      setSuggestions(formattedLinkedCids);
    }
    if (linkOperator === LinkButton.Link) {
      updateSearch(searchTableName, value);
    }
  };
  return (
    <div
      className="multi-select-new"
      data-testid="multi-select-new"
      id="multi-select-new"
      style={{ overflow: "visible" }}
      onClick={handleMultiselectClick}
      ref={wrapperRef}
    >
      <Multiselect
        options={
          linkOperator === LinkButton.Unlink && displayIds.length > 0
            ? getUnlinkOptions()
            : suggestions
        }
        selectedValues={selectedValues}
        onSelect={handleSelectRemove}
        onRemove={handleSelectRemove}
        onSearch={handleSearch}
        displayValue="value"
        showCheckbox={true}
        placeholder={searchLabels[searchTableName]}
        closeIcon="cancel"
        style={multiselectStyle}
        ref={multiselectRef}
        disable={isDefaultOrgId ? true : false}
      />
    </div>
  );
};
