import { useAppSelector } from "../../reduxHooks";
import { startCase } from "lodash";
import { EditIcons } from "../tabularView/editIcons";
import "./detailedViewSections.css";
import { OverWriteComp } from "../tabularView/overWrite";
import { notEditableColumns, overWritableColumns } from "../../config";
import { useState } from "react";
import { Spinner } from "@cimpress/react-components";
export const DetailedViewItem = (props: {
	data: any;
	title: string;
	name: string;
	value: string;
	identifierId: string;
}) => {
	const { inputFields, metaData, firmographics, businessFields, customFields } = useAppSelector(
		(state) => state.view
	);
	const OVERWRITTEN_LABELS = Object.keys(metaData).length > 0 ? metaData["OVERWRITTEN_LABELS"] : {};
	const validationLabel = Object.keys(metaData).length > 0 ? metaData["VALIDATION_LABELS"] : {};
	const searchParams = new URLSearchParams(window.location.search);
	const enrichmentCorrelationId = searchParams.get("enrichmentCorrelationId") || "";
	const enrichmentCustomerIdentifier = searchParams.get("customerIdentifier") || "";
	const [loading, setLoading] = useState(false);
	const editableColumns = () => {
		const editablefields = { ...firmographics, ...businessFields, ...customFields };
		return Object.keys(editablefields).filter((col) => notEditableColumns.indexOf(col) === -1);
	};
	const validationStatus = validationLabel[props.name] ?? "notVerified";
	return (
		<>
			{loading &&(
				<>
					<div className="detailed-cell-overlay"></div>
					<Spinner size="large" className="detailed-cell-spinner" />
				</>
			)}
			<div
				className={props.data === inputFields ? "accordion-input-item" : "accordion-item"}
				data-testid="accordion-item"
				id="accordion-item"
				key={props.name}
			>
				<div
					className={
						props.data === inputFields ? "accordion-input-item-header" : "accordion-item-header"
					}
					data-testid="accordion-item-header"
					id="accordion-item-header"
				>
					{props.data !== inputFields && editableColumns().indexOf(props.name) !== -1 && (
						<EditIcons
							columnName={props.name}
							setLoading={setLoading}
							columnStatus={validationStatus}
							identifierId={props.identifierId}
						/>
					)}
					{overWritableColumns.includes(props.name) && (
						<OverWriteComp
							rowIndex={[]}
							setLoading={setLoading}
							field={props.name}
							value={props.value}
							identifierId={enrichmentCorrelationId}
							identifier={enrichmentCustomerIdentifier}
							overwritten={props.name in OVERWRITTEN_LABELS ? "true" : "false"}
						></OverWriteComp>
					)}
					{startCase(props.name)}
				</div>
				<div
					className="accordion-item-value"
					data-testid="accordion-item-value"
					id="accordion-item-value"
				>
					<span>{props.value}</span>
				</div>
			</div>
		</>
	);
};