import React, { useEffect } from "react";
import { TableContainer } from "./tableContainer";
import { useAppSelector, useAppDispatch } from "../../reduxHooks";
import { AuthContext } from "../../context/authContext";
import { TablePagination } from "./pagination";

import {
	allMappings,
	getColumnData,
	getViewData,
	industryMapping,
	subSegmentList,
} from "../../features/view/viewSlice";
import { DefaultView, IViewRequestData } from "../../types";
import "../../styles/table.css";
import { Spinner } from "@cimpress/react-components";

export function TableView() {
	const { profile } = React.useContext(AuthContext);
	const userId = profile?.email || "";
	const viewState = useAppSelector((state) => state.view);
	const rowData = useAppSelector((state) => state.view.rows);
	const {
		selectedView,
		selectedTable,
		sortColumn,
		unsavedFilters,
		allLoading,
		sortOrder,
		visibleColumns,
		quickFilters,
		filterOP,
		isDetailedView,
		detailedViewTable,
	} = viewState;

	const isLoading = useAppSelector((state) => state.view.isLoading);
	const dispatch = useAppDispatch();

	const getViewRequestData = (pageNumber = 0, pageSize = 100) => {
		let requestData: IViewRequestData = {
			currentPage: pageNumber,
			size: pageSize,
			viewId: selectedView ? selectedView.viewId : DefaultView.Default,
			unsavedFilters:
				unsavedFilters.length > 0 ? unsavedFilters : selectedView?.unsavedFilters || [],
			savedFilters: [],
			selectedTable: isDetailedView ? detailedViewTable : selectedTable,
			selectedColumns: selectedView?.selectedHeaders || [],
			visibleColumns: visibleColumns,
			sortingColumn: sortColumn,
			sortingOrder: sortOrder,
			userId: userId,
			quickFilters: quickFilters || selectedView?.quickFilters,
			filterOp: filterOP,
		};
		return requestData;
	};

	useEffect(() => {
		if (userId) {
			dispatch(getColumnData({ userId }));
			dispatch(industryMapping());
			dispatch(allMappings());
		}
	}, [userId]); // eslint-disable-line
	useEffect(() => {
		if (selectedTable) {
			dispatch(subSegmentList({ tenant: selectedTable }));
		}
	}, [selectedTable]); // eslint-disable-line
	return (
		<div className="table-container-wrapper">
			{isLoading !== "loading" && allLoading && (
				<>
					<div className="table-spinner-overlay"></div>
					<Spinner className="table-spinner" />
				</>
			)}

			<TableContainer></TableContainer>
			<div className={(rowData.length !== 0) ? "" : "disable-pagination"}>
				<TablePagination
					getdata={(pageNumber: number, pageSize: number) => {
						dispatch(getViewData(getViewRequestData(pageNumber, pageSize)));
					}}
				></TablePagination>
			</div>
		</div>
	);
}
