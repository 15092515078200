export const GCCINDUSTRIES=
// eslint-disable-next-line
[
"/Adult", 
"/Arts & Entertainment/Celebrities & Entertainment News",
"/Arts & Entertainment/Other",
"/Arts & Entertainment/Comics & Animation/Anime & Manga",
"/Arts & Entertainment/Comics & Animation/Cartoons",
"/Arts & Entertainment/Comics & Animation/Comics",
"/Arts & Entertainment/Comics & Animation/Other",
"/Arts & Entertainment/Entertainment Industry/Film & TV Industry",
"/Arts & Entertainment/Entertainment Industry/Recording Industry",
"/Arts & Entertainment/Entertainment Industry/Other",
"/Arts & Entertainment/Events & Listings/Bars, Clubs & Nightlife",
"/Arts & Entertainment/Events & Listings/Concerts & Music Festivals",
"/Arts & Entertainment/Events & Listings/Event Ticket Sales",
"/Arts & Entertainment/Events & Listings/Expos & Conventions",
"/Arts & Entertainment/Events & Listings/Film Festivals",
"/Arts & Entertainment/Events & Listings/Food & Beverage Events",,
"/Arts & Entertainment/Events & Listings/Live Sporting Events",
"/Arts & Entertainment/Events & Listings/Movie Listings & Theater Showtimes",
"/Arts & Entertainment/Events & Listings/Other",
"/Arts & Entertainment/Fun & Trivia/Flash-Based Entertainment",
"/Arts & Entertainment/Fun & Trivia/Fun Tests & Silly Surveys",
"/Arts & Entertainment/Fun & Trivia/Other",
"/Arts & Entertainment/Humor/Funny Pictures & Videos",
"/Arts & Entertainment/Humor/Live Comedy",
"/Arts & Entertainment/Humor/Political Humor",
"/Arts & Entertainment/Humor/Spoofs & Satire",
"/Arts & Entertainment/Humor/Other",
"/Arts & Entertainment/Movies/Action & Adventure Films",
"/Arts & Entertainment/Movies/Animated Films",
"/Arts & Entertainment/Movies/Bollywood & South Asian Films",
"/Arts & Entertainment/Movies/Classic Films",
"/Arts & Entertainment/Movies/Comedy Films",
"/Arts & Entertainment/Movies/Cult & Indie Films",
"/Arts & Entertainment/Movies/Documentary Films",
"/Arts & Entertainment/Movies/Drama Films",
"/Arts & Entertainment/Movies/DVD & Video Shopping",
"/Arts & Entertainment/Movies/Family Films",
"/Arts & Entertainment/Movies/Horror Films",
"/Arts & Entertainment/Movies/Movie Memorabilia",
"/Arts & Entertainment/Movies/Movie Reference",
"/Arts & Entertainment/Movies/Musical Films",
"/Arts & Entertainment/Movies/Romance Films",
"/Arts & Entertainment/Movies/Science Fiction & Fantasy Films",
"/Arts & Entertainment/Movies/Thriller, Crime & Mystery Films",
"/Arts & Entertainment/Movies/Other",
"/Arts & Entertainment/Music & Audio/CD & Audio Shopping",
"/Arts & Entertainment/Music & Audio/Classical Music",
"/Arts & Entertainment/Music & Audio/Country Music",
"/Arts & Entertainment/Music & Audio/Dance & Electronic Music",
"/Arts & Entertainment/Music & Audio/Experimental & Industrial Music",
"/Arts & Entertainment/Music & Audio/Folk & Traditional Music",
"/Arts & Entertainment/Music & Audio/Jazz & Blues",
"/Arts & Entertainment/Music & Audio/Music Art & Memorabilia",
"/Arts & Entertainment/Music & Audio/Music Education & Instruction",
"/Arts & Entertainment/Music & Audio/Music Equipment & Technology",
"/Arts & Entertainment/Music & Audio/Music Reference",
"/Arts & Entertainment/Music & Audio/Music Streams & Downloads",
"/Arts & Entertainment/Music & Audio/Music Videos",
"/Arts & Entertainment/Music & Audio/Podcasts",
"/Arts & Entertainment/Music & Audio/Pop Music",
"/Arts & Entertainment/Music & Audio/Radio",
"/Arts & Entertainment/Music & Audio/Religious Music",
"/Arts & Entertainment/Music & Audio/Rock Music",
"/Arts & Entertainment/Music & Audio/Soundtracks",
"/Arts & Entertainment/Music & Audio/Urban & Hip-Hop",
"/Arts & Entertainment/Music & Audio/Vocals & Show Tunes",
"/Arts & Entertainment/Music & Audio/World Music",
"/Arts & Entertainment/Music & Audio/Other",
"/Arts & Entertainment/Offbeat/Occult & Paranormal",
"/Arts & Entertainment/Offbeat/Other",
"/Arts & Entertainment/Online Media/Online Image Galleries",
"/Arts & Entertainment/Online Media/Virtual Tours",
"/Arts & Entertainment/Online Media/Other",
"/Arts & Entertainment/Performing Arts/Acting & Theater",
"/Arts & Entertainment/Performing Arts/Broadway & Musical Theater",
"/Arts & Entertainment/Performing Arts/Circus",
"/Arts & Entertainment/Performing Arts/Dance",
"/Arts & Entertainment/Performing Arts/Magic",
"/Arts & Entertainment/Performing Arts/Opera",
"/Arts & Entertainment/Performing Arts/Other",
"/Arts & Entertainment/TV & Video/Online Video",
"/Arts & Entertainment/TV & Video/TV Commercials",
"/Arts & Entertainment/TV & Video/TV Guides & Reference",
"/Arts & Entertainment/TV & Video/TV Networks & Stations",
"/Arts & Entertainment/TV & Video/TV Shows & Programs",
"/Arts & Entertainment/TV & Video/Other",
"/Arts & Entertainment/Visual Art & Design/Architecture",
"/Arts & Entertainment/Visual Art & Design/Art Museums & Galleries",
"/Arts & Entertainment/Visual Art & Design/Design",
"/Arts & Entertainment/Visual Art & Design/Painting",
"/Arts & Entertainment/Visual Art & Design/Photographic & Digital Arts",
"/Arts & Entertainment/Visual Art & Design/Sculpture",
"/Arts & Entertainment/Visual Art & Design/Visual Arts & Design Education",
"/Arts & Entertainment/Visual Art & Design/Other",
"/Autos & Vehicles/Other",
"/Autos & Vehicles/Bicycles & Accessories/Bike Accessories",
"/Autos & Vehicles/Bicycles & Accessories/Bike Frames",
"/Autos & Vehicles/Bicycles & Accessories/Bike Helmets & Protective Gear",
"/Autos & Vehicles/Bicycles & Accessories/Bike Parts & Repair",
"/Autos & Vehicles/Bicycles & Accessories/BMX Bikes",
"/Autos & Vehicles/Bicycles & Accessories/Cruiser Bicycles",
"/Autos & Vehicles/Bicycles & Accessories/Electric Bicycles",
"/Autos & Vehicles/Bicycles & Accessories/Kids' Bikes",
"/Autos & Vehicles/Bicycles & Accessories/Mountain Bikes",
"/Autos & Vehicles/Bicycles & Accessories/Road Bikes",
"/Autos & Vehicles/Bicycles & Accessories/Other",
"/Autos & Vehicles/Boats & Watercraft",
"/Autos & Vehicles/Campers & RVs",
"/Autos & Vehicles/Classic Vehicles",
"/Autos & Vehicles/Commercial Vehicles/Cargo Trucks & Trailers",
"/Autos & Vehicles/Commercial Vehicles/Other",
"/Autos & Vehicles/Custom & Performance Vehicles",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Audi",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Bentley",
"/Autos & Vehicles/Motor Vehicles (By Brand)/BMW",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Buick",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Cadillac",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Chevrolet",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Chrysler",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Citroën",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Daewoo Motors",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Dodge",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Ferrari",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Fiat",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Ford",
"/Autos & Vehicles/Motor Vehicles (By Brand)/GMC",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Honda",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Hummer",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Hyundai",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Isuzu",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Jaguar",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Jeep",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Kia",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Lamborghini",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Land Rover",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Lincoln",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Maserati",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Mazda",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Mercedes-Benz",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Mercury",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Mini",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Mitsubishi",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Nissan",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Peugeot",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Pontiac",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Porsche",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Ram Trucks",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Renault",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Rolls-Royce",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Saab",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Saturn",
"/Autos & Vehicles/Motor Vehicles (By Brand)/SEAT",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Skoda",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Subaru",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Suzuki",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Tesla Motors",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Toyota",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Vauxhall-Opel",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Volkswagen",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Volvo",
"/Autos & Vehicles/Motor Vehicles (By Brand)/Other",
"/Autos & Vehicles/Motor Vehicles (By Type)/Autonomous Vehicles",
"/Autos & Vehicles/Motor Vehicles (By Type)/Compact Cars",
"/Autos & Vehicles/Motor Vehicles (By Type)/Convertibles",
"/Autos & Vehicles/Motor Vehicles (By Type)/Coupes",
"/Autos & Vehicles/Motor Vehicles (By Type)/Diesel Vehicles",
"/Autos & Vehicles/Motor Vehicles (By Type)/Hatchbacks",
"/Autos & Vehicles/Motor Vehicles (By Type)/Hybrid & Alternative Vehicles",
"/Autos & Vehicles/Motor Vehicles (By Type)/Luxury Vehicles",
"/Autos & Vehicles/Motor Vehicles (By Type)/Microcars & Subcompacts",
"/Autos & Vehicles/Motor Vehicles (By Type)/Motorcycles",
"/Autos & Vehicles/Motor Vehicles (By Type)/Off-Road Vehicles",
"/Autos & Vehicles/Motor Vehicles (By Type)/Scooters & Mopeds",
"/Autos & Vehicles/Motor Vehicles (By Type)/Sedans",
"/Autos & Vehicles/Motor Vehicles (By Type)/Sports Cars",
"/Autos & Vehicles/Motor Vehicles (By Type)/Station Wagons",
"/Autos & Vehicles/Motor Vehicles (By Type)/Trucks, Vans & SUVs",
"/Autos & Vehicles/Motor Vehicles (By Type)/Other",
"/Autos & Vehicles/Personal Aircraft",
"/Autos & Vehicles/Vehicle Codes & Driving Laws/Drunk Driving Law",
"/Autos & Vehicles/Vehicle Codes & Driving Laws/Vehicle Licensing & Registration",
"/Autos & Vehicles/Vehicle Codes & Driving Laws/Other",
"/Autos & Vehicles/Vehicle Parts & Services/Gas Prices & Vehicle Fueling",
"/Autos & Vehicles/Vehicle Parts & Services/Towing & Roadside Assistance",
"/Autos & Vehicles/Vehicle Parts & Services/Vehicle Modification & Tuning",
"/Autos & Vehicles/Vehicle Parts & Services/Vehicle Parts & Accessories",
"/Autos & Vehicles/Vehicle Parts & Services/Vehicle Repair & Maintenance",
"/Autos & Vehicles/Vehicle Parts & Services/Other",
"/Autos & Vehicles/Vehicle Shopping/Used Vehicles",
"/Autos & Vehicles/Vehicle Shopping/Vehicle Dealers & Retailers",
"/Autos & Vehicles/Vehicle Shopping/Vehicle Specs, Reviews & Comparisons",
"/Autos & Vehicles/Vehicle Shopping/Other",
"/Autos & Vehicles/Vehicle Shows",
"/Beauty & Fitness/Beauty Pageants",
"/Beauty & Fitness/Other",
"/Beauty & Fitness/Beauty Services & Spas/Cosmetic Procedures",
"/Beauty & Fitness/Beauty Services & Spas/Manicures & Pedicures",
"/Beauty & Fitness/Beauty Services & Spas/Massage Therapy",
"/Beauty & Fitness/Beauty Services & Spas/Other",
"/Beauty & Fitness/Body Art",
"/Beauty & Fitness/Cosmetology & Beauty Professionals",
"/Beauty & Fitness/Face & Body Care/Clean Beauty",
"/Beauty & Fitness/Face & Body Care/Hygiene & Toiletries",
"/Beauty & Fitness/Face & Body Care/Make-Up & Cosmetics",
"/Beauty & Fitness/Face & Body Care/Perfumes & Fragrances",
"/Beauty & Fitness/Face & Body Care/Skin & Nail Care",
"/Beauty & Fitness/Face & Body Care/Sun Care & Tanning Products",
"/Beauty & Fitness/Face & Body Care/Unwanted Body & Facial Hair Removal",
"/Beauty & Fitness/Face & Body Care/Other",
"/Beauty & Fitness/Fashion & Style/Fashion Designers & Collections",
"/Beauty & Fitness/Fashion & Style/Other",
"/Beauty & Fitness/Fitness/Bodybuilding",
"/Beauty & Fitness/Fitness/Fitness Equipment & Accessories",
"/Beauty & Fitness/Fitness/Fitness Instruction & Personal Training",
"/Beauty & Fitness/Fitness/Gyms & Health Clubs",
"/Beauty & Fitness/Fitness/High Intensity Interval Training",
"/Beauty & Fitness/Fitness/Yoga & Pilates",
"/Beauty & Fitness/Fitness/Other",
"/Beauty & Fitness/Hair Care/Hair Loss",
"/Beauty & Fitness/Hair Care/Shampoos & Conditioners",
"/Beauty & Fitness/Hair Care/Other",
"/Beauty & Fitness/Weight Loss",
"/Books & Literature/Audiobooks",
"/Books & Literature/Book Retailers",
"/Books & Literature/Children's Literature",
"/Books & Literature/E-Books",
"/Books & Literature/Fan Fiction",
"/Books & Literature/Literary Classics",
"/Books & Literature/Poetry",
"/Books & Literature/Writers Resources",
"/Books & Literature/Other",
"/Business & Industrial/Other",
"/Business & Industrial/Advertising & Marketing/Brand Management",
"/Business & Industrial/Advertising & Marketing/Marketing",
"/Business & Industrial/Advertising & Marketing/Public Relations",
"/Business & Industrial/Advertising & Marketing/Sales",
"/Business & Industrial/Advertising & Marketing/Telemarketing",
"/Business & Industrial/Advertising & Marketing/Other",
"/Business & Industrial/Aerospace & Defense/Aviation Industry",
"/Business & Industrial/Aerospace & Defense/Space Technology",
"/Business & Industrial/Aerospace & Defense/Other",
"/Business & Industrial/Agriculture & Forestry/Agricultural Equipment",
"/Business & Industrial/Agriculture & Forestry/Aquaculture",
"/Business & Industrial/Agriculture & Forestry/Crops & Seed",
"/Business & Industrial/Agriculture & Forestry/Farms & Ranches",
"/Business & Industrial/Agriculture & Forestry/Forestry",
"/Business & Industrial/Agriculture & Forestry/Livestock",
"/Business & Industrial/Agriculture & Forestry/Other",
"/Business & Industrial/Automotive Industry",
"/Business & Industrial/Business Education",
"/Business & Industrial/Business Finance/Commercial Lending",,
"/Business & Industrial/Business Finance/Investment Banking",,
"/Business & Industrial/Business Finance/Risk Management",,
"/Business & Industrial/Business Finance/Venture Capital",,
"/Business & Industrial/Business Finance/Other",,
"/Business & Industrial/Business Operations/Business Plans & Presentations",
"/Business & Industrial/Business Operations/Flexible Work Arrangements",
"/Business & Industrial/Business Operations/Human Resources",
"/Business & Industrial/Business Operations/Management",
"/Business & Industrial/Business Operations/Other",
"/Business & Industrial/Business Services/Commercial Distribution",
"/Business & Industrial/Business Services/Consulting",
"/Business & Industrial/Business Services/Corporate Events",
"/Business & Industrial/Business Services/E-Commerce Services",
"/Business & Industrial/Business Services/Fire & Security Services",
"/Business & Industrial/Business Services/Knowledge Management",
"/Business & Industrial/Business Services/Office Services",
"/Business & Industrial/Business Services/Office Supplies",
"/Business & Industrial/Business Services/Outsourcing",
"/Business & Industrial/Business Services/Physical Asset Management",
"/Business & Industrial/Business Services/Quality Control & Tracking",
"/Business & Industrial/Business Services/Shared Workspaces",
"/Business & Industrial/Business Services/Signage",
"/Business & Industrial/Business Services/Warehousing",
"/Business & Industrial/Business Services/Writing & Editing Services",
"/Business & Industrial/Business Services/Other",
"/Business & Industrial/Chemicals Industry/Agrochemicals",
"/Business & Industrial/Chemicals Industry/Cleaning Agents",
"/Business & Industrial/Chemicals Industry/Coatings & Adhesives",
"/Business & Industrial/Chemicals Industry/Dyes & Pigments",
"/Business & Industrial/Chemicals Industry/Plastics & Polymers",
"/Business & Industrial/Chemicals Industry/Other",
"/Business & Industrial/Construction & Maintenance/Building Materials & Supplies",
"/Business & Industrial/Construction & Maintenance/Civil Engineering",
"/Business & Industrial/Construction & Maintenance/Other",
"/Business & Industrial/Energy & Utilities/Electricity",
"/Business & Industrial/Energy & Utilities/Nuclear Energy",
"/Business & Industrial/Energy & Utilities/Oil & Gas",
"/Business & Industrial/Energy & Utilities/Renewable & Alternative Energy",
"/Business & Industrial/Energy & Utilities/Waste Management",
"/Business & Industrial/Energy & Utilities/Other",
"/Business & Industrial/Hospitality Industry/Event Planning",
"/Business & Industrial/Hospitality Industry/Event Venue Rentals",
"/Business & Industrial/Hospitality Industry/Food Service",,
"/Business & Industrial/Hospitality Industry/Other",
"/Business & Industrial/Industrial Materials & Equipment/Fluid Handling",
"/Business & Industrial/Industrial Materials & Equipment/Generators",
"/Business & Industrial/Industrial Materials & Equipment/Heavy Machinery",
"/Business & Industrial/Industrial Materials & Equipment/Industrial Handling & Processing Equipment",
"/Business & Industrial/Industrial Materials & Equipment/Industrial Measurement & Control Equipment",
"/Business & Industrial/Industrial Materials & Equipment/Other",
"/Business & Industrial/Manufacturing/Factory Automation",
"/Business & Industrial/Manufacturing/Other",
"/Business & Industrial/Metals & Mining/Precious Metals",
"/Business & Industrial/Metals & Mining/Other",
"/Business & Industrial/Pharmaceuticals & Biotech",
"/Business & Industrial/Printing & Publishing/Document & Printing Services",
"/Business & Industrial/Printing & Publishing/Other",
"/Business & Industrial/Retail Trade/Retail Equipment & Technology",
"/Business & Industrial/Retail Trade/Other",
"/Business & Industrial/Shipping & Logistics/Other",
"/Business & Industrial/Shipping & Logistics/Freight Transport/Maritime Transport",
"/Business & Industrial/Shipping & Logistics/Freight Transport/Rail Freight",
"/Business & Industrial/Shipping & Logistics/Freight Transport/Other",
"/Business & Industrial/Shipping & Logistics/Import & Export",
"/Business & Industrial/Shipping & Logistics/Mail & Package Delivery",
"/Business & Industrial/Shipping & Logistics/Moving & Relocation",
"/Business & Industrial/Shipping & Logistics/Packaging",
"/Business & Industrial/Shipping & Logistics/Self Storage",
"/Business & Industrial/Small Business/Business Formation",
"/Business & Industrial/Small Business/Home Office",
"/Business & Industrial/Small Business/MLM & Business Opportunities",
"/Business & Industrial/Small Business/Other",
"/Business & Industrial/Textiles & Nonwovens",
"/Computers & Electronics/CAD & CAM",
"/Computers & Electronics/Other",
"/Computers & Electronics/Computer Hardware/Computer Components",
"/Computers & Electronics/Computer Hardware/Computer Drives & Storage",
"/Computers & Electronics/Computer Hardware/Computer Peripherals",
"/Computers & Electronics/Computer Hardware/Computer Servers",
"/Computers & Electronics/Computer Hardware/Desktop Computers",
"/Computers & Electronics/Computer Hardware/Hardware Modding & Tuning",
"/Computers & Electronics/Computer Hardware/Laptops & Notebooks",
"/Computers & Electronics/Computer Hardware/Other",
"/Computers & Electronics/Computer Security/Antivirus & Malware",
"/Computers & Electronics/Computer Security/Hacking & Cracking",
"/Computers & Electronics/Computer Security/Network Security",
"/Computers & Electronics/Computer Security/Other",
"/Computers & Electronics/Consumer Electronics/Audio Equipment",
"/Computers & Electronics/Consumer Electronics/Camera & Photo Equipment",
"/Computers & Electronics/Consumer Electronics/Car Electronics",
"/Computers & Electronics/Consumer Electronics/Drones & RC Aircraft",
"/Computers & Electronics/Consumer Electronics/Electronic Accessories",
"/Computers & Electronics/Consumer Electronics/Gadgets & Portable Electronics",
"/Computers & Electronics/Consumer Electronics/Game Systems & Consoles",
"/Computers & Electronics/Consumer Electronics/GPS & Navigation",
"/Computers & Electronics/Consumer Electronics/Home Automation",
"/Computers & Electronics/Consumer Electronics/Media Streaming Devices",
"/Computers & Electronics/Consumer Electronics/TV & Video Equipment",
"/Computers & Electronics/Consumer Electronics/Virtual Reality Devices",
"/Computers & Electronics/Consumer Electronics/Other",
"/Computers & Electronics/Electronics & Electrical/Data Sheets & Electronics Reference",
"/Computers & Electronics/Electronics & Electrical/Electrical Test & Measurement",
"/Computers & Electronics/Electronics & Electrical/Electromechanical Devices",
"/Computers & Electronics/Electronics & Electrical/Electronic Components",
"/Computers & Electronics/Electronics & Electrical/Optoelectronics & Fiber",
"/Computers & Electronics/Electronics & Electrical/Power Supplies",
"/Computers & Electronics/Electronics & Electrical/Other",
"/Computers & Electronics/Enterprise Technology/Customer Relationship Management (CRM)",
"/Computers & Electronics/Enterprise Technology/Data Management",
"/Computers & Electronics/Enterprise Technology/Enterprise Resource Planning (ERP)",
"/Computers & Electronics/Enterprise Technology/Helpdesk & Customer Support Systems",
"/Computers & Electronics/Enterprise Technology/Other",
"/Computers & Electronics/Networking/Data Formats & Protocols",
"/Computers & Electronics/Networking/Distributed & Cloud Computing",
"/Computers & Electronics/Networking/Network Monitoring & Management",
"/Computers & Electronics/Networking/Networking Equipment",
"/Computers & Electronics/Networking/VPN & Remote Access",
"/Computers & Electronics/Networking/Other",
"/Computers & Electronics/Programming/C & C++",
"/Computers & Electronics/Programming/Development Tools",
"/Computers & Electronics/Programming/Java (Programming Language)",
"/Computers & Electronics/Programming/Scripting Languages",
"/Computers & Electronics/Programming/Windows & .NET",
"/Computers & Electronics/Programming/Other",
"/Computers & Electronics/Software/Business & Productivity Software",
"/Computers & Electronics/Software/Device Drivers",
"/Computers & Electronics/Software/Educational Software",
"/Computers & Electronics/Software/Freeware & Shareware",
"/Computers & Electronics/Software/Intelligent Personal Assistants",
"/Computers & Electronics/Software/Internet Software",
"/Computers & Electronics/Software/Monitoring Software",
"/Computers & Electronics/Software/Multimedia Software",
"/Computers & Electronics/Software/Open Source",
"/Computers & Electronics/Software/Operating Systems",
"/Computers & Electronics/Software/Software Utilities",
"/Computers & Electronics/Software/Other",
"/Finance/Other",
"/Finance/Accounting & Auditing/Billing & Invoicing",
"/Finance/Accounting & Auditing/Bookkeeping",
"/Finance/Accounting & Auditing/Tax Preparation & Planning",
"/Finance/Accounting & Auditing/Other",
"/Finance/Banking/ATMs & Branch Locations",
"/Finance/Banking/Debit & Checking Services",
"/Finance/Banking/Mobile Payments & Digital Wallets",
"/Finance/Banking/Money Transfer & Wire Services",
"/Finance/Banking/Savings Accounts",
"/Finance/Banking/Other",
"/Finance/Credit & Lending/Credit Cards",
"/Finance/Credit & Lending/Credit Reporting & Monitoring",
"/Finance/Credit & Lending/Debt Collection & Repossession",
"/Finance/Credit & Lending/Debt Management",
"/Finance/Credit & Lending/Loans",
"/Finance/Credit & Lending/Other",
"/Finance/Crowdfunding",
"/Finance/Financial Planning & Management/Asset & Portfolio Management",
"/Finance/Financial Planning & Management/Inheritance & Estate Planning",
"/Finance/Financial Planning & Management/Retirement & Pension",
"/Finance/Financial Planning & Management/Other",
"/Finance/Grants, Scholarships & Financial Aid/Government Grants",
"/Finance/Grants, Scholarships & Financial Aid/Study Grants & Scholarships",
"/Finance/Grants, Scholarships & Financial Aid/Other",
"/Finance/Insurance/Health Insurance",
"/Finance/Insurance/Home Insurance",
"/Finance/Insurance/Life Insurance",
"/Finance/Insurance/Travel Insurance",
"/Finance/Insurance/Vehicle Insurance",
"/Finance/Insurance/Other",
"/Finance/Investing/Brokerages & Day Trading",
"/Finance/Investing/Commodities & Futures Trading",
"/Finance/Investing/Currencies & Foreign Exchange",
"/Finance/Investing/Derivatives",
"/Finance/Investing/Funds",
"/Finance/Investing/Real Estate Investment Trusts",
"/Finance/Investing/Socially Responsible Investing",
"/Finance/Investing/Stocks & Bonds",
"/Finance/Investing/Other",
"/Food & Drink/Other",
"/Food & Drink/Beverages/Alcohol-Free Beverages",
"/Food & Drink/Beverages/Alcoholic Beverages",
"/Food & Drink/Beverages/Bottled Water",
"/Food & Drink/Beverages/Coffee & Tea",
"/Food & Drink/Beverages/Energy Drinks",
"/Food & Drink/Beverages/Juice",
"/Food & Drink/Beverages/Nutrition Drinks & Shakes",
"/Food & Drink/Beverages/Soft Drinks",
"/Food & Drink/Beverages/Sports Drinks",
"/Food & Drink/Beverages/Other",
"/Food & Drink/Cooking & Recipes/BBQ & Grilling",
"/Food & Drink/Cooking & Recipes/Cuisines",
"/Food & Drink/Cooking & Recipes/Culinary Training",
"/Food & Drink/Cooking & Recipes/Desserts",
"/Food & Drink/Cooking & Recipes/Healthy Eating",
"/Food & Drink/Cooking & Recipes/Salads",
"/Food & Drink/Cooking & Recipes/Soups & Stews",
"/Food & Drink/Cooking & Recipes/Other",
"/Food & Drink/Food/Other",
"/Food & Drink/Food & Grocery Delivery/Grocery Delivery Services",
"/Food & Drink/Food & Grocery Delivery/Meal Kits",
"/Food & Drink/Food & Grocery Delivery/Restaurant Delivery Services",
"/Food & Drink/Food & Grocery Delivery/Other",
"/Food & Drink/Food & Grocery Retailers/Bakeries",
"/Food & Drink/Food & Grocery Retailers/Butchers",
"/Food & Drink/Food & Grocery Retailers/Convenience Stores",
"/Food & Drink/Food & Grocery Retailers/Delicatessens",
"/Food & Drink/Food & Grocery Retailers/Farmers' Markets",
"/Food & Drink/Food & Grocery Retailers/Other",
"/Food & Drink/Food/Baked Goods",
"/Food & Drink/Food/Breakfast Foods",
"/Food & Drink/Food/Candy & Sweets",
"/Food & Drink/Food/Condiments & Dressings",
"/Food & Drink/Food/Cooking Fats & Oils",
"/Food & Drink/Food/Dairy & Egg Substitutes",
"/Food & Drink/Food/Dairy & Eggs",
"/Food & Drink/Food/Fruits & Vegetables",
"/Food & Drink/Food/Gourmet & Specialty Foods",
"/Food & Drink/Food/Grains & Pasta",
"/Food & Drink/Food/Herbs & Spices",
"/Food & Drink/Food/Jams, Jellies & Preserves",
"/Food & Drink/Food/Meat & Seafood",
"/Food & Drink/Food/Meat & Seafood Substitutes",
"/Food & Drink/Food/Organic & Natural Foods",
"/Food & Drink/Food/Snack Foods",
"/Food & Drink/Restaurants/Catering",
"/Food & Drink/Restaurants/Fast Food",
"/Food & Drink/Restaurants/Fine Dining",
"/Food & Drink/Restaurants/Pizzerias",
"/Food & Drink/Restaurants/Restaurant Reviews & Reservations",
"/Food & Drink/Restaurants/Other",
"/Games/Arcade & Coin-Op Games",
"/Games/Other",
"/Games/Board Games/Chess & Abstract Strategy Games",
"/Games/Board Games/Miniatures & Wargaming",
"/Games/Board Games/Other",
"/Games/Card Games/Collectible Card Games",
"/Games/Card Games/Poker & Casino Games",
"/Games/Card Games/Other",
"/Games/Computer & Video Games/Action & Platform Games",
"/Games/Computer & Video Games/Adventure Games",
"/Games/Computer & Video Games/Browser Games",
"/Games/Computer & Video Games/Casual Games",
"/Games/Computer & Video Games/Competitive Video Gaming",
"/Games/Computer & Video Games/Driving & Racing Games",
"/Games/Computer & Video Games/Fighting Games",
"/Games/Computer & Video Games/Gaming Reference & Reviews",
"/Games/Computer & Video Games/Massively Multiplayer Games",
"/Games/Computer & Video Games/Music & Dance Games",
"/Games/Computer & Video Games/Sandbox Games",
"/Games/Computer & Video Games/Shooter Games",
"/Games/Computer & Video Games/Simulation Games",
"/Games/Computer & Video Games/Sports Games",
"/Games/Computer & Video Games/Strategy Games",
"/Games/Computer & Video Games/Video Game Development",
"/Games/Computer & Video Games/Video Game Emulation",
"/Games/Computer & Video Games/Video Game Mods & Add-Ons",
"/Games/Computer & Video Games/Video Game Retailers",
"/Games/Computer & Video Games/Other",
"/Games/Dice Games",
"/Games/Educational Games",
"/Games/Family-Oriented Games & Activities/Drawing & Coloring",
"/Games/Family-Oriented Games & Activities/Dress-Up & Fashion Games",
"/Games/Family-Oriented Games & Activities/Other",
"/Games/Gambling/Lottery",
"/Games/Gambling/Sports Betting",
"/Games/Gambling/Other",
"/Games/Party Games",
"/Games/Puzzles & Brainteasers",
"/Games/Roleplaying Games",
"/Games/Table Games/Billiards",
"/Games/Table Games/Table Tennis",
"/Games/Table Games/Other",
"/Games/Tile Games",
"/Games/Word Games",
"/Health/Other",
"/Health/Aging & Geriatrics/Alzheimer's Disease",
"/Health/Aging & Geriatrics/Other",
"/Health/Alternative & Natural Medicine/Acupuncture & Chinese Medicine",
"/Health/Alternative & Natural Medicine/Cleansing & Detoxification",
"/Health/Alternative & Natural Medicine/Other",
"/Health/Health Conditions/AIDS & HIV",
"/Health/Health Conditions/Other",
"/Health/Health Conditions/Allergies/Environmental Allergies",
"/Health/Health Conditions/Allergies/Food Allergies",,
"/Health/Health Conditions/Allergies/Other",
"/Health/Health Conditions/Arthritis",
"/Health/Health Conditions/Blood Sugar & Diabetes",
"/Health/Health Conditions/Cancer",
"/Health/Health Conditions/Ear Nose & Throat",
"/Health/Health Conditions/Eating Disorders",
"/Health/Health Conditions/Endocrine Conditions",
"/Health/Health Conditions/Genetic Disorders",
"/Health/Health Conditions/GERD & Digestive Disorders",
"/Health/Health Conditions/Heart & Hypertension",
"/Health/Health Conditions/Infectious Diseases/Covid-19",
"/Health/Health Conditions/Infectious Diseases/Vaccines & Immunizations",
"/Health/Health Conditions/Infectious Diseases/Other",
"/Health/Health Conditions/Injury",
"/Health/Health Conditions/Neurological Conditions",
"/Health/Health Conditions/Obesity",
"/Health/Health Conditions/Pain Management",
"/Health/Health Conditions/Respiratory Conditions",
"/Health/Health Conditions/Skin Conditions",
"/Health/Health Conditions/Sleep Disorders",
"/Health/Health Education & Medical Training",
"/Health/Health Foundations & Medical Research",
"/Health/Medical Devices & Equipment/Assistive Technology",
"/Health/Medical Devices & Equipment/Other",
"/Health/Medical Facilities & Services/Doctors' Offices",
"/Health/Medical Facilities & Services/Hospitals & Treatment Centers",
"/Health/Medical Facilities & Services/Other",
"/Health/Medical Facilities & Services/Medical Procedures/Other",
"/Health/Medical Facilities & Services/Medical Procedures/Surgery/Cosmetic Surgery",
"/Health/Medical Facilities & Services/Physical Therapy",
"/Health/Medical Literature & Resources/Medical Photos & Illustration",
"/Health/Medical Literature & Resources/Other",
"/Health/Men's Health",
"/Health/Mental Health/Anxiety & Stress",
"/Health/Mental Health/Compulsive Gambling",
"/Health/Mental Health/Counseling Services",
"/Health/Mental Health/Depression",
"/Health/Mental Health/Other",
"/Health/Nursing/Assisted Living & Long Term Care",
"/Health/Nursing/Other",
"/Health/Nutrition/Special & Restricted Diets",
"/Health/Nutrition/Vitamins & Supplements",
"/Health/Nutrition/Other",
"/Health/Oral & Dental Care",
"/Health/Pediatrics",
"/Health/Pharmacy/Drugs & Medications",
"/Health/Pharmacy/Other",
"/Health/Public Health/Health Policy",
"/Health/Public Health/Occupational Health & Safety",
"/Health/Public Health/Toxic Substances & Poisoning",
"/Health/Public Health/Other",
"/Health/Reproductive Health/Birth Control",
"/Health/Reproductive Health/Infertility",
"/Health/Reproductive Health/Male Impotence",
"/Health/Reproductive Health/OBGYN",
"/Health/Reproductive Health/Sex Education & Counseling",
"/Health/Reproductive Health/Other",
"/Health/Substance Abuse/Drug & Alcohol Testing",
"/Health/Substance Abuse/Drug & Alcohol Treatment",
"/Health/Substance Abuse/Smoking & Smoking Cessation",
"/Health/Substance Abuse/Steroids & Performance-Enhancing Drugs",
"/Health/Substance Abuse/Other",
"/Health/Vision Care/Eye Exams & Optometry",
"/Health/Vision Care/Eyeglasses & Contacts",
"/Health/Vision Care/Laser Vision Correction",
"/Health/Vision Care/Other",
"/Health/Women's Health",
"/Hobbies & Leisure/Other",
"/Hobbies & Leisure/Clubs & Organizations/Youth Organizations & Resources",
"/Hobbies & Leisure/Clubs & Organizations/Other",
"/Hobbies & Leisure/Crafts/Art & Craft Supplies",
"/Hobbies & Leisure/Crafts/Ceramics & Pottery",
"/Hobbies & Leisure/Crafts/Fiber & Textile Arts,",
"/Hobbies & Leisure/Crafts/Other",
"/Hobbies & Leisure/Merit Prizes & Contests",
"/Hobbies & Leisure/Outdoors/Fishing",
"/Hobbies & Leisure/Outdoors/Hiking & Camping",
"/Hobbies & Leisure/Outdoors/Hunting & Shooting",
"/Hobbies & Leisure/Outdoors/Other",
"/Hobbies & Leisure/Paintball",
"/Hobbies & Leisure/Radio Control & Modeling/Model Trains & Railroads",
"/Hobbies & Leisure/Radio Control & Modeling/Other",
"/Hobbies & Leisure/Recreational Aviation",
"/Hobbies & Leisure/Special Occasions/Anniversaries",
"/Hobbies & Leisure/Special Occasions/Holidays & Seasonal Events",
"/Hobbies & Leisure/Special Occasions/Weddings",
"/Hobbies & Leisure/Special Occasions/Other",
"/Hobbies & Leisure/Sweepstakes & Promotional Giveaways",
"/Hobbies & Leisure/Water Activities/Boating",
"/Hobbies & Leisure/Water Activities/Diving & Underwater Activities",
"/Hobbies & Leisure/Water Activities/Surf & Swim",
"/Hobbies & Leisure/Water Activities/Other",
"/Home & Garden/Other",
"/Home & Garden/Bed & Bath/Bathroom",
"/Home & Garden/Bed & Bath/Bedroom",
"/Home & Garden/Bed & Bath/Other",
"/Home & Garden/Domestic Services/Cleaning Services",
"/Home & Garden/Domestic Services/Other",
"/Home & Garden/Home & Interior Decor",
"/Home & Garden/Home Appliances/Vacuums & Floor Care",
"/Home & Garden/Home Appliances/Water Filters & Purifiers",
"/Home & Garden/Home Appliances/Other",
"/Home & Garden/Home Furnishings/Clocks",
"/Home & Garden/Home Furnishings/Countertops",
"/Home & Garden/Home Furnishings/Curtains & Window Treatments",
"/Home & Garden/Home Furnishings/Kitchen & Dining Furniture",
"/Home & Garden/Home Furnishings/Lamps & Lighting",
"/Home & Garden/Home Furnishings/Living Room Furniture",
"/Home & Garden/Home Furnishings/Outdoor Furniture",
"/Home & Garden/Home Furnishings/Rugs & Carpets",
"/Home & Garden/Home Furnishings/Other",
"/Home & Garden/Home Improvement/Construction & Power Tools",
"/Home & Garden/Home Improvement/Doors & Windows",
"/Home & Garden/Home Improvement/Flooring",
"/Home & Garden/Home Improvement/House Painting & Finishing",
"/Home & Garden/Home Improvement/Locks & Locksmiths",
"/Home & Garden/Home Improvement/Plumbing",
"/Home & Garden/Home Improvement/Roofing",
"/Home & Garden/Home Improvement/Other",
"/Home & Garden/Home Safety & Security/Home Alarm & Security Systems",
"/Home & Garden/Home Safety & Security/Other",
"/Home & Garden/Home Storage & Shelving/Cabinetry",
"/Home & Garden/Home Storage & Shelving/Other",
"/Home & Garden/Home Swimming Pools, Saunas & Spas",
"/Home & Garden/Household Supplies/Household Batteries",
"/Home & Garden/Household Supplies/Household Cleaning Supplies",
"/Home & Garden/Household Supplies/Other",
"/Home & Garden/HVAC & Climate Control/Air Conditioners",
"/Home & Garden/HVAC & Climate Control/Air Filters & Purifiers",
"/Home & Garden/HVAC & Climate Control/Fireplaces & Stoves",
"/Home & Garden/HVAC & Climate Control/Heaters",
"/Home & Garden/HVAC & Climate Control/Household Fans",
"/Home & Garden/HVAC & Climate Control/Other",
"/Home & Garden/Kitchen & Dining/Cookware & Diningware",
"/Home & Garden/Kitchen & Dining/Major Kitchen Appliances",
"/Home & Garden/Kitchen & Dining/Small Kitchen Appliances",
"/Home & Garden/Kitchen & Dining/Other",
"/Home & Garden/Laundry/Washers & Dryers",
"/Home & Garden/Laundry/Other",
"/Home & Garden/Patio, Lawn & Garden/Barbecues & Grills",
"/Home & Garden/Patio, Lawn & Garden/Garden Structures",
"/Home & Garden/Patio, Lawn & Garden/Gardening",
"/Home & Garden/Patio, Lawn & Garden/Landscape Design",
"/Home & Garden/Patio, Lawn & Garden/Other",
"/Home & Garden/Patio, Lawn & Garden/Yard Maintenance/Lawn Mowers",
"/Home & Garden/Patio, Lawn & Garden/Yard Maintenance/Other",
"/Home & Garden/Pest Control",
"/Internet & Telecom/Other",
"/Internet & Telecom/Communications Equipment/Radio Equipment",
"/Internet & Telecom/Communications Equipment/Other",
"/Internet & Telecom/Email & Messaging/Electronic Spam",
"/Internet & Telecom/Email & Messaging/Email",
"/Internet & Telecom/Email & Messaging/Text & Instant Messaging",
"/Internet & Telecom/Email & Messaging/Voice & Video Chat",
"/Internet & Telecom/Email & Messaging/Other",
"/Internet & Telecom/Mobile & Wireless/Mobile & Wireless Accessories",
"/Internet & Telecom/Mobile & Wireless/Mobile Apps & Add-Ons",
"/Internet & Telecom/Mobile & Wireless/Mobile Phones",
"/Internet & Telecom/Mobile & Wireless/Other",
"/Internet & Telecom/Search Engines/People Search",
"/Internet & Telecom/Search Engines/Other",
"/Internet & Telecom/Service Providers/Cable & Satellite Providers",
"/Internet & Telecom/Service Providers/ISPs",
"/Internet & Telecom/Service Providers/Phone Service Providers",
"/Internet & Telecom/Service Providers/Other",
"/Internet & Telecom/Teleconferencing",
"/Internet & Telecom/Web Services/Affiliate Programs",
"/Internet & Telecom/Web Services/Cloud Storage",
"/Internet & Telecom/Web Services/Search Engine Optimization & Marketing",
"/Internet & Telecom/Web Services/Web Design & Development",
"/Internet & Telecom/Web Services/Web Stats & Analytics",
"/Internet & Telecom/Web Services/Other",
"/Jobs & Education/Other",
"/Jobs & Education/Education/Academic Conferences & Publications",
"/Jobs & Education/Education/Alumni & Reunions",
"/Jobs & Education/Education/Colleges & Universities",
"/Jobs & Education/Education/Computer Education",
"/Jobs & Education/Education/Distance Learning",
"/Jobs & Education/Education/Early Childhood Education",
"/Jobs & Education/Education/Homeschooling",
"/Jobs & Education/Education/Open Online Courses",
"/Jobs & Education/Education/Primary & Secondary Schooling (K-12)",
"/Jobs & Education/Education/Private Tutoring Services",
"/Jobs & Education/Education/Special Education",
"/Jobs & Education/Education/Standardized & Admissions Tests",
"/Jobs & Education/Education/Study Abroad",
"/Jobs & Education/Education/Teaching & Classroom Resources",
"/Jobs & Education/Education/Training & Certification",
"/Jobs & Education/Education/Vocational & Continuing Education",
"/Jobs & Education/Education/Other",
"/Jobs & Education/Internships",
"/Jobs & Education/Jobs/Career Resources & Planning",
"/Jobs & Education/Jobs/Job Listings",
"/Jobs & Education/Jobs/Resumes & Portfolios",
"/Jobs & Education/Jobs/Other",
"/Law & Government/Other",
"/Law & Government/Government/Courts & Judiciary",
"/Law & Government/Government/Embassies & Consulates",
"/Law & Government/Government/Executive Branch",
"/Law & Government/Government/Government Contracting & Procurement",
"/Law & Government/Government/Intelligence & Counterterrorism",
"/Law & Government/Government/Legislative Branch",
"/Law & Government/Government/Lobbying",
"/Law & Government/Government/Public Policy",
"/Law & Government/Government/Royalty",
"/Law & Government/Government/Visa & Immigration",
"/Law & Government/Government/Other",
"/Law & Government/Legal/Accident & Personal Injury Law",
"/Law & Government/Legal/Bankruptcy",
"/Law & Government/Legal/Business & Corporate Law",
"/Law & Government/Legal/Constitutional Law & Civil Rights",
"/Law & Government/Legal/Family Law",
"/Law & Government/Legal/Intellectual Property",
"/Law & Government/Legal/Labor & Employment Law",
"/Law & Government/Legal/Legal Education",
"/Law & Government/Legal/Legal Services",
"/Law & Government/Legal/Product Liability",
"/Law & Government/Legal/Real Estate Law",
"/Law & Government/Legal/Other",
"/Law & Government/Military/Air Force",
"/Law & Government/Military/Army",
"/Law & Government/Military/Marines",
"/Law & Government/Military/Navy",
"/Law & Government/Military/Veterans",
"/Law & Government/Military/Other",
"/Law & Government/Public Safety/Crime & Justice",
"/Law & Government/Public Safety/Emergency Services",
"/Law & Government/Public Safety/Law Enforcement",
"/Law & Government/Public Safety/Security Products & Services",
"/Law & Government/Public Safety/Other",
"/Law & Government/Social Services/Welfare & Unemployment",
"/Law & Government/Social Services/Other",
"/News/Other",
"/News/Business News/Company News",
"/News/Business News/Economy News",
"/News/Business News/Financial Markets News",
"/News/Business News/Fiscal Policy News",
"/News/Business News/Other",
"/News/Gossip & Tabloid News/Scandals & Investigations",
"/News/Gossip & Tabloid News/Other",
"/News/Health News",
"/News/Local News",
"/News/Politics/Campaigns & Elections",
"/News/Politics/Media Critics & Watchdogs",
"/News/Politics/Political Polls & Surveys",
"/News/Politics/Other",
"/News/Sports News",
"/News/Technology News",
"/News/Weather",
"/News/World News",
"/Online Communities/Blogging Resources & Services",
"/Online Communities/Other",
"/Online Communities/Dating & Personals/Matrimonial Services",
"/Online Communities/Dating & Personals/Personals",
"/Online Communities/Dating & Personals/Photo Rating Sites",
"/Online Communities/Dating & Personals/Other",
"/Online Communities/File Sharing & Hosting",
"/Online Communities/Online Goodies/Clip Art & Animated GIFs",
"/Online Communities/Online Goodies/Skins, Themes & Wallpapers",
"/Online Communities/Online Goodies/Social Network Apps & Add-Ons",
"/Online Communities/Online Goodies/Other",
"/Online Communities/Online Journals & Personal Sites",
"/Online Communities/Photo & Video Sharing/Photo & Image Sharing",
"/Online Communities/Photo & Video Sharing/Video Sharing",
"/Online Communities/Photo & Video Sharing/Other",
"/Online Communities/Social Networks",
"/Online Communities/Virtual Worlds",
"/People & Society/Other",
"/People & Society/Family & Relationships/Etiquette",
"/People & Society/Family & Relationships/Other",
"/People & Society/Family & Relationships/Family/Other",
"/People & Society/Family & Relationships/Family/Parenting/Babies & Toddlers/Nursery & Playroom",
"/People & Society/Family & Relationships/Marriage",
"/People & Society/Family & Relationships/Romance",
"/People & Society/Family & Relationships/Troubled Relationships",
"/People & Society/Kids & Teens/Children's Interests",
"/People & Society/Kids & Teens/Teen Interests",
"/People & Society/Kids & Teens/Other",
"/People & Society/Religion & Belief",
"/People & Society/Self-Help & Motivational",
"/People & Society/Seniors & Retirement",
"/People & Society/Social Issues & Advocacy/Charity & Philanthropy",
"/People & Society/Social Issues & Advocacy/Discrimination & Identity Relations",
"/People & Society/Social Issues & Advocacy/Drug Laws & Policy",
"/People & Society/Social Issues & Advocacy/Ethics",
"/People & Society/Social Issues & Advocacy/Green Living & Environmental Issues",
"/People & Society/Social Issues & Advocacy/Housing & Development",
"/People & Society/Social Issues & Advocacy/Human Rights & Liberties",
"/People & Society/Social Issues & Advocacy/Poverty & Hunger",
"/People & Society/Social Issues & Advocacy/Work & Labor Issues",
"/People & Society/Social Issues & Advocacy/Other",
"/People & Society/Social Sciences/Anthropology",
"/People & Society/Social Sciences/Archaeology",
"/People & Society/Social Sciences/Communications & Media Studies",
"/People & Society/Social Sciences/Demographics",
"/People & Society/Social Sciences/Economics",
"/People & Society/Social Sciences/Political Science",
"/People & Society/Social Sciences/Psychology",
"/People & Society/Social Sciences/Other",
"/People & Society/Subcultures & Niche Interests",
"/Pets & Animals/Other",
"/Pets & Animals/Animal Products & Services/Animal Welfare",
"/Pets & Animals/Animal Products & Services/Pet Food & Pet Care Supplies",
"/Pets & Animals/Animal Products & Services/Veterinarians",
"/Pets & Animals/Animal Products & Services/Other",
"/Pets & Animals/Pets/Birds",
"/Pets & Animals/Pets/Cats",
"/Pets & Animals/Pets/Dogs",
"/Pets & Animals/Pets/Exotic Pets",
"/Pets & Animals/Pets/Fish & Aquaria",
"/Pets & Animals/Pets/Horses",
"/Pets & Animals/Pets/Rabbits & Rodents",
"/Pets & Animals/Pets/Reptiles & Amphibians",
"/Pets & Animals/Pets/Other",
"/Pets & Animals/Wildlife",
"/Real Estate/Property Development",
"/Real Estate/Other",
"/Real Estate/Real Estate Listings/Bank-Owned & Foreclosed Properties",
"/Real Estate/Real Estate Listings/Commercial Properties",
"/Real Estate/Real Estate Listings/Lots & Land",
"/Real Estate/Real Estate Listings/Residential Rentals",
"/Real Estate/Real Estate Listings/Residential Sales",
"/Real Estate/Real Estate Listings/Timeshares & Vacation Properties",
"/Real Estate/Real Estate Listings/Other",
"/Real Estate/Real Estate Services/Property Inspections & Appraisals",
"/Real Estate/Real Estate Services/Property Management",
"/Real Estate/Real Estate Services/Real Estate Agencies",
"/Real Estate/Real Estate Services/Real Estate Title & Escrow",
"/Real Estate/Real Estate Services/Other",
"/Reference/Other",
"/Reference/Directories & Listings/Business & Personal Listings",
"/Reference/Directories & Listings/Other",
"/Reference/General Reference/Biographies & Quotations",
"/Reference/General Reference/Calculators & Reference Tools",
"/Reference/General Reference/Dictionaries & Encyclopedias",
"/Reference/General Reference/Educational Resources",
"/Reference/General Reference/Forms Guides & Templates",
"/Reference/General Reference/How-To, DIY & Expert Content",
"/Reference/General Reference/Public Records",
"/Reference/General Reference/Time & Calendars",
"/Reference/General Reference/Other",
"/Reference/Geographic Reference/Maps",
"/Reference/Geographic Reference/Other",
"/Reference/Humanities/History",
"/Reference/Humanities/Myth & Folklore",
"/Reference/Humanities/Philosophy",
"/Reference/Humanities/Other",
"/Reference/Language Resources/Foreign Language Resources",
"/Reference/Language Resources/Other",
"/Reference/Libraries & Museums/Libraries",
"/Reference/Libraries & Museums/Museums",
"/Reference/Libraries & Museums/Other",
"/Reference/Technical Reference",
"/Science/Astronomy",
"/Science/Other",
"/Science/Biological Sciences/Genetics",
"/Science/Biological Sciences/Neuroscience",
"/Science/Biological Sciences/Other",
"/Science/Chemistry",
"/Science/Computer Science/Machine Learning & Artificial Intelligence",
"/Science/Computer Science/Other",
"/Science/Earth Sciences/Atmospheric Science",
"/Science/Earth Sciences/Geology",
"/Science/Earth Sciences/Paleontology",
"/Science/Earth Sciences/Other",
"/Science/Ecology & Environment/Climate Change & Global Warming",
"/Science/Ecology & Environment/Other",
"/Science/Engineering & Technology/Augmented & Virtual Reality,",
"/Science/Engineering & Technology/Robotics",
"/Science/Engineering & Technology/Other",
"/Science/Mathematics/Statistics",
"/Science/Mathematics/Other",
"/Science/Physics",
"/Science/Scientific Equipment",
"/Science/Scientific Institutions",
"/Sensitive Subjects/Accidents & Disasters",
"/Sensitive Subjects/Death & Tragedy",
"/Sensitive Subjects/Firearms & Weapons",
"/Sensitive Subjects/Recreational Drugs",
"/Sensitive Subjects/Self-Harm",
"/Sensitive Subjects/Violence & Abuse",
"/Sensitive Subjects/War & Conflict",
"/Sensitive Subjects/Other",
"/Shopping/Antiques & Collectibles",
"/Shopping/Other",
"/Shopping/Apparel/Apparel Services",
"/Shopping/Apparel/Athletic Apparel",
"/Shopping/Apparel/Casual Apparel",
"/Shopping/Apparel/Children's Clothing",
"/Shopping/Apparel/Clothing Accessories",
"/Shopping/Apparel/Costumes",
"/Shopping/Apparel/Eyewear",
"/Shopping/Apparel/Footwear",
"/Shopping/Apparel/Formal Wear",
"/Shopping/Apparel/Headwear",
"/Shopping/Apparel/Men's Clothing",
"/Shopping/Apparel/Outerwear",
"/Shopping/Apparel/Pants & Shorts",
"/Shopping/Apparel/Shirts & Tops",
"/Shopping/Apparel/Sleepwear",
"/Shopping/Apparel/Suits & Business Attire",
"/Shopping/Apparel/Swimwear",
"/Shopping/Apparel/Undergarments",
"/Shopping/Apparel/Uniforms & Workwear",
"/Shopping/Apparel/Women's Clothing",
"/Shopping/Apparel/Other",
"/Shopping/Auctions",
"/Shopping/Classifieds",
"/Shopping/Consumer Resources/Consumer Advocacy & Protection",
"/Shopping/Consumer Resources/Coupons & Discount Offers",
"/Shopping/Consumer Resources/Customer Services",
"/Shopping/Consumer Resources/Identity Theft Protection",
"/Shopping/Consumer Resources/Product Reviews & Price Comparisons",
"/Shopping/Consumer Resources/Other",
"/Shopping/Discount & Outlet Stores",
"/Shopping/Entertainment Media/Entertainment Media Rentals",
"/Shopping/Entertainment Media/Other",
"/Shopping/Gifts & Special Event Items/Custom & Personalized Items",
"/Shopping/Gifts & Special Event Items/Flowers",
"/Shopping/Gifts & Special Event Items/Gifts",
"/Shopping/Gifts & Special Event Items/Greeting Cards",
"/Shopping/Gifts & Special Event Items/Party & Holiday Supplies",
"/Shopping/Gifts & Special Event Items/Other",
"/Shopping/Green & Eco-Friendly Shopping",
"/Shopping/Luxury Goods",
"/Shopping/Mass Merchants & Department Stores",
"/Shopping/Photo & Video Services/Event & Studio Photography",
"/Shopping/Photo & Video Services/Photo Printing Services",
"/Shopping/Photo & Video Services/Stock Photography",
"/Shopping/Photo & Video Services/Other",
"/Shopping/Shopping Portals",
"/Shopping/Swap Meets & Outdoor Markets",
"/Shopping/Tobacco & Vaping Products",
"/Shopping/Toys/Action Figures",
"/Shopping/Toys/Building Toys",
"/Shopping/Toys/Die-cast & Toy Vehicles",
"/Shopping/Toys/Dolls & Accessories",
"/Shopping/Toys/Educational Toys",
"/Shopping/Toys/Outdoor Toys & Play Equipment",
"/Shopping/Toys/Puppets",
"/Shopping/Toys/Ride-On Toys & Wagons",
"/Shopping/Toys/Stuffed Toys",
"/Shopping/Toys/Other",
"/Shopping/Wholesalers & Liquidators",
"/Sports/Other",
"/Sports/Animal Sports/Equestrian",
"/Sports/Animal Sports/Other",
"/Sports/College Sports",
"/Sports/Combat Sports/Boxing",
"/Sports/Combat Sports/Fencing",
"/Sports/Combat Sports/Martial Arts,",
"/Sports/Combat Sports/Wrestling",
"/Sports/Combat Sports/Other",
"/Sports/Extreme Sports/Climbing & Mountaineering",
"/Sports/Extreme Sports/Drag & Street Racing",
"/Sports/Extreme Sports/Stunts & Dangerous Feats",
"/Sports/Extreme Sports/Other",
"/Sports/Fantasy Sports",
"/Sports/Individual Sports/Bowling",
"/Sports/Individual Sports/Cycling",
"/Sports/Individual Sports/Golf",
"/Sports/Individual Sports/Gymnastics",
"/Sports/Individual Sports/Racquet Sports",
"/Sports/Individual Sports/Running & Walking",
"/Sports/Individual Sports/Skate Sports",
"/Sports/Individual Sports/Track & Field",
"/Sports/Individual Sports/Other",
"/Sports/International Sports Competitions/Olympics",
"/Sports/International Sports Competitions/Other",
"/Sports/Motor Sports/Auto Racing",
"/Sports/Motor Sports/Motorcycle Racing",
"/Sports/Motor Sports/Other",
"/Sports/Sport Scores & Statistics",
"/Sports/Sporting Goods/American Football Equipment",
"/Sports/Sporting Goods/Baseball Equipment",
"/Sports/Sporting Goods/Basketball Equipment",
"/Sports/Sporting Goods/Bowling Equipment",
"/Sports/Sporting Goods/Combat Sports Equipment",
"/Sports/Sporting Goods/Cricket Equipment",
"/Sports/Sporting Goods/Electric Skateboards & Scooters",
"/Sports/Sporting Goods/Equestrian Equipment & Tack",
"/Sports/Sporting Goods/Golf Equipment",
"/Sports/Sporting Goods/Gymnastics Equipment",
"/Sports/Sporting Goods/Hockey Equipment",
"/Sports/Sporting Goods/Ice Skating Equipment",
"/Sports/Sporting Goods/Roller Skating & Rollerblading Equipment",
"/Sports/Sporting Goods/Skateboarding Equipment",
"/Sports/Sporting Goods/Soccer Equipment",
"/Sports/Sporting Goods/Sports Memorabilia",
"/Sports/Sporting Goods/Squash & Racquetball Equipment",
"/Sports/Sporting Goods/Table Tennis Equipment",
"/Sports/Sporting Goods/Tennis Equipment",
"/Sports/Sporting Goods/Volleyball Equipment",
"/Sports/Sporting Goods/Water Sports Equipment",
"/Sports/Sporting Goods/Winter Sports Equipment",
"/Sports/Sporting Goods/Other",
"/Sports/Sports Coaching & Training",
"/Sports/Sports Fan Gear & Apparel",
"/Sports/Team Sports/American Football",
"/Sports/Team Sports/Australian Football",
"/Sports/Team Sports/Baseball",
"/Sports/Team Sports/Basketball",
"/Sports/Team Sports/Cheerleading",
"/Sports/Team Sports/Cricket",
"/Sports/Team Sports/Handball",
"/Sports/Team Sports/Hockey",
"/Sports/Team Sports/Rugby",
"/Sports/Team Sports/Soccer",
"/Sports/Team Sports/Volleyball",
"/Sports/Team Sports/Other",
"/Sports/Water Sports/Surfing",
"/Sports/Water Sports/Swimming",
"/Sports/Water Sports/Other",
"/Sports/Winter Sports/Ice Skating",
"/Sports/Winter Sports/Skiing & Snowboarding",
"/Sports/Winter Sports/Other",
"/Travel & Transportation/Other",
"/Travel & Transportation/Hotels & Accommodations/Vacation Rentals & Short-Term Stays",
"/Travel & Transportation/Hotels & Accommodations/Other",
"/Travel & Transportation/Luggage & Travel Accessories/Backpacks & Utility Bags",
"/Travel & Transportation/Luggage & Travel Accessories/Other",
"/Travel & Transportation/Specialty Travel/Adventure Travel",
"/Travel & Transportation/Specialty Travel/Agritourism",
"/Travel & Transportation/Specialty Travel/Business Travel",
"/Travel & Transportation/Specialty Travel/Ecotourism",
"/Travel & Transportation/Specialty Travel/Family Travel",
"/Travel & Transportation/Specialty Travel/Honeymoons & Romantic Getaways",
"/Travel & Transportation/Specialty Travel/Low Cost & Last Minute Travel",
"/Travel & Transportation/Specialty Travel/Luxury Travel",
"/Travel & Transportation/Specialty Travel/Medical Tourism",
"/Travel & Transportation/Specialty Travel/Religious Travel",
"/Travel & Transportation/Specialty Travel/Other",
"/Travel & Transportation/Tourist Destinations/Beaches & Islands",
"/Travel & Transportation/Tourist Destinations/Historical Sites & Buildings",
"/Travel & Transportation/Tourist Destinations/Mountain & Ski Resorts",
"/Travel & Transportation/Tourist Destinations/Regional Parks & Gardens",
"/Travel & Transportation/Tourist Destinations/Theme Parks",
"/Travel & Transportation/Tourist Destinations/Vineyards & Wine Tourism",
"/Travel & Transportation/Tourist Destinations/Zoos, Aquariums & Preserves",
"/Travel & Transportation/Tourist Destinations/Other",
"/Travel & Transportation/Transportation/Air Travel",
"/Travel & Transportation/Transportation/Car Rentals",
"/Travel & Transportation/Transportation/Carpooling",
"/Travel & Transportation/Transportation/Chartered Transportation Rentals",
"/Travel & Transportation/Transportation/Cruises & Charters",
"/Travel & Transportation/Transportation/Long Distance Bus & Rail",
"/Travel & Transportation/Transportation/Other",
"/Travel & Transportation/Transportation/Parking/Airport Parking & Transportation",
"/Travel & Transportation/Transportation/Parking/Other",
"/Travel & Transportation/Transportation/Scooter & Bike Rentals",
"/Travel & Transportation/Transportation/Taxi & Ride Hail Services",
"/Travel & Transportation/Transportation/Traffic & Route Planners",
"/Travel & Transportation/Transportation/Urban Transit",
"/Travel & Transportation/Travel Agencies & Services/Guided Tours & Escorted Vacations",
"/Travel & Transportation/Travel Agencies & Services/Sightseeing Tours",
"/Travel & Transportation/Travel Agencies & Services/Vacation Offers",
"/Travel & Transportation/Travel Agencies & Services/Other",
"/Travel & Transportation/Travel Guides & Travelogues"]