import { Select } from "@cimpress/react-components";
import React, { useEffect, useMemo, useState } from "react";
import { AuthContext } from "../../context/authContext";
import {
	getViewData,
	getViews,
	setRows,
	setTableHeaders,
	setView,
	setVisibleColumns,
} from "../../features/view/viewSlice";
import { useAppDispatch, useAppSelector } from "../../reduxHooks";
import { DefaultView, DeleteCount, IFilter, IViewOptionType, IViewRequestData } from "../../types";
import "./dropDowns.css";
import { LabelWrapper } from "./labelWrapper";
import { deleteSessionData } from "../../hooks/view-data";

export function ViewDropDown() {
	const { profile } = React.useContext(AuthContext);
	const userId = profile?.email || "";
	const viewState = useAppSelector((state) => state.view);
	const { selectedTable, selectedView,filterOP, isDetailedView, detailedViewTable } = viewState;
	const tableName = useMemo(() => selectedTable, [selectedTable]);
	const viewsList = useAppSelector((state) => state.view.viewsList);
	const dispatch = useAppDispatch();
	const defaultView = {
		viewId: DefaultView.Default,
		name: DefaultView.Default,
		value: DefaultView.Default,
		label: DefaultView.Default,
		createdAt: undefined,
		savedFilters: [],
		unsavedFilters: [],
		selectedHeaders: [],
		sortColumn: undefined,
		sortOrder: undefined,
		shared: false,
		userId: userId,
		visibleColumns: [],
		quickFilters: [],
	};
	const [view, setSelectedView] = useState<IViewOptionType>(defaultView);
	const viewId = selectedView ? selectedView.viewId : DefaultView.Default;

	useEffect(() => {
		window.addEventListener("beforeunload", refreshPage);
		return () => {
			window.removeEventListener("beforeunload", refreshPage);
		}; // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getViewRequestData = (
		viewId: string,
		unsavedFilters: IFilter[],
		selectedHeaders: string[],
		sortingColumn: string | undefined,
		sortingOrder: string | undefined,
		userId: string,
		visibleColumns: string[],
		quickFilters: IFilter[]
	) => {
		let requestData: IViewRequestData = {
			currentPage: 0,
			size: 100,
			viewId: viewId,
			unsavedFilters: unsavedFilters,
			savedFilters: [],
			selectedTable: isDetailedView ? detailedViewTable : selectedTable,
			selectedColumns: selectedHeaders,
			sortingColumn,
			sortingOrder,
			userId: userId,
			visibleColumns: visibleColumns,
			quickFilters,
			filterOp: filterOP
		};
		return requestData;
	};

	const refreshPage = () => {
		if (userId) {
			deleteSessionData(DeleteCount.Single, userId, selectedTable, viewId).then((result) => {
				dispatch(
					getViewData(
						getViewRequestData(
							view.viewId,
							view.unsavedFilters,
							view.selectedHeaders,
							view.sortColumn,
							view.sortOrder,
							userId,
							view.visibleColumns,
							view.quickFilters
						)
					)
				);
			});
		}
	};

	useMemo(() => {
		if (selectedView) {
			setSelectedView({
				label: <LabelWrapper index={0} data={selectedView} />,
				value: selectedView.viewId,
				...selectedView,
			});
		}
	}, [selectedView]);
	useEffect(() => {
		if (userId && tableName) {
			dispatch(getViews({ userId, tableName: tableName }));
			setSelectedView(defaultView);
			dispatch(setView(defaultView));
			dispatch(
				getViewData(
					getViewRequestData(
						defaultView.viewId,
						defaultView.unsavedFilters,
						defaultView.selectedHeaders,
						defaultView.sortColumn,
						defaultView.sortOrder,
						userId,
						defaultView.visibleColumns,
						[]
					)
				)
			);
			dispatch(setRows([]));
			dispatch(setVisibleColumns([]));
			dispatch(setTableHeaders([]));
		}
	}, [tableName]); // eslint-disable-line react-hooks/exhaustive-deps

	const defaultOptions: IViewOptionType[] =
		React.useMemo(
			() =>
				viewsList.map((view, index) => {
					return {
						label: <LabelWrapper data={view} index={index} />,
						value: view.viewId,
						...view,
					};
				}),
			[viewsList, selectedView] // eslint-disable-line react-hooks/exhaustive-deps
		) || [];

	const onSingleSelectionChange = (inputview: IViewOptionType) => {
		if (view.value === inputview.value) {
			return;
		}
		setSelectedView(inputview);
		dispatch(setView({ ...inputview }));
		dispatch(
			getViewData(
				getViewRequestData(
					inputview.viewId,
					inputview.unsavedFilters,
					inputview.selectedHeaders,
					inputview.sortColumn,
					inputview.sortOrder,
					userId,
					inputview.visibleColumns,
					inputview.quickFilters
				)
			)
		);
		dispatch(setRows([]));
		dispatch(setVisibleColumns([]));
		dispatch(setTableHeaders([]));
	};
	return (
		<Select
			id="view-dropdown"
			className="view-dropdown-select"
			label={undefined}
			isSearchable={false}
			value={view}
			options={defaultOptions}
			onChange={onSingleSelectionChange}
			helpText={undefined}
		/>
	);
}
