import { Button, Checkbox, Modal, TextField, Tooltip } from "@cimpress/react-components";
import React, { useCallback } from "react";
import { useState } from "react";
import "../../styles/saveView.css";
import "./tooltip.css";
import { AuthContext } from "../../context/authContext";
import { getViews, saveViewData } from "../../features/view/viewSlice";
import { useAppDispatch, useAppSelector } from "../../reduxHooks";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import IconInformationCircle from "@cimpress-technology/react-streamline-icons/lib/IconInformationCircle";

export function SaveView() {
	const [open, setOpen] = useState<boolean>(false);
	const [error, setError] = useState("");
	const [viewName, setViewName] = useState<string>("");
	const [isVisible, setIsVisible] = useState<boolean>(false);
	const view = useAppSelector((state) => state.view);
	const { profile } = React.useContext(AuthContext);
	const userId = profile?.email;
	const {
		visibleColumns,
		savedFilters,
		unsavedFilters,
		sortColumn,
		sortOrder,
		selectedTable,
		viewsList,
		quickFilters,
		selectedHeaders
	} = view;
	const dispatch = useAppDispatch();
	const getSaveViewData = () => {
		return {
			userId,
			unsavedFilters,
			savedFilters,
			tableName: selectedTable,
			selectedHeaders: selectedHeaders,
			sortingColumn: sortColumn,
			sortingOrder: sortOrder,
			visibility: isVisible,
			name: viewName,
			visibleColumns: visibleColumns,
			quickFilters,
		};
	};

	const changeVisibility = () => {
		setIsVisible(!isVisible);
	};
	const duplicateView = (name: string) => {
		return viewsList.some((view) => view.name.toLowerCase() === name.toLowerCase());
	};
	const saveView = () => {
		let regex = /^[a-zA-Z0-9.\-_]+$/;
		if (!viewName) {
			setError("View name can not be empty");
			return;
		}
		if (!regex.test(viewName)) {
			setError(
				"You can only use letters, numbers, full stops (‘.’), underscores (‘_’), and hyphen ('-') in the view name"
			);
			return;
		}

		if (viewName && duplicateView(viewName)) {
			setError("View already exists with same name");
			return;
		}
		if (viewName) {
			dispatch(saveViewData(getSaveViewData()));
			setOpen(false);
			setViewName("");
			setIsVisible(false);
			setError("");
		}
	};
	useCallback(() => {
		if (userId) {
			dispatch(getViews({ userId, tableName: selectedTable }));
		}
	}, [userId]); // eslint-disable-line react-hooks/exhaustive-deps
	const closeModal = () => {
		setViewName("");
		setIsVisible(false);
		setOpen(false);
		setError("");
	};
	return (
		<div className="save-view-wrapper">
			<Button
				className="right-buttons save-view-button"
				variant="secondary"
				onClick={() => setOpen(true)}
			>
				Save View
			</Button>
			<Modal
				className="save-view-modal"
				status="success"
				show={open}
				onRequestHide={closeModal}
				title="Save View"
				closeButton={true}
				footer={
					<div>
						<Button
							className="save-view-cancel"
							style={{
								marginRight: "12px",
								width: "100px",
							}}
							onClick={closeModal}
						>
							Cancel
						</Button>
						<Button
							className="save-view-save-button"
							style={{ width: "100px" }}
							variant="primary"
							onClick={saveView}
						>
							Save
						</Button>
					</div>
				}
			>
				<TextField
					className="save-view-input-area"
					name=""
					label="Enter View Name"
					maxLength={100}
					value={viewName}
					helpText={<div style={{ fontSize: "12px", color: "#D24A35" }}>{error}</div>}
					onChange={(e) => {
						setViewName(e.target.value);
					}}
				/>
				<div className="visibility-checkbox-wrapper">
					<div className="visibility-checkbox">
						<Checkbox
							className="save-view-checkbox"
							label="Make this view visible to my organization"
							checked={isVisible}
							payload={undefined}
							onChange={changeVisibility}
						/>
					</div>
					<div style={{ marginTop: "2px", lineHeight: "16px" }}>
						<Tooltip
							direction="right"
							contents={
								<div className="save-view-content">
									If this is selected everyone in your organization will be able to see and use it
								</div>
							}
						>
							<IconInformationCircle weight="fill" color="#0088A9" size="lg" />
						</Tooltip>
					</div>
				</div>
			</Modal>
		</div>
	);
}
