import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { Button, Modal } from "@cimpress/react-components";
import { AuthContext } from "../context/authContext";
import {
  deleteSessionData,
  getSessionKeys,
  extendSessionData,
} from "../hooks/view-data";
import React from "react";
import { auth } from "../authentication/authHook";
import { DeleteCount } from "../types";
export function SessionLogout() {
  const timeout = 1000 * 60 * 60;
  const promptBeforeIdle = 1000 * 300;
  const { profile } = React.useContext(AuthContext);
  const email = profile?.email || "";
  const [open, setOpen] = useState(false);

  const extendSession = (sessionKeys: string[], userId: string) => {
    if (email === userId) {
      for (let key of sessionKeys) {
        extendSessionData(key);
      }
    }
  };

  const logOut = async () => {
    if (email)
      deleteSessionData(
        DeleteCount.All,
        email,
        DeleteCount.Null,
        DeleteCount.Null
      );
    await auth.logout("/");
  };

  const retain = async () => {
    if (email) {
      getSessionKeys(email).then((response) => {
        const sessionKeys = response["Item"]["Session_Keys"];
        const userId = response["Item"]["User_ID"];
        extendSession(sessionKeys, userId);
      });
    }

    // await auth.logout("/");
  };

  // Time before idle
  // eslint-disable-next-line
  const [remaining, setRemaining] = useState(0);

  const onPrompt = () => {
    // onPrompt will be called `promptBeforeIdle` milliseconds before `timeout`.
    setOpen(true);
    setRemaining(Math.ceil(getRemainingTime() / 1000));
  };

  const onIdle = () => {
    // onIdle will be called after the timeout is reached.
    setOpen(false);
    setRemaining(0);
    logOut();
  };

  const onActive = () => {
    // onActive will only be called if `activate()` is called while `isPrompted()`
    // is true.
    setOpen(false);
    setRemaining(0);
  };

  const { getRemainingTime, isPrompted, activate } = useIdleTimer({
    timeout,
    promptBeforeIdle,
    onPrompt,
    onIdle,
    onActive,
  });

  const handleStillHere = () => {
    setOpen(false);
    activate();
    retain();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  return (
    <Modal
      className="edit-column-modal"
      status="success"
      show={open}
      onRequestHide={() => setOpen(false)}
      title={<div className="edit-column-header">Warning</div>}
      closeButton={false}
      footer={[
        <div className="footer-buttons">
          <Button className="modal-cancel-button" onClick={logOut}>
            Cancel
          </Button>
          <Button
            variant="primary"
            className="modal-confirm-button"
            onClick={handleStillHere}
          >
            OK
          </Button>
        </div>,
      ]}
    >
      <p>
        {" "}
        Your session will expire in 5 minutes. Do you want to extend the
        session?{" "}
      </p>
    </Modal>
  );
}
