import { BreadCrumbs } from "../BreadCrumbs/breadCrumbs";
import "../../styles/middle.css";
import "../../styles/detailedView.css";
import { DetailedViewNavigation } from "./detailedViewNavigaiton";
import { DetailedViewSections } from "./detailedViewSections";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import NavigationIcon from "../../assets/icon-navigation-menu.svg";
import { useAppDispatch, useAppSelector } from "../../reduxHooks";
import { Buffer } from "buffer";
import {
	setDetailedViewTable,
	setTable,
	setbusinessFields,
	setcustomFields,
	setfirmographics,
	setinputFields,
	setisDetailedView,
	setlinkedCustomerIds,
	setDetailedViewRow,
	setMetaData,
	setDataProviderResponse,
	setEnrichedValue,
	setDefaultOrgIdMapping,
	setCheckedStateCorrect,
	industryMapping,
	allMappings
} from "../../features/view/viewSlice";
import {
	detailedViewDataProviders,
	defaultOrganizationIds,
	detailedViewOverview,
} from "../../hooks/view-data";
import { useEffect, useState } from "react";
import { LabelOptions } from "../tabularView/labelOptions";
import { Spinner } from "@cimpress/react-components";
import { notEditableColumns } from "../../config";
import { evoPortalRoute } from "../../utils/commonUtility";

export function DetailedView() {
	const searchParams = new URLSearchParams(window.location.search);
	const encodedTable = searchParams.get("tableName") || "";
	const searchTableName = Buffer.from(encodedTable, "base64").toString("ascii");
	const customerIdentifier = searchParams.get("customerIdentifier") || ""
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState(false);
	const [labelMenu, setLabelMenu] = useState(false);
	const url = window.location.href;
	// Find the specific string you want to use as a reference point
	const specificString = "enrichmentCorrelationId=";
	// Extract the query string from the URL
	const queryStringStartIndex = url.indexOf("/");
	const query = queryStringStartIndex !== -1 ? url.slice(queryStringStartIndex + 1) : "";
	// Extract the string after the reference point
	const customerCorrelationId = query.slice(query.indexOf(specificString) + specificString.length);
	let path_name = [];
	const { businessFields, firmographics, customFields, inputFields, isDetailedView } =
		useAppSelector((state) => state.view);

	if (window.location.href.indexOf("detailedView") !== -1) {
		path_name[0] = `/${evoPortalRoute}`;
		path_name[1] = customerCorrelationId;
	}
	const getColumnNames = () => {
		const editablefields = {
			...firmographics,
			...businessFields,
			...customFields,
		};
		return Object.keys(editablefields).filter((col) => notEditableColumns.indexOf(col) === -1);
	};
	useEffect(() => {
		dispatch(setDetailedViewTable(searchTableName));
		dispatch(setTable(searchTableName));
		defaultOrganizationIds().then((response) => {
			dispatch(setDefaultOrgIdMapping(response));
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (window.location.href.indexOf("detailedView") !== -1) {
		path_name[0] = `/${evoPortalRoute}`;
		path_name[1] = customerCorrelationId;
		if (!isDetailedView) {
			dispatch(setisDetailedView(true));
			dispatch(industryMapping());
			dispatch(allMappings());
			detailedViewOverview(customerCorrelationId, searchTableName.toLowerCase()).then(
				(response) => {
					dispatch(setinputFields(response["input_fields"]));
					dispatch(setfirmographics(response["firmographics"]));
					dispatch(setcustomFields(response["misc_fields"]));
					dispatch(setlinkedCustomerIds(response["linked_customer_ids"]));
					dispatch(setbusinessFields(response["business_fields"]));
					dispatch(setDetailedViewRow(response["record"]));
					dispatch(setMetaData(response["metadata"]));
				}
			);

			detailedViewDataProviders(customerCorrelationId, searchTableName.toLowerCase()).then((response)=>{
				if(response.hasOwnProperty("all_providers")){dispatch(setCheckedStateCorrect(new Array(response.all_providers.length).fill(false)))}
				dispatch(setDataProviderResponse(response))
				dispatch(setEnrichedValue(response["enriched_values"]))
			})
		}
	}

	return (
		<div>
			{loading && (
				<>
					<div className="detailed-cell-overlay"></div>
					<Spinner size="large" className="detailed-cell-spinner" />
				</>
			)}
			<div
				className="bread-crumb-wrapper"
				data-testid="bread-crumb-wrapper"
				id="bread-crumb-wrapper"
			>
				<BreadCrumbs items={path_name} />
			</div>
			<div className="customerid-header" data-testid="customerid-header" id="customerid-header">
				{" "}
				<span className="customerid-content">{customerCorrelationId}</span>
				<div className="navigation-icon-header" id="navigation-icon-header-all-fields-wrapper">
					<img
						id="navigation-icon-header-all-fields"
						alt=""
						src={NavigationIcon}
						className="navigation-icon"
						style={{
							borderRadius: "2px",
							background: "#0088A9",
							width: "2vw",
							height: "4vh",
							paddingLeft: "0.6vw",
							paddingRight: "0.6vw",
						}}
						onClick={() => setLabelMenu(!labelMenu)}
					/>
					{labelMenu && getColumnNames().length > 0 && (
						<LabelOptions
							setLoading={setLoading}
							y={100}
							totalHeight={1200}
							identifierId={customerCorrelationId}
							rowIndexes={[]}
							columnNames={getColumnNames()}
							setIsDropDown={setLabelMenu}
							isDropDown={false}
							position="bottom"
						></LabelOptions>
					)}
				</div>
			</div>
			<div
				className="input-accordioin-wrapper"
				data-testid="input-accordioin-wrapper"
				id="input-accordioin-wrapper"
			>
				<DetailedViewSections title="Customer Declared Information" data={inputFields} tab={""} customerIdentifier={customerIdentifier}/>
			</div>
			<div>
				<DetailedViewNavigation customerIdentifier={customerIdentifier}/>
			</div>
		</div>
	);
}
