import React from "react";
import { AuthContext } from "../context/authContext";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Header from "@cimpress-technology/react-platform-header";
import "../styles/header.css";
import { getViewData } from "../features/view/viewSlice";
import { useAppDispatch, useAppSelector } from "../reduxHooks";
import { DefaultView, IViewRequestData } from "../types";
import { useNavigate } from "react-router-dom";
import { evoPortalRoute } from "../utils/commonUtility";

export const PortalHeader: React.FC = () => {
  const { accessToken, profile, logout, login } = React.useContext(AuthContext);
  const loggedIn = !!accessToken;
  const dispatch = useAppDispatch();
  const email = profile?.email || "";
  const navigate = useNavigate();
  const {
    unsavedFilters,
    pageSize,
    selectedView,
    selectedTable,
    sortColumn,
    sortOrder,
    visibleColumns,
    selectedHeaders,
    filterOP,
  } = useAppSelector((state) => state.view);

  const getViewRequestData = () => {
    const requestData: IViewRequestData = {
      currentPage: 0,
      size: pageSize,
      viewId: selectedView ? selectedView.viewId : DefaultView.Default,
      unsavedFilters:
        unsavedFilters.length > 0
          ? unsavedFilters
          : selectedView?.unsavedFilters || [],
      savedFilters: [],
      selectedTable: selectedTable,
      selectedColumns: selectedHeaders,
      sortingColumn: sortColumn,
      sortingOrder: sortOrder,
      userId: email,
      visibleColumns: visibleColumns,
      filterOp: filterOP,
    };
    return requestData;
  };

  const handleClick = () => {
    const currentUrl = window.location.pathname + window.location.search;
    const evoPortalPath = `/${evoPortalRoute}`;
    if (window.location.href.indexOf(`${evoPortalRoute}`) === -1 || currentUrl.startsWith(`${evoPortalPath}/detailedView`)) {
      navigate(evoPortalPath);
      dispatch(getViewData(getViewRequestData()));
    }
  };

  const handleClickHome = () => {
    if (window.location.href.indexOf("home") === -1) {
      navigate("/home");
    }
  };

  const handleClickProspector = () => {
    if (window.location.href.indexOf("prospector") === -1) {
      navigate("/prospector");
    }
  };

  const handleBulkEnrich = () => {
    if (window.location.href.indexOf("bulkEnrich") === -1) {
      navigate("/bulkEnrich");
    }
  };
  const handleDocIntent = () => {
    if (window.location.href.indexOf("doc-intent") === -1) {
      navigate("/doc-intent");
    }
  }

  // Check if the hostname starts with "enrichment"
  const shouldHideFeatures = window.location.hostname.startsWith("enrichment");
  // Build the appLinks array conditionally
  const appLinks = [
    // Conditionally include Home link
    {
      id: "Home",
      content: (
        // eslint-disable-next-line
        <a
          className="evo-header-clickable"
          style={{ paddingLeft: "3vw" }}
          onClick={handleClickHome}
        >
          Home
        </a>
      ),
    },
    {
      id: "Document Intent",

      content: (
        // eslint-disable-next-line
        <a
          className="evo-header-clickable"
          style={{ paddingLeft: "3vw" }}
          onClick={handleDocIntent}
        >
          Doc Intent
        </a>
      ),
    },
    {
      id: "Evo Portal",
      content: (
        // eslint-disable-next-line
        <a
          className="evo-header-clickable"
          style={{ paddingLeft: "3vw" }}
          onClick={handleClick}
        >
          EVO Portal
        </a>
      ),
    },
    // Conditionally include Prospector link
    !shouldHideFeatures && {
      id: "Prospector",
      content: (
        // eslint-disable-next-line
        <a
          className="evo-header-clickable"
          style={{ paddingLeft: "3vw" }}
          onClick={handleClickProspector}
        >
          Prospector
        </a>
      ),
    },
    // Conditionally include Bulk Enrich link
    {
      id: "Bulk Enrich",
      content: (
        // eslint-disable-next-line
        <a className="evo-header-clickable" onClick={handleBulkEnrich}>
          Bulk Enrichment
        </a>
      ),
    },
  ].filter(Boolean); // Filter out any false values from the array

  return (
    <Header
      responsive
      appTitle={
        <span
          className="customer-enrichment-header-clickable"
          style={{
            fontSize: "20px",
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "24px",
          }}
          onClick={() => {
            navigate("/home");
          }}
        >
          Customer Enrichment
        </span>
      }
      appLinks={appLinks}
      profile={profile}
      onLogInClicked={login}
      onLogOutClicked={logout}
      isLoggedIn={loggedIn}
      accessToken={accessToken}
    />
  );
};
