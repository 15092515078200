import { Button, Card, Modal, Snackbar, Spinner } from "@cimpress/react-components/";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./BulkEnrich.css";
import UploadIcon from "../../assets/upload-icon.svg";
import CSVIcon from "../../assets/csv-icon.svg";
import DeleteIcon from "../../assets/delete-icon.svg";
import { formats } from "../../config";
import { setSnackBar } from "../../features/view/viewSlice";
import { useAppDispatch, useAppSelector } from "../../reduxHooks";
import { SingleFileUploadProps, UploadableFiles } from "../../types";
import { setUploadError, setSheetValidationErrors, validateFile, setisFilePresent,setFiles, setRecordsInFile } from "../../features/bulkEnrichment/bulkEnrichmentSlice";
import { AuthContext } from "../../context/authContext";
import Papa from 'papaparse';

export const SingleFileUpload: React.FC<SingleFileUploadProps> = ({
  setFilePresent,
  isFilePresent,
}) => {
  const { profile } = React.useContext(AuthContext);
  const email = profile?.email || "";
  const dispatch = useAppDispatch();
  const [validationErrors, setValidationErrors] = useState<String[]>([]);
  const [fileLinks, setFileLinks] = useState<string[]>([]);
  const [validationAlert, setValidationAlert] = useState<boolean>(false);
  const [modalState, setModalState] = useState(() => ({
    open: false,
    status: undefined,
  }));
  const { files, isLoadingFileValidation } = useAppSelector((state) => state.bulkEnrichment);
  const closeModal = () => setModalState({ open: false, status: undefined });


const countRowsInCSVFile = (file: File): Promise<number> => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,              
      skipEmptyLines: true,      
      complete: (results) => {
        const data = results.data as object[]; 
        if (data && data.length > 0) {
            resolve(data.length); 
        } else {
          reject("No data found in the file.");
        }
      },
      error: (error) => {
        reject(`Error parsing the file: ${error.message}`);
      }
    });
  });
};

  
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      try {
        setValidationAlert(false);
        setFileLinks([]); 
        setValidationErrors([]); 
        dispatch(setUploadError(""));
        const file = acceptedFiles[0]; 
        countRowsInCSVFile(file)
          .then(rowCount => {
            dispatch(setRecordsInFile(rowCount));
          })
          .catch(error => {
            console.error("Error counting rows in the CSV file:", error);
          });
        const formData = new FormData();
        formData.append('file', acceptedFiles[0]);
        formData.append('requester_email', email);
        const response = await dispatch(validateFile({ formData }));
        if (response.meta.requestStatus === 'fulfilled') {
          setValidationAlert(false);
          dispatch(setSheetValidationErrors(false))
          dispatch(setisFilePresent(true))
          setValidationErrors([]);
          const uploadableFile: UploadableFiles[] = [
            {
              file: acceptedFiles[0], 
              errors: [], 
            }
          ];
          dispatch(setFiles(uploadableFile));
        }
        else{
          setSheetValidationErrors(true)
          const sheetErrorsArray: string[] = [];
          const fileLinksArray: string[] = [];
          // if response.payload.data
          response.payload.data.forEach((item: { error?: string; file_link?: string }) => {
            if (item.error) {
              sheetErrorsArray.push(item.error);
              setValidationErrors(sheetErrorsArray);
              setValidationAlert(true);
              // dispatch(setisFilePresent(false))
            } else if (item.file_link) {
              fileLinksArray.push(item.file_link);
              setFileLinks(fileLinksArray);
              setValidationAlert(true);
            }
          });
        }
      } catch (error) {
        console.error("Error occurred during file processing:", error);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFilePresent, setFiles, validateFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: formats,
    maxFiles: 1,
    multiple: false,
    disabled: files.length ? true : false,
  });

  const units = ["bytes", "KiB", "MiB"];

  function bytesFormat(x: string) {
    let l = 0,
      n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
  }

  return (
    <div>
      {isLoadingFileValidation === "loading" ? <Spinner></Spinner> : (
      <Modal
        status={modalState.status}
        style={{ marginTop: "10vh" }}
        show={modalState.open}
        title={"File upload failed"}
        onRequestHide={closeModal}
        closeButton={true}
        footer={<Button onClick={closeModal}>Close</Button>}
      >
        <div>
          {"File upload failed due to following reasons"}
        </div>
        <ul>
        {validationErrors.length > 0 && Array.isArray(validationErrors[0]) && (
            <li>
              <strong>Errors:</strong>
              <ul>
                {validationErrors[0].map((error: string, index: number) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </li>
          )}
          {fileLinks.length > 0 && (
            <li>
              <strong>File Links:</strong>
              <ul>
                {fileLinks.map((link, index) => (
                  <li key={index}>
                    <a href={link} target="_blank" rel="noopener noreferrer">
                      View File
                    </a>
                  </li>
                ))}
              </ul>
            </li>
          )}
        </ul>
      </Modal>)}
      
      {validationAlert && (
        <Snackbar
          delay={undefined}
          show={true}
          status={"danger"}
        >
          {"File not uploaded ! ! !"}
          <Button
            variant="anchor"
            onClick={() => {
              setModalState({ open: true, status: undefined });
            }}
            style={{ marginLeft: "1vw" }}
          >
            View Info
          </Button>
        </Snackbar>
      )}
      
      <div>
        {isLoadingFileValidation !== "loading" && (
        <div {...getRootProps({ className: "file-uploader" })}>
          <input {...getInputProps()} data-testid="file-uploader-card" id="file-uploader-card"/>
          <div>
            {files.map((SingleFile, index) => (
              <Card key={index} className="accepted-file">
                <div className="accepted-file-container">
                  <div style={{ display: "flex" }}>
                    <img src={CSVIcon} alt="CSV icon" />
                    <div style={{ marginLeft: "20px" }}>
                      <div>{SingleFile.file.name}</div>
                      <div>{bytesFormat(SingleFile.file.size.toString())}</div>
                    </div>
                  </div>
                  <div>
                    <img
                      src={DeleteIcon}
                      alt="Delete File"
                      onClick={() => {
                        dispatch(setFiles([]));
                        setFilePresent(false);
                        dispatch(setSnackBar(false));
                        dispatch(setSheetValidationErrors(false));
                        dispatch(setisFilePresent(false));
                        setFilePresent(false);
                      }}
                      style={{
                        cursor: "pointer",
                        marginTop: "2vh",
                      }}
                    />
                  </div>
                </div>
              </Card>
            ))}
          </div>
          <div className="file-select-button">
            {isDragActive && !isFilePresent ? (
              <p>Drop the file here ...</p>
            ) : (
              (!isFilePresent && isLoadingFileValidation !== 'loading') && (
                <div>
                  <Button variant="primary">Select File</Button>
                </div>
              )
            )}
          </div>
          {!isFilePresent && (
            <div className="drag-drop">
              <img
                src={UploadIcon}
                alt="Upload File"
                style={{ marginLeft: "3.5vw" }}
              />
              <div>Drag and drop or select file to upload</div>
            </div>
          )}
        </div>)}
      </div>
    </div>
  );
};
