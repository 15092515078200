
import React, { useMemo,useRef,useState } from "react";
import { ProspectorTable } from "./prospectorTable"
import { Button, Select, Spinner, TextField } from "@cimpress/react-components";
import { CSVLink } from "react-csv";
import { useAppDispatch } from "../reduxHooks";
import {industryMapping,allMappings as allMappingDispatch, getProspectorViewData, setisProspectorFiltered, setprospectorFilteredData} from "../features/view/viewSlice";
import { useAppSelector } from "../reduxHooks";
import _ from "lodash";
import { IGCCOption, IndustryOption, dropdownFields, getOptions, isDateField, isNumeric, l1IndustryCols, l2IndustryCols } from "../components/tabularView/overWriteModal";
import { GCCINDUSTRIES } from "../utils/gccIndustries";
import "../styles/prospectorTable.css"
import { ProspectorTablePagination } from "./prospectorTablePagination";
import { IProspectorRequestData } from "../types";
import { BreadCrumbs } from "../components/BreadCrumbs/breadCrumbs";
import { countryList, OptionDrop, companyName } from "../config";
interface selectOption {
    label: string;
    value: string;
}

export const Prospector = () => {
    const dispatch = useAppDispatch();
    useMemo(()=>{
        dispatch(industryMapping());
        dispatch(allMappingDispatch());
    },[dispatch])
    const [fieldsFill,setfieldsFill] = useState({country:"",field:"",fieldValue:[""]});
    const [prospectPageSize, setprospectPageSize] = useState(100);
    const [prospectPageNumber, setprospectPageNumber] = useState(1);
    const [selectedCountry, setSelectCountry] = useState("US");
    const [searchValue, setSearchValue] = useState("");
    const [error, setError] = useState("");
    const viewState = useAppSelector((state) => state.view);
    const {prospectIsLoading} = useAppSelector(state => state.view)
	const {
		prospectorColumns,
	} = viewState;
    const renderProspectorColumns = React.useMemo(
		() =>
        prospectorColumns.map((header) => {
				return {
					Header: header,
					accessor: header,
				};
			}),
		[prospectorColumns] // eslint-disable-line react-hooks/exhaustive-deps
	);
    const prospectorRowsData = useAppSelector((state) => state.view.prospectorRows);
	const data = React.useMemo(() => {
		const row = prospectorRowsData;
		return row;
	}, [prospectorRowsData]);
    const columns = React.useMemo(
        () => renderProspectorColumns,
        [renderProspectorColumns]
    );
    
    const optionsDropdown = OptionDrop.map(column => {
        return (
            { value: column, label: column }
        )
    })
    
    const countriesList = countryList.map(country => {
        return (
            { label: country, value: country }
        )
    })
    const handleColumnSelect = (selectValue: selectOption) => {
        setfieldsFill({country:"",field:"",fieldValue:[""]});
        setIndustryL1({ label: "", value: "" });
        setIndustryL2({ label: "", value: "" });
        setGCCIndustry({ label: "", value: "" });
        setTextValue("");
        setSearchValue(selectValue.value);
    };
    const handleCountrySelect = (selectedCountry: selectOption) => {
        dispatch(setisProspectorFiltered(false));
        setfieldsFill({country:selectedCountry.value,field:"",fieldValue:[""]});
        setSearchValue("");
        setIndustryL1({ label: "", value: "" });
        setIndustryL2({ label: "", value: "" });
        setGCCIndustry({ label: "", value: "" });
        setTextValue("");
        setSelectCountry(selectedCountry.value);
        dispatch(getProspectorViewData({
			currentPage: prospectPageNumber,
			size: prospectPageSize,
			fieldSearchResult:{country:selectedCountry.value,field:"",fieldValue:[""]},
		}));
    }
    const allFieldsPresent = () =>{
        if(!searchValue || !selectedCountry || !fieldsFill.fieldValue[0] ){
            return true
        }
        else return false
    }
    const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
	const [textwrittenValue, setTextValue] = useState<string>("");
	const [industryL1, setIndustryL1] = useState<IndustryOption>({
		label: "",
		value: "",
	});
	const [gccIndustry, setGCCIndustry] = useState<IndustryOption>({
		label: "",
		value: "",
	});
	const [industryL2, setIndustryL2] = useState<IndustryOption>({
		label: "",
		value: "",
	});

	const [L1Options, setL1Options] = useState<IndustryOption[]>([{ label: "", value: "" }]);

	const [L2Options, setL2Options] = useState<IndustryOption[]>([{ label: "", value: "" }]);
	const [gccOptions] = useState<IGCCOption[]>(
		GCCINDUSTRIES.map((industry) => ({ label: industry, value: industry }))
	);
    
    const {
        allMappings,
		subSegments,
        industryMap,
        isProspectorFiltered,prospectorFilteredData
	} = useAppSelector((state) => state.view);
    
    
    useMemo(() => {
		if (l1IndustryCols.indexOf(searchValue) !== -1)
			setL1Options(Object.keys(industryMap).map((l1) => ({ label: l1, value: l1 })));
	}, [industryMap, searchValue]);
    useMemo(() => {
		if (l2IndustryCols.indexOf(searchValue) !== -1 && prospectorFilteredData["field"] === "industry_l1") {
			setIndustryL2({ label: "", value: "" });

			let l2List = industryMap[prospectorFilteredData["fieldValue"][0]]
				? industryMap[prospectorFilteredData["fieldValue"][0]].map((l2) => ({
					label: l2,
					value: l2,
				}))
				: [];
			setL2Options([...l2List]);
		}
	}, [industryMap, prospectorFilteredData, searchValue]);
    
    
    const onL1Change = (obj: { label: string; value: string }) => {
        setfieldsFill({country:selectedCountry,field:searchValue,fieldValue:[obj.value]})
		setIndustryL1(obj);
		setError("");
	};
    const onL2Change = (obj: { label: string; value: string }) => {
        setfieldsFill({country:selectedCountry,field:searchValue,fieldValue:[obj.value]})
		setIndustryL2(obj);
		setError("");
	};
	const onGCCChange = (obj: { label: string; value: string }) => {
        setfieldsFill({country:selectedCountry,field:searchValue,fieldValue:[obj.value]});
		setGCCIndustry(obj);
		setError("");
	};
	const onTextWrite = (value: any) => {
        setfieldsFill({country:selectedCountry,field:searchValue,fieldValue:[value]});
        if(isDateField(searchValue) && value.length < 4){
            setError(`${searchValue} should be in YYYY`);
            setTextValue(value);
        }
        else if (isDateField(searchValue) && new Date().getFullYear() < Number(value)) {
            setError(`${searchValue} can not be future date`);
            setTextValue(value);
        }
        else {
            setError(""); 
		    setTextValue(value);
        }
	};
    
    const getProspectorRequestData = (pageNumber = 0, pageSize = 100) => {
		let requestData: IProspectorRequestData = {
			currentPage: pageNumber,
			size: pageSize,
			fieldSearchResult:fieldsFill,
		};
		return requestData;
	};
    const getProspectorRequestDataPagination = (pageNumber = 0, pageSize = 100) => {
		let requestData: IProspectorRequestData = {
			currentPage: pageNumber,
			size: pageSize,
			fieldSearchResult:isProspectorFiltered?prospectorFilteredData:{country:selectedCountry,field:"",fieldValue:[""]},
		};
		return requestData;
	};
    const handleSearchClick = () => {
        dispatch(setisProspectorFiltered(true));
        dispatch(setprospectorFilteredData(fieldsFill));
        dispatch(getProspectorViewData(getProspectorRequestData(prospectPageNumber, prospectPageSize)));
    };
    const handleDownloadData = () => {
        if (csvLinkRef.current) {
            csvLinkRef.current.link.click();
          }
    }
    return (<div className="prospector-container">
        <div className="prospector-breadcrumb"><BreadCrumbs items={["/prospector"]} /></div>
        <div className="prospector-middle-section">
            <div>
                <Select label="Select Country" className="prospector-select" value={{ label: selectedCountry, value: selectedCountry }} options={countriesList} onChange={handleCountrySelect}></Select>
            </div>
            <div className="prospector-search-select">
                <Select className="prospector-select" label="Select Field to Search" value={{ label: searchValue, value: searchValue }} options={optionsDropdown} onChange={handleColumnSelect}>
                </Select></div>
            <div >
                <div>
        {l1IndustryCols.indexOf(searchValue) !== -1 && (
            <div style={{display:"flex"}}>
                <div className="prospector-select">
                    <Select
                        maxMenuHeight={150}
                        menuShouldScrollIntoView={false}
                        label={"Industry L1"}
                        isSearchable={true}
                        id={`industry-select-${searchValue}`}
                        value={industryL1}
                        options={L1Options}
                        onChange={onL1Change}
                        helpText={undefined}
                    />
                </div>
            </div>
        )}
        {l2IndustryCols.indexOf(searchValue) !== -1 && (
            <div style={{display:"flex"}}>
                <div className="prospector-select">
                    <Select
                        maxMenuHeight={150}
                        menuShouldScrollIntoView={false}
                        label={"Industry L2"}
                        isSearchable={true}
                        id={`industry-select-${searchValue}`}
                        value={industryL2}
                        options={L2Options}
                        onChange={onL2Change}
                        helpText={undefined}
                    />
                </div>
            </div>
        )}
        {searchValue === "CATEGORY" && (
            <div className="prospector-select">
                <Select
                    maxMenuHeight={150}
                    menuShouldScrollIntoView={false}
                    label={"Category"}
                    isSearchable={true}
                    id={`industry-select-${searchValue}`}
                    value={gccIndustry}
                    options={gccOptions}
                    onChange={onGCCChange}
                    helpText={undefined}
                />
            </div>
        )}
        {l1IndustryCols.indexOf(searchValue) === -1 &&
            l2IndustryCols.indexOf(searchValue) === -1 &&
            searchValue !== "CATEGORY" && (
                <div className="prospector-select">
                    {dropdownFields.indexOf(searchValue) !== -1 ? (
                        <Select
                            label={searchValue}
                            isSearchable={true}
                            menuShouldScrollIntoView={false}
                            id={`override-text-input-${searchValue}`}
                            value={{
                                label: _.startCase(textwrittenValue),
                                value: _.startCase(textwrittenValue),
                            }}
                            options={getOptions(searchValue, allMappings, subSegments)}
                            onChange={(obj) => onTextWrite(obj.value)}
                        />
                    ) : (
                        <>{searchValue && <TextField
                            className={companyName.indexOf(searchValue) !== -1?"prospector-search-field-company":"prospector-search-field"}
                            helpText={<div style={{ fontSize: "12px", color: "#D24A35" }}>{error}</div>}
                            label={searchValue}
                            min={0}
                            type={isNumeric(searchValue) || isDateField(searchValue) ? "number" : "text"}
                            id={`override-text-input-${searchValue}`}
                            name={undefined}
                            value={textwrittenValue}
                            onChange={(e) => onTextWrite(e.target.value)}
                        />}
                            
                        </>
                    )}
                </div>
            )}</div>
            </div>
            <div>
                <Button variant="primary" style={{height:"44px",marginLeft:"10px"}} disabled={allFieldsPresent()} onClick={handleSearchClick}>Search Data</Button>
            </div>
            <div style={{ marginLeft: "120px",marginTop:"1px" }}>
                <Button variant="primary" className="download-btn" onClick={handleDownloadData}>Download Data</Button>
                <CSVLink ref={csvLinkRef} data={data} filename={'table-data.csv'}>
                </CSVLink>
            </div>
        </div>
        {prospectIsLoading === "loading" && <>
					<div className="cell-overlay"></div>
					<Spinner size="large" className="cell-spinner-prospector" />
				</>}
        <ProspectorTable columns={columns} data={data} />
        <ProspectorTablePagination getdata={(pageNumber: number, pageSize: number) => {
					dispatch(getProspectorViewData(getProspectorRequestDataPagination(pageNumber, pageSize)));
				}}  setprospectPageNumber={setprospectPageNumber} setprospectPageSize={setprospectPageSize}></ProspectorTablePagination>
    </div>
    );
}
