import { TextField, Tooltip } from "@cimpress/react-components";
import { useEffect, useRef, useState } from "react";
import { detailedViewDataProviders, detailedViewOverview, opensearchResults } from "../../hooks/view-data";
import { useAppDispatch, useAppSelector } from "../../reduxHooks";
import "./searchBar.css";
import { DefaultView, ISearch, IViewRequestData } from "../../types";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as CrossIcon } from "../../assets/cross.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import { ReactComponent as SearchIcon } from "../../assets/icon-search-regular.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import { DetectOutsideClick } from "../detectClickOutside";
import React from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { Buffer } from "buffer";
import {
  DEBOUNCE_TIME,
  MAX_SEARCH_CHARACTERS,
  PRINTDEAL_TABLES,
  WMD_TABLES,
} from "../../config";
import { AuthContext } from "../../context/authContext";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import IconInformationCircle from "@cimpress-technology/react-streamline-icons/lib/IconInformationCircle";
import {
  getViewData,
  setDetailedViewTable,
  setcustomFields,
  setfirmographics,
  setinputFields,
  setisDetailedView,
  setlinkedCustomerIds,
  setbusinessFields,
  setMetaData,
  setDetailedViewRow,
  setCheckedStateCorrect,
  setDataProviderResponse,
  setEnrichedValue
} from "../../features/view/viewSlice";
import { evoPortalRoute } from "../../utils/commonUtility";

export function SearchBar() {
  const [search, setSearch] = useState("");
  const [searchResults, setsearchResults] = useState([]);
  const [isLabelOption, setLabelOption] = useState<boolean>(false);
  const labelOptionRef = useRef<HTMLInputElement>(null);
  const [displaySuggestions, setdisplaySuggestions] = useState(false);
  const { accessToken } = React.useContext(AuthContext);
  const { profile } = React.useContext(AuthContext);
  const email = profile?.email || "";
  const dispatch = useAppDispatch();
  const [iconState, setIconstate] = useState(false);
  const navigate = useNavigate();
  const url = window.location.href;

  const {
    unsavedFilters,
    pageSize,
    selectedView,
    selectedTable,
    sortColumn,
    sortOrder,
    visibleColumns,
    selectedHeaders,
    filterOP,
  } = useAppSelector((state) => state.view);

  useEffect(() => {
    if (window.location.href.indexOf("detailedView") === -1) {
      setSearch("");
      setIconstate(false);
    }
  }, [url]);

  const getViewRequestData = (selectedRecord: ISearch) => {
    let requestData: IViewRequestData = {
      currentPage: 0,
      size: pageSize,
      viewId: selectedView ? selectedView.viewId : DefaultView.Default,
      unsavedFilters:
        unsavedFilters.length > 0
          ? unsavedFilters
          : selectedView?.unsavedFilters || [],
      savedFilters: [],
      selectedTable: selectedTable,
      selectedColumns: selectedHeaders,
      sortingColumn: sortColumn,
      sortingOrder: sortOrder,
      userId: email,
      visibleColumns: visibleColumns,
      filterOp: filterOP,
    };
    return requestData;
  };
  const getId = (data:ISearch) => {
		if (data["ENRICHMENT_CORRELATION_ID"] === data["CUSTOMER_NUMBER"])
			return "CUSTOMER_NUMBER";
		else if (data["ENRICHMENT_CORRELATION_ID"] === data["CUSTOMERID"]) {
			return "CUSTOMERID";
		} else if (data["ENRICHMENT_CORRELATION_ID"] === data["CUSTOMER_ID"])
			return "CUSTOMER_ID";
		else return "CUSTOMER_CORRELATION_ID";

		
	};
  const handleSuggestionClick = (selectedRecord: ISearch) => {
    const encodedTable = Buffer.from(selectedTable).toString("base64");
    const newSearchUrl =
      `/${evoPortalRoute}/detailedView?viewTab=overview&tableName=` +
      encodedTable + 
			"&customerIdentifier=" +
			getId(selectedRecord) +
      "&enrichmentCorrelationId=" +
      selectedRecord.ENRICHMENT_CORRELATION_ID;
    navigate(newSearchUrl);
    setdisplaySuggestions(false);
    dispatch(setisDetailedView(true));
    dispatch(setDetailedViewTable(selectedTable));
    detailedViewOverview(selectedRecord.ENRICHMENT_CORRELATION_ID, selectedTable.toLowerCase()).then(
      (response) => {
        dispatch(setinputFields(response["input_fields"]));
        dispatch(setfirmographics(response["firmographics"]));
        dispatch(setcustomFields(response["misc_fields"]));
        dispatch(setlinkedCustomerIds(response["linked_customer_ids"]));
        dispatch(setbusinessFields(response["business_fields"]));
        dispatch(setMetaData(response["metadata"]));
        dispatch(setDetailedViewRow(response["record"]));
      }
    );
    detailedViewDataProviders(selectedRecord.ENRICHMENT_CORRELATION_ID, selectedTable.toLowerCase()).then((response)=>{
      if(response.hasOwnProperty("all_providers")){dispatch(setCheckedStateCorrect(new Array(response.all_providers.length).fill(false)))}
      dispatch(setDataProviderResponse(response))
      dispatch(setEnrichedValue(response["enriched_values"]))
    })
  };

  const renderAllRecords = () => {
    setSearch("");
    setIconstate(false);
    navigate(`/${evoPortalRoute}`);
    if (!displaySuggestions) {
      dispatch(setisDetailedView(true));
      dispatch(getViewData(getViewRequestData({})));
    }
  };

  const getOpensearchResult = (tableName: string, query: string) => {
    setSearch(query);
    setdisplaySuggestions(true);
    if (query && accessToken) {
      opensearchResults(tableName, query, accessToken).then((response) => {
        setsearchResults(response);
        setLabelOption(true);
      });
    }
  };

  const getLabel = (selectedTable: string) => {
    let message = "";
    if (WMD_TABLES.indexOf(selectedTable) >= 0) {
      message =
        "Search by customer id, kundennummer, company name, organization id or email";
    } else if (PRINTDEAL_TABLES.indexOf(selectedTable) >= 0) {
      message =
        "Search by customer number, customer id, customer company name, organization id or email";
    } else {
      message =
        "Search by customer correlation id, customer id, company name, organization id or email";
    }
    return message;
  };

  const getCustomerCorrelationId = (result: ISearch) => {
    if (result.CUSTOMER_NUMBER) {
      return result.CUSTOMER_NUMBER;
    } else if (result.CUSTOMER_CORRELATION_ID) {
      return result.CUSTOMER_CORRELATION_ID;
    } else {
      return result.CUSTOMER_ID;
    }
  };


  const getCustomerCorrelationIdLabel = (result: ISearch) => {
    if (result.CUSTOMER_NUMBER) {
      return "CUSTOMER NUMBER";
    } else if (result.CUSTOMER_CORRELATION_ID) {
      return "CUSTOMER CORRELATION ID"
    } else {
      return "CUSTOMER ID";
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceOnChange = React.useCallback(
    _.debounce(getOpensearchResult, DEBOUNCE_TIME),
    []
  );

  const updateSearch = (tableName: string, searchValue: string) => {
    setIconstate(true);
    setSearch(searchValue);
    debounceOnChange(tableName, searchValue);
  };

  DetectOutsideClick(labelOptionRef, setLabelOption);
  return (
    <>
      <div className="search-bar" id="search-bar">
        {" "}
        <div className="custom-search " id="custom-search" >
          <TextField
            id="search-input-area"
            className="search-input-area"
            style={{ height: "40px" }}
            label={getLabel(selectedTable)}
            value={search}
            maxLength={MAX_SEARCH_CHARACTERS}
            onChange={(e) => updateSearch(selectedTable, e.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                updateSearch(selectedTable, search);
              }
            }}
            required
          />{" "}
          <span className="advance-search">
            {search && (
              <i className="close-icon active" aria-hidden="true">
                <CrossIcon
                  className="search-cross-button"
                  onClick={renderAllRecords}
                />
              </i>
            )}
          </span>
          <div className={iconState ? "clicked-icon" : "unclicked-icon"} id="search-icon-wrapper">
            <FontAwesomeIcon
              id="search-icon"
              style={{
                marginTop: "1px",
                height: "18px",
                width: "32px",
                color: "#0088A9",
                textDecorationThickness: "10px",
                backgroundColor: "#fff",
              }}
              onClick={() => {
                setIconstate(true);
                updateSearch(selectedTable, search);
              }}
              icon={faMagnifyingGlass}
            />
          </div>
        </div>
        <div
          style={{
            right: "2%",
            top: "5px",
            lineHeight: "16px",
            position: "absolute",
          }}
        >
          <Tooltip
            direction="right"
            contents={
              <div className="search-tooltip">
                <li>{getLabel(selectedTable)}</li>
                <li>Please search with "" (double quotes) for exact match</li>
              </div>
            }
          >
            <IconInformationCircle weight="fill" color="#0088A9" size="lg" />
          </Tooltip>
        </div>{" "}
        <div
          className="omni-search-container"
          ref={labelOptionRef}
          style={{
            height:
              isLabelOption &&
              displaySuggestions &&
              search &&
              searchResults.length > 0
                ? searchResults.length === 1
                  ? "150px"
                  : searchResults.length > 4
                  ? "500px"
                  : `${searchResults.length * 150}px`
                : "0px",
            overflow: search && searchResults.length > 4 ? "scroll" : "hidden",
            width: "650 px",
            borderBottom: searchResults.length === 1 ? "0px" : "2px",
          }}
        >
          {" "}
          {isLabelOption &&
            displaySuggestions &&
            searchResults.map((result: ISearch,index) => {
              if (search && searchResults.length > 0) {
                return (
                  <>
                    {" "}
                    <div
                      className="omni-search-suggestions-container"
                      id="omni-search-suggestions-container"
                      style={{
                        gridTemplateColumns: "repeat(1, 1fr)",
                        columnGap: "5px",
                      }}
                    >
                      {" "}
                      <div className="omni-search-suggestion"  id="omni-search-suggestion">
                        {" "}
                        <div className="omni-search-suggestion-body" id="omni-search-suggestion-body">
                          {" "}
                          <div
                            className="suggestion-item-outer-container"
                            id="suggestion-item-outer-container"
                            style={{
                              flex: "1 1 0%",
                            }}
                          >
                            {" "}
                            <div className="suggestion-item-container" id={`suggestion-item-container-${index}`}>
                              {" "}
                              <div
                                className="suggestion-item"
                                id={`suggestion-item-${index}`}
                                style={{
                                  gridTemplateColumns: "repeat(2, 1fr)",
                                }}
                                onClick={() => {
                                  handleSuggestionClick(result);
                                }}
                              >
                                {" "}
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {" "}
                                  <div
                                    style={{
                                      fontFamily: "Open Sans",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "11px",
                                      color: "#8090A2",
                                      lineHeight: "5px",
                                      marginTop: "5px",
                                      marginBottom: "4px",
                                      alignItems: "center",
                                    }}
                                  >
                                    {" "}
                                    {result.CUSTOMER_COMPANY_NAME
                                      ? "CUSTOMER COMPANY NAME"
                                      : "COMPANY NAME"}
                                  </div>{" "}
                                  <div
                                    style={{
                                      whiteSpace: "normal",
                                      wordBreak: "break-all",
                                    }}
                                  >
                                    {" "}
                                    <div className="suggestion-item-col-val suggestion-item-col-val-primary" id="suggestion-item-col-val suggestion-item-col-val-primary">
                                      {" "}
                                      {result.CUSTOMER_COMPANY_NAME
                                        ? result.CUSTOMER_COMPANY_NAME
                                        : result.COMPANY_NAME}{" "}
                                    </div>{" "}
                                  </div>{" "}
                                </div>{" "}
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {" "}
                                  <div
                                    style={{
                                      fontFamily: "Open Sans",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "11px",
                                      color: "#8090A2",
                                      lineHeight: "1px",
                                      marginTop: "5px",
                                      marginBottom: "4px",
                                      alignItems: "center",
                                    }}
                                  >
                                    {getCustomerCorrelationIdLabel(result)}
                                  </div>
                                  <div
                                    style={{
                                      whiteSpace: "normal",
                                      wordBreak: "break-all",
                                    }}
                                  >
                                    <div className="suggestion-item-col-val suggestion-item-col-val-primary" id="suggestion-item-col-val suggestion-item-col-val-primary">
                                      {getCustomerCorrelationId(result)}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {" "}
                                  <div
                                    style={{
                                      fontFamily: "Open Sans",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "11px",
                                      color: "#8090A2",
                                      lineHeight: "5px",
                                      marginTop: "5px",
                                      marginBottom: "4px",
                                      alignItems: "center",
                                    }}
                                  >
                                    {" "}
                                    {result.KUNDENNUMMER
                                      ? "KUNDENNUMMER"
                                      : "CUSTOMER ID"}{" "}
                                  </div>{" "}
                                  <div
                                    style={{
                                      whiteSpace: "normal",
                                      wordBreak: "break-all",
                                    }}
                                  >
                                    {" "}
                                    <div className="suggestion-item-col-val suggestion-item-col-val-primary" id="suggestion-item-col-val suggestion-item-col-val-primary">
                                      {" "}
                                      {result.KUNDENNUMMER
                                        ? result.KUNDENNUMMER
                                        : result.CUSTOMER_ID}{" "}
                                    </div>{" "}
                                  </div>{" "}
                                </div>{" "}
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontFamily: "Open Sans",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "11px",
                                      color: "#8090A2",
                                      lineHeight: "5px",
                                      marginTop: "5px",
                                      marginBottom: "4px",
                                      alignItems: "center",
                                    }}
                                  >
                                    EMAIL ID
                                  </div>
                                  <div
                                    style={{
                                      whiteSpace: "normal",
                                      wordBreak: "break-all",
                                    }}
                                  >
                                    <div className="suggestion-item-col-val suggestion-item-col-val-primary" id="suggestion-item-col-val suggestion-item-col-val-primary">
                                      {result.EMAIL_ID}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontFamily: "Open Sans",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "11px",
                                      color: "#8090A2",
                                      lineHeight: "5px",
                                      marginTop: "5px",
                                      marginBottom: "4px",
                                      alignItems: "center",
                                    }}
                                  >
                                    ORGANIZATION ID
                                  </div>
                                  <div
                                    style={{
                                      whiteSpace: "normal",
                                      wordBreak: "break-all",
                                    }}
                                  >
                                    <div className="suggestion-item-col-val suggestion-item-col-val-primary" id="suggestion-item-col-val suggestion-item-col-val-primary">
                                      {result.ORGANIZATION_ID}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              }
              return null;
            })}
        </div>
      </div>
    </>
  );
}
