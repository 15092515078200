import { useEffect, useState } from "react";
import { Checkbox } from "@cimpress/react-components";
import { useAppDispatch, useAppSelector } from "../../reduxHooks";
import {
  setIsLinkedCid,
  setSelectedLinkUnlinkCustomerIds,
  setbusinessFields,
  setcustomFields,
  setfirmographics,
  setinputFields,
  setisDetailedView,
  setlinkedCustomerIds,
  setDetailedViewTable,
  setinitialDetailedView,
  setMetaData,
  setDetailedViewRow,
  setCheckedStateCorrect,
  setDataProviderResponse,
  setEnrichedValue,
} from "../../features/view/viewSlice";
import { detailedViewDataProviders, detailedViewOverview } from "../../hooks/view-data";
import { DetailedViewSectionsData, LinkButton } from "../../types";
import { useNavigate } from "react-router-dom";
import { Buffer } from "buffer";
import "./detailedViewSections.css";
import { evoPortalRoute } from "../../utils/commonUtility";

export const DetailedViewCheckbox = (props: {
  displayedId: string;
  linkedCIds: DetailedViewSectionsData[];
  linkOperator: string;
  itemIndex: number;
  tab:string,
  customerIdentifer:string
}) => {
  const searchParams = new URLSearchParams(window.location.search);
  const encodedTable = searchParams.get("tableName") || "";
  const searchTableName = Buffer.from(encodedTable, "base64").toString("ascii");

  const [isClicking, setIsClicking] = useState<boolean>(false);

  const [isChecked, setIsChecked] = useState<boolean>(false);
  const {
    selectedLinkUnlinkCustomerIds,
    detailedViewTable,
    searchedCorrelationIds,
    businessFields,
    defaultOrgIdMapping,
  } = useAppSelector((state) => state.view);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const linkedCids = props.linkedCIds;
  const linkOperator = props.linkOperator;
  const isSingleCid = linkedCids.length === 1;
  const isDefaultOrgId = Object.values(defaultOrgIdMapping).includes(
    businessFields.ORGANIZATION_ID
  );

  useEffect(() => {
    if (isChecked) {
      linkOperator === LinkButton.Link
        ? setIsChecked(false)
        : setIsChecked(true);
    }
  }, [linkOperator, isChecked]);

  useEffect(() => {
    if (selectedLinkUnlinkCustomerIds.length === 0) {
      setIsChecked(false);
    }
  }, [selectedLinkUnlinkCustomerIds]);

  const handleSelect = (customerId: string) => {
    if (!isClicking) {
      const index = selectedLinkUnlinkCustomerIds.indexOf(customerId);
      const existingIds = [...selectedLinkUnlinkCustomerIds];
      linkOperator === LinkButton.Link
        ? setIsChecked(false)
        : setIsChecked(true);
      if (index === -1) {
        existingIds.push(customerId);
        setIsChecked(true);
      } else {
        existingIds.splice(index, 1);
        setIsChecked(false);
      }
      dispatch(setinitialDetailedView(existingIds.length === 0));
      dispatch(setSelectedLinkUnlinkCustomerIds(existingIds));
      dispatch(setIsLinkedCid(existingIds.length > 0));
      dispatch(setlinkedCustomerIds(linkedCids));
    }
  };

  const getEnrichmentCorrelationIdFromDisplayId = (displayId: string) => {
    const matchedItem = linkedCids.find(
      (item) => item.DISPLAY_ID === displayId
    );
    return matchedItem ? matchedItem.ENRICHMENT_CORRELATION_ID : "";
  };

  const checkStatus = () => {
    if (
      searchedCorrelationIds.length !== 0 ||
      isSingleCid ||
      linkOperator === LinkButton.Link 
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleLinkedCidClick = (customerId: string) => {
    setIsClicking(true);
    const enrichmentCorrelationId =
      getEnrichmentCorrelationIdFromDisplayId(customerId);
    const encodedTable = Buffer.from(detailedViewTable).toString("base64");
    const newSearchUrl =
      `/${evoPortalRoute}/detailedView?viewTab=${props.tab}&tableName=` +
      encodedTable +
			"&customerIdentifier=" +
			props.customerIdentifer +
      "&enrichmentCorrelationId=" +
      enrichmentCorrelationId;
    navigate(newSearchUrl);
    dispatch(setisDetailedView(true));
    dispatch(setDetailedViewTable(searchTableName));
    detailedViewOverview(
      enrichmentCorrelationId,
      searchTableName.toLowerCase()
    ).then((response) => {
      setIsChecked(false);
      setIsClicking(false);
      dispatch(setinputFields(response["input_fields"]));
      dispatch(setfirmographics(response["firmographics"]));
      dispatch(setcustomFields(response["misc_fields"]));
      dispatch(setlinkedCustomerIds(response["linked_customer_ids"]));
      dispatch(setbusinessFields(response["business_fields"]));
      dispatch(setMetaData(response["metadata"]));
      dispatch(setinitialDetailedView(true));
      dispatch(setDetailedViewRow(response["record"]));
    });
    detailedViewDataProviders(enrichmentCorrelationId, searchTableName.toLowerCase()).then((response)=>{
      if(response.hasOwnProperty("all_providers")){dispatch(setCheckedStateCorrect(new Array(response.all_providers.length).fill(false)))}
      dispatch(setDataProviderResponse(response))
      dispatch(setEnrichedValue(response["enriched_values"]))
    })
  };

  return (
    <div className="checkbox-row" data-testid="checkbox-row" id="checkbox-row">
      {isDefaultOrgId ? (
        <div
          className="checkbox-col-md-4"
          data-testid="checkbox-col-md-4"
          id="checkbox-col-md-4"
        >
          <span
            onClick={() => {
              handleLinkedCidClick(props.displayedId);
            }}
          >
            {props.displayedId}{" "}
          </span>
        </div>
      ) : (
        <div
          className="checkbox-col-md-4"
          data-testid="checkbox-col-md-4"
          id="checkbox-col-md-4"
        >
          <Checkbox
            id={`checkbox-${props.itemIndex}`}
            label={
              <span
                id={`checkbox-label-${props.itemIndex}`}
                onClick={() => {
                  handleLinkedCidClick(props.displayedId);
                }}
              >
                {props.displayedId}
              </span>
            }
            checked={isChecked}
            payload={undefined}
            onChange={() => handleSelect(props.displayedId)}
            disabled={checkStatus()}
          />
        </div>
      )}
    </div>
  );
};
