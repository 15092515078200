import { DropDowns } from "./dropDowns";
import "../../styles/middle.css";
import { BreadCrumbs } from "../BreadCrumbs/breadCrumbs";
import { SessionLogout } from "../sessionLogout";
import { useLocation } from "react-router-dom";
export function Middle() {
  let path_name = [useLocation().pathname];

  return (
    <div>
      <div className="bread-crumb-wrapper">
        <BreadCrumbs items={path_name} />
      </div>

      <div className="middle-component">
        <DropDowns></DropDowns>

        <SessionLogout></SessionLogout>
      </div>
    </div>
  );
}
