import React, { createContext } from "react";
import { AuthenticationProps } from "../types";
import { useAuthentication } from "../authentication/authHook";
export const AuthContext = createContext({} as AuthenticationProps);

export const AuthProvider: React.FC<{
  children: React.ReactElement;
}> = (props) => {
  const authObject = useAuthentication();

  return (
    <AuthContext.Provider value={authObject}>
      {props.children}
    </AuthContext.Provider>
  );
};
