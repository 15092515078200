import { Checkbox } from "@cimpress/react-components";
import _ from "lodash";
import { useMemo, useState } from "react";
import { getFilters, setAppliedSavedFilters } from "../../features/view/viewSlice";
import { useAppDispatch, useAppSelector } from "../../reduxHooks";
import { IFilter } from "../../types";
import "./filters.css";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as DeleteIcon } from "../../assets/delete-icon.svg";

import { deleteSingleFilter } from "../../features/view/viewSlice";
import React from "react";
import { AuthContext } from "../../context/authContext";
const isDefaultFilter = (allUnsavedFilters: IFilter[]) => {
	let singleFilter = allUnsavedFilters[0];
	if (singleFilter.column === "" || singleFilter.operator === "" || singleFilter.value[0] === "")
		return true;
	return false;
};
const removeExistingAppliedFilters = (
	existingSingleFilter: IFilter[],
	unsavedFilterArr: IFilter[]
) => {
	const diff = _.difference(unsavedFilterArr, existingSingleFilter);
	const notEmptyFilters = diff.filter(
		(singleFilter) =>
			singleFilter.column !== "" || singleFilter.operator !== "" || singleFilter.value[0] !== ""
	);
	return notEmptyFilters;
};
export function SingleCheckbox(props: {
	name: string;
	id: string;
	allUnsavedFilters: IFilter[];
	setAllUnsavedFilters: React.Dispatch<React.SetStateAction<IFilter[]>>;
}) {
	const dispatch = useAppDispatch();
	const { savedFilters, appliedSavedFilters, selectedTable } = useAppSelector(
		(state) => state.view
	);
	const { profile } = React.useContext(AuthContext);
	const email = profile?.email || "";
	const isSavedChecked = () => {
		if (appliedSavedFilters) {
			let existingSavedFilters = appliedSavedFilters.filter(
				(appliedFilter) => appliedFilter.filterId === props.id
			);
			if (existingSavedFilters.length > 0) {
				return true;
			}
		}

		return false;
	};
	const [isChecked, setIsChecked] = useState<boolean>(isSavedChecked());
	useMemo(() => {
		let currentFilter = savedFilters.filter((saved) => saved.filterId === props.id);
		if (
			currentFilter &&
			appliedSavedFilters &&
			appliedSavedFilters.length > 0 &&
			appliedSavedFilters[0].filterId !== currentFilter[0].filterId
		)
			setIsChecked(false);
		if (
			currentFilter &&
			appliedSavedFilters &&
			appliedSavedFilters.length > 0 &&
			appliedSavedFilters[0].filterId === currentFilter[0].filterId
		)
			setIsChecked(true);
		if (appliedSavedFilters.length === 0) setIsChecked(false);
	}, [appliedSavedFilters]); // eslint-disable-line react-hooks/exhaustive-deps

	useMemo(() => setIsChecked(isSavedChecked), [props.id]); // eslint-disable-line react-hooks/exhaustive-deps
	const selectMyfilter = () => {
		if (!isChecked) {
			let savedFilter = savedFilters.filter((filter) => filter.filterId === props.id);
			let existingAppliedSavedFilter =
				appliedSavedFilters.length > 0 ? appliedSavedFilters[0].filters : [];

			dispatch(setAppliedSavedFilters([...savedFilter]));
			let unsavedFilters =
				props.allUnsavedFilters.length === 1 && isDefaultFilter(props.allUnsavedFilters)
					? []
					: removeExistingAppliedFilters(existingAppliedSavedFilter, [...props.allUnsavedFilters]);

			let unsavedFilterArr = unsavedFilters.concat(savedFilter[0].filters);

			props.setAllUnsavedFilters(unsavedFilterArr);
		} else {
			let index = -1;
			appliedSavedFilters.forEach((filter, i) => {
				if (filter.filterId === props.id) index = i;
			});
			if (index !== -1) {
				let arr = [...appliedSavedFilters];
				let filterToRemove = appliedSavedFilters[index];

				arr.splice(index, 1);
				dispatch(setAppliedSavedFilters([]));
				props.setAllUnsavedFilters(
					_.differenceWith([...props.allUnsavedFilters], [...filterToRemove.filters], _.isEqual)
				);
			}
		}

		setIsChecked(!isChecked);
	};
	const deleteFilter = () => {
		dispatch(
			deleteSingleFilter({
				id: props.id,
				filterName: props.name,
			})
		).then((res) =>
			dispatch(
				getFilters({
					userId: email,
					tableName: selectedTable,
				})
			)
		);
	};
	return (
		<div className="saved-filters" data-testid="saved-filters">
			<Checkbox
				label={props.name}
				checked={isChecked}
				payload={undefined}
				onChange={selectMyfilter}
				disabled={false}
			/>
			{isChecked && (
				<div className="saved-filter-delete-icon" onClick={deleteFilter}>
					<DeleteIcon style={{ cursor: "pointer" }} />
				</div>
			)}
		</div>
	);
}
