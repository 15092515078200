import { Tag } from "@cimpress/react-components";

import _ from "lodash";
import React from "react";
import { AuthContext } from "../../context/authContext";
import { getViewData, setUnsavedFilters } from "../../features/view/viewSlice";
import { useAppDispatch, useAppSelector } from "../../reduxHooks";
import { DefaultView, IFilter } from "../../types";
import "./dropDowns.css";

export function FilterTag() {
	const dispatch = useAppDispatch();
	const { profile } = React.useContext(AuthContext);
	const userId = profile?.email || "";
	const {
		unsavedFilters,
		pageSize,
		selectedView,
		selectedTable,
		sortColumn,
		sortOrder,
		visibleColumns,
		selectedHeaders,
		quickFilters,
		filterOP,
	} = useAppSelector((state) => state.view);

	const getViewRequestData = (arr: IFilter[]) => {
		return {
			currentPage: 0,
			size: pageSize,
			viewId: selectedView ? selectedView.viewId : DefaultView.Default,
			unsavedFilters: arr,
			savedFilters: [],
			selectedTable: selectedTable,
			selectedColumns: selectedHeaders || [],
			visibleColumns: visibleColumns || [],
			sortingColumn: sortColumn,
			sortingOrder: sortOrder,
			userId: userId,
			isApplyFilters: true,
			quickFilters,
			filterOp: filterOP,
		};
	};
	const onRemoveClick = (index: number) => {
		let arr = [...unsavedFilters];
		arr.splice(index, 1);
		dispatch(setUnsavedFilters(arr));
		if (userId) dispatch(getViewData(getViewRequestData(arr)));
	};

	return (
		<div className="filter-tag">
			{unsavedFilters.map((singleFilter, index) => {
				let value = singleFilter.value[0];
				if (
					singleFilter.value.length > 1 &&
					singleFilter.column.toLowerCase() === "processing_timestamp"
				) {
					value =
						singleFilter.value[0].toString().split("T")[0] +
						" " +
						" to " +
						singleFilter.value[1].toString().split("T")[0];
				}

				const tagFilter =
					_.startCase(singleFilter.column.toLowerCase()) +
					" " +
					singleFilter.operator +
					" " +
					value;
				const tag = { value: tagFilter };
				return (
					<Tag
						style={{ marginRight: "4px", marginTop: "10px", fontSize: "10px" }}
						key={tagFilter}
						removable
						{...tag}
						onRemoveClick={() => onRemoveClick(index)}
					/>
				);
			})}
		</div>
	);
}
