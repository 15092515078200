
import _ from "lodash";
import { IRow } from "../types";
import { detailedViewRowCount, evoOverrideConfidenceScore, sectorCodeADescription } from "./commonUtility";

const employeeCountRanges = ['1 to 10', '11 to 50', '51 to 200', '201 to 500', '501 to 1000', '1001 to 5000', '5001 to 10000', '10000+']
const employeeCountList = [1, 11, 51, 201, 501, 1001, 5001, 10001]
const establishmentYears = [1800, 1900, 2000, 2015, 2020]
const establishmentYearRange = ["bevor 1899", "1900-1999", "2000-2014", "2015-2019", "2020-present"]
const employeeColumnNames = ["GLOBAL_EMPLOYEE_COUNT", "LOCAL_EMPLOYEE_COUNT", "EMPLOYEE_COUNT", "NUMBER_OF_EMPLOYEES",
                         "EMPLOYEES"]
enum EmpCount{
    globalEmployeeCount="GLOBAL_EMPLOYEE_COUNT",
    localEmployeeCount="LOCAL_EMPLOYEE_COUNT"
}
interface IMapping{[key:string]:{[key:string]:string}}

const getWmdIndustry=(wmdMap:{[key:string]:string},inputL1:string,inputL2:string)=>{
    let key=inputL1+inputL2
    if (key in wmdMap){
        return wmdMap[key]
    }
    return ""
}
const getSicFromIndustry=(sic_to_industry_map:{[key:string]:string},inputL1:string,inputL2:string)=>{
      const sics=[]
      for (let sic of Object.keys(sic_to_industry_map)){
         let l1=sic_to_industry_map[sic][0]
         let l2=sic_to_industry_map[sic][1]
         if (inputL1===l1.toLowerCase() &&inputL2===l2.toLowerCase()){
             sics.push(sic)
         }
     }
     return sics
}
const getSbiFromSic=(sbi_to_sic_map:{[key:string]:string},sicCode:string)=>{
    for(let sbi of Object.keys(sbi_to_sic_map) ){
        if (sbi_to_sic_map[sbi]===sicCode){
            return sbi
        }
    }
    return ""
}

const getAllColumns=(row:IRow[])=>{
    const allColumns:{[key:string]:boolean}={}
    if (row.length === detailedViewRowCount){
        for (let column in row[0]){
            allColumns[column]=true
        }
    }
   for(let col of row){
       let {status,...rest}=col
       let columnName=Object.keys(rest)[0]
       allColumns[columnName]=true
   }
   return allColumns
}
const isFieldPresent=(row:IRow[],fieldToSearch:string)=>{
    if (row.length === detailedViewRowCount){
        for (let column in row[0]){
            if(column === fieldToSearch){
                return true
            }
        }
    }
    for(let col of row){
       let {status,...rest}=col
       let columnName=Object.keys(rest)[0]
       if(columnName===fieldToSearch){
           return true
       }
   }
   return false
}
const findField=(fieldToSearch:string,row:IRow[])=>{
    if (row.length === detailedViewRowCount){
        for (let column in row[0]){
            if(column === fieldToSearch){
                return row[0][column]
            }
        }
    }
    for(let col of row){
       let {status,...rest}=col
       let columnName=Object.keys(rest)[0]
       if(columnName===fieldToSearch){
           return rest[columnName]
       }
   }
   return ""
}
const updateFields=(rowToBeUpdated:IRow[],fieldsToUpdate:{[key:string]:string|undefined })=>{
    for (let col of rowToBeUpdated){
        let {status,...rest}=col
       
        for(let [field,value] of Object.entries(fieldsToUpdate)){
           if(value && field in rest ){
            col[field]=value==='Null'?"":value 
        }  
    }
}
}

const getIndex=(clonedRows:IRow[][],identifier:string,identifierId:string)=>{
        for(let index=0;index<clonedRows.length;index++ ){
            const row=clonedRows[index]
            for(let col of row){
                if (identifier in col && col[identifier]===identifierId){
                return index
                }
            }
        }
        return -1
    }
const getEmpCountRange=(empCount:number)=>{
    for(let index=0;index<employeeCountList.length;index++){
        if(empCount<=employeeCountList[index]||(employeeCountList[index]<=empCount &&employeeCountList[index+1]>empCount)){
            return employeeCountRanges[index]
        }
    }
     return employeeCountRanges[employeeCountList.length-1]
}
function updateEmployeeRange(rows:IRow[][],identifier:string,identifierId:string,fields:string[],values:string[]){
    const empCountField=fields[0]
    const value=values[0]
    const clonedRows=_.cloneDeep(rows)
    let rowIndex=getIndex(clonedRows,identifier,identifierId)
    const rowToBeUpdated=clonedRows[rowIndex]
    const rowBeforeUpdate=_.cloneDeep(rowToBeUpdated)
    const allColumns=getAllColumns(rowToBeUpdated)
    for (let col of rowToBeUpdated){ 
        if(empCountField in col){
           col[empCountField]=value
        }
    }
    let rangeField="EMPLOYEE_RANGE" in allColumns?"EMPLOYEE_RANGE":`${empCountField}_RANGE`

    if(rangeField in allColumns){
        let empCountRange=getEmpCountRange(parseInt(value))
        if (rowToBeUpdated.length === detailedViewRowCount){
            for (let rangeField in rowToBeUpdated[0]){
                if (rangeField in rowToBeUpdated[0]){
                    rowToBeUpdated[0][rangeField]=empCountRange  
                }
            }
        }
        for (let col of rowToBeUpdated){
            let {status,...rest}=col
            if (rangeField in rest){
                col[rangeField]=empCountRange  
            }  
        }
    }

    return {allRows:clonedRows,updatedRow:rowToBeUpdated,rowBeforeUpdate}
}
const updateGlobalCount=(rows:IRow[][],identifier:string,identifierId:string,fields:string[],values:string[])=>{
    const clonedRows=_.cloneDeep(rows)
    const globalCountField=fields[0]
    const updatedGlobalCount=values[0]
    let rowIndex=getIndex(clonedRows,identifier,identifierId)
    const rowToBeUpdated=clonedRows[rowIndex]
    const rowBeforeUpdate=_.cloneDeep(rowToBeUpdated)
    const allColumns=getAllColumns(rowToBeUpdated)
    for (let col of rowToBeUpdated){ 
        if(globalCountField in col){
           col[globalCountField]=updatedGlobalCount
        }
    }
   
    if (EmpCount.localEmployeeCount in allColumns ){
        if (rowToBeUpdated.length === detailedViewRowCount){
            for (let column in rowToBeUpdated[0]){
                const localCountField = column
                if (localCountField===EmpCount.localEmployeeCount && parseInt(rowToBeUpdated[0][localCountField])>parseInt(updatedGlobalCount)){
                    rowToBeUpdated[0][localCountField]=updatedGlobalCount  
                }
            }
        }
        for (let col of rowToBeUpdated){
            let {status,...rest}=col
            const [localCountField]=Object.keys(rest)
            if (localCountField===EmpCount.localEmployeeCount &&parseInt(col[localCountField])>parseInt(updatedGlobalCount)){
                col[localCountField]=updatedGlobalCount  
            }  
        }
    }
    return {allRows:clonedRows,updatedRow:rowToBeUpdated,rowBeforeUpdate}
}
const updateLocalCount=(rows:IRow[][],identifier:string,identifierId:string,fields:string[],values:string[])=>{
    const clonedRows=_.cloneDeep(rows)
    const localCountField=fields[0]
    const updatedLocalCount=values[0]
    let rowIndex=getIndex(clonedRows,identifier,identifierId)
    const rowToBeUpdated=clonedRows[rowIndex]
    const rowBeforeUpdate=_.cloneDeep(rowToBeUpdated)
    const allColumns=getAllColumns(rowToBeUpdated)
    for (let col of rowToBeUpdated){ 
        if(localCountField in col){
           col[localCountField]=updatedLocalCount
        }
    }
    if (EmpCount.globalEmployeeCount in allColumns ){
        if (rowToBeUpdated.length === detailedViewRowCount){
            for (let column in rowToBeUpdated[0]){
                const globalCountField = column
                if (globalCountField===EmpCount.globalEmployeeCount && parseInt(rowToBeUpdated[0][globalCountField])<parseInt(updatedLocalCount)){
                    rowToBeUpdated[0][globalCountField]=updatedLocalCount  
                }
            }
        }
        for (let col of rowToBeUpdated){ 
            let {status,...rest}=col
            const [globalCountField]=Object.keys(rest)
            if (globalCountField===EmpCount.globalEmployeeCount && parseInt(col[globalCountField])<parseInt(updatedLocalCount)){
                col[globalCountField]=updatedLocalCount  
            }  
        }
    }
    return {allRows:clonedRows,updatedRow:rowToBeUpdated,rowBeforeUpdate}
}
const updateSbi=(rows:IRow[][],identifier:string,identifierId:string,fields:string[],values:string[],allMappings:IMapping)=>{
    const sic_to_industry_map=allMappings['sic_to_industry_map']
    const sbi_sic_map=allMappings['sbi_to_sic_map']
    const getSbiDescription = (sbiCode: string, map: { [x: string]: string | any[]; }) => map[sbiCode]?.[0] ?? " ";
    const getSbiSectorCode = (sbiCode: string, map: { [x: string]: string | any[]; }) => map[sbiCode]?.[1] ?? " ";
    const sbi_to_description_map=allMappings["sbi_to_description_map"]
    const wmdL1L2Map=allMappings['l1_l2_wmd_map']
    const clonedRows=_.cloneDeep(rows)
    let rowIndex=getIndex(clonedRows,identifier,identifierId)
    const rowToBeUpdated=clonedRows[rowIndex]
    const rowBeforeUpdate=_.cloneDeep(rowToBeUpdated)
    let inputL1=""
    let inputL2=""
    let sbi=""
    let sbiDescription=" "
    let wmdIndustry=""
    let sbi2DigitCode = ""
    let sbi2DigitCodeDescription = " "
    let sbiSectorCode = " "
    let sbiSectorCodeADesc = " "
    let sbiSectorCodeDesc = " "
    let L1IndustryField=fields[0].split("_").indexOf("CE")!==-1?"CE_INDUSTRY_L1":"CATEGORY_L1"
    let L2IndustryField=fields[0].split("_").indexOf("CE")!==-1?"CE_INDUSTRY_L2":"CATEGORY_L2"
    inputL1=fields.length===2?values[0].trim():findField(L1IndustryField,rowToBeUpdated)
    inputL2=fields.length===2?values[1].trim():values[0].trim()
    let sicCodeList=getSicFromIndustry(sic_to_industry_map,inputL1.toLowerCase(),inputL2.toLowerCase())
    for( let sic of sicCodeList){
            sbi=getSbiFromSic(sbi_sic_map,sic)
            if(sbi){
                sbiDescription=sbi_to_description_map[sbi]?.[0] ?? " "
                sbi2DigitCode=sbi.slice(0, 2)
                sbi2DigitCodeDescription=getSbiDescription(sbi.slice(0, 2), sbi_to_description_map)
                sbiSectorCode=getSbiSectorCode(sbi, sbi_to_description_map);
                if (sbiSectorCode === " ") {
                    sbiSectorCode = getSbiSectorCode(sbi.slice(0, 2), sbi_to_description_map);
                }
                if (sbiSectorCode === "A"){
                    sbiSectorCodeADesc = sectorCodeADescription;
                }
                sbiSectorCodeDesc=getSbiDescription(sbiSectorCode, sbi_to_description_map) !== " " ? getSbiDescription(sbiSectorCode, sbi_to_description_map) : sbiSectorCodeADesc
                break
            }      
    }
    if(isFieldPresent(rowToBeUpdated,"WMD_INDUSTRY")){
         wmdIndustry=getWmdIndustry(wmdL1L2Map,inputL1.toLowerCase(),inputL2.toLowerCase())
    } 
    let fieldsToBeUpdated = {
        [L1IndustryField]: inputL1,
        [L2IndustryField]: inputL2,
        "INDUSTRY_CONFIDENCE_SCORE": evoOverrideConfidenceScore
    };
    
    if (isFieldPresent(rowToBeUpdated, "SBI")) {
        fieldsToBeUpdated = {
            ...fieldsToBeUpdated,
            "SBI": sbi || ' ',
            "SBI_DESCRIPTION": sbiDescription,
            "SBI_2_DIGIT_CODE": sbi2DigitCode || ' ',
            "SBI_2_DIGIT_CODE_DESCRIPTION": sbi2DigitCodeDescription,
            "SBI_SECTOR_CODE": sbiSectorCode || ' ',
            "SBI_SECTOR_CODE_DESCRIPTION": sbiSectorCodeDesc,
            "INDUSTRY_CONFIDENCE_SCORE": evoOverrideConfidenceScore
        };
    }    
    const finalFieldsToBeUpdated=isFieldPresent(rowToBeUpdated,"WMD_INDUSTRY")?{...fieldsToBeUpdated,"WMD_INDUSTRY":wmdIndustry,"INDUSTRY_CONFIDENCE_SCORE": evoOverrideConfidenceScore}:{...fieldsToBeUpdated,"INDUSTRY_CONFIDENCE_SCORE": evoOverrideConfidenceScore}
    updateFields(rowToBeUpdated,finalFieldsToBeUpdated)
    return {allRows:clonedRows,updatedRow:rowToBeUpdated,rowBeforeUpdate}
}
const updateIndustryAndDescription=(rows:IRow[][],identifier:string,identifierId:string,fields:string[],values:string[],allMappings:IMapping)=>{
    const sbi_sic_map = allMappings['sbi_to_sic_map']
    const sic_to_industry_map = allMappings['sic_to_industry_map']
    const clonedRows = _.cloneDeep(rows)
    const inputSbiCode = values[0]
    let rowIndex = getIndex(clonedRows, identifier, identifierId)
    let sicCode = sbi_sic_map[inputSbiCode]
    const rowToBeUpdated = clonedRows[rowIndex]
    const rowBeforeUpdate = _.cloneDeep(rowToBeUpdated)
    let sicInfo = sic_to_industry_map[sicCode] || sic_to_industry_map[`0${sicCode}`]
    const sbiToDescriptionMap = allMappings["sbi_to_description_map"]
    // let sbiDescription = sicInfo?.length === sicMinInfoLength && !_.isUndefined(sicInfo[sicMinInfoLength]) ? sicInfo[sicMinInfoLength] : ' '
    const getSbiDescription = (sbiCode: string, map: { [x: string]: string | any[]; }) => map[sbiCode]?.[0] ?? " ";
    const getSbiSectorCode = (sbiCode: string, map: { [x: string]: string | any[]; }) => map[sbiCode]?.[1] ?? " ";
    const sbiDescription = getSbiDescription(inputSbiCode, sbiToDescriptionMap);
    let sbiSectorCode = getSbiSectorCode(inputSbiCode, sbiToDescriptionMap);
    let sbiSectorCodeADesc = " "
    if (sbiSectorCode === " ") {
        sbiSectorCode = getSbiSectorCode(inputSbiCode.slice(0, 2), sbiToDescriptionMap);
    }
    if (sbiSectorCode === "A"){
        sbiSectorCodeADesc = sectorCodeADescription
    }
    let industryL1 = sicInfo ? sicInfo[0] : " "
    let industryL2 = sicInfo ? sicInfo[1] : " "
    const sbi2DigitCode = inputSbiCode.slice(0, 2)
    const sbi2DigitCodeDescription = getSbiDescription(inputSbiCode.slice(0, 2), sbiToDescriptionMap)
    const sbiSectorCodeDescription = getSbiDescription(sbiSectorCode, sbiToDescriptionMap) !== " " ? getSbiDescription(sbiSectorCode, sbiToDescriptionMap) : sbiSectorCodeADesc
    let sbiFields = { "SBI": inputSbiCode, "SBI_DESCRIPTION": sbiDescription, "SBI_2_DIGIT_CODE": sbi2DigitCode, 
    "SBI_2_DIGIT_CODE_DESCRIPTION": sbi2DigitCodeDescription,"SBI_SECTOR_CODE": sbiSectorCode || " ","SBI_SECTOR_CODE_DESCRIPTION": sbiSectorCodeDescription,"INDUSTRY_CONFIDENCE_SCORE": evoOverrideConfidenceScore}
    let categoryPresent = isFieldPresent(rowToBeUpdated, "CATEGORY_L1")
    let sbiFieldsWithCategory = categoryPresent ? { ...sbiFields, "CATEGORY_L1": industryL1, "CATEGORY_L2": industryL2,"INDUSTRY_CONFIDENCE_SCORE": evoOverrideConfidenceScore } : sbiFields
    let fieldsToBeUpdated = !categoryPresent && isFieldPresent(rowToBeUpdated, "CE_INDUSTRY_L1",) ? { ...sbiFields, "CE_INDUSTRY_L1": industryL1 || " ", "CE_INDUSTRY_L2": industryL2 || " " ,"INDUSTRY_CONFIDENCE_SCORE":evoOverrideConfidenceScore} : categoryPresent ? sbiFieldsWithCategory : sbiFields
    updateFields(rowToBeUpdated, fieldsToBeUpdated)
    return { allRows: clonedRows, updatedRow: rowToBeUpdated, rowBeforeUpdate }
}
const updateEstablishedYearRange=(rows:IRow[][],identifier:string,identifierId:string,fields:string[],values:string[])=>{
    const correspondingField=`${fields[0]}_RANGE`
    const clonedRows=_.cloneDeep(rows)
    const empField=fields[0]
    let rowIndex=getIndex(clonedRows,identifier,identifierId)
    const rowToBeUpdated=clonedRows[rowIndex]
    const rowBeforeUpdate=_.cloneDeep(rowToBeUpdated)
    const inputYear=values[0]
    const fieldsToBeUpdated:{[key:string]:string}=isFieldPresent(rowToBeUpdated,correspondingField)?{[correspondingField]:"",[empField]:inputYear}:{[empField]:inputYear}
    if(isFieldPresent(rowToBeUpdated,correspondingField)){
        for(let index =0;index<establishmentYears.length;index++){
            if(parseInt(inputYear)<=establishmentYears[index] ||(parseInt(inputYear)>=establishmentYears[index] && parseInt(inputYear)<establishmentYears[index+1])){
                fieldsToBeUpdated[correspondingField]=establishmentYearRange[index]
                break
            } 
            if(index===establishmentYears.length-1){
                fieldsToBeUpdated[correspondingField]=establishmentYearRange[index]
            }
            
        }
    }
    updateFields(rowToBeUpdated,fieldsToBeUpdated)
    return {allRows:clonedRows,updatedRow:rowToBeUpdated,rowBeforeUpdate}
}
const updateRevenue=(rows:IRow[][],identifier:string,identifierId:string,fields:string[],values:string[])=>{
    const clonedRows=_.cloneDeep(rows)
    let rowIndex=getIndex(clonedRows,identifier,identifierId)
    const rowToBeUpdated=clonedRows[rowIndex]
    const rowBeforeUpdate=_.cloneDeep(rowToBeUpdated)
    const inputRevenue=values[0]
    const revenueField=fields[0]
    const fieldsToBeUpdated={[revenueField]:inputRevenue}
    updateFields(rowToBeUpdated,fieldsToBeUpdated)
    return {allRows:clonedRows,updatedRow:rowToBeUpdated,rowBeforeUpdate}
}
const updateZZP=(rows:IRow[][],identifier:string,identifierId:string,fields:string[],values:string[])=>{
    const clonedRows=_.cloneDeep(rows)
    let rowIndex=getIndex(clonedRows,identifier,identifierId)
    const rowToBeUpdated=clonedRows[rowIndex]
    const rowBeforeUpdate=_.cloneDeep(rowToBeUpdated)
    let fieldsToBeUpdated:{[key:string]:string}={"ZZP":values[0]}
    if(values[0]==="true"){
        for(let empColumn in employeeColumnNames){
            if(isFieldPresent(rowToBeUpdated,empColumn)){
                fieldsToBeUpdated={...fieldsToBeUpdated,[empColumn]:'1'}
            }
        }
    }
    updateFields(rowToBeUpdated,fieldsToBeUpdated)
    return {allRows:clonedRows,updatedRow:rowToBeUpdated,rowBeforeUpdate}
}
const updateWebsite=(rows:IRow[][],identifier:string,identifierId:string,fields:string[],values:string[])=>{
    const clonedRows=_.cloneDeep(rows)
    let rowIndex=getIndex(clonedRows,identifier,identifierId)
    const rowToBeUpdated=clonedRows[rowIndex]
    const rowBeforeUpdate=_.cloneDeep(rowToBeUpdated)
    const fieldName = fields[0]
    const fieldsToBeUpdated={[fieldName]:values[0]}
    updateFields(rowToBeUpdated,fieldsToBeUpdated)
    return {allRows:clonedRows,updatedRow:rowToBeUpdated,rowBeforeUpdate}
}
const updateCategory=(rows:IRow[][],identifier:string,identifierId:string,fields:string[],values:string[])=>{
    const clonedRows=_.cloneDeep(rows)
    let rowIndex=getIndex(clonedRows,identifier,identifierId)
    const rowToBeUpdated=clonedRows[rowIndex]
    const rowBeforeUpdate=_.cloneDeep(rowToBeUpdated)
    const fieldsToBeUpdated={"CATEGORY":values[0],"INDUSTRY_CONFIDENCE_SCORE": evoOverrideConfidenceScore}
    updateFields(rowToBeUpdated,fieldsToBeUpdated)
    return {allRows:clonedRows,updatedRow:rowToBeUpdated,rowBeforeUpdate}
}
const updateSubSegment=(rows:IRow[][],identifier:string,identifierId:string,fields:string[],values:string[])=>{
    const clonedRows=_.cloneDeep(rows)
    let rowIndex=getIndex(clonedRows,identifier,identifierId)
    const rowToBeUpdated=clonedRows[rowIndex]
    const rowBeforeUpdate=_.cloneDeep(rowToBeUpdated)
    const fieldsToBeUpdated={"SUB_SEGMENT":values[0]}
    updateFields(rowToBeUpdated,fieldsToBeUpdated)
    return {allRows:clonedRows,updatedRow:rowToBeUpdated,rowBeforeUpdate}
}

const employeeCountFieldsMap:{[key:string]:any} = {EMPLOYEES:updateEmployeeRange, EMPLOYEE_COUNT: updateEmployeeRange,
                             NUMBER_OF_EMPLOYEES: updateEmployeeRange}
const globalLocalCountMap={"GLOBAL_EMPLOYEE_COUNT":updateGlobalCount,"LOCAL_EMPLOYEE_COUNT":updateLocalCount}
const industryFieldsMap={"CE_INDUSTRY_L1": updateSbi, "CE_INDUSTRY_L2": updateSbi,"SBI":updateIndustryAndDescription,"CATEGORY_L1":updateSbi,"CATEGORY_L2":updateSbi,"INDUSTRY_CONFIDENCE_SCORE": evoOverrideConfidenceScore}
const establishedYearMap = {"ESTABLISHMENT_YEAR": updateEstablishedYearRange,
                                 "DATE_OF_ESTABLISHMENT": updateEstablishedYearRange,
                                 "YEAR_OF_ESTABLISHMENT": updateEstablishedYearRange}
const categoryFieldsMap={"CATEGORY":updateCategory}
const revenueMap = {"REVENUE_USD": updateRevenue, "REVENUE": updateRevenue, "REVENUE_IN_USD": updateRevenue,
               "REVENUE_IN_EUROS": updateRevenue}
const zzpMap = {"ZZP": updateZZP}
const websiteMap={"WEBSITE":updateWebsite,"COMPANY_WEBSITE":updateWebsite}
const subSegmentMap={"SUB_SEGMENT":updateSubSegment}
const coupledFields=[employeeCountFieldsMap,globalLocalCountMap,industryFieldsMap,establishedYearMap,revenueMap,zzpMap,websiteMap,categoryFieldsMap,subSegmentMap]
export const overwriteRow=(fields:string[],values:string[],identifier:string,identifierId:string,rows:IRow[][],mappings:IMapping)=>{
    for(let field of coupledFields){
        if (fields[0] in field){
             return  field[fields[0]](rows,identifier,identifierId,fields,values,mappings)
        }
    }
}
const getUpdatedColums = (fields: string[]) => {
		return fields
			.map((col) => {
				if (col.indexOf("L1") !== -1) return "L1";
				else if (col.indexOf("L2") !== -1) return "L2";
				else return _.startCase(col.toLowerCase());
			})
			.join(",");
	};
export const messageLabels = (fields:string[]) => {
			if (getUpdatedColums(fields) === "Year Of Establishment") {
				return "The value of Year of Establishment";
			} else if (getUpdatedColums(fields) === "Sbi") {
				return "The value of SBI";
			} else if (getUpdatedColums(fields) === "L1,L2") {
				return "The values of L1 & L2 industry";
			} else if (getUpdatedColums(fields) === "L2") {
				return "The value of L2 industry";
			} else {
				return `The value of ${getUpdatedColums(fields)}`;
			}
};
