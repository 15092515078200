import { Pagination, Select } from "@cimpress/react-components";
import { useState } from "react";
import {
  setPageSize,
  setPageNumber,
  setSelectedRowIndexes,
  setRows,
  setVisibleColumns,
  setTableHeaders,
} from "../../features/view/viewSlice";
import { useAppDispatch, useAppSelector } from "../../reduxHooks";
import "../../styles/pagination.css";
export function TablePagination(props: {
  getdata(pageNumber: number, pageSize: number): void;
}) {
  const pageNumber = useAppSelector((state) => state.view.pageNumber);
  const totalRecords = useAppSelector((state) => state.view.totalRecords);
  const pageSize = useAppSelector((state) => state.view.pageSize);
  const totalPages = Math.ceil(totalRecords / pageSize);
  const [selectedOption, setSelectedOption] = useState({
    label: pageSize.toString(),
    value: pageSize,
  });
  const dispatch = useAppDispatch();
  const onPageChange = ({ selected }: { selected: number }) => {
    dispatch(setPageNumber(selected));
    dispatch(setSelectedRowIndexes([]));

    props.getdata(selected, pageSize);

    dispatch(setRows([]));
    dispatch(setVisibleColumns([]));
    dispatch(setTableHeaders([]));
  };
  const onPageSizeChange = (selected: { label: string; value: number }) => {
    dispatch(setPageSize(selected.value));
    dispatch(setSelectedRowIndexes([]));
    setSelectedOption(selected);
    props.getdata(pageNumber, selected.value);
    dispatch(setRows([]));
    dispatch(setVisibleColumns([]));
    dispatch(setTableHeaders([]));
  };
  const pageSizes = [
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];
  return (
    <div className="pagination-wrapper">
      <div className="page-size-selector">
        <Select
          className="page-selector-dropdown"
          isClearable={false}
          label=""
          value={selectedOption}
          options={pageSizes}
          onChange={onPageSizeChange}
          helpText=""
          menuPlacement="auto"
          hideSelectedOptions={true}
          menuPosition="absolute"
        />
      </div>
      <div className="page-selector" style={{left:"78vw",width:"40vw"}}>
        <Pagination
          initialPage={0}
          pageCount={totalPages}
          onPageChange={onPageChange}
          pagesOutsideElipses={1}
          pagesBetweenElipses={3}
        />
      </div>
    </div>
  );
}
