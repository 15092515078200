import React from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useTable, useColumnOrder, useResizeColumns} from "react-table";
import { useSticky } from "react-table-sticky";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useFlexLayout } from "react-table";
import { ProspectorTableBody } from "./prospectorTableBody"
import { ProspectorTableHeader } from "./prospectorTableHeader"
import "../styles/prospectorTable.css"

export const ProspectorTable = ({ columns, data }:any) => {
    const columnOrder = ["companyLogo","companyName","emailId","companyWebiste","countryCode",...columns.map((col:any) => col.accessor)]
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable(
        {
          columns,
          data,
          initialState:{columnOrder}
        },
        useResizeColumns,
        useFlexLayout,
        useColumnOrder,
        useSticky
      );
    return (
        <div className="prospector-table-container-wrapper">
        <div className="prospector-table-wrapper">
      <table {...getTableProps()} className="prospector-table-class" id="prospector-table-class">
        <ProspectorTableHeader headerGroups={headerGroups}/>
        <ProspectorTableBody rows={rows}
						getTableBodyProps={getTableBodyProps}
						prepareRow={prepareRow} />
        
      </table>
      </div></div>
    );
  };
