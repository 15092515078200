import { Tooltip } from "@cimpress/react-components";
import { IFilter, IViewType } from "../../types";
import "./tooltip.css";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import IconInformationCircle from "@cimpress-technology/react-streamline-icons/lib/IconInformationCircle";
import _ from "lodash";
const ToolTipContent = (props: { data: IViewType }) => {
  const visibleColumns = props.data.selectedHeaders || [];
  const savedFilters = props.data.savedFilters || [];
  const unsavedFilters = props.data.unsavedFilters || [];
  const createdAt = props.data.createdAt;
  return (
    <div className="view-option-tooltip-content">
      <div
        className="tooltip-dropdown-column-content"
        style={{ paddingBottom: "4px" }}
      >
        Visible Columns (Including input columns):
      </div>
      {visibleColumns.length === 0 && (
        <div className="tooltip-dropdown-column-content"> ALL</div>
      )}
      {visibleColumns.map((col) => (
        <div className="tooltip-dropdown-column-content">
          {_.startCase(col)}
        </div>
      ))}
      {savedFilters.length ||
        (unsavedFilters.length > 0 && (
          <div style={{ marginTop: "5px"}}>Applied filters:</div>
        ))}
      {savedFilters.map((filter: any) => (
        <div className="tooltip-dropdown-column-content">{filter.name}</div>
      ))}
      {unsavedFilters.map((filter: IFilter) => (
        <div className="tooltip-dropdown-column-content">
          {filter.column} {filter.operator} {filter.value[0]}
        </div>
      ))}
      <div className="view-info">
        <IconInformationCircle></IconInformationCircle> View Created on {createdAt}
      </div>
    </div>
  );
};
export function LabelWrapper(props: { data: IViewType; index: Number }) {
  return (
    <>
      <Tooltip
        className="views-tooltip"
        direction="bottom"
        contents={<ToolTipContent data={props.data} />}
      >
        <div id={`view-dropdown-item-${props.index}`}>{props.data.name}</div>
      </Tooltip>
    </>
  );
}
